import api from '../utils/api'

export const addDaypart = () => dispatch => {
    dispatch({
      type: 'ADD_DAYPART_ACTION'
    })
}

export const getAppointmentsAction = (projectid) => dispatch => {

    dispatch({
        type: 'APPOINTMENTS_ACTION_FETCH'
    })

    return api('get','/appointments/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTS_ACTION_ERROR',
        })
        return false;
    });
}

export const getAppointmentAction = (appointmentid) => dispatch => {

    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('get','/appointment/'+appointmentid, null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const addAppointmentAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('post','/appointments/'+projectid, params, true).then((response) => {
        dispatch( {
            type: 'ADD_APPOINTMENT_ACTION_SUCCESS',
            payload: response.data
        } );
        return response;
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const resetAppointmentAction = () => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_RESET'
    })
}

export const patchAppointmentAction = (appointmentid, params, dry_run = true) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_UPDATE_ACTION_FETCH'
    })

    return api('post','/appointment/'+appointmentid+'/'+(dry_run === false ? 'false' : 'true'), params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_UPDATE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_UPDATE_ACTION_ERROR',
        })
        return false;
    });
}

export const clustersAppointmentAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'CLUSTERS_APPOINTMENT_ACTION_FETCH'
    })

    return api('post','/appointment/'+appointmentid+'/clusters', params, true).then((response) => {
        dispatch({
            type: 'CLUSTERS_APPOINTMENT_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'CLUSTERS_APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const getAppointmentScheduleAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('get','/appointment/' + appointmentid + '/schedule', params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_SCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

// export const getAppointmentFreeSlotsAction = (appointmentid, params) => dispatch => {
export const getAppointmentFreeSlotsAction = (cluster_address_id, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_FREESLOTS_ACTION_FETCH'
    })
    return api('get','/appointment/cluster-address/' + cluster_address_id + '/free-slots', params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_FREESLOTS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const getAppointmentLabelsAction = (appointment_id) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_LABEL_SUGGESTIONS_ACTION_FETCH'
    })
    return api('get','/appointment/' + appointment_id + '/labels', null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_LABEL_SUGGESTIONS_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_LABEL_SUGGESTIONS_ACTION_ERROR',
        })
        return false;
    });
}

export const applyAppointmentScheduleAction = (appointmentid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })

    return api('post','/appointment/'+appointmentid+'/schedule', params, true).then((response) => {
        dispatch({
            type: 'APPLY_APPOINTMENT_SCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const updateAppointmentScheduleAction = (appointment_id, address_id, params) => dispatch => {
    dispatch({
        type: 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_FETCH'
    })

    return api('post', '/appointment/'+appointment_id+'/schedule/'+address_id, params, true).then((response) => {

        // console.log('/appointment/'+appointment_id+'/schedule/'+address_id, params, response);

        dispatch({
            type: 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const addAppointmentAddressNoteAction = (appointment_id, address_id, params) => dispatch => {
    dispatch({
        type: 'ADD_APPOINTMENT_ADDRESS_NOTE_ACTION_FETCH'
    })

    return api('post', '/appointment/'+appointment_id+'/schedule/'+address_id+'/note', params, true).then((response) => {
        dispatch({
            type: 'ADD_APPOINTMENT_ADDRESS_NOTE_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'ADD_APPOINTMENT_ADDRESS_NOTE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateAppointmentAddressLabelsAction = (appointment_id, address_id, labels) => dispatch => {
    dispatch({
        type: 'UPDATE_APPOINTMENT_ADDRESS_LABELS_ACTION_FETCH'
    })

    return api('post', '/appointment/'+appointment_id+'/schedule/'+address_id+'/labels', { labels: JSON.stringify(labels) }, true).then((response) => {
        dispatch({
            type: 'UPDATE_APPOINTMENT_ADDRESS_LABELS_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'UPDATE_APPOINTMENT_ADDRESS_LABELS_ACTION_ERROR',
        })
        return false;
    });
}

export const removeAppointmentsAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENTS_REMOVE_ACTION_FETCH'
    })
    return api('post','/appointments/'+projectid+'/multidelete/', params, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTS_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTS_REMOVE_ACTION_ERROR',
        })
        return false;
    });
}

export const scheduleAddressesAction = (appointmentid, params = null) => dispatch => {
    dispatch({
        type: 'SCHEDULE_APPOINTMENT_FETCH'
    })
    return api((!params ? 'get' : 'post'),'/appointment/'+appointmentid+'/schedule-addresses', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_APPOINTMENT_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_APPOINTMENT_ERROR',
        })
        return false;
    });
}

export const searchScheduleAddressesAction = (appointmentid, search) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })
    return api('post','/appointment/'+appointmentid+'/schedule-addresses/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_APPOINTMENT_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const downloadAppointmentAction = (appointmentid) => dispatch => {
    dispatch({
        type: 'APPOINTMENT_ACTION_FETCH'
    })
    return api('get','/appointment/'+appointmentid+'/download-ics', null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENT_DOWNLOAD_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENT_ACTION_ERROR',
        })
        return false;
    });
}

export const requestExportAppointmentAction = (id) => dispatch => {

  dispatch({
    type: 'APPOINTMENT_ACTION_FETCH'
  })

  return api('get', 'appointment/'+id+'/request/export', null, true).then((response) => {
    dispatch({
      type: 'APPOINTMENT_EXPORT_REQUEST_ACTION_SUCCESS',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'APPOINTMENT_ACTION_ERROR',
    })
    return false;
  });
}

export const getAppointmentsUsersAction = (project_id) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_USERS_ACTION_FETCH'
    })

    return api('get', 'appointments/'+project_id+'/users', null, true).then((response) => {
      dispatch({
        type: 'APPOINTMENTS_USERS_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_USERS_ACTION_FETCH_ERROR',
      })
      return false;
    });
}

export const getAppointmentsUserAction = (project_id, user_id) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_USER_ACTION_FETCH'
    });

    return api('get', 'appointments/'+project_id+'/user/'+user_id, null, true).then((response) => {
      dispatch({
        type: 'APPOINTMENTS_USER_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_USER_ACTION_FETCH_ERROR',
      })
      return false;
    });
}

export const addAppointmentsUserAvailabilityAction = (project_id, user_id, values) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_USER_ADD_ACTION'
    });

    return api('post', 'appointments/'+project_id+'/user/'+user_id+'/availability/add', values, true).then((response) => {
      dispatch({
        type: 'APPOINTMENTS_USER_ADD_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_USER_ADD_ACTION_ERROR',
      });
      return false;
    });
}

export const updateAppointmentsUserAvailabilityAction = (project_id, user_id, id, values) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_USER_UPDATE_ACTION'
    });

    return api('post', 'appointments/'+project_id+'/user/'+user_id+'/availability/'+id, values, true).then((response) => {
      dispatch({
        type: 'APPOINTMENTS_USER_UPDATE_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_USER_UPDATE_ACTION_ERROR',
      });
      return false;
    });
}

export const deleteAppointmentsUserAvailabilitiesAction = (project_id, user_id, ids) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_USER_UPDATE_ACTION'
    });

    return api('post', 'appointments/'+project_id+'/user/'+user_id+'/availability/delete', { ids: ids }, true).then((response) => {
      dispatch({
        type: 'APPOINTMENTS_USER_UPDATE_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_USER_UPDATE_ACTION_ERROR',
      });
      return false;
    });
}

export const getAppointmentsStatusAction = (project_id) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_STATUS_ACTION_FETCH'
    })

    return api('get', 'appointments/'+project_id+'/status', null, true).then((response) => {
      dispatch({
        type: 'APPOINTMENTS_STATUS_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_STATUS_ACTION_FETCH_ERROR',
      })
      return false;
    });
}

export const searchAppointmentsStatusAction = (project_id, params) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_STATUS_ACTION_FETCH'
    });

    return api('post', 'appointments/'+project_id+'/status/search', params, true).then((response) => {
      dispatch({
        type: 'APPOINTMENTS_STATUS_ACTION_SUCCESS',
        payload: response.data
      });

      return response.data;
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_STATUS_ACTION_FETCH_ERROR',
      });

      return false;
    });
}

export const getAppointmentsCalendarAction = (project_id, filter = null) => dispatch => {

    dispatch({
      type: 'APPOINTMENTS_CALENDAR_ACTION_FETCH'
    })

    return api('post', 'appointments/'+project_id+'/calendar', filter, true).then((response) => {
      if(!filter)
      {
          dispatch({
            type: 'APPOINTMENTS_CALENDAR_ACTION_SUCCESS',
            payload: response.data
          });
      }
      else
      {
          dispatch({
            type: 'APPOINTMENTS_CALENDAR_FILTER_ACTION_SUCCESS',
            payload: response.data
          });
      }
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENTS_CALENDAR_ACTION_FETCH_ERROR',
      })
      return false;
    });
}

export const saveAppointmentAddressesAction = (appointment_id, ids, dry_run = true) => dispatch => {

    dispatch({
      type: 'APPOINTMENT_ADDRESSES_UPDATE_ACTION'
    });

    return api('post', 'appointment/'+appointment_id+'/addresses/'+(dry_run !== false ? 'true' : 'false'), { ids: ids }, true).then((response) => {
      dispatch({
        type: 'APPOINTMENT_ADDRESSES_UPDATE_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENT_ADDRESSES_UPDATE_ACTION_ERROR',
      });
      return false;
    });
}

export const addAppointmentUserAvailabilityAction = (appointment_id, values) => dispatch => {

    dispatch({
      type: 'APPOINTMENT_ADD_USER_ACTION'
    });

    return api('post', 'appointment/'+appointment_id+'/user/add', values, true).then((response) => {
      dispatch({
        type: 'APPOINTMENT_ADD_USER_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENT_ADD_USER_ACTION_ERROR',
      });
      return false;
    });
}

export const updateAppointmentUserAvailabilityAction = (appointment_id, user_id, values, dry_run = true) => dispatch => {

    dispatch({
      type: 'APPOINTMENT_UPDATE_USER_ACTION'
    });

    return api('post', 'appointment/'+appointment_id+'/user/'+user_id+'/availability/'+(dry_run !== false ? 'true' : 'false'), values, true).then((response) => {
      dispatch({
        type: 'APPOINTMENT_UPDATE_USER_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENT_UPDATE_USER_ACTION_ERROR',
      });
      return false;
    });
}

export const deleteAppointmentUsersAvailabilityAction = (appointment_id, ids, dry_run = true) => dispatch => {

    dispatch({
      type: 'APPOINTMENT_DELETE_USERS_UPDATE_ACTION'
    });

    return api('post', 'appointment/'+appointment_id+'/users/delete/'+(dry_run !== false ? 'true' : 'false'), { ids: ids }, true).then((response) => {
      dispatch({
        type: 'APPOINTMENT_DELETE_USERS_UPDATE_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'APPOINTMENT_DELETE_USERS_UPDATE_ACTION_ERROR',
      });
      return false;
    });
}

export const getSlotsCounterAppointmentAction = (appointment_id, values) => dispatch => {

    dispatch({
      type: 'SLOTS_COUNTER_APPOINTMENT_ACTION_FETCH'
    });

    return api('post', 'appointment/'+appointment_id+'/slots-counter/appointment', values, true).then((response) => {
      dispatch({
        type: 'SLOTS_COUNTER_APPOINTMENT_ACTION_SUCCESS',
        payload: response.data
      });
    }).catch((e) => {
      dispatch({
        type: 'SLOTS_COUNTER_APPOINTMENT_ACTION_ERROR',
      });
      return false;
    });
}

export const getSlotsCounterClusterAction = (appointment_id, cluster_nr, values) => dispatch => {

    dispatch({
      type: 'SLOTS_COUNTER_CLUSTER_ACTION_FETCH'
    });

    return api('post', 'appointment/'+appointment_id+'/slots-counter/cluster/'+cluster_nr, values, true).then((response) => {
      dispatch({
        type: 'SLOTS_COUNTER_CLUSTER_ACTION_SUCCESS',
        payload: response.data
      });

      return response.data;
    }).catch((e) => {
      dispatch({
        type: 'SLOTS_COUNTER_CLUSTER_ACTION_ERROR',
      });
      return false;
    });
}

export const resetMadeAppointmentsAction = (appointment_id, params) => dispatch => {

    dispatch({
      type: 'RESET_MADE_APPOINTMENTS_ACTION_FETCH'
    });

    return api('post', 'appointment/'+appointment_id+'/reset-made-appointments', params, true).then((response) => {
      dispatch({
        type: 'RESET_MADE_APPOINTMENTS_ACTION_SUCCESS',
        payload: response.data
      });

      return response.data;
    }).catch((e) => {
      dispatch({
        type: 'RESET_MADE_APPOINTMENTS_ACTION_ERROR',
      });
      return false;
    });
}
