import React, { Component } from 'react';
// import './ProjectsSettings.css';
import ProjectSidemenu from './ProjectSidemenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProjectAction, patchProjectAction, /*syncProjectModulesAction,*/ getProjectModulesAction, updateProjectModulesAction } from '../../../actions/projectActions';
import { showSuccess } from '../../../utils/Notifications';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, Input, Tooltip } from 'antd';
import _ from 'underscore';

const { Title, Text } = Typography;

class ProjectModules extends Component {

  state = {
    edit: true,
    module_18: null,
    loading: true
  };

  componentDidMount() {

    this.props.getProjectModulesAction(this.props.match.params.id).then(() => {

      this.props.modules.map((module) => {
        this.setState({ ['module_' + module.id]: module.active ? true : false });
      });

      this.setState({ loading: false });
    });
  }

  handleSubmit = e => {

    this.props.form.validateFields((err, values) => {

      if (!err) {
        var result = Object.keys(values).filter((key) => {

          if(values[key] === true) {

            return key;
          }

          return null;
        });

        this.setState({ loading: true });

        this.props.updateProjectModulesAction(this.props.match.params.id, {'ids': result, module_18_name: values.module_18_name }).then(() => {

            showSuccess();

            // this.props.getProjectAction(this.props.match.params.id).then(() => {

              this.props.getProjectModulesAction(this.props.match.params.id).then(() => {

                this.setState({ loading: false });
              });
            // });

            // this.props.getProjectModulesAction(this.props.match.params.id).then(() => {});

            //     // showSuccess();
            // });
        });
      }
    });

  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ProjectSidemenu id={this.props.match.params.id} activeMenu={["3"]} {...this.props}/>

          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>

            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('project-modules', 'write')} type="new" onClick={() => this.handleSubmit(null)}>Opslaan</Button>
                </div>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>

              { !this.state.loading && this.props.modules && this.props.modules.map && this.props.modules.map((module) => {

                if(module.parent_id == null)
                {
                  return <Col span={12}>
                    <div>
                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Text strong>{module.display_name}</Text>
                            { module.is_default != '1' ?
                            <Tooltip class="mhs" title="Voor deze module worden extra kosten in rekening gebracht zolang deze actief is.">
                                &nbsp;<InfoCircleTwoTone />
                            </Tooltip>
                            :
                            <Tooltip class="mhs" title="Dit is de basis module voor het project. Deze kan niet aan of uitgezet worden. In de basis zitten de volgende modules: adressen, correspondentie en de project media bibliotheek.">
                                &nbsp;<InfoCircleTwoTone />
                            </Tooltip> }
                            <br />
                            {getFieldDecorator(String(module.id), {
                              valuePropName: 'checked',
                              initialValue: (module.is_default || module.active ? true : false),
                              onChange: (data) => { this.setState({ ['module_'+module.id]: data });  }
                            })(
                              <Switch
                                disabled={module.is_default == '1' ? true : false}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ display: (this.state['module_' + module.id] || module.is_default == '1' ? 'block' : 'none') }}>
                        { this.props.modules.map((sub_module, sub_key) => {

                          if(sub_module.parent_id == module.id)
                          {
                            return (<Row>
                              <Col span={24}>
                                <Form.Item>
                                  <Text>{sub_module.display_name}</Text>
                                  { sub_module.name == 'export' ?
                                  (<Tooltip class="mhs" title="Alleen van toepassing op de modules waar de gebruiker toegang tot heeft en welke een export mogelijkheid bieden.">
                                      &nbsp;<InfoCircleTwoTone />
                                  </Tooltip>) : null }
                                  <br />
                                  {getFieldDecorator(String(sub_module.id), {
                                    valuePropName: 'checked',
                                    initialValue: (sub_module.is_default || sub_module.active ? true : false),
                                    onChange: (data) => { this.setState({ ['module_'+sub_module.id]: data });  }
                                  })(
                                    <Switch
                                      disabled={sub_module.is_default == '1' ? true : false}
                                      checkedChildren={<EyeOutlined />}
                                      unCheckedChildren={<EyeInvisibleOutlined />}
                                      />
                                  )}
                                </Form.Item>
                              </Col>
                              { this.props.modules.map((sub_sub_module, sub_sub_key) => {

                                if(sub_sub_module.parent_id == sub_module.id)
                                {
                                    return (<Col span={24}>
                                      <Form.Item>
                                        <Text>{sub_sub_module.display_name}</Text>
                                        <br />
                                        {getFieldDecorator(String(sub_sub_module.id), {
                                          valuePropName: 'checked',
                                          initialValue: (sub_sub_module.is_default || sub_sub_module.active ? true : false),
                                          onChange: (data) => { this.setState({ ['module_'+sub_sub_module.id]: data });  }
                                        })(
                                          <Switch
                                            // disabled={sub_sub_module.is_default == '1' ? true : false}
                                            checkedChildren={<EyeOutlined />}
                                            unCheckedChildren={<EyeInvisibleOutlined />}
                                            />
                                        )}
                                      </Form.Item>
                                    </Col>)
                                }
                              })/**/}
                            </Row>)
                          }
                        })}
                      </Row>
                    </div>
                  </Col>
                }

              }) }
            </Row>

            { this.state['module_18'] ?
            <div>
                <Divider></Divider>
                <Row gutter={24}>
                  <Col span={24}>
                    <Title level={4}>Eigen module</Title>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Naam" hasFeedback>
                      {getFieldDecorator('module_18_name', {
                        initialValue: String(this.props.project.module_18_name ? this.props.project.module_18_name : 'Eigen module'),
                      })(
                        <Input maxLength={24} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
            </div> : null }

          </Form>
        </div>
      </div>
    );
  }
}

const ProjectModulesForm = Form.create({ name: 'project_modules' })(ProjectModules);
const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.project.isFetching,
    isError: state.project.isError,
    project: state.project.project,
    modules: state.project.modules,
    customer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    patchProjectAction : bindActionCreators(patchProjectAction, dispatch),
    // syncProjectModulesAction : bindActionCreators(syncProjectModulesAction, dispatch),
    getProjectModulesAction : bindActionCreators(getProjectModulesAction, dispatch),
    updateProjectModulesAction : bindActionCreators(updateProjectModulesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModulesForm);
