export default (state = { 
    isError: false, 
    isFetching: false, 
    default_prices: {},
    customer_prices: {},
    exact_organisations: [],
}, action) => {
    switch (action.type) {
    case 'INVOICE_ACTION_FETCH':
    return {
        ...state,
        // default_prices: {},
        // customer_prices: {},
        isFetching: true,
        isError: false,
    }
    case 'INVOICE_DEFAULT_PRICES_ACTION_SUCCESS':
    return {
        ...state,
        default_prices: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INVOICE_SETTINGS_CUSTOMER_GET_ACTION_SUCCESS':
    return {
        ...state,
        customer_prices: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INVOICE_SETTINGS_CUSTOMER_UPDATE_ACTION_SUCCESS':
    return {
        ...state,
        customer_prices: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INVOICE_EXACT_ORGANISATIONS_GET_ACTION_SUCCESS':
    return {
        ...state,
        exact_organisations: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'INVOICE_ACTION_RESET':
    return {
        ...state,
        default_prices: {},
        customer_prices: {},
        exact_organisations: [],
        isFetching: false,
        isError: false,
    }
    case 'INVOICE_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
