import api from '../utils/api'

export const getCustomerAppointmentUsersAction = (customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    return api('get','/appointment-users/customer/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const searchCustomerAppointmentUsersAction = (search, customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    return api('post','/appointment-users/customer/'+customer_id+'/search', { search: search }, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const getAppointmentUserAction = (appointment_user_id) => dispatch => {

    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    return api('get','/appointment-user/'+appointment_user_id, null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const addCustomerAppointmentUserAction = (params, customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'APPOINTMENTUSER_ACTION_FETCH'
    })

    return api('post','/appointment-users/customer/'+customer_id+'/add', params, true).then((response) => {
        dispatch({
            type: 'ADD_APPOINTMENTUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const updateAppointmentUserAction = (id, params) => dispatch => {

    dispatch({
        type: 'APPOINTMENTUSER_ACTION_FETCH'
    })

    return api('post','/appointment-user/'+id+'/update', params, true).then((response) => {
        dispatch({
            type: 'UPDATE_APPOINTMENTUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_ERROR',
        })
        return false;
    });
}/**/

export const resetAppointmentUsersAction = (appointment_user_id = false) => dispatch => {

    dispatch({
        type: 'RESET_APPOINTMENTUSERS_ACTION_FETCH'
    })
}

export const resetAppointmentUserAction = (appointment_user_id = false) => dispatch => {

    dispatch({
        type: 'RESET_APPOINTMENTUSER_ACTION_FETCH'
    })
}

export const getAppointmentUsersLibraryAction = () => (dispatch, getState) => {

    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('get','/library/appointment-users/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}

export const getAppointmentUserLibraryAction = (appointment_user_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'APPOINTMENTUSER_ACTION_FETCH'
    })

    return api('get','/library/appointment-user/'+appointment_user_id, params, true).then((response) => {
        dispatch({
          type: 'GET_APPOINTMENTUSER_LIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_ERROR',
        })
        return false;
    });
}

export const searchAppointmentUsersLibraryAction = (search) => (dispatch, getState) => {

    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    var customer_id = false;

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/appointment-users/'+customer_id+'/search', {search: search}, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}

export const addAppointmentUserLibraryAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'APPOINTMENTUSER_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/appointment-users/'+customer_id, params, true).then((response) => {
        dispatch({
            type: 'ADD_APPOINTMENTUSER_LIBRARY_ACTION_SUCCESS',
            payload: response.data.appointment_user
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_ERROR',
        })
        return false;
    });
}

export const patchAppointmentUserLibraryAction = (appointment_user_id, params) => (dispatch, getState) => {

    dispatch({
        type: 'APPOINTMENTUSER_ACTION_FETCH'
    })

    return api('post','/library/appointment-user/'+appointment_user_id, params, true).then((response) => {
        dispatch({
            type: 'PATCH_APPOINTMENTUSER_LIBRARY_ACTION_SUCCESS',
            payload: response.data.appointment_user
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_ERROR',
        })
        return false;
    });
}

export const removeAppointmentUsersLibraryAction = (params) => (dispatch, getState) => {

    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    var customer_id = '';

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer)
    {
        customer_id = getState().auth.selectedCustomer.id;
    }

    return api('post','/library/appointment-users/'+customer_id+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'REMOVE_APPOINTMENTUSERS_LIBRARY_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}

export const duplicateAppointmentUserLibraryAction = (project_id, appointment_user_id) => (dispatch, getState) => {

    dispatch({
        type: 'APPOINTMENTUSER_ACTION_FETCH'
    })

    return api('post','/library/appointment-user/'+appointment_user_id+'/clone', {project_id: project_id}, true).then((response) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_SUCCESS',
            // payload: response.data.appointment_user
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_ERROR',
        })
        return false;
    });
}

/*export const patchAppointmentUserAction = (appointment_user_id, params, file) => dispatch => {
    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    return api('post','/appointment-user/'+appointment_user_id, params, true, file).then((response) => {
        dispatch({
            type: 'APPOINTMENTUSER_ACTION_SUCCESS',
            payload: response.data.appointment_user
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

/*export const removeAppointmentUsersAction = (projectid, params) => dispatch => {
    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    return api('post','/appointment-users/'+projectid+'/multidelete', params, true).then((response) => {
        dispatch({
            type: 'REMOVE_APPOINTMENTUSER_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/

/*export const updateAppointmentUsersOrderAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'APPOINTMENTUSERS_ACTION_FETCH'
    })

    return api('post','/appointment-users/order/'+projectid, params, true).then((response) => {
        dispatch({
            type: 'SORT_APPOINTMENTUSERS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'APPOINTMENTUSERS_ACTION_ERROR',
        })
        return false;
    });
}/**/
