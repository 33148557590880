export default (state = {isError: false, isFetching: false, appointment_users: [], appointment_user: {email_address: ''}, globalAppointmentUsers: [], globalAppointmentUser: {}}, action) => {
    switch (action.type) {
    case 'APPOINTMENTUSERS_ACTION_FETCH':
    return {
        ...state,
        appointment_users: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENTUSER_ACTION_FETCH':
    return {
        ...state,
        appointment_user: { email_address: '' },
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENTUSERS_ACTION_SUCCESS':
    return {
        ...state,
        appointment_users: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'GLOBAL_APPOINTMENTUSERS_ACTION_SUCCESS':
    return {
        ...state,
        globalAppointmentUsers: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SORT_APPOINTMENTUSERS_ACTION_SUCCESS':
    return {
        ...state,
        //appointment_users: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENTUSER_ACTION_SUCCESS':
    return {
        ...state,
        appointment_user: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CLONE_APPOINTMENTUSER_ACTION_SUCCESS':
    return {
        ...state,
        appointment_users: [action.payload, ...state.appointment_users],
        isFetching: false,
        isError: false,
    }
    case 'GET_APPOINTMENTUSER_LIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        appointment_user: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_APPOINTMENTUSER_ACTION_SUCCESS':
    return {
        ...state,
        appointment_users: [action.payload, ...state.appointment_users],
        appointment_user: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_APPOINTMENTUSER_ACTION_SUCCESS':
    return {
        ...state,
        appointment_users: state.appointment_users.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'REMOVE_APPOINTMENTUSERS_LIBRARY_ACTION_SUCCESS':
    return {
        ...state,
        appointment_users: state.appointment_users.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'RESET_APPOINTMENTUSERS_ACTION_FETCH':
    return {
        ...state,
        appointment_users: [],
        isFetching: false,
        isError: false,
    }
    case 'RESET_APPOINTMENTUSER_ACTION_FETCH':
    return {
        ...state,
        appointment_user: {},
        isFetching: false,
        isError: false,
    }
    case 'SORT_APPOINTMENTUSERS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENTUSERS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    default:
        return state
    }
}
