import React, { Component } from 'react';
// import './ProjectsSettings.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getProjectAction, statusProjectsAction, patchProjectAction } from '../../../actions/projectActions';
import { getCustomersAction, getCustomerAction } from '../../../actions/customerActions';
import { getAccountsAction } from '../../../actions/accountActions';
import { getClientsAction } from '../../../actions/clientsActions';
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import ProjectSidemenu from './ProjectSidemenu';
import { showSuccess } from '../../../utils/Notifications'
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/nl';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';

import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Select,
  Tooltip,
  Switch,
  Checkbox,
  TimePicker,
  DatePicker,
} from 'antd';

dayjs.locale('nl');

const { Title, Text } = Typography;

const { TextArea } = Input;

const Option = Select.Option;

class ProjectsSettings extends Component {
  state = {
    disabled: true,
    edit: true,
    status: null
  };

  componentDidMount() {

    this.props.getProjectAction(this.props.match.params.id).then(() => {

      this.setState({ status: this.props.project.status });

      this.props.getCustomerAction(this.props.project.customer_id);

      this.setState({ use_wocoapp: this.props.project.use_wocoapp });

      this.props.getAccountsAction();

      this.props.getClientsAction();

      if(this.props.HaveRole(['super-admin'])) {
        this.props.getCustomersAction();
      }
    });
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit = e => {

    e.preventDefault();

      this.props.form.validateFields((err, values) => {

        if (!err) {

          if(values.cleanup_at)
          {
              values.cleanup_at = values.cleanup_at.format('YYYY-MM-DD');
          }

          // merge e-mail(s)
          if(Array.isArray(values.mail_occupants_messages_to))
          {
              values.mail_occupants_messages_to = values.mail_occupants_messages_to.join(',');
          }

          this.props.patchProjectAction(this.props.match.params.id, values, this.state.filelist).then(() => {
            showSuccess();
          });
        }
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <ProjectSidemenu id={this.props.match.params.id} activeMenu={["1"]} {...this.props}/>
          <Form layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('project-settings', 'write')}  type="new" onClick={this.handleSubmit}>Opslaan</Button>
                </div>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Projectgegevens</Title>
              </Col>
              <Col span={10}>
                {this.state.edit ? (
                  <Form.Item required label="Projectnaam" hasFeedback>
                    {getFieldDecorator('name', {
                      initialValue: this.props.project.name,
                      rules: [{ required: true, message: 'Vul een projectnaam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Projectnaam</Text>
                    <br />
                    <Text type="secondary">{this.props.project.name}</Text>
                  </div>
                )}
              </Col>
              <Col span={10}>
                {this.state.edit ? (
                  <Form.Item label="Interne naam" hasFeedback>
                    {getFieldDecorator('internal_name', {
                      initialValue: this.props.project.internal_name,
                      // rules: [{ required: true, message: 'Vul een projectnaam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Projectnaam</Text>
                    <br />
                    <Text type="secondary">{this.props.project.name}</Text>
                  </div>
                )}
              </Col>
              <Col span={4}>
                {this.state.edit ? (
                  <Form.Item required label="Projectnummer" hasFeedback>
                    {getFieldDecorator('project_number', {
                      initialValue: this.props.project.project_number,
                      rules: [{ required: true, message: 'Vul een projectnummer in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Projectnummer</Text>
                    <br />
                    <Text type="secondary">{this.props.project.project_number}</Text>
                  </div>
                )}
              </Col>

              <Col span={12}>
                {this.props.HaveRole(['super-admin']) ? (
                  <Form.Item required label="Aangemaakt door bedrijf" hasFeedback>
                    {getFieldDecorator('customer_id', {
                      initialValue: this.props.project.customer_id,
                      rules: [{ required: true, message: 'Selecteer een bedrijf' }],
                    })(
                      <Select
                        placeholder="Selecteer een bedrijf"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        {this.props.customers.map((v, k) => (
                          <Option key={v.id} value={v.id}>{v.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : null }
              </Col>
              <Col span={12}>
                {this.props.HaveRole(['super-admin']) ? (
                  <Form.Item required label="Aangemaakt door" hasFeedback>
                    {getFieldDecorator('user_id', {
                      initialValue: this.props.project.user_id,
                      rules: [{ required: true, message: 'Selecteer een account' }],
                    })(
                      <Select
                        showSearch
                        placeholder="Selecteer een account"
                        optionFilterProp="children"
                        >
                        {this.props.accounts.map((v, k) => (
                          <Option key={v.id} value={v.id}>{v.email}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  ) : null }
              </Col>
              <Col span={12}>
                <Form.Item label={this.props.selectedCustomer.package_id == 3 ? 'Aannemers' : 'Opdrachtgever'} hasFeedback>
                  {getFieldDecorator('client_id', {
                    initialValue: this.props.project.client_id,
                  })(
                    <Select
                      allowClear
                      showSearch
                      placeholder="Selecteer een opdrachtgever"
                      optionFilterProp="children"
                      >
                      {this.props.clients.map((v, k) => (
                        <Option key={v.id} value={v.id}>{v.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label={(
                    <span>
                    Meldingen project mailen naar
                    <Tooltip className="mhs" title='Vul hier een of meerdere e-mailadressen in van degenen die een melding mogen ontvangen over activiteiten in het project. Activiteiten: nieuw bericht, enquete ingevuld, afspraak gemaakt of gewijzigd. Meerdere e-mailadressen scheiden met een komma (, ).'>
                      <InfoCircleTwoTone />
                    </Tooltip>
                    </span>
                  )} hasFeedback>
                    {getFieldDecorator('mail_occupants_messages_to', {
                      initialValue: this.props.project.mail_occupants_messages_to.split(','),
                      rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                    })(
                      <Select mode="tags" open={false} listHeight={0} tokenSeparators={[',']} style={{ width: '100%' }} onChange={(value) => { this.setState({mail_occupants_messages_to: value.join(',')}); }}></Select>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Berichten van bewoners mailen naar</Text>
                    <br />
                    <Text type="secondary">{this.props.project.mail_occupants_messages_to}</Text>
                  </div>
                )}
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Facturatie</Title>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item label="Eigen factuur kenmerk" hasFeedback>
                    {getFieldDecorator('invoice_reference', {
                      initialValue: this.props.project.invoice_reference,
                      rules: [{ required: false, message: 'Geef een factuur kenmerk op voor dit project' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Eigen factuur kenmerk</Text>
                    <br />
                    <Text type="secondary">{this.props.project.invoice_reference}</Text>
                  </div>
                )}
              </Col>
              <Col span={12}>
                  <Form.Item label="Project apart factureren:">
                      {getFieldDecorator('separate_invoice', {
                        valuePropName: 'checked',
                        initialValue: this.props.project.separate_invoice
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      )}
                  </Form.Item>
                </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Status</Title>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Huidige status" hasFeedback>
                    {getFieldDecorator('status', {
                      initialValue: String(this.props.project.status),
                      onChange: (value) => { this.setState({ status: value }); }
                    })(
                      <Select
                        placeholder="Huidige status"
                        >
                        <Option value="1">Open</Option>
                        <Option value="2">Afgerond</Option>
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Huidige status</Text>
                    <br />
                    <Text type="secondary">{String(this.props.project.status) === "1" ? "Open" : "Afgerond"}</Text>
                  </div>
                )}
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label={(
                    <span>
                    Type project
                    <Tooltip className="mhs" title='Als een project op Privé ingesteld staat, krijgen alle adressen een persoonlijke inlog. Wanneer er wordt gekozen voor Openbaar, zal de informatie van het project openbaar zijn. Een project kan alleen openbaar gemaakt worden als er een eigen app gebruikt wordt en of een specifiek (sub)domein is ingesteld. Vraag bij Cobee naar de mogelijkheden hiervoor.'>
                      <InfoCircleTwoTone />
                    </Tooltip>
                    </span>
                  )} hasFeedback>
                    {getFieldDecorator('is_public', {
                      initialValue: String(this.props.project.is_public),
                    })(
                      <Select
                        placeholder="Type project"
                        >
                        <Option value="0">Privé</Option>
                        <Option disabled={(this.props.selectedCustomer.project_domain == null && !this.props.selectedCustomer.has_app) ? true : false } value="1">Openbaar</Option>
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Type project</Text>
                    <br />
                    <Text type="secondary">{String(this.props.project.is_public) === "0" ? "Privé" : "Openbaar"}</Text>
                  </div>
                )}
              </Col>
              { this.state.status == 2 ? <Col span={12}>
                  <Form.Item label={
                      <span>
                          Datum waarop het project definitief wordt verwijderd
                          <Tooltip className="mhs" title='Dit is de datum waarop het project volledig verwijderd zal worden om te voldoen aan de privacy wetgeving. Normaliter is deze datum binnen 90 dagen na het afronden van het project.'>
                            <InfoCircleTwoTone />
                          </Tooltip>
                      </span>}>
                  {getFieldDecorator('cleanup_at', {
                    initialValue: this.props.project.cleanup_at ? dayjs(this.props.project.cleanup_at) : dayjs().add(90, 'day'),
                    rules: [{ required: true, message: 'Vul een datum in' }],
                  })(
                    <DatePicker
                        disabled={this.state.edit ? false : true}
                        format={'DD/MM/YYYY'}
                        />
                  )}
                  </Form.Item>
              </Col> : null}
            </Row>

            <Row>
                <Col span={12}>
                  <Form.Item label="Project verbergen voor bewoners:">
                      {getFieldDecorator('hide_project_for_occupants', {
                        valuePropName: 'checked',
                        initialValue: this.props.project.hide_project_for_occupants
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      )}
                  </Form.Item>
                </Col>
            </Row>

            <div style={{display: (this.props.customer.use_wocoapp ? 'block' : 'none')}}>
                <Divider></Divider>

                <Row gutter={24}>
                    <Col span={24}>
                      <Title level={4}>WocoAPP koppeling</Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item label="Gebruik WocoAPP koppeling">
                          {getFieldDecorator('use_wocoapp', {
                            valuePropName: 'checked',
                            initialValue: this.state.use_wocoapp ? true : false,
                            onChange: (data) => { this.setState({ use_wocoapp: data });  }
                          })(
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          )}
                      </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} style={{display: this.state.use_wocoapp ? 'block' : 'none'}}>
                    <Col span={12}>
                        <Form.Item label="WocoAPP api key">
                          {getFieldDecorator('wocoapp_api_key', {
                            initialValue: String(this.props.project.wocoapp_api_key),
                          })(
                            <Input />
                          )}
                        </Form.Item>
                    </Col>
                    {/*<Col span={12}>
                        <Form.Item label="Cobee API key">
                          {getFieldDecorator('wocoapp_cobee_api_key', {
                            initialValue: String(this.props.project.wocoapp_cobee_api_key),
                          })(
                            <Input />
                          )}
                        </Form.Item>
                    </Col>*/}
                </Row>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const ProjectsSettingsForm = Form.create({ name: 'projects_general' })(ProjectsSettings);


const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isCustomersFetching : state.customer.isFetching,
    isAccountsFetching : state.account.isFetching,
    isError : state.project.isError,
    project: state.project.project,
    customers: state.customer.customers,
    customer: state.customer.customer,
    accounts: state.account.accounts,
    clients: state.clients.clients,
    selectedCustomer: state.auth.selectedCustomer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    getAccountsAction : bindActionCreators(getAccountsAction, dispatch),
    statusProjectsAction : bindActionCreators(statusProjectsAction, dispatch),
    patchProjectAction : bindActionCreators(patchProjectAction, dispatch),
    getClientsAction : bindActionCreators(getClientsAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSettingsForm);
