import React, { Component } from 'react';
// import './Appointments.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import { getAppointmentsAction, removeAppointmentsAction } from '../../../../actions/appointmentsActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { API_URL } from '../../../../constants/settings';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import AppointmentsSideMenu from './AppointmentsSideMenu';

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Upload,
  Tag,
  Button,
  Drawer,
  Modal,
  Popconfirm,
  message,
  Menu,
  Dropdown,
  Search,
} from 'antd';

class Appointments extends Component {

  state = {
      drawerVisible: false,
      selectedRowKeys: [],
  };

  componentDidMount() {
    this.props.getAppointmentsAction(this.props.match.params.id);
  }

  onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
  }

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.addAppointmentsform.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.addAppointmentsAction(this.props.match.params.id, values);
  //       this.addAppointmentsform.props.form.resetFields();
  //     }
  //   });
  // }

  showConfirm = () => {
      Modal.confirm({
          title: 'Weet je zeker dat je deze items wilt verwijderen?',
          content: 'Klik op JA om deze actie te bevestigen.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {
                
                this.props.removeAppointmentsAction(this.props.match.params.id, {'ids': this.state.selectedRowKeys}).then(() => {

                  this.setState({selectedRowKeys: []});

                    resolve();

                    showSuccess('Succesvol verwijderd');

                    this.props.getAppointmentsAction(this.props.match.params.id);
                });
              }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
      });
  }

  exportAppointment = () => {

      window.open(API_URL+'/appointment/'+this.state.selectedRowKeys[0]+'/export?token='+ localStorage.getItem('authToken'), '_blank');

      this.setState({selectedRowKeys: []});
  }

  render() {

    const columns = [{
        title: 'Onderwerp',
        dataIndex: 'subject',
        render: (text, record) => (
          <span>
            <Link to={`/projects/${record.project_id}/modules/appointment/${record.id}/detail`}>
              {text}
            </Link>
          </span>
        )
    },
    {
        title: 'Aantal adressen',
        dataIndex: 'nr_of_addresses'
    },
    {
        title: 'Afspraken gemaakt',
        dataIndex: 'nr_of_appointments'
    },
    {
        title: 'Afspraken niet gemaakt',
        dataIndex: 'nr_of_appointments_to_be_made'
    }];
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    return (

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'1'} />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={12}>
                    <Link to={`/projects/${this.props.match.params.id}/modules/appointment/add`}>
                      <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" icon={<PlusOutlined />} onClick={() => this.setState({drawerVisible: true})}>Toevoegen</Button>
                    </Link>
                    <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('appointment', 'write') ? false : true } overlay={(
                        <Menu>
                          <Menu.Item disabled={!this.props.HaveAbility('appointment', 'delete')} onClick={() => this.showConfirm()} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                          {/*<Menu.Item disabled={this.state.selectedRowKeys.length != 1 ? true : false} onClick={() => this.exportAppointment()} key="6">Exporteren</Menu.Item>*/}
                        </Menu>
                      )}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  {/*<Col span={12}>
                      <Input.Search
                        allowClear
                        placeholder="Zoeken..."
                        onSearch={value => console.log(value)}
                        style={{ width: 200, float: 'right' }}
                      />
                  </Col>*/}
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      rowKey='id'
                      size="middle"
                      rowSelection={rowSelection}
                      columns={columns}
                      dataSource={this.props.isFetching ? [] : this.props.appointments}
                      loading={this.props.isFetching}
                      />
                  </Col>
                </Row>

            </div>
        </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.appointment.isFetching,
      isError : state.appointment.isError,
      appointments: state.appointment.appointments,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getAppointmentsAction : bindActionCreators(getAppointmentsAction, dispatch),
      //addAppointmentsAction : bindActionCreators(addAppointmentsAction, dispatch),
      removeAppointmentsAction : bindActionCreators(removeAppointmentsAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
