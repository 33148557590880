import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../../utils/Notifications';
import { resetDeliveryAction, getDeliveryAction, getDrawingsAction, getDeliverAddressesAction, addDeliveryAction, updateDeliveryAction } from '../../../../actions/deliverActions';
import DeliverSideMenu from '../DeliverSideMenu';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Card,
} from 'antd';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import '../deliver.css';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import DeliverySteps from './Steps.js';


class DeliveryDetails extends Component
{
    state = {
        selectedRowKeys: [],

        show_photo_modal: false,
        photo_url: false,

        // tags: [],
        inputVisible: false,
        inputValue: '',

        errors: [],
        can_write: false,

        comment: ''
    };

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write')
        });

        this.props.resetDeliveryAction();

        this.loadData();
    }

    loadData()
    {
        if(this.props.match.params.delivery_id != 'add')
        {
            this.props.getDeliveryAction(this.props.match.params.delivery_id).then(() => {

                this.setState({ selectedRowKeys: this.props.delivery.delivery_drawing_ids });
            });
        }

        this.props.getDrawingsAction(this.props.match.params.id, 'all');


        this.props.getDeliverAddressesAction(this.props.match.params.id);
    }

    handleSave(redirect = true)
    {
        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors)
            {   // ok
                values.delivery_drawing_ids = this.state.selectedRowKeys;

                if(this.props.match.params.delivery_id == 'add')
                {   // add
                    this.props.addDeliveryAction(this.props.match.params.id, values).then((data) => {

                        showSuccess('Succesvol opgeslagen');

                        //if(values.show_addresses)
                        {
                            this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/delivery/'+data.id+'/addresses');
                        }
                        // else
                        // {
                        //     this.props.history.push(`/projects/${this.props.match.params.id}/deliver/deliveries`);
                        // }
                    });
                }
                else
                {   // update
                    this.props.updateDeliveryAction(this.props.match.params.delivery_id, values).then(() => {

                        showSuccess('Succesvol opgeslagen');

                        // if(redirect)
                        {
                            //this.props.history.push(`/projects/${this.props.match.params.id}/deliver/deliveries`);
                            this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/delivery/'+this.props.match.params.delivery_id+'/addresses');
                        }
                        // else
                        // {
                        //     this.loadData();
                        // }
                    });
                }
            }
        });
    }

    onSelectChange = (selectedRowKeys) => {
  
        this.setState({ selectedRowKeys: selectedRowKeys });
    }

    render()
    {
        const columns = [
            {
                title: 'Tekening',
                dataIndex: 'description'
            },
        ];

        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="5"/>

                <div style={{padding: 24, width: '100%'}}>

                    <Form layout={'vertical'}>

                        <Row className="mbm">
                            <Col span={24}>
                                <div>
                                    <Button disabled={!this.state.can_write} type={'new'} onClick={() => { this.handleSave(); }}>Opslaan en volgende</Button>
                                    <Link to={`/projects/${this.props.match.params.id}/deliver/deliveries`}>
                                        <Button>Sluiten</Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mbm">
                          <Col span={24}>
                            <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                                <DeliverySteps type="navigation" size="small" current={0} onChange={this.onChange} style={{display: 'block'}} {...this.props} />
                            </Card>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Omschrijving"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('description', {
                                        rules: [{ required: true, message: true }],
                                        initialValue: this.props.delivery.description,
                                    })(
                                    <Input
                                        disabled={!this.state.can_write}
                                        />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        {/*<Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Tekening"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('delivery_drawing_id', {
                                        rules: [{ required: true, message: true }],
                                        initialValue: this.props.delivery.delivery_drawing_id,
                                    })(
                                    <Select
                                        disabled={!this.state.can_write}
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een tekening"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        { this.props.drawings.map((record, index) => {
                                            return <Option value={record.id}>{record.description}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>/**/}

                        <Row className="mbm">
                            <Col span={24} style={{ paddingTop: 6 }}>
                                Aantal gekoppelde tekeningen: {this.state.selectedRowKeys.length}
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <CustomTable
                                    rowKey='id'
                                    size="middle"
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={this.props.drawings}
                                    loading={this.props.isFetching}
                                    />
                            </Col>
                        </Row>

                        { /*this.props.match.params.delivery_id != 'add' ?
                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Adres"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('address_id', {
                                        initialValue: this.props.delivery.address_id ? this.props.delivery.address_id : false,
                                    })(
                                    <Select
                                        disabled={!this.state.can_write}
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een adres"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        mode='single'
                                    >
                                        { this.props.addresses.map((record, index) => {
                                            return <Option value={record.id}>{record.full_address}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>
                        :
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Koppelen aan één of meerdere adressen"
                                    >
                                    {getFieldDecorator('show_addresses', {
                                        rules: [{ required: false }],
                                        initialValue: false,
                                    })(
                                    <Switch
                                        disabled={!this.state.can_write}
                                        checkedChildren={<Icon type="check" />}
                                        unCheckedChildren={<Icon type="close" />}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>/**/
                        }

                    </Form>

                </div>

            </div>
        );
    }
}

const DeliveryDetailsForm = Form.create({ name: 'point_details' })(DeliveryDetails);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        delivery : state.deliver.delivery,
        drawings : state.deliver.drawings,
        addresses : state.deliver.addresses,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetDeliveryAction : bindActionCreators(resetDeliveryAction, dispatch),
        getDeliveryAction : bindActionCreators(getDeliveryAction, dispatch),
        addDeliveryAction : bindActionCreators(addDeliveryAction, dispatch),
        updateDeliveryAction : bindActionCreators(updateDeliveryAction, dispatch),
        getDrawingsAction : bindActionCreators(getDrawingsAction, dispatch),
        getDeliverAddressesAction : bindActionCreators(getDeliverAddressesAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsForm);
