// mac
// export const GLOBAL_API_URL = "http://public.test";
// export var API_URL = GLOBAL_API_URL+'/api'; // localStorage.getItem('apiURL') && isValidUrl(localStorage.getItem('apiURL')) ? localStorage.getItem('apiURL')+'/api' : GLOBAL_API_URL+'/api';
// export const JOB_URL = "http://public.test/api";
// export const OCCUPANTS_URL = "http://localhost:3001";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyCigdMGTV0TrHoqjMlxMRBHzA7K1ZLsYnU";
// export const COBEE_VERSION = '6.2.0';

// local
// export const GLOBAL_API_URL = "https://api.cobee.maartenkoopmans.nl";
// export var API_URL = GLOBAL_API_URL+'/api'; // localStorage.getItem('apiURL') && isValidUrl(localStorage.getItem('apiURL')) ? localStorage.getItem('apiURL')+'/api' : GLOBAL_API_URL+'/api';
// export const JOB_URL = "https://jobs.cobee.maartenkoopmans.nl/api";
// export const OCCUPANTS_URL = "https://maartenkoopmans.nl:3001";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyCigdMGTV0TrHoqjMlxMRBHzA7K1ZLsYnU";
// export const COBEE_VERSION = '6.2.0';

// staging (license-v2)
export const GLOBAL_API_URL = "https://api.staging.cobee.nl";
export var API_URL = GLOBAL_API_URL+'/api'; // localStorage.getItem('apiURL') && isValidUrl(localStorage.getItem('apiURL')) ? localStorage.getItem('apiURL')+'/api' : GLOBAL_API_URL+'/api';
export const JOB_URL = "https://api.staging.cobee.nl/api";
export const OCCUPANTS_URL = "https://bewoners.staging.cobee.nl";
export const GOOGLE_MAPS_API_KEY = "AIzaSyAXWyEDFSHl0QxeK2rPfL2ZnVR2cx6Kqt4";
export const COBEE_VERSION = '6.2.0';

// production
// export const GLOBAL_API_URL = "https://api.cobee.nl";
// export var API_URL = GLOBAL_API_URL+'/api'; // localStorage.getItem('apiURL') && isValidUrl(localStorage.getItem('apiURL')) ? localStorage.getItem('apiURL')+'/api' : GLOBAL_API_URL+'/api';
// export const JOB_URL = "https://jobs.cobee.nl/api";
// export const OCCUPANTS_URL = "https://bewoners.cobee.nl";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyAXWyEDFSHl0QxeK2rPfL2ZnVR2cx6Kqt4";
// export const COBEE_VERSION = '6.2.0';

//
function isValidUrl(urlString)
{
    if(!urlString || urlString == 'null' || urlString == 'undefined'){ return false; }

    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator

    return !!urlPattern.test(urlString);
}
