import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { ClusterOutlined, TeamOutlined, AuditOutlined, CalendarOutlined } from '@ant-design/icons';
import { Menu, Tag } from 'antd';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

class AccountsSideMenu extends Component {

   state = {
       selectedMenu: [],
       openMenu: [],
   }

   componentDidMount()
   {
       if(this.props.match.url.includes('appointment-user'))
       {
           this.setState({
               selectedMenu: ['appointment-users'],
               openMenu: ['module-users']
           });
       }

       if(this.props.match.url.includes('delivery-user'))
       {
           this.setState({
               selectedMenu: ['delivery-users'],
               openMenu: ['module-users']
           });
       }
   }

   render() {
       return (
         <Menu
             mode="inline"
             style={{ width: 300}}
             // defaultSelectedKeys={[this.props.activeMenu]}
             selectedKeys={this.state.selectedMenu}
             openKeys={this.state.openMenu}
             onSelect={(e) => { this.setState({selectedMenu: e.selectedKeys}); }}
             onOpenChange={(e) => { this.setState({openMenu: e}); }}
             >
             <Menu.Item key={'accounts'}>
               <Link to={'/accounts'}>
                 <TeamOutlined />
                 <span>Accounts</span>
               </Link>
             </Menu.Item>
             <Menu.Item key={'account-roles'}>
               <Link to={'/account-roles'}>
                 <ClusterOutlined />
                 <span>Rollen</span>
               </Link>
             </Menu.Item>
             <Menu.SubMenu
               key="module-users"
               title={<span><ClusterOutlined /><span>Verantwoordelijken</span></span>}
               >
                 <Menu.Item key={'delivery-users'}>
                   <Link to={'/delivery-users'}>
                     <AuditOutlined />
                     <span>Opleveren</span>
                   </Link>
                 </Menu.Item>
                 <Menu.Item key={'appointment-users'}>
                   <Link to={'/appointment-users'}>
                     <CalendarOutlined />
                     <span>Afspraken</span>
                   </Link>
                 </Menu.Item>
             </Menu.SubMenu>
         </Menu>
       );
   }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

// export default connect(mapStateToProps, mapDispatchToProps)(AccountsSideMenu);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsSideMenu));
