import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Typography, Modal, Upload, Input, Select, Switch, Button, Drawer, Popconfirm, message, Divider, Steps, Slider, PageHeader, Tag, Table, Calendar, Alert, Menu, Dropdown, Timeline, DatePicker, Checkbox, Radio, Space } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { scheduleAddressesAction, resetAppointmentAction, getAppointmentFreeSlotsAction, updateAppointmentScheduleAction, searchScheduleAddressesAction, addAppointmentAddressNoteAction, updateAppointmentAddressLabelsAction, getAppointmentLabelsAction, resetMadeAppointmentsAction } from '../../../../../actions/appointmentsActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import { showSuccess, showError } from '../../../../../utils/Notifications';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import PatchAppointmentForm from '../Form/PatchAppointment';
// import ClusterFreeSlots from '../Component/ClusterFreeSlots';
import DataForm from '../Form/Data';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import _ from 'underscore';
import { HaveAbility } from '../../../../../utils/RolesAndAbilities';
import AppointmentsSideMenu from '../AppointmentsSideMenu';
import { DownOutlined, FormOutlined } from '@ant-design/icons';
import './appointmentoverview.css';

dayjs.locale('nl');

const { TextArea } = Input;

class AppointmentOverview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedClusterId: null,
      selectedAddressId: null,
      dateModal: false,
      loading: false,
      free_slots: [],
      sms_enabled: false,
      selectedRowKeys: [],
      label_suggestions: [],
    };
  }

  // saveClusters = () => {
  //   showSuccess();
  //   this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/agenda/`);
  // }

  componentDidMount() {

    // this.props.resetAppointmentAction();

    if((_.findWhere(this.props.project.modules, { id: 10 }) && _.findWhere(this.props.selectedCustomer.modules, { id: 10 })))
    {
      // enable sms
      this.setState({ sms_enabled: true });
    }

    // this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {});
    this.loadData();
  }

  loadData()
  {
      this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {

          this.setState({ loading: false });
      });
  }

  openConfirmModal(addressId) {

      this.setState({ free_slots: false }, () => {
          this.setState({ confirmModal: true, /*selectedClusterId: clusterId,*/ selectedAddressId: addressId });
      });
  }

  openCancelModal(addressId) {

      this.setState({ free_slots: false }, () => {
          this.setState({ cancelModal: true, /*selectedClusterId: clusterId,*/ selectedAddressId: addressId });
      });
  }

  openDateModal(/*clusterId,*/ addressId) {

    this.props.getAppointmentFreeSlotsAction(addressId).then(() => {

      this.setState({ free_slots: this.props.free_slots, selectedRowKeys: [] }, () => {

        this.setState({ dateModal: true, /*selectedClusterId: clusterId,*/ selectedAddressId: addressId });

      });

    });

    // this.setState({ dateModal: true, /*selectedClusterId: clusterId,*/ selectedAddressId: addressId });
  }

  openNotesModal(record) {

    this.setState({ selectedAddressId: record.id, notes: record.notes }, () => {
        this.setState({ notesModal: true });
    });
  }

  addNote()
  {
      var values = {};

      values.note = this.state.note;

      if(!values.note)
      {
          showError('Geef een notitie op');
      }
      else
      {
          this.props.addAppointmentAddressNoteAction(this.props.match.params.appointmentid, this.state.selectedAddressId, values).then((data) => {

              showSuccess('Succesvol opgeslagen');

              this.setState({ notesModal: false, note: '' });

              // this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {});
              this.loadData();
          });
      }
  }

  openLabelsModal(record) {

      this.props.getAppointmentLabelsAction(this.props.match.params.appointmentid).then((data) => {

          this.setState({ label_suggestions: data });

          this.setState({ selectedAddressId: record.id, labels: record.labels /*? JSON.parse(record.labels) : []*/ }, () => {

              this.setState({ labelsModal: true });
          });
      });
  }

  updateLabels()
  {
      var labels = this.state.labels;

      this.props.updateAppointmentAddressLabelsAction(this.props.match.params.appointmentid, this.state.selectedAddressId, labels).then(() => {

          showSuccess('Succesvol opgeslagen');

          this.setState({ labelsModal: false, labels: [] });

          // this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {});
          this.loadData();
      });
  }

  updateAddressAppointment() {

      var error = false;

      var values = {};

      // address
      var address_id = this.state.selectedAddressId;

      // type
      values.type_of_date_and_time = this.state.type_of_date_and_time ? this.state.type_of_date_and_time : 'slot'; // slot | other

      // slot || date, time & user
      if(values.type_of_date_and_time == 'slot' && (!this.state.selectedRowKeys || !this.state.selectedRowKeys.length || this.state.selectedRowKeys.length != 1))
      {
          error = true;

          showError('Geef een datum en tijd op');
      }
      else
      {
          if(values.type_of_date_and_time == 'slot')
          {
              var nr = this.state.selectedRowKeys[0];

              var selected_slot = false;

              this.props.free_slots.map((free_slot) => {

                  if(free_slot.nr == nr) {

                      selected_slot = free_slot;

                      values.date = selected_slot.date;
                      values.time = selected_slot.time;

                      values.appointment_availability_id = selected_slot.appointment_availability_id;
                      values.appointment_user_availability_id = selected_slot.appointment_user_availability_id;

                      values.appointment_user_id = selected_slot.appointment_user_id;
                  }
              });

              if(!selected_slot)
              {
                  error = true;

                  showError('Er is geen geldige datum en tijdstip opgegeven en / of is niet langer beschikbaar');
              }
          }
          else
          {
              if(!this.state.new_slot)
              {
                  error = true;

                  showError('Geef een datum en tijd op');
              }
              else
              {
                  values.date = this.state.new_slot.format('YYYY-MM-DD');
                  values.time = this.state.new_slot.format('HH:mm');
              }

              if(this.props.appointment.use_users && !this.state.appointment_user_id)
              {
                  error = true;

                  showError('Geef een verantwoordelijke op');
              }
              else
              {
                  values.appointment_user_id = this.state.appointment_user_id;
              }
          }
      }

      // email
      values.send_email = this.state.send_email;

      if(this.state.send_email)
      {
          if(!this.state.email_address)
          {
              error = true;

              showError('Geef een geldig e-mailadres op');
          }
          else
          {
              values.email_address = this.state.email_address;
          }
      }

      // sms
      values.send_sms = this.state.send_sms;

      if(this.state.send_sms)
      {
          if(!this.state.mobile)
          {
              error = true;

              showError('Geef een geldig mobiel nummer op');
          }
          else
          {
              values.mobile = this.state.mobile;
          }
      }

      if(!error)
      {
          this.setState({ loading: true }, () => {

              this.props.updateAppointmentScheduleAction(this.props.match.params.appointmentid, address_id, values).then((data) => {

                  console.log('updateAppointmentScheduleAction', data);

                  if(data && data.success)
                  {
                      this.setState({ selectedAddressId: null, dateModal: false });

                      showSuccess('Succesvol opgeslagen');

                      // this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {
                      //
                      //     this.setState({ loading: false });
                      // });

                      this.loadData();
                  }
                  else
                  {
                      showError('De afspraak kon niet worden gemaakt');

                      this.setState({ loading: false });
                  }
              });

          });/**/
      }
      else
      {
          this.setState({ loading: false });
      }
  }

  confirmAppointment(address_id)
  {
      var values = {
          confirm: '1',
          send_email: this.state.use_email,
          email_address: this.state.email_address,
          send_sms: this.state.use_sms,
          mobile: this.state.mobile
      };

      // Modal.confirm({
      //     title: 'Weet je zeker dat je deze afspraak wilt bevestigen?',
      //     content: 'Klik op JA om deze actie te bevestigen.',
      //     okText: 'Ja',
      //     okType: 'danger',
      //     cancelText: 'Nee',
      //     onOk: () => {
      //
      //         return new Promise((resolve, reject) => {

                  this.setState({ loading: true }, () => {

                      this.props.updateAppointmentScheduleAction(this.props.match.params.appointmentid, address_id, values).then(() => {

                        this.setState({selectedClusterId: null, selectedAddressId: null, confirmModal: false});

                        showSuccess('Succesvol bevestigd');

                        // this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {
                        //
                        //     this.setState({ loading: false });
                        // });

                        this.loadData();

                        // resolve();

                      });
                  });

      //         }).catch(() => {});
      //
      //     },
      //     onCancel(){},
      // });
  }

  cancelAppointment(address_id)
  {
      var values = {
          cancel: '1',
          send_email: this.state.use_email,
          email_address: this.state.email_address,
          send_sms: this.state.use_sms,
          mobile: this.state.mobile
      };

      // Modal.confirm({
      //     title: 'Weet je zeker dat je deze afspraak wilt annuleren?',
      //     content: 'Klik op JA om deze actie te bevestigen.',
      //     okText: 'Ja',
      //     okType: 'danger',
      //     cancelText: 'Nee',
      //     onOk: () => {
      //
      //         return new Promise((resolve, reject) => {

                  this.setState({ loading: true }, () => {

                      this.props.updateAppointmentScheduleAction(this.props.match.params.appointmentid, address_id, values).then(() => {

                        this.setState({selectedClusterId: null, selectedAddressId: null, cancelModal: false});

                        showSuccess('Succesvol geannuleerd');

                        // this.props.scheduleAddressesAction(this.props.match.params.appointmentid).then(() => {
                        //
                        //     this.setState({ loading: false });
                        // });

                        this.loadData();

                        // resolve();

                      });
                  });

      //         }).catch(() => {});
      //
      //     },
      //     onCancel(){},
      // });
  }

  onSearch(value) {

      this.props.searchScheduleAddressesAction(this.props.match.params.appointmentid, value);
  }

  getLabelsFilter()
  {
      var filters = [];

      this.props.labels_filter.map((label) => {
         filters.push({
              text: label,
              value: label
          });
      });

      return filters;
  }

  getClustersFilter()
  {
      var filters = [];

      this.props.clusters_filter.map((record) => {
         filters.push({
              text: record,
              value: record
          });
      });

      return filters;
  }

  getUsersFilter()
  {
      var filters = [];

      this.props.users_filter.map((record) => {
         filters.push({
              text: record,
              value: record
          });
      });

      return filters;
  }

  getAppointmentDatesFilter()
  {
      var filters = [];

      this.props.appointment_dates_filter && this.props.appointment_dates_filter.map && this.props.appointment_dates_filter.map((record) => {
         filters.push({
              // text: dayjs(record).format('DD-MM-YYYY'),
              text: dayjs(record).format('dddd D MMMM YYYY'),
              value: record
          });
      });

      return filters;
  }

  getAppointmentTimesFilter()
  {
      var filters = [];

      this.props.appointment_times_filter && this.props.appointment_times_filter.map && this.props.appointment_times_filter.map((record) => {
          filters.push({
              text: record,
              value: record
          });
      });

      return filters;
  }

  getAppointmentUsersFilter()
  {
      var filters = [];

      this.props.appointment_users_filter && this.props.appointment_users_filter.map && this.props.appointment_users_filter.map((record) => {
         filters.push({
              text: record,
              value: record
          });
      });

      return filters;
  }

  getLabelSuggestions()
  {
      var labels = [];

      this.state.label_suggestions.map((label) => {

          labels.push({ label: label, value: label });
      });

      return labels;
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showResetConfirm = () => {
    Modal.confirm({
        title: 'Weet je zeker dat je deze items wilt resetten? Dit gaat alleen op voor adressen waar (nog) een afspraak voor is ingepland.',
        content: 'Klik op JA om deze actie te bevestigen.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {

              this.props.resetMadeAppointmentsAction(this.props.match.params.appointmentid, {'ids': this.state.selectedRowKeys}).then(() => {

                this.setState({selectedRowKeys: []});

                  resolve();

                  showSuccess('Succesvol gereset');

                  this.loadData();
              });
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
    });
  }

  showRescheduleConfirm = () => {
    Modal.confirm({
        title: 'Weet je zeker dat je deze items opnieuw wilt inplannen? Dit gaat alleen op voor adressen waar (nog) geen afspraak voor is ingepland.',
        content: 'Klik op JA om deze actie te bevestigen.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            return new Promise((resolve, reject) => {

              this.props.scheduleAddressesAction(this.props.match.params.appointmentid, {'ids': this.state.selectedRowKeys}).then(() => {

                this.setState({selectedRowKeys: []});

                  resolve();

                  showSuccess('Succesvol opnieuw ingepland');

                  this.loadData();
              });
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
    });
  }

  render() {

    const columns = [
      {
        title: 'Adres',
        dataIndex: 'full_address',
        key: 'name',
        render: (text, record) => (
          <span>{text}{record.notes && record.notes.length ? <FormOutlined style={{ marginLeft: 4 }} /> : ''}</span>
        )
      },
      {
        title: 'Status',
        dataIndex: 'appointment_status',
        key: 'appointment_status',
        render: (text, record) => (
          <span style={{display: 'inline-block', height: 18, width: 18, borderRadius: 18, backgroundColor: text}}></span>
        ),
        filters: [
          {
            text: 'Geen afspraak',
            value: 'red',
          },
          {
            text: 'Geen volledige / kloppende afspraak (datum en of tijd)',
            value: 'orange',
          },
          {
            text: 'Volledige / kloppende afspraak',
            value: 'green',
          }
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.appointment_status === value,
      },
      {
        title: 'Gepland',
        dataIndex: 'appointment_datetime',
        key: 'appointment_datetime',
        sorter: (a, b) => { return /*a.appointment_sortable && a.appointment_sortable.localeCompare &&*/ a.appointment_sortable.localeCompare(b.appointment_sortable)},
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => {

            if(record.appointment_cancelled == '1')
            {
                return 'Geannuleerd';
            }
            else if(value)
            {
                if(this.props.appointment.slot == 'specific')
                {
                    var time = dayjs(value).format('HH:mm');

                    return dayjs(value).format('dddd D MMMM YYYY') + ' - ' + (time != '00:00' ? time : 'tijdstip onbekend');
                }
                else
                {
                    return dayjs(record.appointment_date).format('dddd D MMMM YYYY') + ' - ' + (record.appointment_daypart ? record.appointment_daypart : 'dagdeel onbekend');
                }
            }
            else{ return ''; }
        }
      },
      {
        title: 'Labels',
        dataIndex: 'labels',
        filters: this.getLabelsFilter(),
        filterMultiple: false,
        onFilter: (value, record) => {
            var found = false;

            record.labels && record.labels.map && record.labels.map((label, index) => {

                if(value.includes(label)){
                    found = true;
                }
            });

            return found;
        },
        render: (index, record) => {
          return (
            <span>
              { record.labels && record.labels.map && record.labels.map((label, index) => { return <span>{(index > 0 ? <span style={{ marginRight: 8 }}>,</span> : null)}<span className="label">{label}</span></span> }) }
            </span>
          );
        }
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster_name',
        key: 'cluster_name',
        filters: this.getClustersFilter(),
        filterMultiple: false,
        onFilter: (value, record) => record.cluster_name === value,
      },
      {
        title: 'Verantwoordelijke',
        dataIndex: 'appointment_user_name',
        filters: this.getUsersFilter(),
        filterMultiple: false,
        onFilter: (value, record) => record.appointment_user_name === value,
        // render: (index, record) => {
        //   return (
        //     <span>
        //       {record.available_user_name ? record.available_user_name : record.available_user_email_address}
        //     </span>
        //   )
        // }
      },
      {
          title: 'Bevestigd',
          dataIndex: 'appointment_confirmed',
          filterMultiple: false,
          filters: [
              {
                  text: 'Ja',
                  value: '1'
              },
              {
                  text: 'Nee',
                  value: '0'
              }
          ],
          onFilter: (value, record) => record.appointment_confirmed === value,
          render: (index, record) => {
              return record.appointment_confirmed == '1' ? 'Ja' : 'Nee'
          }
      },
      {
        title: '',
        dataIndex: '',
        width: 260,
        key: 'cluster_schedule_action',
        render: (index, record) => {

            return (

                <span>
                    <Dropdown
                        trigger={['click']}
                        overlay={(
                            <Menu>
                              <Menu.Item
                                  disabled={!this.props.HaveAbility('appointment', 'write')}
                                  onClick={() => {
                                      this.openDateModal(record.id);
                                  }}
                                  >
                                  { record && record.appointment_date ? 'Wijzig afspraak' : 'Maak afspraak' }
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                  disabled={!this.props.HaveAbility('appointment', 'write') || !record || !record.appointment_date || record.appointment_cancelled == '1' || record.appointment_confirmed == '1'}
                                  onClick={() => {
                                      // this.confirmAppointment(record.id);
                                      this.openConfirmModal(record.id);
                                  }}
                                  >
                                  Bevestigen
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                  disabled={!this.props.HaveAbility('appointment', 'write') || !record || !record.appointment_date || record.appointment_cancelled == '1'}
                                  onClick={() => {
                                      // this.cancelAppointment(record.id);
                                      this.openCancelModal(record.id);
                                  }}
                                  danger
                                  >
                                  Annuleren
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                  disabled={!this.props.HaveAbility('appointment', 'read')}
                                  onClick={() => {
                                      this.openNotesModal(record);
                                  }}
                                  >
                                  Notities
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                  disabled={!this.props.HaveAbility('appointment', 'write')}
                                  onClick={() => {
                                      this.openLabelsModal(record);
                                  }}
                                  >
                                  Labels
                              </Menu.Item>
                            </Menu>
                        )}
                        >
                        <Button type="secondary" style={{color: 'green', borderColor: 'green'}}>
                            Acties <DownOutlined />
                        </Button>
                    </Dropdown>
                </span>
          )
        }
      },
    ];

    //
    var appointment_columns = [
        {
          title: 'Datum',
          dataIndex: 'date',
          filters: this.getAppointmentDatesFilter(),
          filterMultiple: true,
          onFilter: (values, record) => values.includes(record.date),
          render: (value, record) => {
              return dayjs(value).format('dddd D MMMM YYYY')
          },
        },
        {
          title: 'Tijdstip',
          dataIndex: 'time',
          filters: this.getAppointmentTimesFilter(),
          filterMultiple: true,
          onFilter: (values, record) => values.includes(record.time),
        },
        {
          title: 'Verantwoordelijke',
          dataIndex: 'appointment_user_name',
          filters: this.getAppointmentUsersFilter(),
          filterMultiple: true,
          onFilter: (values, record) => values.includes(record.appointment_user_name),
        },
    ];

    if(!this.props.appointment.use_users)
    {
        delete appointment_columns[2];
    }

    const { getFieldDecorator } = this.props.form;


    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    return (

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'1'} />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      {/*<Button disabled={!this.props.HaveAbility('appointment', 'write')} onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/agenda`)} type="new">Opslaan en volgende</Button>*/}
                      <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                <Row className="mbm">
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <AppointmentSteps current={4} {...this.props}/>
                  </Card>
                </Row>

                <Row className="mbm">
                  <Col span={24}>
                    <Alert showIcon message="Let op: Vergeet niet een correspondentie aan te maken om de bewoners op de hoogte te stellen van de afspraak." type="info" />
                  </Col>
                </Row>

                <Row className="mbm">
                  <Col span={12}>
                    <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('appointment', 'write') ? false : true } overlay={(
                        <Menu>
                          <Menu.Item disabled={!this.props.HaveAbility('appointment', 'write')} onClick={() => this.showResetConfirm()} key="1" style={{ color: '#f5222d' }}>Afspraken annuleren</Menu.Item>
                          { this.props.appointment.type == 'before' ? <Menu.Divider /> : null}
                          { this.props.appointment.type == 'before' ? <Menu.Item disabled={!this.props.HaveAbility('appointment', 'write')} onClick={() => this.showRescheduleConfirm()} key="1" style={{ color: '#f5222d' }}>Afspraken (Opnieuw) automatisch inplannen</Menu.Item> : null }
                        </Menu>
                      )}>
                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={value => this.onSearch(value)}
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>
                      <Row gutter={24}>
                        <Col span={24}>
                          <CustomTable
                            rowKey={'id'}
                            columns={columns}
                            dataSource={this.props.addresses}
                            loading={this.props.isFetching}
                            rowSelection={rowSelection}
                          />
                        </Col>
                      </Row>
                  </Col>
                </Row>

                <Modal
                  title="Afspraak gegevens"
                  width={960}
                  visible={this.state.dateModal}
                  destroyOnClose={true}
                  footer={null}
                  onOk={() => this.setState({ dateModal: false })}
                  onCancel={() => this.setState({ dateModal: false })}
                  >

                  <Form layout={'vertical'}>

                      <Row gutter={24}>
                        <Col span={24}>

                        {/*<Form.Item required={true} label={'Kies een datum en tijd'}>
                          { getFieldDecorator('type_of_date_and_time', {
                            initialValue: 'slot',
                            onChange: (e) => { this.setState({ type_of_date_and_time: e.target.value, selectedRowKeys: [], date: null, time: null }); },
                            rules: [{ required: true }],
                          })(
                            <Radio.Group>
                              <Space direction="vertical">
                                <Radio value={'slot'}>Kies een voorgedefinieerd beschikbaar tijdslot</Radio>
                                <Radio value={'other'}>{!this.props.appointment.use_users ? 'Kies zelf een willekeurige datum en tijd (buiten de ingestelde beschikbaarheid om)' : 'Kies zelf een willekeurige datum, tijd en verantwoordelijke (buiten de ingestelde beschikbaarheid om)'}</Radio>
                              </Space>
                            </Radio.Group>
                          )}
                        </Form.Item>*/}

                          { this.state.type_of_date_and_time != 'other' ?
                          <CustomTable
                            rowKey={'nr'}
                            columns={appointment_columns}
                            dataSource={this.props.free_slots}
                            rowSelection={
                                {
                                    selectedRowKeys: this.state.selectedRowKeys,
                                    type: 'radio',
                                    columnWidth: 48,
                                    onChange: (selectedRowKeys) => {
                                        this.setState({ selectedRowKeys: selectedRowKeys });
                                    }
                                }
                            }
                            scroll={{ y: 300 }}
                            sticky={true}
                            pagination={false}
                            style={{ marginBottom: 24 }}
                            /> : null }

                        <Form.Item required={true} label={!this.props.appointment.use_users ? 'Kies zelf een willekeurige datum en tijd (buiten de ingestelde beschikbaarheid om)' : 'Kies zelf een willekeurige datum, tijd en verantwoordelijke (buiten de ingestelde beschikbaarheid om)'}>
                            <Switch
                                value={this.state.type_of_date_and_time == 'other' ? true : false}
                                onChange={(checked) => {
                                    if(checked)
                                    {
                                        this.setState({ type_of_date_and_time: 'other', selectedRowKeys: [], date: null, time: null });
                                    }
                                    else
                                    {
                                        this.setState({ type_of_date_and_time: 'slot', selectedRowKeys: [], date: null, time: null });
                                    }
                                }}
                                />
                        </Form.Item>

                          <Row>
                            <Col span={8}>
                              { this.state.type_of_date_and_time == 'other' ?
                              <Form.Item required={this.state.type_of_date_and_time == 'other' ? true : false } label={'Vrije keuze datum en tijd'}>
                                { getFieldDecorator('new_slot', {
                                  initialValue: '',
                                  rules: [{ required: true, message: 'Selecteer datum en tijd' }],
                                })(
                                  <DatePicker
                                    showTime
                                    format="DD-MM-YYYY HH:mm"
                                    placeholder="Selecteer datum en tijd"
                                    onOk={(value) => {
                                        this.setState({
                                            new_slot: value,
                                            date: dayjs(value).format('YYYY-MM-DD'),
                                            time: dayjs(value).format('HH:mm')
                                        });
                                    }}
                                    />
                                )}
                              </Form.Item> : null }
                            </Col>
                            <Col span={16}>
                              { this.state.type_of_date_and_time == 'other' && this.props.appointment.use_users ?
                              <Form.Item required={this.state.type_of_date_and_time == 'other' ? true : false } label={'Verantwoordelijke'}>
                                { getFieldDecorator('available_user_id', {
                                  initialValue: '',
                                  onChange: (value) => { this.setState({ appointment_user_id: value }); },
                                  rules: [{ required: true, message: 'Selecteer verantwoordelijke' }],
                                })(
                                  <Select>
                                    { this.props.cluster_users && this.props.cluster_users.map && this.props.cluster_users.map((record) => {
                                        return <Select.Option value={record.id} key={record.id}>{record.full_name ? record.full_name : record.email_address}</Select.Option>
                                    }) }
                                  </Select>
                                )}
                              </Form.Item> : null }
                            </Col>
                          </Row>

                          { this.state.selectedSlotId == 'other' ?
                          <Form.Item required={this.state.selectedSlotId == 'other' ? true : false } label={'Verantwoordelijke selecteren'}>
                            { getFieldDecorator('user', {
                              initialValue: '',
                              rules: [{ required: true, message: 'Selecteer verantwoordelijke' }],
                            })(
                              <Select />
                            )}
                          </Form.Item> : null }

                          <Form.Item label={'Neem e-mailadres en mobiel over van contactpersoon bewoner'}>
                              <Select onChange={(result) => {
                                  if(this.props.address_occupants[result].email_address != '' ) {

                                      this.setState({email_address: this.props.address_occupants[result].email_address });
                                  } else {
                                      this.setState({email_address: '' });
                                  }

                                    if(this.props.address_occupants[result].mobile != ''  && this.props.address_occupants[result].mobile != null ) {

                                      this.setState({mobile: this.props.address_occupants[result].mobile });
                                    } else {

                                      this.setState({mobile: '' });
                                    }

                                }} style={{width: '100%', marginBottom: 0}}>
                                { this.props.address_occupants && this.props.address_occupants.map && this.props.address_occupants.map((element, index) => (
                                  <Select.Option value={index} key={element.id}>{element.full_name}</Select.Option>
                                )) }
                              </Select>
                          </Form.Item>

                          <div>

                            <div>
                              { getFieldDecorator('send_email', {
                                initialValue: false,
                                valuePropName: 'checked',
                                onChange: (e) => { this.setState({ send_email: e.target.checked }); },
                                rules: [{ required: false, message: 'Vul je voornaam in.' }],
                              })(
                                <Checkbox>E-mail versturen</Checkbox>
                              )}

                              { this.state.send_email ? (
                                <div>
                                  <Form.Item required label={'E-mailadres'}>
                                    { getFieldDecorator('email_address', {
                                      initialValue: this.state.email_address,
                                      onChange: (e) => { this.setState({ email_address: e.target.value }); },
                                      rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                                    })(
                                      <Input type="email" />
                                    )}
                                  </Form.Item>
                                </div>
                              ) : null }
                            </div>

                            { this.state.sms_enabled ?
                            <div>
                              { getFieldDecorator('send_sms', {
                                initialValue: false,
                                valuePropName: 'checked',
                                onChange: (e) => { this.setState({ send_sms: e.target.checked }); },
                                rules: [{ required: false, message: 'Vul je voornaam in.' }],
                              })(
                                <Checkbox style={{marginBottom: 10}}>SMS versturen</Checkbox>
                              )}

                              { this.state.send_sms ? (
                                <Form.Item required label={'Mobiel'}>
                                  { getFieldDecorator('mobile_number', {
                                    initialValue: this.state.mobile,
                                    onChange: (e) => { this.setState({ mobile: e.target.value }); },
                                    rules: [{ required: true, message: 'Vul een nummer in' }],
                                  })(
                                    <Input />
                                  )}
                                </Form.Item>
                              ) : null }
                            </div> : null }

                          </div>

                        </Col>
                      </Row>

                  </Form>

                  <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button loading={this.state.loading} onClick={() => { this.setState({ dateModal: false }); }} style={{ marginRight: 12 }}>Annuleren</Button>
                        <Button loading={this.state.loading} onClick={() => { this.updateAddressAppointment(); }} type="new">Opslaan</Button>
                    </Col>
                  </Row>

                </Modal>

                <Modal
                  title="Afspraak bevestigen"
                  width={960}
                  visible={this.state.confirmModal}
                  destroyOnClose={true}
                  footer={null}
                  // okText={'Afspraak annuleren'}
                  // cancelText={'Sluiten'}
                  // onOk={() => this.setState({ cancelModal: false })}
                  // onCancel={() => this.setState({ cancelModal: false })}
                  >

                  <Form layout={'vertical'}>

                      <Row gutter={24}>
                        <Col span={24}>

                          <Form.Item label={'Neem e-mailadres en mobiel over van contactpersoon bewoner'}>
                              <Select onChange={(result) => {
                                  if(this.props.address_occupants[result].email_address != '' ) {

                                      this.setState({email_address: this.props.address_occupants[result].email_address });
                                  } else {
                                      this.setState({email_address: '' });
                                  }

                                    if(this.props.address_occupants[result].mobile != ''  && this.props.address_occupants[result].mobile != null ) {

                                      this.setState({mobile: this.props.address_occupants[result].mobile });
                                    } else {

                                      this.setState({mobile: '' });
                                    }

                                }} style={{width: '100%', marginBottom: 0}}>
                                { this.props.address_occupants && this.props.address_occupants.map && this.props.address_occupants.map((element, index) => (
                                  <Select.Option value={index} key={element.id}>{element.full_name}</Select.Option>
                                )) }
                              </Select>
                          </Form.Item>

                          <div>

                            <div>
                              { getFieldDecorator('send_email', {
                                initialValue: false,
                                valuePropName: 'checked',
                                onChange: (e) => { this.setState({ send_email: e.target.checked }); },
                                rules: [{ required: false, message: 'Vul je voornaam in.' }],
                              })(
                                <Checkbox>E-mail versturen</Checkbox>
                              )}

                              { this.state.send_email ? (
                                <div>
                                  <Form.Item required label={'E-mailadres'}>
                                    { getFieldDecorator('email_address', {
                                      initialValue: this.state.email_address,
                                      onChange: (e) => { this.setState({ email_address: e.target.value }); },
                                      rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                                    })(
                                      <Input type="email" />
                                    )}
                                  </Form.Item>
                                </div>
                              ) : null }
                            </div>

                            { this.state.sms_enabled ?
                            <div>
                              { getFieldDecorator('send_sms', {
                                initialValue: false,
                                valuePropName: 'checked',
                                onChange: (e) => { this.setState({ send_sms: e.target.checked }); },
                                rules: [{ required: false, message: 'Vul je voornaam in.' }],
                              })(
                                <Checkbox style={{marginBottom: 10}}>SMS versturen</Checkbox>
                              )}

                              { this.state.send_sms ? (
                                <Form.Item required label={'Mobiel'}>
                                  { getFieldDecorator('mobile_number', {
                                    initialValue: this.state.mobile,
                                    onChange: (e) => { this.setState({ mobile: e.target.value }); },
                                    rules: [{ required: true, message: 'Vul een nummer in' }],
                                  })(
                                    <Input />
                                  )}
                                </Form.Item>
                              ) : null }
                            </div> : null }

                          </div>

                        </Col>
                      </Row>

                  </Form>

                  <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button loading={this.state.loading} onClick={() => { this.setState({ confirmModal: false }); }} style={{ marginRight: 12 }}>Annuleren</Button>
                        <Button loading={this.state.loading} onClick={() => { this.confirmAppointment(this.state.selectedAddressId); }} type="new">Toepassen</Button>
                    </Col>
                  </Row>

                </Modal>

                <Modal
                  title="Afspraak annuleren"
                  width={960}
                  visible={this.state.cancelModal}
                  destroyOnClose={true}
                  footer={null}
                  // okText={'Afspraak annuleren'}
                  // cancelText={'Sluiten'}
                  // onOk={() => this.setState({ cancelModal: false })}
                  // onCancel={() => this.setState({ cancelModal: false })}
                  >

                  <Form layout={'vertical'}>

                      <Row gutter={24}>
                        <Col span={24}>

                          <Form.Item label={'Neem e-mailadres en mobiel over van contactpersoon bewoner'}>
                              <Select onChange={(result) => {
                                  if(this.props.address_occupants[result].email_address != '' ) {

                                      this.setState({email_address: this.props.address_occupants[result].email_address });
                                  } else {
                                      this.setState({email_address: '' });
                                  }

                                    if(this.props.address_occupants[result].mobile != ''  && this.props.address_occupants[result].mobile != null ) {

                                      this.setState({mobile: this.props.address_occupants[result].mobile });
                                    } else {

                                      this.setState({mobile: '' });
                                    }

                                }} style={{width: '100%', marginBottom: 0}}>
                                { this.props.address_occupants && this.props.address_occupants.map && this.props.address_occupants.map((element, index) => (
                                  <Select.Option value={index} key={element.id}>{element.full_name}</Select.Option>
                                )) }
                              </Select>
                          </Form.Item>

                          <div>

                            <div>
                              { getFieldDecorator('send_email', {
                                initialValue: false,
                                valuePropName: 'checked',
                                onChange: (e) => { this.setState({ send_email: e.target.checked }); },
                                rules: [{ required: false, message: 'Vul je voornaam in.' }],
                              })(
                                <Checkbox>E-mail versturen</Checkbox>
                              )}

                              { this.state.send_email ? (
                                <div>
                                  <Form.Item required label={'E-mailadres'}>
                                    { getFieldDecorator('email_address', {
                                      initialValue: this.state.email_address,
                                      onChange: (e) => { this.setState({ email_address: e.target.value }); },
                                      rules: [{ required: true, message: 'Vul een e-mailadres in' }],
                                    })(
                                      <Input type="email" />
                                    )}
                                  </Form.Item>
                                </div>
                              ) : null }
                            </div>

                            { this.state.sms_enabled ?
                            <div>
                              { getFieldDecorator('send_sms', {
                                initialValue: false,
                                valuePropName: 'checked',
                                onChange: (e) => { this.setState({ send_sms: e.target.checked }); },
                                rules: [{ required: false, message: 'Vul je voornaam in.' }],
                              })(
                                <Checkbox style={{marginBottom: 10}}>SMS versturen</Checkbox>
                              )}

                              { this.state.send_sms ? (
                                <Form.Item required label={'Mobiel'}>
                                  { getFieldDecorator('mobile_number', {
                                    initialValue: this.state.mobile,
                                    onChange: (e) => { this.setState({ mobile: e.target.value }); },
                                    rules: [{ required: true, message: 'Vul een nummer in' }],
                                  })(
                                    <Input />
                                  )}
                                </Form.Item>
                              ) : null }
                            </div> : null }

                          </div>

                        </Col>
                      </Row>

                  </Form>

                  <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button loading={this.state.loading} onClick={() => { this.setState({ cancelModal: false }); }} style={{ marginRight: 12 }}>Annuleren</Button>
                        <Button loading={this.state.loading} onClick={() => { this.cancelAppointment(this.state.selectedAddressId); }} type="new">Toepassen</Button>
                    </Col>
                  </Row>

                </Modal>

                <Modal
                  title="Notities"
                  width={960}
                  visible={this.state.notesModal}
                  destroyOnClose={true}
                  // footer={null}
                  // onOk={() => this.setState({ notesModal: false })}
                  okButtonProps={{ style: { display: 'none' } }}
                  onCancel={() => this.setState({ notesModal: false })}
                  >
                    <TextArea
                        rows={4}
                        onChange={(e) => { this.setState({ note: e.target.value }); }}
                        />

                    <Button style={{ marginTop: 12 }} onClick={() => { this.addNote(); }} type="new">Toevoegen</Button>

                    <Timeline
                        style={{ marginTop: 36 }}
                        items={
                            this.state.notes && this.state.notes.map && this.state.notes.map((note) => {

                                return {
                                    children: dayjs(note.created_at).format('dddd D MMMM YYYY') + ' om ' + dayjs(note.created_at).format('HH:mm') + ' - ' + note.note
                                };
                            })
                        }
                        />
                </Modal>

                <Modal
                  title="Labels"
                  width={960}
                  visible={this.state.labelsModal}
                  destroyOnClose={true}
                  // footer={null}
                  okText={'Toepassen'}
                  cancelText={'Annuleren'}
                  onOk={() => { this.updateLabels(); }}
                  onCancel={() => this.setState({ labelsModal: false })}
                >
                    <Select
                        mode="tags"
                        allowClear
                        style={{ width: '100%' }}
                        options={this.getLabelSuggestions()}
                        value={ this.state.labels }
                        onChange={(value, options) => { this.setState({ labels: value }); }}
                        />
                </Modal>

            </div>
      </div>
    );
  }
}

const AppointmentOverviewForm = Form.create({ name: 'form' })(AppointmentOverview);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.appointment.isFetching,
    isError: state.appointment.isError,
    selectedCustomer: state.auth.selectedCustomer,
    project: state.project.project,
    appointment: state.appointment.appointment,
    addresses: state.appointment.addresses,
    free_slots: state.appointment.free_slots,
    cluster_users: state.appointment.cluster_users,
    address_occupants: state.appointment.address_occupants,
    labels_filter: state.appointment.labels_filter,
    clusters_filter: state.appointment.clusters_filter,
    users_filter: state.appointment.users_filter,
    appointment_dates_filter: state.appointment.appointment_dates_filter,
    appointment_times_filter: state.appointment.appointment_times_filter,
    appointment_users_filter: state.appointment.appointment_users_filter,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleAddressesAction : bindActionCreators(scheduleAddressesAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    getAppointmentFreeSlotsAction : bindActionCreators(getAppointmentFreeSlotsAction, dispatch),
    updateAppointmentScheduleAction : bindActionCreators(updateAppointmentScheduleAction, dispatch),
    searchScheduleAddressesAction : bindActionCreators(searchScheduleAddressesAction, dispatch),
    addAppointmentAddressNoteAction : bindActionCreators(addAppointmentAddressNoteAction, dispatch),
    getAppointmentLabelsAction : bindActionCreators(getAppointmentLabelsAction, dispatch),
    updateAppointmentAddressLabelsAction : bindActionCreators(updateAppointmentAddressLabelsAction, dispatch),
    resetMadeAppointmentsAction : bindActionCreators(resetMadeAppointmentsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentOverviewForm);
