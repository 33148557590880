import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCustomerAppointmentUsersAction, searchCustomerAppointmentUsersAction } from '../../actions/appointmentusersActions';
import PageHeader from '../../components/PageHeader/PageHeader';
import CustomTable from '../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess, showExtraConfirm } from '../../utils/Notifications';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Input, Row, Col, message, Drawer, Select } from 'antd';
import { Link } from 'react-router-dom';
import AccountsSideMenu from './AccountsSideMenu';

function onChange(pagination, sorter){}

const { Option } = Select;

const confirm = Modal.confirm;


class AppointmentUsers extends Component {
  state = {
    visible: false,
    drawer: false,
    loading: true
  };

  componentDidMount()
  {
    this.props.getCustomerAppointmentUsersAction().then(() => {
        this.setState({ loading: false });
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  addUser()
  {
      this.props.history.push('/appointment-users/add');
  }

  render()
  {
    const columns = [
    {
      title: 'E-mail',
      dataIndex: 'email_address',
      sorter: (a, b) => { return a.email_address.localeCompare(b.email_address)},
      render: (text, record) => (
        <span>
          <Link to={`/appointment-user/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },
    {
      title: 'Naam',
      dataIndex: 'full_name',
      sorter: (a, b) => { if(!a.full_name){ a.full_name = ''; } if(!b.full_name){ b.full_name = ''; } return a.full_name.localeCompare(b.full_name)},
      render: (text, record) => (
        <span>
          {record.full_name}
        </span>
      )
    },
    {
      title: 'Bedrijfsnaam',
      dataIndex: 'company_name',
      sorter: (a, b) => { if(!a.company_name){ a.company_name = ''; } if(!b.company_name){ b.company_name = ''; } return a.company_name.localeCompare(b.company_name)},
      render: (text, record) => (
        <span>
          {record.company_name}
        </span>
      )
    }
    ];

    return (
      <div>
        <PageHeader path={[{'label': 'Verantwoordelijken'}]} />
        <div style={{ backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden' }}>
            <AccountsSideMenu activeMenu='4' />
            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12}>
                    <Button disabled={!this.props.HaveAbility('users' ,'write')} icon={<PlusOutlined />} type="primary ant-btn-new" onClick={() => { this.props.history.push('/appointment-users/add'); }}>
                      Toevoegen
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ (value) => { this.props.searchCustomerAppointmentUsersAction(value); }}
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey='id'
                      columns={columns}
                      loading={this.state.loading}
                      dataSource={this.props.accounts}
                      />
                  </Col>
                </Row>
            </div>
        </div>
      </div>
    );
  }
}
// const AppointmentUsersForm = Form.create({ name: 'add_account' })(AppointmentUsers);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment_users.isFetching,
    isError : state.appointment_users.isError,
    accounts : state.appointment_users.appointment_users,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAppointmentUsersAction : bindActionCreators(getCustomerAppointmentUsersAction, dispatch),
    searchCustomerAppointmentUsersAction : bindActionCreators(searchCustomerAppointmentUsersAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentUsers);
