import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Upload,
  Input,
  Select,
  Switch,
  Button,
  Drawer,
  Popconfirm,
  message,
  Divider,
  Steps,
  Slider,
  PageHeader,
  Tag,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateScheduleSettingsAction, updateScheduleBlockedDatesAction, addDayScheduleAction, updateDayScheduleAction, updateScheduleGanttAction } from '../../../../../actions/scheduleActions';
import { showSuccess } from '../../../../../utils/Notifications';
import Settings from '../Form/Settings';
import BlockedDates from '../Form/BlockedDates';
import DaySchedule from '../Form/DaySchedule';
import Calendar from '../Form/Calendar';
import ScheduleSteps from '../Steps/ScheduleSteps';
import ScheduleKypSteps from '../Steps/ScheduleKypSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';

class ScheduleSettings extends Component
{
  state = {
      use_kyp: false,
      cobee_kyp_addresses: {}
  };

  useKyp(use)
  {
      // this.use_kyp = use;
      this.setState({ use_kyp: use });
  }

  saveSchedule(e) {
    this.saveSettings(e);
  }

  saveSettings(e) {

    this.settingsForm.props.form.validateFields((err, values) => {

      if(!this.state.use_kyp)
      {
          values.image_id = this.settingsForm.state.image_id;
      }
      // else
      // {
      //     values.cobee_kyp_addresses = JSON.stringify(this.state.cobee_kyp_addresses);
      // }

      this.props.updateScheduleSettingsAction(this.props.match.params.id, values).then(() => {

        showSuccess('Succesvol opgeslagen');

        if(!this.state.use_kyp)
        {
          this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/blocked-dates`);
        }
        else
        {
          this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/kyp-addresses`);
        }
      });
    });
  }

  render()
  {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => this.saveSchedule(e) }>Opslaan en volgende</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            { !this.state.use_kyp ? <ScheduleSteps {...this.props} />
            :
            <ScheduleKypSteps {...this.props} />}
          </Card>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card>
              <Settings
                wrappedComponentRef={(formRef) => { this.settingsForm = formRef; }}
                useKyp={(use) => { this.useKyp(use); }}
                // setCobeeKypAddresses={(cobee_kyp_addresses) => { this.setState({ cobee_kyp_addresses: cobee_kyp_addresses }); }}
                onSubmit={(e) => { this.saveSettings(e); }}
                { ...this.props }
                />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    updateScheduleBlockedDatesAction : bindActionCreators(updateScheduleBlockedDatesAction, dispatch),
    addDayScheduleAction : bindActionCreators(addDayScheduleAction, dispatch),
    updateDayScheduleAction : bindActionCreators(updateDayScheduleAction, dispatch),
    updateScheduleGanttAction : bindActionCreators(updateScheduleGanttAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSettings);
