import React, { Component } from 'react';
// import './ProjectsCorrespondenceDetail.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_URL, JOB_URL } from '../../../../constants/settings'
import { getGroupsAction } from '../../../../actions/groupsActions';
import { getAddressesAction } from '../../../../actions/addressesActions';
import { patchOnStationeryAction, sendTestEmailAction, getCorrespondenceAddressesAction, addCorrespondenceAction, setupCorrespondenceMethodsAction, patchCorrespondenceAction, getCorrespondenceAction, requestDownloadLetters, requestDownloadMethod } from '../../../../actions/correspondencesActions';
import { getNrOfNewQueuedItems } from '../../../../actions/queueActions';
import SetupMailCorrespondence from '../Form/SetupMailCorrespondence';
import EmailGeneratedLetters from '../Form/EmailGeneratedLetters';
import EmailTestForm from '../Form/EmailTestForm';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import CorrespondenceSteps from '../Steps/CorrespondenceSteps';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { showSuccess } from '../../../../utils/Notifications';
import { CheckOutlined, CloseOutlined, DownOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Tag,
  Button,
  Modal,
  Dropdown,
  Search,
  Steps,
  Divider,
  Collapse,
  Panel,
  Badge,
  Table,
  Tabs,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Popover,
  TimePicker,
  message,
  Tooltip,
  Select,
  Menu,
} from 'antd';
// import './ProjectsCorrespondenceCompose.css';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

const { TabPane } = Tabs;

class ProjectsCorrespondenceCompose extends Component {

  state = {
    current: 1,

    sendbycustomer: false,
    on_stationery: false,
    print_double_sided: false,

    letterVisible: false,
    letterMailVisible: false,
    emailVisible: false,
    smsVisible: false,
    pushVisible: false,

    subject: '',

    selectedLetterIds: [],
    selectedLetterEmailIds: [],
    selectedEmailIds: [],
    selectedSmsIds: [],
    selectedPushIds: [],

    currentDataSource: [],
    expandTable: true,

    sendDate: null,
    sendTime: null,

    emailModal: false,
    emailTestModal: '',

    emailTo: '',
    downloadLetters: false,

    expandedRowKeys: [],

    getMailAddresses: [],

    activeTab: this.props.match.params.tab ? this.props.match.params.tab : false
  };

  componentDidMount() {

    if(this.props.match.params.tab)
    {
        this.setActiveTab(this.props.match.params.tab);
    }

    this.props.getCorrespondenceAddressesAction(this.props.match.params.id).then(() => {

      this.props.getGroupsAction({"projectId": this.props.match.params.id});

      if(this.props.match.params.correspondenceid) {

        this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

          this.setState({

            subject: this.props.correspondence && this.props.correspondence.subject,
            selectedLetterIds: this.props.correspondence.selection && this.props.correspondence.selection.mail,
            selectedLetterEmailIds: this.props.correspondence.selection && this.props.correspondence.selection.email_mail,
            selectedEmailIds: this.props.correspondence.selection && this.props.correspondence.selection.email,
            selectedSmsIds: this.props.correspondence.selection && this.props.correspondence.selection.sms,
            selectedPushIds: this.props.correspondence.selection && this.props.correspondence.selection.push,
          })

          if(this.props.correspondence.status === 'send' && !this.props.HaveRole(['super-admin'])) {
              this.props.history.push('/projects/'+this.props.match.params.id+'/correspondence');
          }

          this.setState({
              currentDataSource: this.props.addresses,
              // on_stationery: this.getMethod('mail', null, null) && this.getMethod('mail', null, null).on_stationery == 1 ? true : false,
              on_stationery: this.props.correspondence && this.props.correspondence.on_stationery != 1 ? true : false,
              sendbycustomer: this.getMethod('mail', null, null) && this.getMethod('mail', null, null).send_by_customer == 1 ? true : false,
              print_double_sided: this.getMethod('mail', null, null) && this.getMethod('mail', null, null).print_double_sided == 1 ? true : false,
              return_envelope_id: this.getMethod('mail', null, null) ? this.getMethod('mail', null, null).return_envelope_id : null,
              letter_paper_id: this.getMethod('mail', null, null) || this.getMethod('mail', null, null).letter_paper_id === 0 || this.getMethod('mail', null, null).letter_paper_id === '0' ? this.getMethod('mail', null, null).letter_paper_id : this.props.project.letterpaper_id,
              envelope_id: this.getMethod('mail', null, null) || this.getMethod('mail', null, null).envelope_id === 0 || this.getMethod('mail', null, null).envelope_id === '0' ? this.getMethod('mail', null, null).envelope_id : this.props.project.envelope_type,
              delivery_method: this.getMethod('mail', null, null) ? (this.getMethod('mail', null, null).delivery_method == 'cheaper' ? 'cheaper' : true) : true,
              track_and_trace: this.getMethod('mail', null, null) ? (this.getMethod('mail', null, null).track_and_trace ? true : false) : null,
              getMailAddresses: this.getMailAddresses()
          }, () => {});

        });
      }

    });
  }

  setActiveTab(tab, fallback = false)
  {
      if(!tab && fallback && !this.state.activeTab && !this.activeTab)
      {
          tab = fallback;

          this.activeTab = tab;
      }

      if(tab)
      {
          var tab_nr = tab;

               if(tab == 'mail')      { tab_nr = '1'; }
          else if(tab == 'email_mail'){ tab_nr = '2'; }
          else if(tab == 'email')     { tab_nr = '3'; }
          else if(tab == 'sms')       { tab_nr = '4'; }
          else if(tab == 'push')      { tab_nr = '5'; }

          this.setState({ activeTab: tab_nr }, () => {

              window.history.pushState('Correspondentie', 'Correspondentie', '/projects/'+this.props.match.params.id+'/correspondence/'+this.props.match.params.correspondenceid+'/compose/tab/'+tab);
          });
      }

      //
      return true;
  }

  saveMethodletterIdentity = (value, method) => {

    this.props.patchOnStationeryAction(this.props.match.params.correspondenceid, {'on_stationery': value ? false : true});
  }

  saveLetterPaper = (value, method, type) => {

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {'letter_paper_id': value, 'type': type});

    this.setState({ letter_paper_id: value });
  }

  saveEnvelope = (value, method, type) => {

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {'envelope_id': value, 'type': type});

    this.setState({ envelope_id: value });
  }

  saveDeliveryMethod = (value) => {

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {'delivery_method': value, 'type': 'mail'});

    this.setState({ delivery_method: value });
  }

  saveTrackAndTrace = (value) => {

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {'track_and_trace': value, 'type': 'mail'});

    this.setState({ track_and_trace: value });
  }

  saveMethodDoubleSidedByCustomer = (value, method) => {

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, { 'print_double_sided': value, 'type': method });

    this.setState({ print_double_sided: value });
  }

  saveReturnEnvelope = (value, method, type) => {

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, { 'return_envelope_id': value, 'type': method });

    this.setState({ return_envelope_id: (value == 'null' ? false : true) });
  }

  saveMethodSendByCustomer = (value, method) => {

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, { 'send_by_customer': value, 'type': method });

    this.setState({ sendbycustomer: value });
  }

  saveMethodDateTime = (dateTime, method, addressid, occupantid) => {

    var values = { 'scheduled_for': dateTime ? dayjs(dateTime).format('YYYY-MM-DD HH:mm:ss') : 'null', 'type': method };

    if(addressid) {
      values = {...values, 'address_id': addressid};
    }

    if(occupantid) {
      values = {...values, 'occupant_id': occupantid};
    }

    this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, values);
  }

  getMethod = (type, addressid, occupantid) => {

    var method = this.props.correspondence.methods && this.props.correspondence.methods.find((element) => element.type == type && element.address_id == addressid && element.occupant_id == occupantid);

    if(method && type == 'mail')
    {
        if(!method.letter_paper_id && method.letter_paper_id !== 0 && method.letter_paper_id !== '0')
        {
            method.letter_paper_id = this.props.project.letterpaper_id;
        }

        if(!method.envelope_id && method.envelope_id !== 0 && method.envelope_id !== '0')
        {
            method.envelope_id = this.props.project.envelope_type;
        }
    }

    return method ? method : { letter_paper_id: this.props.project.letterpaper_id, envelope_id: this.props.project.envelope_type };
  }

  getMailAddresses = (method = false) => {

    var filtered_result = [];

    var addresses = JSON.parse(JSON.stringify(this.props.correspondence.addresses));

    filtered_result = addresses.filter((element) => {

      var filtered_occupants = element.occupants && element.occupants.filter((occupant) => {

        if(method == 'mail' && !element.has_different_address_occupant)
        {
            return null;
        }

        if(method != 'mail' && this.state.selectedLetterIds.indexOf('occ-'+occupant.id) != -1) {

          occupant.only_occupants = true;

          return occupant;
        }
        else if(method == 'mail')
        {
            return occupant;
        }

      });

      element.occupants      = filtered_occupants && filtered_occupants.length ? filtered_occupants : null;
      element.only_occupants = false;

      if(this.state.selectedLetterIds.indexOf(element.id) != -1) {
        element.only_occupants = true;
      }

      element.occupants = filtered_occupants;

      //
      if(!element.occupants || element.occupants.length < 1 || (method == 'mail' && !element.has_different_address_occupant))
      {
          delete element.occupants;
      }

      if(this.state.selectedLetterIds.indexOf(element.id) != -1 || element.occupants && element.occupants.length > 0) {
        return element;
      }

    });

    return filtered_result;
  }

  getEmailMailAddresses = () => {

    var addresses = JSON.parse(JSON.stringify(this.props.correspondence.addresses));

    var filtered_result = [];

    filtered_result = addresses.filter((element) => {

      var filtered_occupants = element.occupants && element.occupants.filter((occupant) => {

        if(this.state.selectedLetterEmailIds.indexOf('occ-'+occupant.id) != -1) {
          return occupant;
        }
      });

      element.occupants = filtered_occupants;

      if(filtered_occupants.length > 0){
        return filtered_occupants;
      }
    });

    return filtered_result;
  }

  getEmailAddresses = () => {

    var addresses = JSON.parse(JSON.stringify(this.props.correspondence.addresses));

    var filtered_result = [];

    filtered_result = addresses.filter((element) => {

      var filtered_occupants = element.occupants && element.occupants.filter((occupant) => {

        if(this.state.selectedEmailIds.indexOf('occ-'+occupant.id) != -1) {
          return occupant;
        }
      });

      element.occupants = filtered_occupants;

      if(filtered_occupants.length > 0){
        return filtered_occupants;
      }
    });

    return filtered_result;
  }

  getSmsAddresses = () => {

    var addresses = JSON.parse(JSON.stringify(this.props.correspondence.addresses));

    var filtered_result = [];

    filtered_result = addresses.map((element) => {

      var filtered_occupants = element.occupants && element.occupants.filter((occupant) => {

        if(this.state.selectedSmsIds.indexOf('occ-'+occupant.id) != -1) {
          return occupant;
        }

      });

      element.occupants = filtered_occupants;

      if(filtered_occupants.length > 0){
        element.occupants = filtered_occupants;
        return element;
      }
    });

    return filtered_result;
  }

  sendTestEmail = () => {

    this.emailTestForm.props.form.validateFields((error, values) => {

      if(!error) {

        this.props.sendTestEmailAction(this.props.match.params.correspondenceid, {...values, 'type': this.state.emailTestModal});

        this.emailTestForm.props.form.resetFields();

        this.setState({emailModal: false, emailTestModal:''});
      }
    });
  }

  downloadMethod = (method, sub_method = false) => {

    // modal
    this.props.requestDownloadMethod(this.props.match.params.correspondenceid, method, sub_method, { 'dpi': 72 }).then(() => {

      showSuccess('Download verzoek ingediend');

      this.props.getNrOfNewQueuedItems();

    });
  }

  downloadLetters = () => {

    // modal
    this.props.requestDownloadLetters(this.props.match.params.correspondenceid, { /*'email': this.state.emailTo,*/ 'dpi': 72 }).then(() => {

      this.setState({emailTo: ''});

      showSuccess('Download verzoek ingediend');

      this.props.getNrOfNewQueuedItems();

    });
  }

  downloadEmailMailEmails = () => {

      this.downloadMethod('email_mail', 'email');
  }

  downloadEmailMailMails = () => {

      this.downloadMethod('email_mail', 'mail');
  }

  downloadEmails = () => {

      this.downloadMethod('email');
  }

  downloadSMS = () => {

      this.downloadMethod('sms');
  }

  downloadPush = () => {

      this.downloadMethod('push');
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div id="correspondence-compose">
        <Row className="mbm">
          <Col span={12}>
            <div>
              <Button disabled={!this.props.HaveAbility('correspondence', 'write')} loading={this.props.isFetching} type="new" onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/send`)}>Opslaan en volgende</Button>
              <Link to={`/projects/${this.props.match.params.id}/correspondence`}>
                <Button>Sluiten</Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Card style={{backgroundColor: '', borderRadius: '5px', marginBottom: 16}} bodyStyle={{padding:0}}>
          <Row>
            <CorrespondenceSteps current={this.state.current} {...this.props}/>
          </Row>
        </Card>

        <div>
          <Tabs
            activeKey={this.state.activeTab}
            type="card"
            onChange={(key) => { this.setActiveTab(key); }}
            tabBarStyle={{ marginBottom: 0 }}
            tabBarGutter={10}
            >
          { this.state.selectedLetterIds && this.state.selectedLetterIds.length > 0 && this.setActiveTab(false, '1') && (
            <TabPane tab="Brieven" key="1">
              <Card style={{ backgroundColor: 'white', borderRadius: '5px', borderTop: 0, marginBottom: 24 }}>
                <div id={'settings'}>
                  <Row style={{ marginBottom: 24 }}>
                    <Col span={24} align="left">
                      {/*<Button disabled={this.getMethod('mail', null, null) && !this.getMethod('mail', null, null).message && !this.getMethod('mail', null, null).pdf_attachment} onClick={() => this.downloadLetters()} style={{marginRight: 10}}>Download alle brieven</Button>
                      <Button disabled={this.getMethod('mail', null, null) && !this.getMethod('mail', null, null).message && !this.getMethod('mail', null, null).pdf_attachment} onClick={() => window.open(JOB_URL + '/correspondence/'+ this.props.match.params.correspondenceid +'/preview/mail?dpi=72&token=' + localStorage.getItem('authToken'), '_blank')} style={{marginRight: 10}}>Voorbeeld</Button>*/}

                      <Button disabled={!this.props.HaveAbility('correspondence', 'write')} type="new" onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/mail/mail`)}>Samenstellen</Button>

                      <Dropdown disabled={!this.getMethod('mail', null, null) || (!this.getMethod('mail', null, null).message && !this.getMethod('mail', null, null).pdf_attachment)} trigger={['click']} overlay={(
                        <Menu>
                          <Menu.Item key="1" disabled={this.getMethod('mail', null, null) && !this.getMethod('mail', null, null).message && !this.getMethod('mail', null, null).pdf_attachment}>
                            <Link onClick={() => { this.downloadLetters(); }}>
                              Download alle brieven
                            </Link>
                          </Menu.Item>
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item key="2" disabled={this.getMethod('mail', null, null) && !this.getMethod('mail', null, null).message && !this.getMethod('mail', null, null).pdf_attachment}>
                            <Link onClick={() => { window.open(JOB_URL + '/correspondence/'+ this.props.match.params.correspondenceid +'/view/mail/Voorbeeld brief.pdf?dpi=72&token=' + localStorage.getItem('authToken'), '_blank'); }}>
                              Voorbeeld
                            </Link>
                          </Menu.Item>
                        </Menu>
                        )}
                        >
                        <Button style={{ marginRight: 10 }}>
                          Acties <DownOutlined />
                        </Button>
                      </Dropdown>

                    </Col>
                  </Row>

                  <Row gutter={24}>
                        <Col span={8}>
                          <Form.Item label="Zelf versturen" style={{ display: 'inline-block', marginBottom: 8 }}>
                            <Switch
                              disabled={!this.props.HaveAbility('correspondence', 'write')}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              checked={this.state.sendbycustomer} onChange={(value) => { this.setState({ sendbycustomer: value }); this.saveMethodSendByCustomer(value, 'mail'); }}
                              />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label="Op blanco briefpapier" style={{ display: 'inline-block', marginBottom: 8 }}>
                            <Switch
                                disabled={!this.props.HaveAbility('correspondence', 'write')}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={this.state.on_stationery} onChange={(value) => { this.setState({ on_stationery: value }); this.saveMethodletterIdentity(value, 'mail'); }}
                                />
                            <Tooltip className="mhs" title='Als je de brief zelf wil uitprinten op briefpapier dan zet je deze optie aan. Cobee zal dan de correspondentie genereren zonder het briefpapier op de achtergrond van de brief.'>
                              <InfoCircleTwoTone />
                            </Tooltip>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item label="Dubbelzijdig" style={{ display: 'inline-block', marginBottom: 8 }}>
                            <Switch
                                disabled={!this.props.HaveAbility('correspondence', 'write') /*|| this.state.sendbycustomer*/}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={/*!this.state.sendbycustomer &&*/ this.state.print_double_sided} onChange={(value) => this.saveMethodDoubleSidedByCustomer(value, 'mail')}
                                />
                          </Form.Item>
                        </Col>

                        <Col span={8} style={{ display: !this.state.on_stationery ? 'inline-block' : 'none', marginBottom: 8 }}>
                          <Form.Item label="Briefpapier" style={{ display: !this.state.on_stationery ? 'inline-block' : 'none', width: '100%' }}>
                            {getFieldDecorator('letter_paper_id', {
                                disabled: !this.props.HaveAbility('correspondence', 'write'),
                                initialValue: String(this.state.letter_paper_id),
                                onChange: (value) => { this.saveLetterPaper(value, 'letter_paper_id', 'mail'); }
                              })(
                              <Select
                                style={{minWidth: 240, width: '100%'}}
                                >
                                {/*<Select.Option key={null}>Standaard briefpapier</Select.Option>*/}
                                <Select.Option key="0">Blanco briefpapier</Select.Option>
                                { this.props.correspondence && this.props.correspondence.letter_papers  && this.props.correspondence.letter_papers.map((element, index) => {
                                    return <Select.Option key={element.id}>{element.name}</Select.Option>
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>

                        <Col span={8} style={{ display: !this.state.on_stationery && !this.state.sendbycustomer ? 'inline-block' : 'none', marginBottom: 8 }}>
                          <Form.Item label="Enveloppe" style={{ display: !this.state.on_stationery && !this.state.sendbycustomer ? 'inline-block' : 'none', width: '100%' }}>
                            {getFieldDecorator('envelope_id', {
                                disabled: !this.props.HaveAbility('correspondence', 'write'),
                                initialValue: String(this.state.envelope_id),
                                onChange: (value) => { this.saveEnvelope(value, 'envelope_id', 'mail'); }
                              })(
                              <Select
                                style={{minWidth: 240, width: '100%'}}
                                >
                                {/*<Select.Option key={null}>Standaard enveloppe (A5)</Select.Option>*/}
                                <Select.Option key="0">Blanco enveloppe (A5)</Select.Option>
                                { this.props.correspondence && this.props.correspondence.envelopes  && this.props.correspondence.envelopes.map((element, index) => {
                                    return <Select.Option key={element.id}>{element.name + ' ('+ element.size +')'}</Select.Option>
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>

                      </Row>

                      <Row gutter={24}>

                        <Col span={8} style={{ display: !this.state.sendbycustomer && this.props.correspondence && this.props.correspondence.return_envelopes  && this.props.correspondence.return_envelopes.length ? 'inline-block' : 'none', marginBottom: 8}}>
                          <Form.Item label="Retourenveloppe meesturen" style={{ display: !this.state.sendbycustomer && this.props.correspondence && this.props.correspondence.return_envelopes  && this.props.correspondence.return_envelopes.length ? 'inline-block' : 'none'}}>
                            <Switch
                                disabled={!this.props.HaveAbility('correspondence', 'write') || this.state.sendbycustomer}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={!this.state.sendbycustomer && this.state.return_envelope_id && this.state.return_envelope_id !== false ? true : false}
                                onChange={(value) => this.saveReturnEnvelope((value === false ? 'null' : this.props.correspondence.return_envelopes[0].id), 'mail', 'toggle')}
                                />
                          </Form.Item>
                        </Col>

                        <Col span={8} style={{ display: !this.state.sendbycustomer && this.state.return_envelope_id ? 'inline-block' : 'none', marginBottom: 8 }}>
                          <Form.Item label="Retourenveloppe" style={{ display: !this.state.sendbycustomer && this.state.return_envelope_id ? 'inline-block' : 'none' }}>
                            {getFieldDecorator('return_envelope_id', {
                                disabled: !this.props.HaveAbility('correspondence', 'write') || this.state.sendbycustomer,
                                initialValue: this.state.return_envelope_id !== true && this.state.return_envelope_id >= 1 ? String(this.state.return_envelope_id) : (this.props.correspondence && this.props.correspondence.return_envelopes && this.props.correspondence.return_envelopes.length > 0 ? String(this.props.correspondence.return_envelopes[0].id) : null),
                                onChange: (value) => { this.saveReturnEnvelope(value, 'mail', 'dropdown'); }
                              })(
                              <Select
                                style={{minWidth: 160}}
                                >
                                { this.props.correspondence && this.props.correspondence.return_envelopes  && this.props.correspondence.return_envelopes.map((element, index) => {
                                    return <Select.Option key={element.id}>{element.name}</Select.Option>
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={8} style={{ display: !this.state.sendbycustomer ? 'inline-block' : 'none', marginBottom: 8 }}>
                          <Form.Item label="Verzenden op" style={{ display: !this.state.sendbycustomer ? 'inline-block' : 'none' }}>
                            <DatePicker
                                placeholder="Direct"
                                format={'DD/MM/YYYY'}
                                disabled={!this.props.HaveAbility('correspondence', 'write') || this.state.sendbycustomer}
                                defaultValue={this.getMethod('mail', null, null) && this.getMethod('mail', null, null).scheduled_for ? dayjs(moment(this.getMethod('mail', null, null).scheduled_for)) : null} onChange={(value) => this.saveMethodDateTime(value, 'mail')}
                                onOk={(value) => this.saveMethodDateTime(value, 'mail')} />
                              <Tooltip className="mhs" title='Brieven die voor 14:30 uur verstuurd zijn door Cobee, worden normaliter de volgende werkdag (voor de postbode) bezorgd. Hou er rekening mee dat de hoeveelheid adressen van invloed is op de verzendtijd (meer adressen betekend een langere tijd voor Cobee om de brieven te verzenden).'>
                                <InfoCircleTwoTone />
                              </Tooltip>
                          </Form.Item>
                        </Col>

                        <Col span={8} style={{ display: !this.state.sendbycustomer ? 'inline-block' : 'none', marginBottom: 8}}>
                          <Form.Item label="Snelle bezorging" style={{ display: !this.state.sendbycustomer ? 'inline-block' : 'none'}}>
                            <Switch
                                disabled={!this.props.HaveAbility('correspondence', 'write') || this.state.sendbycustomer}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={!this.state.sendbycustomer && this.state.delivery_method != 'cheaper' ? true : false}
                                onChange={(value) => this.saveDeliveryMethod((!value ? 'cheaper' : false))}
                                />
                            <Tooltip className="mhs" title='Als snelle bezorgen uit staat, worden brieven binnen 5 dagen bezorgd maar zijn de verzendkosten lager.'>
                              <InfoCircleTwoTone />
                            </Tooltip>
                          </Form.Item>
                        </Col>

                        <Col span={8} style={{ display: !this.state.sendbycustomer ? 'inline-block' : 'none', marginBottom: 8}}>
                          <Form.Item label="Aangetekend versturen" style={{ display: !this.state.sendbycustomer ? 'inline-block' : 'none'}}>
                            <Switch
                                disabled={!this.props.HaveAbility('correspondence', 'write') || this.state.sendbycustomer}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={!this.state.sendbycustomer && this.state.track_and_trace ? true : false}
                                onChange={(value) => this.saveTrackAndTrace(value ? true : false)}
                                />
                            <Tooltip className="mhs" title='Bij het inschakelen van track & trace worden extra kosten in rekening gebracht.'>
                              <InfoCircleTwoTone />
                            </Tooltip>
                          </Form.Item>
                        </Col>

                      </Row>

                  <Row>
                    <Col span={24}>
                      <Collapse bordered={true} defaultActiveKey={[]} style={{marginTop: 16}}>
                        <Collapse.Panel header={`${this.state.selectedLetterIds ? this.state.selectedLetterIds.length : ''} brie${this.state.selectedLetterIds && this.state.selectedLetterIds.length > 1 ? 'ven' : 'f'}`} key="1">
                          <CustomTable columns={!this.state.sendbycustomer ?
                              [
                                {
                                  title: 'Adres',
                                  dataIndex: 'full_address',
                                  key: 'full_address',
                                  render: (text, record) =>
                                  record.full_address && record.full_address ? (
                                    record.full_address
                                  ) : (
                                    <span>
                                      {record.full_name} <Tag>{record.only_occupants}{record.description ? record.description : 'Bewoner'}</Tag>
                                  </span>
                                )
                              },
                              {
                                title: 'Verzenden op!',
                                key: 'sent_date',
                                render: (record, index) => (record.only_occupants || record.has_different_address_occupant || record.address_id) ? (
                                  <span>
                                    <DatePicker disabled={!this.props.HaveAbility('correspondence', 'write')} format={'DD/MM/YYYY'} placeholder="Zoals algemene brief" defaultValue={this.getMethod('mail', record.address_id ? null : record.id, record.address_id ? record.id : null) && this.getMethod('mail', record.address_id ? null : record.id, record.address_id ? record.id : null).scheduled_for ? dayjs(moment(this.getMethod('mail', record.address_id ? null : record.id, record.address_id ? record.id : null).scheduled_for)) : null }
                                      onChange={(value) => this.saveMethodDateTime(value, 'mail', record.address_id ? null : record.id, record.address_id ? record.id : null)
                                      } onOk={(value)   => this.saveMethodDateTime(value, 'mail', record.address_id ? null : record.id, record.address_id ? record.id : null)} />
                                    </span>
                                  ) : null,
                                },
                                {
                                  width: 500,
                                  title: 'Acties!',
                                  render: (record, index) =>  {

                                    if(!record.only_occupants && !record.has_different_address_occupant && !record.address_id) {
                                      return;
                                    }

                                    var own_letter = false;

                                    if(record.address_id)
                                    {
                                        if(this.getMethod('mail', null, record.id) && this.getMethod('mail', null, record.id).message != null && this.getMethod('mail', null, record.id).message != '') {
                                            own_letter = true;
                                        }
                                    }
                                    else
                                    {
                                        if(this.getMethod('mail', record.id, null) && this.getMethod('mail', record.id, null).message != null && this.getMethod('mail', record.id, null).message != '') {
                                            own_letter = true;
                                        }
                                    }

                                    return (
                                      <span>
                                        <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/mail/${record.address_id ? 'occupant' : 'address'}/${record.id}`}>
                                          <Button disabled={!this.getMethod('mail', null, null).message || !this.props.HaveAbility('correspondence', 'write')} type='link'>Samenstellen</Button>
                                        </Link>
                                        {own_letter && (
                                          <Button onClick={() => {

                                              var params = {
                                                // 'address_id': record.id,
                                                'type': 'mail',
                                                'message': '',
                                                'salutation': '',
                                                'salutation_type': 'personal',
                                              };

                                              if(record.address_id)
                                              {
                                                  params.occupant_id = record.id;
                                              }
                                              else
                                              {
                                                  params.address_id = record.id;
                                              }

                                              this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, params).then(() => {

                                                this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {

                                                  this.setState({
                                                      currentDataSource: this.props.addresses,
                                                      getMailAddresses: this.getMailAddresses()
                                                  });

                                                });

                                              });

                                            }} disabled={!this.getMethod('mail', null, null).message} type='link'>Terugzetten naar algemene brief</Button>
                                          )}
                                        </span>
                                      )},
                                    }
                                  ]
                                  :
                                  [
                                    {
                                      title: 'Adres',
                                      dataIndex: 'full_address',
                                      key: 'full_address',
                                      render: (text, record) =>
                                      record.full_address && record.full_address ? (
                                        record.full_address
                                      ) : (
                                        <span>
                                          {record.full_name} <Tag>{record.only_occupants}{record.description ? record.description : 'Bewoner'}</Tag>
                                      </span>
                                        )
                                    },
                                    {
                                          width: 500,
                                          title: 'Acties',
                                          render: (record, index) =>  {

                                            if(!record.only_occupants) {
                                              return;
                                            }

                                            var own_letter = false;

                                            if(this.getMethod('mail', record.id, null) && this.getMethod('mail', record.id, null).message != null && this.getMethod('mail', record.id, null).message != '') {
                                              own_letter = true;
                                            }

                                            return (
                                              <span>
                                                <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/mail/${record.address_id ? 'occupant' : 'address'}/${record.id}`}>
                                                  <Button disabled={!this.getMethod('mail', null, null).message || !this.props.HaveAbility('correspondence', 'write')} type='link'>Samenstellen</Button>
                                                </Link>
                                                {own_letter && (
                                                  <Button onClick={() => {
                                                      this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {
                                                        'address_id': record.id,
                                                        'type': 'mail',
                                                        'message': '',
                                                        'salutation': '',
                                                        'salutation_type': 'personal',
                                                      }).then(() => {
                                                        this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {
                                                          this.setState({
                                                              currentDataSource: this.props.addresses,
                                                              getMailAddresses: this.getMailAddresses()
                                                          });
                                                        })
                                                      });
                                                      }} disabled={!this.getMethod('mail', null, null).message} type='link'>Terugzetten naar algemene brief</Button>
                                                  )}
                                                </span>
                                              )},
                                    }
                                  ]
                                  }
                                  rowKey="id"
                                  childrenColumnName="occupants"
                                  // dataSource={this.state.getMailAddresses}
                                  dataSource={this.getMailAddresses('mail')}
                                  // expandable={{
                                  //     rowExpandable: (record) => { console.log('rowExpandable', record); return !record.occupants || !record.occupants.length || !record.has_different_address_occupant ? false : true }
                                  // }}
                                />
                            </Collapse.Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </TabPane>
              )}

              { this.state.selectedLetterEmailIds.length > 0 && this.setActiveTab(false, '2') && (
              <TabPane tab="Brief per e-mail" key="2">
                <Card style={{backgroundColor: 'white', borderRadius: '5px', borderTop: 0, marginBottom: 24 }}>

                  <Row type="flex" align="middle">

                    <Col span={24} align="left" style={{ marginBottom: 24 }}>

                      <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/email/email_mail`}>
                        <Button disabled={!this.props.HaveAbility('correspondence', 'write')} type="new">Samenstellen e-mail</Button>
                      </Link>

                      <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/mail/email_mail`}>
                        <Button disabled={!this.props.HaveAbility('correspondence', 'write')} type="new">Samenstellen brief</Button>
                      </Link>

                      <Dropdown disabled={!this.getMethod('email_mail', null, null).message} trigger={['click']} overlay={(
                        <Menu>
                          <Menu.Item key="1" disabled={this.getMethod('email_mail', null, null) && (!this.getMethod('mail', null, null) || !this.getMethod('mail', null, null).message)}>
                            <Link onClick={() => { this.downloadEmailMailEmails(); }}>
                              Download alle e-mails
                            </Link>
                          </Menu.Item>
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item key="2" disabled={this.getMethod('email_mail', null, null) && (!this.getMethod('mail', null, null) || !this.getMethod('mail', null, null).letter_attachment)}>
                            <Link onClick={() => { this.downloadEmailMailMails(); }}>
                              Download alle brieven
                            </Link>
                          </Menu.Item>
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item key="3" disabled={!this.getMethod('email_mail', null, null) || !this.getMethod('email_mail', null, null).message}>
                            <Link onClick={() => this.setState({emailModal: true, emailTestModal: 'email_mail'})}>
                              Voorbeeld
                            </Link>
                          </Menu.Item>
                        </Menu>
                        )}
                        >
                        <Button style={{ marginRight: 10 }}>
                          Acties <DownOutlined />
                        </Button>
                      </Dropdown>

                    </Col>
                  </Row>

                  <Form layout='inline'>
                    <Form.Item label="Verzenden op">
                      <DatePicker showTime={{ format: 'HH:mm' }} placeholder="Direct" format={'DD/MM/YYYY - HH:mm'}
                        defaultValue={this.getMethod('email_mail', null, null).scheduled_for ? dayjs(this.getMethod('email_mail', null, null).scheduled_for) : null} onChange={(value) => this.saveMethodDateTime(value, 'email_mail')} onOk={(value) => this.saveMethodDateTime(value, 'email_mail')} />
                    </Form.Item>
                  </Form>

                  <Collapse bordered={true} defaultActiveKey={[]} style={{marginTop: 16}}>
                    <Collapse.Panel header={`${this.state.selectedLetterEmailIds ? this.state.selectedLetterEmailIds.length : ''} e-mail${this.state.selectedLetterEmailIds && this.state.selectedLetterEmailIds.length > 1 ? '' : 's'}`} key="1">
                      <CustomTable columns={[
                        {
                          title: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                              <Button style={{width: 17, height: 17, margin: 0, padding: 0, paddingTop: -5, marginRight: 10, marginTop: 2}} onClick={() => {

                                  var expandIds = [];

                                  if(!this.state.expandButton) {
                                    this.getEmailMailAddresses().map((v) => {

                                      if(v.occupants) {
                                        expandIds.push(v.id)
                                      }
                                    });
                                  }

                                  this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
                                }}>
                                <span style={{ display: 'flex', justifyContent: 'center', marginTop: -4}}>{this.state.expandButton ? '-' : '+'}</span>
                              </Button>
                              <span>Adressen</span>
                            </div>
                          ),
                          dataIndex: 'full_address',
                          key: 'full_address',
                          render: (text, record) =>
                            record.full_address && record.full_address ? (
                              record.full_address
                            ) : (
                            <span>
                              {record.full_name} <Tag>{record.only_occupants}{record.description ? record.description : 'Bewoner'}</Tag>
                            </span>
                            )
                        },
                        {
                          title: 'Verzenden op',
                          key: 'sent_date',
                          render: (record, index) => record.address_id ? (
                              <span>
                                <DatePicker
                                  format={'DD/MM/YYYY - HH:mm'}
                                  showTime={{format: 'HH:mm'}}
                                  placeholder="Zoals algemene email"
                                  defaultValue={this.getMethod('email_mail', null, record.id) && this.getMethod('email_mail', null, record.id).scheduled_for ? dayjs(this.getMethod('email_mail', null, record.id).scheduled_for) : null }
                                  onChange={(value) => { this.saveMethodDateTime(value, 'email_mail', null, record.id); }}
                                  // onOk={(value) => this.saveMethodDateTime(value, 'email_mail', null, record.occupant_id)}
                                  />
                              </span>) : null,
                        },
                        {
                          width: 500,
                          title: 'Acties',
                          render: (record, index) =>  {

                            if(!record.address_id) {
                              return;
                            }

                            var own_letter = false;

                            if(this.getMethod('email_mail', null, record.id) && this.getMethod('email_mail', null, record.id).letter_attachment != null && this.getMethod('email_mail', null, record.id).letter_attachment != '') {
                              own_letter = true;
                            }

                            return (
                                <span>
                                  <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/email_mail/mail/${record.address_id ? 'occupant' : 'address'}/${record.id}`}>
                                    <Button disabled={!this.getMethod('email_mail', null, null).letter_attachment || !this.props.HaveAbility('correspondence', 'write')} type='link'>Brief samenstellen</Button>
                                  </Link>
                                  {own_letter && (
                                    <Button onClick={() => {
                                        this.props.setupCorrespondenceMethodsAction(this.props.match.params.correspondenceid, {
                                          [record.address_id ? 'occupant_id' : 'address_id']: record.id,
                                          'type': 'email_mail',
                                          'message': '',
                                          'letter_attachment': '',
                                          'salutation': '',
                                          'salutation_type': 'personal',
                                        }).then(() => {
                                          this.props.getCorrespondenceAction(this.props.match.params.correspondenceid).then(() => {
                                            this.setState({
                                                currentDataSource: this.props.addresses,
                                                getMailAddresses: this.getMailAddresses()
                                            });
                                          })
                                        });
                                      }} disabled={!this.getMethod('email_mail', null, null).letter_attachment} type='link'>Terugzetten naar algemene brief</Button>
                                    )}
                                </span>
                                )
                              },
                          }
                        ]}
                        rowKey="id"
                        childrenColumnName="occupants"
                        onExpandedRowsChange={(id) => this.setState({expandedRowKeys: id})}
                        expandedRowKeys={this.state.expandedRowKeys}
                        dataSource={this.getEmailMailAddresses()}
                        />
                    </Collapse.Panel>

                  </Collapse>

                </Card>

              </TabPane>
              )}

              { this.state.selectedEmailIds.length > 0 && this.setActiveTab(false, '3') && (
              <TabPane tab="E-mail" key="3">

                <Card style={{backgroundColor: 'white', borderRadius: '5px', borderTop: 0, marginBottom: 24 }}>

                  <Row type="flex" align="middle" style={{ marginBottom: 24 }}>

                    <Col span={24} align="left">

                      <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/email/email`}>
                        <Button disabled={!this.props.HaveAbility('correspondence', 'write')} type="new">Samenstellen</Button>
                      </Link>
                      {/*<Button disabled={!this.getMethod('email', null, null).message} onClick={() => this.setState({emailModal: true, emailTestModal: 'email'})} style={{marginRight: 10}}>Voorbeeld</Button>*/}
                      <Dropdown disabled={!this.getMethod('email', null, null) || !this.getMethod('email', null, null).message} trigger={['click']} overlay={(
                        <Menu>
                          <Menu.Item key="1" disabled={!this.getMethod('email', null, null) || !this.getMethod('email', null, null).message}>
                            <Link to={false} onClick={() => { this.downloadEmails(); return true; }}>
                              Download alle e-mails
                            </Link>
                          </Menu.Item>
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item key="2" disabled={!this.getMethod('email', null, null) || !this.getMethod('email', null, null).message}>
                            <Link to={false} onClick={() => this.setState({emailModal: true, emailTestModal: 'email'})}>
                              Voorbeeld
                            </Link>
                          </Menu.Item>
                        </Menu>
                        )}
                        >
                        <Button style={{ marginRight: 10 }}>
                          Acties <DownOutlined />
                        </Button>
                      </Dropdown>

                    </Col>

                  </Row>

                  <Form layout='inline'>
                    <Form.Item label="Verzenden op">
                      <DatePicker showTime={{ format: 'HH:mm' }} placeholder="Direct" format={'DD/MM/YYYY - HH:mm'}
                        defaultValue={this.getMethod('email', null, null).scheduled_for ? dayjs(moment(this.getMethod('email', null, null).scheduled_for)) : null} onChange={(value) => this.saveMethodDateTime(value, 'email')} onOk={(value) => this.saveMethodDateTime(value, 'email')} />
                    </Form.Item>
                  </Form>

                  <Collapse bordered={true} defaultActiveKey={[]} style={{marginTop: 16}}>
                    <Collapse.Panel header={`${this.state.selectedEmailIds ? this.state.selectedEmailIds.length : ''} e-mail${this.state.selectedLetterIds && this.state.selectedLetterIds.length > 1 ? '' : 's'}`} key="1">
                      <CustomTable columns={[
                          {
                            title: (
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Button style={{width: 17, height: 17, margin: 0, padding: 0, paddingTop: -5, marginRight: 10, marginTop: 2}} onClick={() => {

                                  var expandIds = [];
                                  if(!this.state.expandButton) {
                                    this.getEmailAddresses().map((v) => {
                                      if(v.occupants) {
                                        expandIds.push(v.id)
                                      }
                                    });
                                  }

                                  this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
                                }}><span style={{ display: 'flex', justifyContent: 'center', marginTop: -4}}>{this.state.expandButton ? '-' : '+'}</span></Button>
                                <span>Adressen</span>
                              </div>
                            ),
                            dataIndex: 'full_address',
                            key: 'full_address',
                            render: (text, record) =>
                            record.full_address && record.full_address ? (
                              record.full_address
                            ) : (
                              <span>
                                {record.full_name} <Tag>{record.only_occupants}{record.description ? record.description : 'Bewoner'}</Tag>
                            </span>
                          )
                        },
                        {
                          title: 'Verzenden op',
                          key: 'sent_date',
                          render: (record, index) => record.address_id ? (
                            <span>
                              <DatePicker format={'DD/MM/YYYY - HH:mm'} showTime={{ format: 'HH:mm' }} placeholder="Zoals algemene email" defaultValue={this.getMethod('email', record.address_id ? null : record.id, record.address_id ? record.id : null) && this.getMethod('email', record.address_id ? null : record.id, record.address_id ? record.id : null).scheduled_for ? dayjs(moment(this.getMethod('email', record.address_id ? null : record.id, record.address_id ? record.id : null).scheduled_for)) : null }
                                onChange={(value) => this.saveMethodDateTime(value, 'email', record.address_id ? null : record.id, record.address_id ? record.id : null)
                                } onOk={(value) => this.saveMethodDateTime(value, 'email', record.address_id ? null : record.id, record.address_id ? record.id : null)} />

                              </span>
                            ) : null,
                          },
                          {
                            width: 500,
                            title: 'Acties',
                            render: (record, index) =>  {

                              if(!record.only_occupants) {
                                return;
                              }

                              return (
                                <span>
                                  <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/email/${record.address_id ? 'occupant' : 'address'}/${record.id}`}>
                                    <Button disabled={!this.getMethod('mail', null, null).message} type='link'>Samenstellen</Button>
                                  </Link>
                                </span>
                              )},
                            }
                          ]}
                          rowKey="id"
                          childrenColumnName="occupants"
                          onExpandedRowsChange={(id) => this.setState({expandedRowKeys: id})}
                          expandedRowKeys={this.state.expandedRowKeys}
                          dataSource={this.getEmailAddresses()}
                          />

                      </Collapse.Panel>

                    </Collapse>

                  </Card>

                </TabPane>
                )}

                { this.state.selectedSmsIds.length > 0 && this.setActiveTab(false, '4') && (
                <TabPane tab="SMS" key="4">
                  <Card style={{ backgroundColor: 'white', borderRadius: '5px', borderTop: 0, marginBottom: 24 }}>
                    <Row type="flex" align="middle">
                      <Col span={24} align="left" style={{ marginBottom: 24 }}>
                        <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/sms`}>
                          <Button disabled={!this.props.HaveAbility('correspondence', 'write')} type="new">Samenstellen</Button>
                        </Link>

                        <Dropdown disabled={!this.getMethod('sms', null, null) || !this.getMethod('sms', null, null).message} trigger={['click']} overlay={(
                          <Menu>
                            <Menu.Item key="1" disabled={!this.getMethod('sms', null, null) || !this.getMethod('sms', null, null).message}>
                              <Link to={false} onClick={() => { this.downloadSMS(); return true; }}>
                                Download alle SMS berichten
                              </Link>
                            </Menu.Item>
                          </Menu>
                          )}
                          >
                          <Button style={{ marginRight: 10 }}>
                            Acties <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Form layout='inline'>
                      <Form.Item label="Verzenden op">
                        <DatePicker showTime={{ format: 'HH:mm' }} placeholder="Direct" format={'DD/MM/YYYY - HH:mm'}
                          defaultValue={this.getMethod('sms', null, null).scheduled_for ? dayjs(moment(this.getMethod('sms', null, null).scheduled_for)) : null} onChange={(value) => this.saveMethodDateTime(value, 'sms')} onOk={(value) => this.saveMethodDateTime(value, 'sms')} />
                      </Form.Item>
                    </Form>

                    <Collapse bordered={true} defaultActiveKey={[]} style={{marginTop: 16}}>
                      <Collapse.Panel header={`${this.state.selectedSmsIds ? this.state.selectedSmsIds.length : ''} sms${this.state.selectedSmsIds && this.state.selectedSmsIds.length > 1 ? 'jes' : ''}`} key="1">
                        <CustomTable columns={[
                            {
                              title: (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                  <Button style={{width: 17, height: 17, margin: 0, padding: 0, paddingTop: -5, marginRight: 10, marginTop: 2}} onClick={() => {

                                    var expandIds = [];
                                    if(!this.state.expandButton) {
                                      this.getSmsAddresses().map((v) => {
                                        if(v.occupants) {
                                          expandIds.push(v.id)
                                        }
                                      });
                                    }

                                    this.setState({expandedRowKeys: expandIds, expandButton: !this.state.expandButton});
                                  }}><span style={{ display: 'flex', justifyContent: 'center', marginTop: -4}}>{this.state.expandButton ? '-' : '+'}</span></Button>
                                  <span>Adressen</span>
                                </div>
                              ),
                              dataIndex: 'full_address',
                              key: 'full_address',
                              render: (text, record) =>
                              record && record.full_address ? (
                                record.full_address
                            ) : record ? (
                                <span>
                                  {record.full_name} <Tag>{record.only_occupants}{record.description ? record.description : 'Bewoner'}</Tag>
                              </span>
                            ) : null
                          },
                          {
                            title: 'Verzenden op',
                            key: 'sent_date',
                            render: (record, index) => record && record.address_id ? (
                              <span>
                                <DatePicker format={'DD/MM/YYYY - HH:mm'} showTime={{ format: 'HH:mm' }} placeholder="Zoals algemene sms" defaultValue={this.getMethod('sms', record.address_id ? null : record.id, record.address_id ? record.id : null) && this.getMethod('sms', record.address_id ? null : record.id, record.address_id ? record.id : null).scheduled_for ? dayjs(moment(this.getMethod('sms', record.address_id ? null : record.id, record.address_id ? record.id : null).scheduled_for)) : null }
                                  onChange={(value) => this.saveMethodDateTime(value, 'sms', record.address_id ? null : record.id, record.address_id ? record.id : null)
                                  } onOk={(value) => this.saveMethodDateTime(value, 'sms', record.address_id ? null : record.id, record.address_id ? record.id : null)} />

                                </span>
                              ) : null,
                            },
                            {
                              width: 500,
                              title: 'Acties',
                              render: (record, index) =>  {

                                if(!record || !record.only_occupants) {
                                  return;
                                }

                                return (
                                  <span>
                                    <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/email/${record.address_id ? 'occupant' : 'address'}/${record.id}`}>
                                      <Button disabled={!this.getMethod('sms', null, null).message} type='link'>Samenstellen</Button>
                                    </Link>
                                  </span>
                                )},
                              }
                            ]}
                            rowKey="id"
                            childrenColumnName="occupants"
                            onExpandedRowsChange={(id) => this.setState({expandedRowKeys: id})}
                            expandedRowKeys={this.state.expandedRowKeys}
                            dataSource={this.getSmsAddresses()} />
                        </Collapse.Panel>
                      </Collapse>
                  </Card>
                </TabPane>
                )}

                { this.state.selectedPushIds.length > 0 && this.setActiveTab(false, '5') && (
                <TabPane tab="Push berichten" key="5">
                  <Card style={{backgroundColor: 'white', borderRadius: '5px', borderTop: 0, marginBottom: 24 }}>
                    <Row type="flex" align="middle">
                      <Col span={24} align="left" style={{ marginBottom: 24 }}>
                        <Link to={`/projects/${this.props.match.params.id}/correspondence/${this.props.match.params.correspondenceid}/compose/push`}>
                          <Button disabled={!this.props.HaveAbility('correspondence', 'write')} type="new">Samenstellen</Button>
                        </Link>

                        <Dropdown disabled={!this.getMethod('push', null, null) || !this.getMethod('push', null, null).message} trigger={['click']} overlay={(
                          <Menu>
                            <Menu.Item key="1" disabled={!this.getMethod('push', null, null) || !this.getMethod('push', null, null).message}>
                              <Link to={false} onClick={() => { this.downloadPush(); return true; }}>
                                Download alle push berichten
                              </Link>
                            </Menu.Item>
                          </Menu>
                          )}
                          >
                          <Button style={{ marginRight: 10 }}>
                            Acties <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Form layout='inline'>
                      <Form.Item label="Verzenden op">
                        <DatePicker showTime={{ format: 'HH:mm' }} placeholder="Direct" format={'DD/MM/YYYY - HH:mm'}
                          defaultValue={this.getMethod('push', null, null).scheduled_for ? dayjs(moment(this.getMethod('push', null, null).scheduled_for)) : null} onChange={(value) => this.saveMethodDateTime(value, 'push')} onOk={(value) => this.saveMethodDateTime(value, 'push')} />
                      </Form.Item>
                    </Form>
                  </Card>
                </TabPane>
                )}
                </Tabs>
              </div>

              <Modal
                title="Email voorbeeld"
                visible={this.state.emailModal}
                okText='Versturen'
                onOk={() => this.sendTestEmail()}
                onCancel={() => this.setState({emailModal: false})}
              >
                <EmailTestForm wrappedComponentRef={formRef => { this.emailTestForm = formRef }} />
              </Modal>


              <Modal
                title="Download brieven"
                visible={this.state.downloadLetters}

                onOk={() => {

                    //this.setState({ downloadLetters: false });
                    //this.downloadLetters();

                    this.emailGeneratedLetters.props.form.validateFields((error, values) => {

                      if(!error) {
                        this.props.requestDownloadLetters(this.props.match.params.correspondenceid, { 'email': values.email }).then(() => {});

                        this.setState({ downloadLetters: false });

                      this.emailGeneratedLetters.props.form.resetFields();
                    }
                  });
              }}

              onCancel={() => this.setState({downloadLetters: false})}
              okText={'Versturen'}
            >
              <EmailGeneratedLetters wrappedComponentRef={formRef => { this.emailGeneratedLetters = formRef }} />
                {/*<Form.Item label="Verstuur de download link naar het volgende e-mailadres:">
                  <Input value={this.state.emailTo} onChange={(e) => this.setState({emailTo: e.target.value})} />
                </Form.Item>*/}

            </Modal>

          </div>
    );
      }
    }

const ProjectsCorrespondenceComposeForm = Form.create({ name: 'ProjectsCorrespondenceCompose' })(ProjectsCorrespondenceCompose);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.correspondence.isFetching,
    isError : state.correspondence.isError,
    correspondence : state.correspondence.correspondence,
    addresses : state.correspondence.addresses,
    groups : state.group.groups,
    project : state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCorrespondenceAddressesAction : bindActionCreators(getCorrespondenceAddressesAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    addCorrespondenceAction : bindActionCreators(addCorrespondenceAction, dispatch),
    patchCorrespondenceAction : bindActionCreators(patchCorrespondenceAction, dispatch),
    getCorrespondenceAction : bindActionCreators(getCorrespondenceAction, dispatch),
    setupCorrespondenceMethodsAction : bindActionCreators(setupCorrespondenceMethodsAction, dispatch),
    sendTestEmailAction : bindActionCreators(sendTestEmailAction, dispatch),
    patchOnStationeryAction : bindActionCreators(patchOnStationeryAction, dispatch),
    requestDownloadLetters : bindActionCreators(requestDownloadLetters, dispatch),
    getNrOfNewQueuedItems : bindActionCreators(getNrOfNewQueuedItems, dispatch),
    requestDownloadMethod : bindActionCreators(requestDownloadMethod, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsCorrespondenceComposeForm);
