import api from '../utils/api'

export const getScheduleAction = (projectid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_FETCH'
    })

    return api('get','/schedule/'+projectid, null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const getScheduledAddressesAction = (project_id) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_FETCH'
    })

    return api('get','/schedule/'+project_id+'/scheduled-addresses', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ADDRESSES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const searchScheduledAddressesAction = (project_id, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_FETCH'
    })

    return api('post','/schedule/'+project_id+'/scheduled-addresses/search', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ADDRESSES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const getScheduleBlockedDatesAction = (projectid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_BLOCKEDDATES_ACTION_FETCH'
    })

    return api('get','/schedule/' + projectid + '/blocked-dates/', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_BLOCKEDDATES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_BLOCKEDDATES_ACTION_ERROR',
        })
        return false;
    });
}

export const getDayScheduleAction = (projectid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_DAYSCHEDULE_ACTION_FETCH'
    })

    return api('get','/schedule/' + projectid + '/groups/', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_DAYSCHEDULE_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_DAYSCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const getScheduleGroupAction = (groupid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_GROUP_ACTION_FETCH'
    })

    return api('get','/schedule/group/' + groupid, null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_GROUP_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const getScheduleGroupDaysAction = (groupid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_GROUPDAYS_ACTION_FETCH'
    })

    return api('get','/schedule/group/' + groupid + '/days', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_GROUPDAYS_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_GROUPDAYS_ACTION_ERROR',
        })
        return false;
    });
}

export const getScheduleGanttAction = (scheduleid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_FETCH'
    })

    return api('get','/schedule/group/' + scheduleid + '/gantt/address-schedule', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GANTT_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateScheduleSettingsAction = (id, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_SETTINGS_UPDATE'
    })

    return api('post', '/schedule/' + id, params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_SETTINGS_UPDATE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_SETTINGS_ERROR',
        })
        return false;
    });
}

export const addScheduleBlockedDateAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_BLOCKEDDATES_ADD'
    })

    return api('post', '/schedule/'+projectid+'/blocked-date', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_BLOCKEDDATES_ADD_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_BLOCKEDDATES_ERROR',
        })
        return false;
    });
}

export const updateScheduleBlockedDatesAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_BLOCKEDDATES_UPDATE'
    })

    return api('post', '/schedule/'+projectid+'/gantt', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_BLOCKEDDATES_UPDATE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_BLOCKEDDATES_ERROR',
        })
        return false;
    });
}

export const patchDayScheduleGanttAction = (scheduleid, params) => dispatch => {
    dispatch({
        type: 'SCHEDULE_BLOCKEDDATES_ACTION_FETCH'
    })
    return api('post','/schedule/group/' + scheduleid + '/gantt/address-schedule', params, true).then((response) => {
        // dispatch({
        //     type: 'SCHEDULE_ACTION_BLOCKEDDATES_REMOVE_SUCCESS',
        //     payload: response.data
        // });
    }).catch((e) => {
        // dispatch({
        //     type: 'SCHEDULE_BLOCKEDDATES_ACTION_ERROR',
        // })
        return false;
    });
}

export const removeBlockedDatesAction = (scheduleid, params) => dispatch => {
    dispatch({
        type: 'SCHEDULE_BLOCKEDDATES_ACTION_FETCH'
    })
    return api('post','/schedule/' + scheduleid + '/blocked-date/multidelete/', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_BLOCKEDDATES_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_BLOCKEDDATES_ACTION_ERROR',
        })
        return false;
    });
}

export const removeDayScheduleAction = (scheduleid, params) => dispatch => {
    dispatch({
        type: 'SCHEDULE_DAYSCHEDULE_ACTION_FETCH'
    })
    return api('post','/schedule/' + scheduleid + '/group/multidelete/', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_DAYSCHEDULE_REMOVE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_DAYSCHEDULE_ACTION_ERROR',
        })
        return false;
    });
}

export const addDayScheduleAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_GROUP_ADD'
    })

    return api('post', '/schedule/'+projectid+'/group', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GROUP_ADD_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GROUP_ERROR',
        })
        return false;
    });
}

export const updateDayScheduleAction = (groupid, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_GROUP_UPDATE'
    })

    return api('post', '/schedule/group/'+groupid, params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GROUP_UPDATE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GROUP_ERROR',
        })
        return false;
    });
}

export const duplicateDayScheduleAction = (id) => dispatch => {

    // dispatch({
    //     type: 'SCHEDULE_ACTION_GROUP_UPDATE'
    // })

    return api('get', '/schedule/group/' + id + '/clone', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_DUPLICATE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GROUP_ERROR',
        })
        return false;
    });
}

export const updateScheduleGanttAction = (projectid, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_GANTT_UPDATE'
    })

    return api('post', '/schedule/'+projectid+'/gantt', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GANTT_UPDATE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GANTT_ERROR',
        })
        return false;
    });
}

export const getGanttAction = (projectid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_GANTT'
    })

    return api('get', '/schedule/'+projectid+'/gantt', null, true).then((response) => {
        dispatch({
            type: 'ACTION_GANTT_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GANTT_ERROR',
        })
        return false;
    });
}

export const resetScheduleAction = () => dispatch => {
    dispatch({
        type: 'SCHEDULE_ACTION_RESET'
    })
}

export const regenerateScheduleAction = (projectid) => dispatch => {

    // dispatch({
    //     type: 'SCHEDULE_GROUPADDRESSES_ACTION_FETCH'
    // })

    return api('get','/schedule/' + projectid + '/gantt/regenerate', null, true).then((response) => {
        // dispatch({
        //     type: 'SCHEDULE_GROUPADDRESSES_ACTION_SUCCESS',
        //     payload: response.data
        // });
        return true;
    }).catch((e) => {
        // dispatch({
        //     type: 'SCHEDULE_GROUPADDRESSES_ACTION_ERROR',
        // })
        return false;
    });
}

export const getSelectedGroupAddresses = (groupid) => dispatch => {

    dispatch({
        type: 'SCHEDULE_GROUPADDRESSES_ACTION_FETCH'
    })

    return api('get','/schedule/group/' + groupid + '/addresses', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_GROUPADDRESSES_ACTION_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_GROUPADDRESSES_ACTION_ERROR',
        })
        return false;
    });
}

export const updateSelectedGroupAddresses = (groupid, params) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_GGROUPADDRESSES_UPDATE'
    })

    return api('post', '/schedule/group/'+groupid+'/addresses/', params, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GROUPADDRESSES_UPDATE_SUCCESS',
            payload: response.data
        });
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_GROUPADDRESSES_ERROR',
        })
        return false;
    });
}

export const getKypProjectsAction = (customer_id) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_KYP_PROJECTS_UPDATE'
    })

    return api('get', '/schedule/kyp/projects/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_KYP_PROJECTS_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_KYP_PROJECTS_ERROR',
        });

        return false;
    });
}

export const getKypProjectAddressesAction = (project_id, kyp_project_id) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_KYP_PROJECT_ADDRESSES_UPDATE'
    })

    return api('get', '/schedule/'+project_id+'/kyp/'+kyp_project_id+'/addresses', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_KYP_PROJECT_ADDRESSES_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_KYP_PROJECT_ADDRESSES_ERROR',
        })
        return false;
    });
}

export const getScheduleKypItemsAction = (project_id, kyp_project_id) => dispatch => {

    dispatch({
        type: 'SCHEDULE_ACTION_KYP_PROJECT_ITEMS_UPDATE'
    })

    return api('get', '/schedule/'+project_id+'/kyp/'+kyp_project_id+'/items', null, true).then((response) => {
        dispatch({
            type: 'SCHEDULE_ACTION_KYP_PROJECT_ITEMS_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'SCHEDULE_ACTION_KYP_PROJECT_ITEMS_ERROR',
        })
        return false;
    });
}