import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import ChoiceSideMenu from '../ChoiceSideMenu';
import ChoiceFormSteps from './ChoiceFormSteps';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Card,
    Button,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../utils/Notifications';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder';
import { getChoiceFormByIdAction, updateChoiceFormAction, addChoiceFormToProjectAction } from '../../../../actions/choiceActions';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';
import moment from 'moment';
import 'moment/locale/nl';
import { JOB_URL } from '../../../../constants/settings';

class ChoiceFormCompose extends React.Component {

  state = {
      formData: null,
      can_write: false,
      loading: false
  }

  constructor(props) {

    super(props);
  }

  componentDidMount()
  {
      this.setState({
          can_write: this.props.HaveAbility('choice' ,'write')
      });

    this.props.getChoiceFormByIdAction(this.props.match.params.form_id, false).then(() => {

        if(this.props.choice_form && this.props.choice_form.form && this.IsJsonString(this.props.choice_form.form)){

          if(JSON.parse(this.props.choice_form.form))
          {
              this.refs.FormBuilder.parse(JSON.parse(this.props.choice_form.form));
              this.setState({
                  formData: JSON.parse(this.props.choice_form.form)
              });
          }
        }
        else
        {   // default form
            var default_section = [
                {
                    "id": "default",
                    "label": "Nieuwe sectie",
                    "elements": []
                }
            ];

            this.refs.FormBuilder.parse(default_section);

            this.setState({ formData: default_section });
        }
    });
  }

  IsJsonString = (str) => {

    try {

      JSON.parse(str);
    } catch (e) {

      return false;
    }

    return true;
  }

  patchInformationAddresses() {}

  save()
  {
      var formData = JSON.stringify(this.state.formData);

      var params = {
          form: formData,
      };

      this.props.updateChoiceFormAction(this.props.match.params.form_id, params).then(() => {});
  }

  saveStep(url = false)
  {
      var formData = null;

      if(this.refs.FormBuilder)
      {
          formData = JSON.stringify(this.refs.FormBuilder.getFormData());
      }

      this.saveAndContinue(url, formData);
  }

  saveAndContinue(url = false, formData = null)
  {
      if(!formData)
      {
          formData = JSON.stringify(this.refs.FormBuilder.getFormData());
      }

      var params = {
          form: formData
      };

      this.props.updateChoiceFormAction(this.props.match.params.form_id, params).then(() => {

          showSuccess('Succesvol opgeslagen');

          if(!url){ url = `/projects/${this.props.match.params.id}/choice/form/addresses/${this.props.match.params.form_id}`; }

          this.props.history.push(url);
      });
  }

  render()
  {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ChoiceSideMenu projectid={this.props.match.params.id} activeMenu="2"/>

          <div style={{padding: 24, width: '100%'}}>

              {/* buttons */}
              <Row className="mbm">

                <Col span={12}>
                  <div>
                    <Button disabled={!this.state.can_write || this.state.loading} type="new" onClick={() => this.saveAndContinue()}>Opslaan en volgende</Button>
                    <Link to={`/projects/${this.props.match.params.id}/choice/forms`}>
                      <Button>Sluiten</Button>
                    </Link>
                  </div>
                </Col>

                <Col span={12} align={'right'}>
                    <a href={JOB_URL + `/choice/form/${this.props.match.params.form_id}/preview?token=${localStorage.authToken}`} target='_blank'>
                      <Button>Voorbeeld</Button>
                    </a>
                </Col>

              </Row>

              <Row className="mbm">

                <Col span={24}>
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <ChoiceFormSteps current={1} saveStep={(url) => { this.saveStep(url); }} {...this.props} />
                  </Card>
                </Col>

              </Row>

              <Row>
                <Col span={24}>
                  <FormBuilder
                    onChangeForm={(data) => this.setState({ formData: data }, () => { this.save(); })}
                    ref={'FormBuilder'}
                    formId={this.props.match.params.form_id}
                    // sections={1}
                    enabledElements={
                        this.props.project.is_public ?
                        ['c_title', 'c_textinput', 'c_textareainput', 'c_numberinput', 'c_calculatorinput', 'c_timeinput', 'c_dateinput', 'c_choice', /*'c_contactinput',*/ 'c_select', 'c_addressinput', 'c_photoinput']
                        :
                        ['c_title', 'c_textinput', 'c_textareainput', 'c_numberinput', 'c_calculatorinput', 'c_timeinput', 'c_dateinput', 'c_choice', 'c_contactinput', 'c_select', 'c_addressinput', 'c_photoinput']
                    }
                    disablePrices={this.props.choice_form && this.props.choice_form.disable_prices ? true : false}
                    disabled={!this.props.HaveAbility('choice' ,'write')}
                    />
                </Col>
              </Row>

          </div>

      </div>
    );
  }
}

// export default ProjectIntakeAddresses;
const ChoiceFormComposeForm = Form.create({ name: 'choice_form' })(ChoiceFormCompose);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.intake.isFetching,
        isError : state.intake.isError,
        choice_form : state.choice.choice_form,
        project : state.project.project
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChoiceFormByIdAction : bindActionCreators(getChoiceFormByIdAction, dispatch),
        addChoiceFormToProjectAction : bindActionCreators(addChoiceFormToProjectAction, dispatch),
        updateChoiceFormAction : bindActionCreators(updateChoiceFormAction, dispatch),
        // checkChoiceFormAction : bindActionCreators(checkChoiceFormAction, dispatch),
        HaveAbility:  bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceFormComposeForm);
