import React, { Component } from 'react';
// import '../Schedule.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox, DatePicker, Input, Typography, Switch, Select } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { getScheduleAction, getKypProjectsAction, getKypProjectAddressesAction } from '../../../../../actions/scheduleActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import AddFromMedia from '../../../../../components/AddFromMedia/AddFromMedia';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title, Text, Paragraph } = Typography;

class Settings extends Component
{
  state = {
    schedule: {},
    image_id: false,
    filelist: [],
    // cobee_kyp_addresses: [],
  }

  componentDidMount()
  {
    this.props.getScheduleAction(this.props.match.params.id).then(() => {

      if(this.props.customer.use_kyp)
      {
          this.props.getKypProjectsAction(this.props.customer.id);

          // this.props.getAddressesAction({ projectId: this.props.project.id }).then(() => {

          //     var cobee_kyp_addresses = {};

          //     this.props.addresses.map((address) => {

          //         cobee_kyp_addresses[''+address.id] = address.construction_number;
          //     });

          //     this.setState({ cobee_kyp_addresses: cobee_kyp_addresses });

          // });

          // if(this.props.project.kyp_project_id)
          // {
          //     this.props.getKypProjectAddressesAction(this.props.project.id, this.props.project.kyp_project_id);
          // }

          if(this.props.schedule.use_kyp_schedule)
          {
              this.props.useKyp(true);
          }
      }

      this.setState({
        use_kyp_schedule: this.props.customer.use_kyp == true ? (this.props.schedule.use_kyp_schedule ? true : false) : false,
        kyp_project_id: this.props.project.kyp_project_id,
        image_id: this.props.schedule.image_id
      });

    });
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout={'vertical'} onSubmit={this.props.onSubmit}>

        { this.props.customer.use_kyp == true ? <Row>
          <Col span={24}>
            <Title level={4}>Kyp</Title>
          </Col>

          <Col span={24}>
              <Form.Item label="Gebruik Kyp:">
                {getFieldDecorator('use_kyp_schedule', {
                  valuePropName: 'checked',
                  initialValue: this.state.use_kyp_schedule ? true : false,
                  onChange: (checked) => {
                      this.setState({ use_kyp_schedule: checked }, () => { this.props.useKyp(this.state.use_kyp_schedule ? true : false); });
                  }
                })(
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    />
                )}
              </Form.Item>
          </Col>

          { this.state.use_kyp_schedule ? <Col span={24}>
              <Form.Item label="Kyp project:">
                {getFieldDecorator('kyp_project_id', {
                  initialValue: ''+(this.props.project.kyp_project_id ? this.props.project.kyp_project_id : ''),
                  onChange: (value) => {
                      this.setState({ kyp_project_id: value });
                      // this.props.getAddressesAction({ projectId: this.props.project.id }, value);

                      // if(value)
                      // {
                      //     this.props.getKypProjectAddressesAction(this.props.project.id, value);
                      // }
                  }
                })(
                <Select>
                    <Select.Option value={''}></Select.Option>
                    { this.props.kyp_projects.map((kyp_project) => {
                        return <Select.Option key={''+kyp_project.id} value={''+kyp_project.id}>{kyp_project.name}</Select.Option>
                    }) }
                </Select>
                )}
              </Form.Item>
          </Col> : null }

          { /*this.state.use_kyp_schedule ? <Col span={12}>

            <Row gutter={24} style={{ marginBottom: 8 }}>
                <Col span={12} style={{ fontWeight: 'bold' }}>Cobee adres</Col>
                <Col span={12} style={{ fontWeight: 'bold' }}>Kyp adres</Col>
            </Row>

            { this.props.addresses.map((address) => (
            <Row gutter={24} style={{ marginBottom: 4 }}>
                <Col span={12}>{address.full_address}</Col>
                <Col span={12}>
                    <Select
                        defaultValue={''+(address.construction_number ? address.construction_number : '')}
                        onChange={(value) => {
                            this.setState({
                                cobee_kyp_addresses: {...this.state.cobee_kyp_addresses, [''+address.id]: value}
                            }, () => {
                                this.props.setCobeeKypAddresses(this.state.cobee_kyp_addresses);
                            });
                        }}
                        style={{ width: '100%' }}
                        >
                        <Select.Option value={''}></Select.Option>
                        { this.props.kyp_addresses.map((kyp_address) => {
                            return <Select.Option value={''+kyp_address}>{kyp_address}</Select.Option>
                        }) }
                    </Select>
                </Col>
            </Row>
            ))}

          </Col> : null*/ }

        </Row> : null }

        { !this.state.use_kyp_schedule ? <div>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Werkdagen</Title>
              </Col>
              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('monday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.monday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Maandag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('tuesday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.tuesday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Dinsdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('wednesday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.wednesday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Woensdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('thursday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.thursday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Donderdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('friday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.friday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Vrijdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('saturday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.saturday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Zaterdag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  {getFieldDecorator('sunday_workday', {
                    initialValue: this.props.schedule && this.props.schedule.sunday_workday ? true : false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>
                      Zondag
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>

                <Form.Item label="Opmerkingen" hasFeedback>
                  {getFieldDecorator('comment', {
                    initialValue: this.props.schedule && this.props.schedule.comment,
                  })(
                    <TextArea rows={4} />
                  )}
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item label="Afbeelding">
                  <AddFromMedia
                    projectId={this.props.match.params.id}
                    initialMediaId={this.props.schedule && this.props.schedule.image_id}
                    initialMediaUrl={this.props.schedule && this.props.schedule.image_url}
                    onMediaSelection={(item) => this.setState({image_id: item.id})}
                    onMediaRemove={() => this.setState({image_id: null})}
                  />

                </Form.Item>
              </Col>
            </Row>
        </div> : null }
      </Form>
    );
  }
}

const SettingsPage = Form.create({ name: 'data_form' })(Settings);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.schedule.isFetching,
    isError: state.schedule.isError,
    schedule: state.schedule.schedule,
    kyp_projects: state.schedule.kyp_projects,
    // addresses: state.address.addresses,
    // kyp_addresses: state.schedule.kyp_addresses,
    customer: state.customer.customer,
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScheduleAction: bindActionCreators(getScheduleAction, dispatch),
    getKypProjectsAction: bindActionCreators(getKypProjectsAction, dispatch),
    // getAddressesAction: bindActionCreators(getAddressesAction, dispatch),
    // getKypProjectAddressesAction: bindActionCreators(getKypProjectAddressesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
