import React, { Component } from 'react';
// import '@ant-design/compatible/assets/index.css';
// import { Form } from '@ant-design/compatible';
import { Row, Col, Card, Select, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../../utils/Notifications';
import ScheduleKypSteps from '../Steps/ScheduleKypSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { updateScheduleSettingsAction, getKypProjectAddressesAction } from '../../../../../actions/scheduleActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';

class ScheduleKypAddresses extends Component
{
  state = {
      cobee_kyp_addresses: {}
  };

  componentDidMount()
  {
    this.props.getAddressesAction({ projectId: this.props.project.id }).then(() => {

        var cobee_kyp_addresses = {};

        this.props.addresses.map((address) => {

            cobee_kyp_addresses[''+address.id] = address.construction_number;
        });

        this.setState({ cobee_kyp_addresses: cobee_kyp_addresses });

    });

    if(this.props.project.kyp_project_id)
    {
        this.props.getKypProjectAddressesAction(this.props.project.id, this.props.project.kyp_project_id);
    }    
  }

  saveSettings(e) {

      var values = {};

      values.cobee_kyp_addresses = JSON.stringify(this.state.cobee_kyp_addresses);

      this.props.updateScheduleSettingsAction(this.props.match.params.id, values).then(() => {

        showSuccess('Succesvol opgeslagen');

        this.props.history.push(`/projects/${this.props.match.params.id}/modules/schedule/kyp-information-items`);
      });
  }

  render()
  {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => this.saveSettings(e) }>Opslaan en volgende</Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleKypSteps current={1} {...this.props} />
          </Card>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Card>

                <Row gutter={24} style={{ marginBottom: 8 }}>
                    <Col span={6} style={{ fontWeight: 'bold' }}>Cobee adres</Col>
                    <Col span={6} style={{ fontWeight: 'bold' }}>Kyp adres</Col>
                </Row>

                { this.props.addresses.map((address) => (
                <Row gutter={24} style={{ marginBottom: 4 }}>
                    <Col span={6}>{address.full_address}</Col>
                    <Col span={6}>
                        <Select
                            defaultValue={''+(address.construction_number ? address.construction_number : '')}
                            onChange={(value) => {
                                this.setState({
                                    cobee_kyp_addresses: { ...this.state.cobee_kyp_addresses, [''+address.id]: value }
                                });
                            }}
                            style={{ width: '100%' }}
                            >
                            <Select.Option value={''}></Select.Option>
                            { this.props.kyp_addresses.map((kyp_address) => {
                                return <Select.Option value={''+kyp_address}>{kyp_address}</Select.Option>
                            }) }
                        </Select>
                    </Col>
                </Row>
                ))}

            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    project: state.project.project,
    addresses: state.address.addresses,
    kyp_addresses: state.schedule.kyp_addresses,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    // getKypProjectsAction : bindActionCreators(getKypProjectsAction, dispatch),
    getKypProjectAddressesAction : bindActionCreators(getKypProjectAddressesAction, dispatch),
    getAddressesAction: bindActionCreators(getAddressesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleKypAddresses);
