import React, { Component } from 'react';
// import './Appointments.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import { getAppointmentsUserAction, addAppointmentsUserAvailabilityAction, updateAppointmentsUserAvailabilityAction, deleteAppointmentsUserAvailabilitiesAction } from '../../../../actions/appointmentsActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { API_URL } from '../../../../constants/settings';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { DownOutlined, PlusOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import AppointmentsSideMenu from './AppointmentsSideMenu';
import { Form, Row, Col, Card, Typography, Input, Upload, Tag, Button, Drawer, Modal, Popconfirm, message, Menu, Dropdown, Search, Select, DatePicker, TimePicker, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

class AppointmentsUser extends Component {

  form = null;

  state = {

      id: false,
      showNewRecordModal: false,

      hideDayInWeek: false,
      hideDate: false,

      initialValues: {
          day_of_week: [],
          date_available: null,
          from_time_available: dayjs().set('hour', 9).set('minute', 0),
          untill_time_available: dayjs().set('hour', 17).set('minute', 0),
          available: true // flipped
      },

      selectedRowKeys: []
  };

  componentDidMount()
  {
      this.loadData();
  }

  loadData()
  {
      this.props.getAppointmentsUserAction(this.props.match.params.id, this.props.match.params.user_id);
  }

  editRecord(id = null)
  {
      if(this.form)
      {
          this.form.resetFields();
      }

      if(!id)
      {
          this.setState({
              id: false,
              initialValues: {
                  day_of_week: [],
                  date_available: null,
                  from_time_available: dayjs().set('hour', 9).set('minute', 0),
                  untill_time_available: dayjs().set('hour', 17).set('minute', 0),
                  available: true // flipped
              },
              hideDate: false,
              hideDayInWeek: false,
          }, () => {

              if(this.form)
              {
                  this.form.resetFields();
              }

              this.setState({ showNewRecordModal: true });
          });
      }
      else
      {
          // fetch record, set values and show modal
          var found_record = false;

          this.props.appointment_user.availability.map((record) => {

                if(record.id == id)
                {
                    found_record = record;
                }
          });

          if(found_record)
          {
              this.setState({
                  id: id,
                  initialValues: {
                      day_of_week: found_record.day_of_week >= 1 ? ''+found_record.day_of_week : null,
                      date_available: found_record.date_available ? dayjs(found_record.date_available, 'YYYY-MM-DD') : null,
                      from_time_available: found_record.from_time_available ? dayjs(found_record.from_time_available, 'HH:mm:ss') : null,
                      untill_time_available: found_record.untill_time_available ? dayjs(found_record.untill_time_available, 'HH:mm:ss') : null,
                      available: !found_record.available // flip
                  },
                  hideDate: found_record.day_of_week ? true : false,
                  hideDayInWeek: found_record.date_available ? true : false,
              }, () => {

                  if(this.form)
                  {
                      this.form.resetFields();
                  }

                  this.setState({ showNewRecordModal: true });
              });
          }
      }
  }

  handleAvailability(values)
  {
      if(values.date_available)
      {
          values.date_available = values.date_available.format('YYYY-MM-DD');
      }

      if(values.from_time_available)
      {
          values.from_time_available = values.from_time_available.format('HH:mm');
      }

      if(values.untill_time_available)
      {
          values.untill_time_available = values.untill_time_available.format('HH:mm');
      }

      values.available = values.available ? false : true;

      //
      if(!this.state.id)
      {   // add
          this.props.addAppointmentsUserAvailabilityAction(this.props.match.params.id, this.props.match.params.user_id, values).then(() => {

              showSuccess();

              this.loadData();

              this.setState({ showNewRecordModal: false });
          });
      }
      else
      {   // update
          this.props.updateAppointmentsUserAvailabilityAction(this.props.match.params.id, this.props.match.params.user_id, this.state.id, values).then(() => {

              showSuccess();

              this.loadData();

              this.setState({ showNewRecordModal: false });
          });
      }
  }

  deleteAvailabilities()
  {
      Modal.confirm({
          title: 'Verwijderen',
          content: 'Klik op JA om de geselecteerde regels te verwijderen.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
              return new Promise((resolve, reject) => {

                  this.props.deleteAppointmentsUserAvailabilitiesAction(this.props.match.params.id, this.props.match.params.user_id, this.state.selectedRowKeys).then(() => {

                      this.loadData();
                  });

                  resolve();

              }).catch(() => {});
          },
          onCancel: () => {},
      });
  }

  getDayInWeekLabel(day_in_week)
  {
      if(day_in_week == '1'){ return 'Maandag'; }
      else if(day_in_week == '2'){ return 'Dinsdag'; }
      else if(day_in_week == '3'){ return 'Woensdag'; }
      else if(day_in_week == '4'){ return 'Donderdag'; }
      else if(day_in_week == '5'){ return 'Vrijdag'; }
      else if(day_in_week == '6'){ return 'Zaterdag'; }
      else if(day_in_week == '7'){ return 'Zondag'; }

      return false;
  }

  onSelectChange = (selectedRowKeys) => {

      this.setState({ selectedRowKeys });
  }

  render()
  {
      const { selectedRowKeys } = this.state;

      const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
          hideDefaultSelections: true,
      };

      const columns = [
          {
              title: 'Dag in de week',
              dataIndex: 'day_of_week',
              render: (text, record) => (
                  text ? <Link to={false} onClick={() => { this.editRecord(record.id) }}>{this.getDayInWeekLabel(text)}</Link> : 'N.v.t.'
              )
          },
          {
              title: 'Datum',
              dataIndex: 'date_available',
              render: (text, record) => (
                  text ? <Link to={false} onClick={() => { this.editRecord(record.id) }}>{dayjs(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</Link> : 'N.v.t.'
              )
          },
          {
              title: 'Vanaf',
              dataIndex: 'from_time_available',
              render: (text, record) => (
                  text ? text.substring(0, 5) : 'Geen vanaf'
              )
          },
          {
              title: 'Tot en met',
              dataIndex: 'untill_time_available',
              render: (text, record) => (
                  text ? text.substring(0, 5) : 'Geen tot en met'
              )
          },
          {
              title: 'Beschikbaar',
              dataIndex: 'available',
              render: (text, record) => (text == '1' ? 'Ja' : 'Nee')
          }
      ];

      return (

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'2'} />

            <div style={{padding: 24, width: '100%'}}>

                { this.props.appointment_user ?
                <div>
                    { this.props.appointment_user.first_name || this.props.appointment_user.affix || this.props.appointment_user.last_name ?
                    <h2 style={{ marginTop: 0, paddingTop: 0 }}>{this.props.appointment_user.first_name} {this.props.appointment_user.affix} {this.props.appointment_user.last_name}</h2> : null }
                    <h3 style={{ marginTop: 0, paddingTop: 0 }}>{this.props.appointment_user.email}</h3>
                </div> : null }

                <Row className="mbm">
                  <Col span={24}>
                    <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" icon={<PlusOutlined />} onClick={() => { this.editRecord(false); }}>Toevoegen</Button>
                    <Button disabled={!this.props.HaveAbility('appointment', 'delete') || !this.state.selectedRowKeys || !this.state.selectedRowKeys.length} type="primary" danger onClick={() => { this.deleteAvailabilities(); }}>Verwijderen</Button>
                  </Col>
                </Row>

                <Modal
                    title="Beschikbaarheid"
                    visible={this.state.showNewRecordModal}
                    onCancel={() => {

                        this.setState({ showNewRecordModal: false });
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'availability',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    forceRender={true}
                    >
                    <Form
                        id="availability"
                        onFinish={(values) => { this.handleAvailability(values); }}
                        layout={'vertical'}
                        initialValues={this.state.initialValues}
                        preserve={false}
                        ref={(ref) => { this.form = ref; }}
                        >

                        { !this.state.hideDayInWeek ? <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    name="day_of_week"
                                    label={
                                        <Tooltip title='Geef één of meerdere dagen op waarop de persoon wel of juist niet beschikbaar is. Maak je gebruik van deze optie, dan vervalt het datum veld.'>
                                            Dag in de week
                                            <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    }
                                    hasFeedback
                                    >
                                    <Select
                                        placeholder="Selecteer een dag in de week"
                                        allowClear={true}
                                        mode={!this.state.id ? 'multiple' : null}
                                        options={[
                                            {
                                                value: '1',
                                                label: 'Maandag'
                                            },
                                            {
                                                value: '2',
                                                label: 'Dinsdag'
                                            },
                                            {
                                                value: '3',
                                                label: 'Woensdag'
                                            },
                                            {
                                                value: '4',
                                                label: 'Donderdag'
                                            },
                                            {
                                                value: '5',
                                                label: 'Vrijdag'
                                            },
                                            {
                                                value: '6',
                                                label: 'Zaterdag'
                                            },
                                            {
                                                value: '7',
                                                label: 'Zondag'
                                            }
                                        ]}
                                        onChange={(value) => {

                                            if(value && value.length > 0)
                                            {
                                                this.setState({ hideDate: true });
                                            }
                                            else
                                            {
                                                this.setState({ hideDate: false });
                                            }
                                        }}
                                        />
                                </Form.Item>

                            </Col>
                        </Row> : null }

                        { !this.state.hideDate ? <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    name="date_available"
                                    label={
                                        <Tooltip title='Geef een datum op waarop de persoon wel of juist niet beschikbaar is. Maak je gebruik van deze optie, dan vervalt het dag in de week veld.'>
                                            Datum
                                            <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    }
                                    >
                                    <DatePicker
                                        format={'DD-MM-YYYY'}
                                        onChange={(date, string) => {

                                            if(string)
                                            {
                                                this.setState({ hideDayInWeek: true });
                                            }
                                            else
                                            {
                                                this.setState({ hideDayInWeek: false });
                                            }
                                        }}
                                        />
                                </Form.Item>

                            </Col>
                        </Row> : null }

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    name="from_time_available"
                                    label={
                                        <Tooltip title='Optioneel: geef een vanaf tijd op voor de beschikbaarheid van de persoon op basis van dag in de week of datum. Is dit veld leeg, dan is de persoon vanaf 00:00 beschikbaar. Is er ook geen tot en met tijd ingevuld, dan is de persoon de hele dag beschikbaar.'>
                                            Vanaf
                                            <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    }
                                    >
                                    <TimePicker
                                        format={'HH:mm'}
                                        allowClear={true}
                                        // defaultValue={dayjs().set('hour', 9).set('minute', 0)}
                                        />
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    name="untill_time_available"
                                    label={
                                        <Tooltip title='Optioneel: geef een tot en met tijd op voor de beschikbaarheid van de persoon op basis van dag in de week of datum. Is dit veld leeg, dan is de persoon tot 00:00 beschikbaar. Is er ook geen van tijd ingevuld, dan is de persoon de hele dag beschikbaar.'>
                                            Tot en met
                                            <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    }
                                    >
                                    <TimePicker
                                        format={'HH:mm'}
                                        allowClear={true}
                                        // defaultValue={dayjs().set('hour', 17).set('minute', 0)}
                                        />
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    name="available"
                                    label={
                                        <Tooltip title='Geef met dit veld aan of de persoon wel of juist niet beschikbaar is op basis van de opgegeven instellingen.'>
                                            Niet beschikbaar
                                            <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    }
                                    >
                                    <Switch />
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Row>

                  <Col span={24}>

                    <CustomTable
                      rowKey='id'
                      size="middle"
                      columns={columns}
                      dataSource={this.props.isFetching ? [] : (this.props.appointment_user && this.props.appointment_user.availability && this.props.appointment_user.availability.length ? this.props.appointment_user.availability : [])}
                      loading={this.props.isFetching}
                      rowSelection={rowSelection}
                      />

                  </Col>

                </Row>

            </div>

        </div>
    );

  }
}

// const AppointmentsUserForm = Form.create({ name: 'availability' })(AppointmentsUser);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.appointment.isFetching,
        isError : state.appointment.isError,
        appointment_user: state.appointment.appointment_user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAppointmentsUserAction : bindActionCreators(getAppointmentsUserAction, dispatch),
        addAppointmentsUserAvailabilityAction : bindActionCreators(addAppointmentsUserAvailabilityAction, dispatch),
        updateAppointmentsUserAvailabilityAction : bindActionCreators(updateAppointmentsUserAvailabilityAction, dispatch),
        deleteAppointmentsUserAvailabilitiesAction : bindActionCreators(deleteAppointmentsUserAvailabilitiesAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsUser);
