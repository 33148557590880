import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import './ProjectsDashboard.css';
import { Card, Row, Col, Table } from 'antd';
import { Link } from 'react-router-dom';
import { getProjectDashboard } from '../../../actions/dashboardActions';
import { getProjectModulesAction } from '../../../actions/projectActions';

const columns = [
  {
    title: 'Onderwerp',
    dataIndex: 'subject',
    key: 'subject',
    render: (text, object) => <Link to={`/projects/${object.project_id}/correspondence/${object.id}/setup`}>{text}</Link>,
  }, {
    title: 'Project',
    dataIndex: 'project_name',
    key: 'subject',
    //render: (text, object) => <a href={`/projects/${object.project_id}/correspondence/${object.id}/setup`}>{text}</a>,
  }
]

class ProjectsDashboard extends Component {

    state = {

    }

    componentDidMount()
    {
        this.props.getProjectDashboard(this.props.match.params.id).then(() => {
            // this.props.getProjectModulesAction(this.props.match.params.id);
            this.setState({latest_correspondence: this.props.latest_correspondence});
        });
    }

    render() {
        return (
            <div className="dashboard">
                <Row gutter={24} type="flex">
                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Aantal adressen">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_addresses}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Ingelogd">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_logged_in}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Aantal contactpersonen">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_contactpersons}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Aantal adressen ingelogd via app">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_logged_in_by_app}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Afspraken gemaakt">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_appointments_made} / {this.props.stats && this.props.stats.total_nr_of_appointments}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Openstaande afspraken">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_appointments_to_be_made}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Nieuwe berichten">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_new_messages}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Enquêtes">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_surveys}</h2>
                      </Card>
                    </Col>

                    <Col span={8} style={{marginBottom: 24}}>
                      <Card size="small" title="Ingevulde enquêtes">
                        <h2 style={{margin:0}}>{this.props.stats && this.props.stats.nr_of_surveys_filled_in}</h2>
                      </Card>
                    </Col>

                    {/*<Card>Afspraken gemaakt: {this.props.project.nr_of_appointments_made} / {this.props.project.nr_of_appointments_to_be_made}</Card>
                    <Card>Aantal contactpersonen: {this.props.project.nr_of_contactpersons}</Card>
                    <Card>Aantal app bezoekers: {this.props.project.nr_of_logged_in_by_app}</Card>
                    <Card>Aantal logins: {this.props.project.nr_of_logged_in}</Card>*/}

                  {/*<Col span={8}>
                      <Card style={{ height: '100%' }} cover title={(
                          <div>
                              Laatste correspondentie
                          </div>
                          )} bordered={false}>
                          <Table
                              columns={columns}
                              dataSource={this.props.latest_correspondence}
                              pagination={false}
                              showHeader={true} />
                      </Card>
                  </Col>
                  <Col span={8}>
                      <Card style={{ height: '100%' }} cover title={(
                          <div>
                              Eerst volgende ingeplande correspondentie
                          </div>
                          )} bordered={false}>
                          <Table
                              columns={columns}
                              dataSource={this.props.latest_correspondence_scheduled}
                              pagination={false}
                              showHeader={true} />
                      </Card>
                  </Col>
                  <Col span={8}>
                      <Card style={{ height: '100%' }} cover title={(
                          <div>
                              Laatst verstuurde correspondentie
                          </div>
                          )} bordered={false}>
                          <Table
                              columns={columns}
                              dataSource={this.props.latest_correspondence_send}
                              pagination={false}
                              showHeader={true} />
                      </Card>
                  </Col>*/}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        latest_correspondence: state.dashboard.latest_correspondence,
        latest_correspondence_scheduled: state.dashboard.latest_correspondence_scheduled,
        latest_correspondence_send: state.dashboard.latest_correspondence_send,
        stats: state.dashboard.stats,
        project: state.project.project,
        // modules: state.project.modules,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProjectDashboard: bindActionCreators(getProjectDashboard, dispatch),
        getProjectModulesAction: bindActionCreators(getProjectModulesAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsDashboard);
