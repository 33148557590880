import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Alert, Card, Typography, Upload, Input, Select, Switch, Button, Drawer, Popconfirm, message, Divider, Steps, Slider, PageHeader, Tag, Table } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, patchAppointmentAction, addAppointmentAction, resetAppointmentAction, clustersAppointmentAction, getAppointmentScheduleAction, applyAppointmentScheduleAction, getSlotsCounterClusterAction } from '../../../../../actions/appointmentsActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import { showSuccess } from '../../../../../utils/Notifications';
import PatchAppointmentForm from '../Form/PatchAppointment';
import Cluster from '../Component/Cluster';
import DataForm from '../Form/Data';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import _ from 'underscore';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import AppointmentsSideMenu from '../AppointmentsSideMenu';

class AppointmentClusters extends Component {

  clusters = [];
  cluster_counters = [];
  cluster_refs = [];

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      clusters: [],
      cluster_counters: [],
      excludedIds:[],
    };
  }

  componentDidMount() {

    this.props.resetAppointmentAction();

    this.props.getAppointmentAction(this.props.match.params.appointmentid).then(() => {

      var cluster_counters = [];

      var clusters = this.props.clusters;

      this.props.appointment.clusters.map((cluster, index) => {

        cluster_counters[index] = cluster.nr_of_slots;

        clusters[index].appointment_user_ids = cluster.appointment_user_ids;
      });

      this.cluster_counters = cluster_counters;

      this.setState({
          clusters: clusters, // this.props.clusters,
          excludedIds: _.flatten(this.props.clusters),
          // cluster_counters: cluster_counters,
          loading: false,
      });

    });

    this.props.getAddressesAction({ 'projectId':this.props.match.params.id }).then(() => {

      this.props.getGroupsAction({ 'projectId': this.props.match.params.id });
    });
  }

  saveClusters = () => {

    // this.props.clustersAppointmentAction(this.props.match.params.appointmentid, {'clusters' : this.state.clusters.filter((element) => element != null)}).then(() => {
    this.props.clustersAppointmentAction(this.props.match.params.appointmentid, { 'clusters' : this.state.clusters }).then(() => {

      showSuccess();

      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/overview`);
    });
  }

  updateExcludedIds = () => {

    var selectedIds           = _.flatten(this.state.clusters);
    var differenceExcludedIds = _.difference(this.state.excludedIds, selectedIds);
    var updateExcludedIds     = _.without(this.state.excludedIds, ...differenceExcludedIds)
    this.setState({excludedIds: updateExcludedIds});
  }

  deleteCluster = (myindex) => {

    var updatedClusters = this.state.clusters;
    delete updatedClusters[myindex];

    this.setState({
      clusters: updatedClusters,
    }, () => {

      this.updateExcludedIds();
    })
  }

  updateClusterCounter(cluster_nr)
  {
      this.props.getSlotsCounterClusterAction(this.props.match.params.appointmentid, cluster_nr, { clusters: this.state.clusters }).then((data) => {

          // this.clusters[cluster_nr - 1].counter = data.nr_of_slots;
          // console.log(this.clusters, cluster_nr);

          // this.setState({ cluster_counters: { ...this.state.cluster_counters, nr_of_slots: data.nr_of_slots } });

          this.cluster_counters[cluster_nr - 1] = data.nr_of_slots;

          // this.cluster_refs[cluster_nr - 1].counter = data.nr_of_slots;
          this.cluster_refs[cluster_nr - 1].updateCounter(data.nr_of_slots);

          // console.log('updateClusterCounter', cluster_nr, this.cluster_counters);
      });
  }

  setClusterRef(ref, index)
  {
      // console.log('setClusterRef', ref, index);

      this.cluster_refs[index] = ref;
  }

  render() {
    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'1'} />

          <div style={{padding: 24, width: '100%'}}>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={() => this.saveClusters()}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="mbm">
              <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                <AppointmentSteps current={3} {...this.props}/>
              </Card>
            </Row>

            <Row gutter={24}>
              <Col span={24}>

                { !this.state.loading ? <Card>
                  <Row className="mbm">
                    <Col span={24}>
                      <div>
                        <Button icon={<PlusOutlined />} disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={() => this.setState({clusters: [...this.state.clusters, []]})}>Toevoegen</Button>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[24, 24]}>

                    {!this.state.isFetching && this.state.clusters.length == 0 ? (
                      <Col span={24}>
                        <Alert showIcon description={"Voeg minimaal één cluster toe"} message={'Let op'}></Alert>
                      </Col>
                    ) : null}

                    {this.state.clusters.map((element, index) => {

                      if(!Array.isArray(element))
                      {
                        return false;
                      }

                      return (
                        <Col key={index} span={12}>

                          <Cluster
                            setClusterRef={(ref, index) => { this.setClusterRef(ref, index); }}
                            key={index}
                            index={index}
                            counter={this.cluster_counters[index]}
                            exclude={this.state.excludedIds}
                            appointment={this.props.appointment}
                            selected={this.state.clusters[index]}
                            addresses={this.props.appointment && this.props.appointment.addresses ? this.props.appointment.addresses : []}
                            groups={this.props.groups}
                            users={this.props.appointment && this.props.appointment.users ? this.props.appointment.users : []}
                            setSelectedUsers={(value) => {

                              var updatedClusters = this.state.clusters.map((element, clusterindex) => {

                                if(index == clusterindex) {

                                  element.appointment_user_ids = value;

                                  return element;
                                }

                                return element;
                              });

                              this.setState({
                                 clusters: updatedClusters,
                             }, () => { this.updateClusterCounter(index + 1); });
                            }}
                            updateAddressesSortOrder={(addresses, appointment_user_ids) => {
                                var updatedClusters = this.state.clusters.map((element, clusterindex) => {

                                  if(index == clusterindex) {

                                    var ids = [];

                                    addresses.map((record) => {

                                        if(this.state.clusters[clusterindex].includes(record.id))
                                        {
                                            ids.push(record.id);
                                        }
                                    });

                                    ids['appointment_user_ids'] = appointment_user_ids;

                                    element = ids;

                                    return element;
                                  }

                                  return element;
                                });

                                this.setState({
                                  clusters: updatedClusters
                                }, () => {
                                  this.updateExcludedIds();
                                });
                            }}
                            onSelect={(ids) => {
                              var updatedClusters = this.state.clusters.map((element, clusterindex) => {

                                if(index == clusterindex) {
                                  element = ids;
                                  return element;
                                }
                                return element;
                              });

                              this.setState({
                                excludedIds: [...ids, ...this.state.excludedIds],
                                clusters: updatedClusters,
                              }, () => {
                                this.updateExcludedIds();
                              });
                            }}
                            onDeleteCluster={() => this.deleteCluster(index)}
                            />
                        </Col>
                      );
                    })}

                  </Row>
                </Card> : null }

              </Col>
            </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    appointment: state.appointment.appointment,
    clusters: state.appointment.clusters,
    groups: state.group.groups,
    nr_of_slots: state.appointment.nr_of_slots,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    patchAppointmentAction : bindActionCreators(patchAppointmentAction, dispatch),
    addAppointmentAction : bindActionCreators(addAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    clustersAppointmentAction : bindActionCreators(clustersAppointmentAction, dispatch),
    getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch),
    applyAppointmentScheduleAction : bindActionCreators(applyAppointmentScheduleAction, dispatch),
    getSlotsCounterClusterAction : bindActionCreators(getSlotsCounterClusterAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentClusters);
