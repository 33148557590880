import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Typography, Upload, Input, Select, Switch, Button, Drawer, Popconfirm, message, Divider, Steps, Slider, PageHeader, Tag, InputNumber, TimePicker, Tooltip, DatePicker, Checkbox } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, patchAppointmentAction, addAppointmentAction, resetAppointmentAction, clustersAppointmentAction, getAppointmentScheduleAction, applyAppointmentScheduleAction, addDaypart } from '../../../../../actions/appointmentsActions';
import PatchAppointmentForm from '../Form/PatchAppointment';
import ClustersForm from '../Form/Clusters';
import DataForm from '../Form/Data';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import moment from 'moment';
import 'moment/locale/nl';
import _ from 'underscore';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import AppointmentsSideMenu from '../AppointmentsSideMenu';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';
import { SketchPicker, ChromePicker, TwitterPicker } from 'react-color';

dayjs.locale('nl');

class AppointmentDetail extends Component {

  state = {
      loading: true,
      sms_enabled: false,
      appointment: {},
      showColor: false,
      color: '#cccccc',
      send_email_reminder_nr_of_hours_before: 24,
      send_sms_reminder_nr_of_hours_before: 12
  };

  componentDidMount()
  {
    this.setState({ loading: true }, () => {

        var sms_enabled = false;

        if((_.findWhere(this.props.project.modules, { id: 10 }) && _.findWhere(this.props.selectedCustomer.modules, { id: 10 })))
        {
          // enable sms
          sms_enabled = true;

          this.setState({ sms_enabled: true });
        }

        if(this.props.match.params.appointmentid) {

          this.props.getAppointmentAction(this.props.match.params.appointmentid).then(() => {

              this.setState({

                  appointment: this.props.appointment,
                  color: this.props.appointment && this.props.appointment.color ? this.props.appointment.color : '#cccccc',
                  occupant_view_only: this.props.appointment.occupant_view_only ? true : false,
                  email_occupant_mutations: this.props.appointment.email_occupant_mutations,
                  email_occupant_mutations_to: this.props.appointment.email_occupant_mutations_to,
                  enable_reminders: this.props.appointment.enable_reminders,
                  send_email_reminder: sms_enabled ? this.props.appointment.send_email_reminder : false,
                  send_email_reminder_nr_of_hours_before: parseFloat(this.props.appointment.send_email_reminder_nr_of_hours_before),
                  send_sms_reminder: this.props.appointment.send_sms_reminder,
                  send_sms_reminder_nr_of_hours_before: parseFloat(this.props.appointment.send_sms_reminder_nr_of_hours_before),

              }, () => { this.setState({ loading: false }); });
          });
        }
        else
        {
          this.props.resetAppointmentAction();

          // defaullt settings / values
          this.setState({
              email_occupant_mutations: true,
              email_occupant_mutations_to: this.props.project.mail_occupants_messages_to
          });

          this.setState({ loading: false });
        }
    });
  }

  saveAppointmentForm(e)
  {
    this.props.form.validateFields((err, values) => {

      values.email_occupant_mutations_to = values.email_occupant_mutations_to.join ? values.email_occupant_mutations_to.join(',') : values.email_occupant_mutations_to;

      if (!err) {

        if(this.props.match.params.appointmentid) {

          this.props.patchAppointmentAction(this.props.match.params.appointmentid, values, false).then(() => {
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/addresses/`);
          });

        } else {
          this.props.addAppointmentAction(this.props.match.params.id, values).then((response) => {
            this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${response.data.appointment.id}/addresses/`);
          });
        }

        showSuccess('Succesvol opgeslagen');
      }
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'1'} />

          <div style={{padding: 24, width: '100%'}}>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={(e) => this.saveAppointmentForm(e)}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="mbm">
              <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                <AppointmentSteps {...this.props}/>
              </Card>
            </Row>

            <Row gutter={24}>
              <Col span={24}>

                  { !this.state.loading ? <Form onSubmit={(e) => { this.saveAppointmentForm(e); }} layout={'vertical'} colon={true}>

                    <Row gutter={24} className='mbm'>
                        <Col span={24}>
                            <Card>
                                <Row gutter={24} className='mbm'>
                                    <Col span={4}>
                                      <Form.Item label="Kleur" hasFeedback>
                                        {getFieldDecorator('color', {
                                            initialValue: this.props.appointment && this.props.appointment.color ? this.props.appointment.color : '#cccccc',
                                            // rules: [{ required: true, message: 'Vul een omschrijving in' }],
                                          })(
                                              <Input prefix={(<span style={{marginLeft: -3, paddingRight: 5, width:15, height: 15, background: this.state.color, borderRadius: 3}}></span>)} onFocus={() => this.setState({showcolor: true})} onBlur={() => this.setState({showcolor: true})}></Input>
                                            )}

                                            {this.state.showcolor && (
                                              <div style={{position: 'absolute', zIndex: '2'}}>
                                                <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}} onClick={() => this.setState({showcolor: false})} />
                                                  <TwitterPicker
                                                    colors={['#f5222d', '#fa8c16', '#fadb14', '#a0d911', '#52c41a', '#13c2c2', '#1890ff', '#2f54eb', '#722ed1', '#eb2f96']}
                                                    style={{position: 'absolute'}}
                                                    color={this.state.color ? this.state.color : '#cccccc'}
                                                    onChangeComplete={(color) => {
                                                      this.setState({color: color.hex});
                                                      this.props.form.setFieldsValue({'color': color.hex});
                                                    }}
                                                    />
                                              </div>
                                            )}
                                      </Form.Item>
                                    </Col>
                                  <Col span={20}>
                                    <Form.Item required label="Onderwerp" hasFeedback>
                                      {getFieldDecorator('subject', {
                                        initialValue: this.state.appointment.subject,
                                        rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                      })(
                                        <Input />
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                      <Form.Item required label="Contactpersoon e-mailadres" hasFeedback>
                                        {getFieldDecorator('contact_email', {
                                          initialValue: this.state.appointment.contact_email,
                                          rules: [{ required: true, type: 'email', message: 'Vul een geldig e-mailadres in'}],
                                        })(
                                          <Input />
                                        )}
                                      </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                      <Form.Item key={2} required label="Contactpersoon telefoonnummer" hasFeedback>
                                        {getFieldDecorator('contact_phone_number', {
                                          initialValue: this.state.appointment.contact_phone_number ? this.state.appointment.contact_phone_number : '',
                                          rules: [{ required: true, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                                        })(
                                          <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input"/>
                                        )}
                                      </Form.Item>
                                  </Col>

                                </Row>

                                <Row gutter={24} className='mbm'>

                                    <Col span={12}>
                                        <Form.Item
                                            // label="Bewoner mag afspraak alleen inzien"
                                            label={
                                            <span>
                                                Bewoner mag afspraak alleen inzien
                                                <Tooltip className="mhs" title="Geef hiermee aan of de bewoner de afspraak alleen in mag zien en niet kan aanpassen">
                                                  <InfoCircleTwoTone />
                                                </Tooltip>
                                            </span>
                                            }
                                            >
                                          {getFieldDecorator('occupant_view_only', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.appointment.occupant_view_only ? true : false,
                                            onChange: (value) => { this.setState({ occupant_view_only: value }); }
                                          })(
                                            <Switch
                                              checkedChildren={<CheckOutlined />}
                                              unCheckedChildren={<CloseOutlined />}
                                              />
                                          )}
                                        </Form.Item>
                                    </Col>

                                    <Col span={12} style={{ display: !this.state.occupant_view_only ? 'block' : 'none' }}>
                                        <Form.Item
                                            // label="Bewoner heeft mogelijkheid om afspraak te annuleren"
                                            label={
                                            <span>
                                                Bewoner heeft mogelijkheid om afspraak te annuleren
                                                <Tooltip className="mhs" title="Geef hiermee aan of de bewoner de afspraak ook mag annuleren">
                                                  <InfoCircleTwoTone />
                                                </Tooltip>
                                            </span>
                                            }
                                            >
                                          {getFieldDecorator('possibility_for_occupant_to_cancel_appointment', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.appointment.possibility_for_occupant_to_cancel_appointment ? true : false,
                                          })(
                                            <Switch
                                              checkedChildren={<CheckOutlined />}
                                              unCheckedChildren={<CloseOutlined />}
                                              />
                                          )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                { !this.state.occupant_view_only ? <Row gutter={24} className='mbm'>

                                    <Col span={12}>
                                        <Form.Item
                                            //label="Verstuur notificaties"
                                            label={
                                            <span>
                                                Verstuur notificaties
                                                <Tooltip className="mhs" title="Verstuur een notificatie wanneer een bewoner de afspraak wijzigd">
                                                  <InfoCircleTwoTone />
                                                </Tooltip>
                                            </span>
                                            }
                                            >
                                          {getFieldDecorator('email_occupant_mutations', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.email_occupant_mutations ? true : false,
                                            onChange: (value) => { this.setState({ email_occupant_mutations: value }); }
                                          })(
                                            <Switch
                                              checkedChildren={<CheckOutlined />}
                                              unCheckedChildren={<CloseOutlined />}
                                              />
                                          )}
                                        </Form.Item>
                                    </Col>

                                    <Col span={12} style={{ display: this.state.email_occupant_mutations ? 'block' : 'none' }}>
                                        <Form.Item
                                            // label="Verstuur notificaties naar"
                                            label={
                                            <span>
                                                Verstuur notificaties naar
                                                <Tooltip className="mhs" title="Verstuur de notificaties naar één of meerdere opgegeven e-mailadressen (gescheden met een komma)">
                                                  <InfoCircleTwoTone />
                                                </Tooltip>
                                            </span>
                                            }
                                            >
                                          {getFieldDecorator('email_occupant_mutations_to', {
                                            initialValue: this.state.email_occupant_mutations_to && this.state.email_occupant_mutations_to.split ? this.state.email_occupant_mutations_to.split(',') : '',
                                            //rules: [{ required: true, message: 'Vul een tijd in min. in!' }],
                                          })(
                                          <Select
                                            mode="tags"
                                            open={false}
                                            listHeight={0}
                                            tokenSeparators={[',']}
                                            style={{ width: '100%' }}
                                            onChange={(value) => {
                                                this.setState({ email_occupant_mutations_to: value.join(',') });
                                            }}
                                            ></Select>
                                          )}
                                        </Form.Item>
                                    </Col>
                                </Row> : null }

                                <Row gutter={24} className='mbm'>
                                    <Col span={24}>
                                        <Form.Item
                                            // label="Automatisch een herinnering versturen"
                                            label={
                                            <span>
                                                Automatisch een herinnering versturen
                                                <Tooltip className="mhs" title="Geef hiermee aan of de bewoner automatisch een herinnering moet krijgen van de afspraak">
                                                  <InfoCircleTwoTone />
                                                </Tooltip>
                                            </span>
                                            }
                                            >
                                          {getFieldDecorator('enable_reminders', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.enable_reminders,
                                            onChange: (value) => { this.setState({ enable_reminders: value, send_email_reminder: false, send_sms_reminder: false }); }
                                          })(
                                            <Switch
                                              checkedChildren={<CheckOutlined />}
                                              unCheckedChildren={<CloseOutlined />}
                                              />
                                          )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                { this.state.enable_reminders ?
                                <Row gutter={24} className='mbm'>
                                    <Col span={12}>
                                        <Form.Item label="Automatisch een e-mail herinnering versturen">
                                          {getFieldDecorator('send_email_reminder', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.send_email_reminder,
                                            onChange: (value) => { this.setState({ send_email_reminder: value }); }
                                          })(
                                            <Switch
                                              checkedChildren={<CheckOutlined />}
                                              unCheckedChildren={<CloseOutlined />}
                                              />
                                          )}
                                        </Form.Item>
                                    </Col>
                                    { this.state.sms_enabled ?
                                    <Col span={12}>
                                        <Form.Item label="Automatisch een SMS herinnering versturen">
                                          {getFieldDecorator('send_sms_reminder', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.send_sms_reminder,
                                            onChange: (value) => { this.setState({ send_sms_reminder: value }); }
                                          })(
                                            <Switch
                                              checkedChildren={<CheckOutlined />}
                                              unCheckedChildren={<CloseOutlined />}
                                              />
                                          )}
                                        </Form.Item>
                                    </Col> : null }
                                </Row> : null }

                                { this.state.enable_reminders ?
                                <Row gutter={24} className='mbm'>

                                    <Col span={12} style={{display: this.state.send_email_reminder ? 'block' : 'none'}}>
                                        <Form.Item required={this.state.send_email_reminder ? true : false} label="Hoeveel uur voor de afspraak moet er een e-mail herinnering verstuurd worden?">
                                          {getFieldDecorator('send_email_reminder_nr_of_hours_before', {
                                            initialValue: this.state.send_email_reminder_nr_of_hours_before,
                                            onChange: (value) => { this.setState({ send_email_reminder_nr_of_hours_before: value }); },
                                            rules: [{ required: (this.state.send_email_reminder ? true : false), type: 'number', message: 'Vul een aantal uren in'}]
                                          })(
                                            <InputNumber />
                                          )}
                                        </Form.Item>
                                    </Col>

                                    { this.state.send_sms_reminder ?
                                    <Col span={12} offset={this.state.send_email_reminder ? 0 : 12}>
                                        <Form.Item required={this.state.send_sms_reminder ? true : false} label="Hoeveel uur voor de afspraak moet er een SMS herinnering verstuurd worden?">
                                          {getFieldDecorator('send_sms_reminder_nr_of_hours_before', {
                                            initialValue: this.state.send_sms_reminder_nr_of_hours_before,
                                            onChange: (value) => { this.setState({ send_sms_reminder_nr_of_hours_before: value }); },
                                            rules: [{ required: (this.state.send_sms_reminder ? true : false), type: 'number', message: 'Vul een aantal uren in'}]
                                          })(
                                            <InputNumber />
                                          )}
                                        </Form.Item>
                                    </Col> : null }

                                </Row> : null }

                                { this.state.enable_reminders ? <Row gutter={24} className='mbm'>
                                    { this.state.send_email_reminder ?
                                    <Col span={12}>
                                      <Form.Item label={(
                                          <span>
                                            E-mail tekst voor herinnering
                                            {/*<Tooltip className="mhs">
                                              <Icon type="info-circle" theme="twoTone" />
                                            </Tooltip>*/}
                                          </span>
                                          )}>
                                          {getFieldDecorator('reminder_email', {
                                            initialValue: this.state.appointment && this.state.appointment.reminder_email ? this.state.appointment.reminder_email : 'Beste bewoner,<br/>\
<br/>\
Graag herinneren wij u aan de afspraak <placeholder id="current" class="cobee-tag">afspraak: onderwerp</placeholder><br/>\
Datum: <placeholder id="current" class="cobee-tag">datum afspraak</placeholder><br/>\
Tijd: <placeholder id="current" class="cobee-tag">tijd afspraak</placeholder><br/>\
<br/>\
Graag tot dan!<br/>\
<br/>\
Met vriendelijke groet,<br/>\
<br/>\
<placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                                            rules: [{ required: true, message: 'Vul een herinneringstekst voor de e-mail in' }],
                                            valuePropName: 'value',
                                            trigger: 'onEditorChange',
                                            // onEditorChange: (value, editor) => { this.props.form.setFieldsValue({reminder_email: value}); this.setState({ reminder_email: value }); }
                                          })(
                                            <CustomEditor height="400" placeholders={'appointment-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ reminder_email: e }); }} />
                                          )}
                                        </Form.Item>
                                    </Col> : null }
                                    <Col span={12} offset={this.state.send_email_reminder ? 0 : 12} style={{display: this.state.send_sms_reminder ? 'block' : 'none'}}>
                                      <Form.Item label={(
                                          <span>
                                            SMS tekst voor herinnering
                                            {/*<Tooltip className="mhs" title='Gebruik de placeholder [datum afspraak] om de datum en [tijd afspraak] om de tijd van de afspraak per adres in de SMS in te laden.'>
                                              <InfoCircleTwoTone />
                                            </Tooltip>*/}
                                          </span>
                                          )}>

                                          {getFieldDecorator('reminder_text', {
                                            initialValue: this.state.appointment && this.state.appointment.reminder_text ? this.state.appointment.reminder_text : 'Beste bewoner, graag herinneren wij u aan de afspraak op [datum afspraak] om [tijdstip afspraak] uur. Graag tot dan!',
                                            rules: [{ message: 'Vul een herinneringstekst voor de SMS in' }],
                                            valuePropName: 'value',
                                            trigger: 'onEditorChange',
                                            onEditorChange: (value, editor) => { this.props.form.setFieldsValue({reminder_text: value}); this.setState({ reminder_text: value }); },
                                          })(
                                            <CustomEditor height="370" placeholders={'appointment-sms'} project_id={this.props.match.params.id} />
                                          )}

                                          { /*getFieldDecorator('reminder_text', {
                                              initialValue: this.state.appointment && this.state.appointment.reminder_text ? this.state.appointment.reminder_text : 'Beste bewoner, graag herinneren wij u aan de afspraak op [tijdstip afspraak] uur. Graag tot dan!',
                                              rules: [{ required: true, message: 'Vul een herinneringstekst in' }],
                                          })(
                                          <Input.TextArea />
                                          )*/ }

                                          {(this.props.form.getFieldValue('reminder_text') ? this.props.form.getFieldValue('reminder_text').length : 0) + " tekens / " + Math.ceil(this.props.form.getFieldValue('reminder_text') ? (this.props.form.getFieldValue('reminder_text').length / 160) : 0) + " SMS bericht(en)" }

                                        </Form.Item>
                                    </Col>
                                </Row> : null }


                                <Row gutter={24} className='mbm'>
                                    <Col span={12}>
                                      <Form.Item label={(
                                          <span>
                                            E-mail tekst voor bevestiging
                                            {/*<Tooltip className="mhs">
                                              <Icon type="info-circle" theme="twoTone" />
                                            </Tooltip>*/}
                                          </span>
                                          )}>
                                          {getFieldDecorator('confirmation_email', {
                                            initialValue: this.state.appointment && this.state.appointment.confirmation_email ? this.state.appointment.confirmation_email : 'Geachte heer/mevrouw,<br />\
<br />\
Hierbij bevestigen wij uw afspraak voor <placeholder id="current" class="cobee-tag">afspraak: onderwerp</placeholder><br />\
Datum: <placeholder id="current" class="cobee-tag">datum afspraak</placeholder><br />\
Tijdstip: <placeholder id="current" class="cobee-tag">tijd afspraak</placeholder><br />\
<br />\
Indien u vragen heeft over de afspraak, neemt u dan contact op via telefoonnummer <placeholder id="current" class="cobee-tag">contactpersoon telefoonnummer</placeholder>.<br />\
<br />\
Bent u verhinderd? Dan verzoeken wij u minimaal <placeholder id="current" class="cobee-tag">minimale tijd in uren voor annuleren</placeholder> uur voorafgaand aan de afspraak te annuleren. Om de afspraak te wijzigen of annuleren logt u in en gaat u naar de pagina "Afspraak maken".<br />\
<br />\
Met vriendelijke groet,<br />\
<br />\
<placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                                            rules: [{ required: true, message: 'Vul een herinneringstekst voor de e-mail in' }],
                                            valuePropName: 'value',
                                            trigger: 'onEditorChange',
                                            // onEditorChange: (value, editor) => { this.props.form.setFieldsValue({confirmation_email: value}); this.setState({ confirmation_email: value }); }
                                          })(
                                            <CustomEditor height="400" placeholders={'appointment-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ confirmation_email: e }); }} />
                                          )}
                                        </Form.Item>
                                    </Col>
                                    { this.state.sms_enabled ? <Col span={12}>
                                      <Form.Item label={(
                                          <span>
                                            SMS tekst voor bevestiging
                                            {/*<Tooltip className="mhs" title='Gebruik de placeholder [datum afspraak] om de datum en [tijd afspraak] om de tijd van de afspraak per adres in de SMS in te laden.'>
                                              <InfoCircleTwoTone />
                                            </Tooltip>*/}
                                          </span>
                                          )}>
                                          {getFieldDecorator('confirmation_text', {
                                            initialValue: this.state.appointment && this.state.appointment.confirmation_text ? this.state.appointment.confirmation_text : 'Beste bewoner, hierbij bevestigen wij de afspraak op [datum afspraak] om [tijd afspraak] uur. Graag tot dan!',
                                            rules: [{ message: 'Vul een bevestigingstekst voor de SMS in' }],
                                            valuePropName: 'value',
                                            trigger: 'onEditorChange',
                                            onEditorChange: (value, editor) => { this.props.form.setFieldsValue({ confirmation_text: value }); this.setState({ confirmation_text: value }); },
                                          })(
                                            <CustomEditor height="370" placeholders={'appointment-sms'} project_id={this.props.match.params.id} />
                                          )}

                                          {(this.props.form.getFieldValue('confirmation_text') ? this.props.form.getFieldValue('confirmation_text').length : 0) + " tekens / " + Math.ceil(this.props.form.getFieldValue('confirmation_text') ? (this.props.form.getFieldValue('confirmation_text').length / 160) : 0) + " SMS bericht(en)" }

                                        </Form.Item>
                                    </Col> : null}
                                </Row>


                                <Row gutter={24} className='mbm'>
                                    <Col span={12}>
                                      <Form.Item label={(
                                          <span>
                                            E-mail tekst voor annulering
                                          </span>
                                          )}>
                                          {getFieldDecorator('cancel_email', {
                                            initialValue: this.state.appointment && this.state.appointment.cancel_email ? this.state.appointment.cancel_email : 'Beste bewoner,<br/>\
<br/>\
Hierbij bevestigen wij de annulering van de afspraak <placeholder id="current" class="cobee-tag">afspraak: onderwerp</placeholder><br/>\
Datum: <placeholder id="current" class="cobee-tag">datum afspraak</placeholder><br/>\
Tijd: <placeholder id="current" class="cobee-tag">tijd afspraak</placeholder><br/>\
<br/>\
Met vriendelijke groet,<br/>\
<br/>\
<placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                                            rules: [{ required: true, message: 'Vul een annuleringstekst voor de e-mail in' }],
                                            valuePropName: 'value',
                                            trigger: 'onEditorChange',
                                          })(
                                            <CustomEditor height="400" placeholders={'appointment-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ cancel_email: e }); }} />
                                          )}
                                        </Form.Item>
                                    </Col>
                                    { this.state.sms_enabled ? <Col span={12}>
                                      <Form.Item label={(
                                          <span>
                                            SMS tekst voor annulering
                                            {/*<Tooltip className="mhs" title='Gebruik de placeholder [datum afspraak] om de datum en [tijd afspraak] om de tijd van de afspraak per adres in de SMS in te laden.'>
                                              <InfoCircleTwoTone />
                                            </Tooltip>*/}
                                          </span>
                                          )}>
                                          {getFieldDecorator('cancel_text', {
                                            initialValue: this.state.appointment && this.state.appointment.cancel_text ? this.state.appointment.cancel_text : 'Beste bewoner, hierbij bevestigen wij de annulering van de afspraak op [datum afspraak] om [tijd afspraak] uur.',
                                            rules: [{ message: 'Vul een bevestigingstekst voor de SMS in' }],
                                            valuePropName: 'value',
                                            trigger: 'onEditorChange',
                                            onEditorChange: (value, editor) => { this.props.form.setFieldsValue({ cancel_text: value }); this.setState({ cancel_text: value }); },
                                          })(
                                            <CustomEditor height="370" placeholders={'appointment-sms'} project_id={this.props.match.params.id} />
                                          )}

                                          {(this.props.form.getFieldValue('cancel_text') ? this.props.form.getFieldValue('cancel_text').length : 0) + " tekens / " + Math.ceil(this.props.form.getFieldValue('cancel_text') ? (this.props.form.getFieldValue('cancel_text').length / 160) : 0) + " SMS bericht(en)" }

                                        </Form.Item>
                                    </Col> : null }
                                </Row>

                            </Card>
                        </Col>
                    </Row>

                  </Form> : null }

              </Col>
            </Row>
          </div>
      </div>
    );
  }
}

const AppointmentDetailForm = Form.create({ name: 'appointment_form' })(AppointmentDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.appointment.isFetching,
    isError: state.appointment.isError,
    appointment: state.appointment.appointment,
    dayparts: state.appointment.dayparts,
    clusters: state.appointment.clusters,
    selectedCustomer: state.auth.selectedCustomer,
    project: state.project.project,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    patchAppointmentAction : bindActionCreators(patchAppointmentAction, dispatch),
    addAppointmentAction : bindActionCreators(addAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    clustersAppointmentAction : bindActionCreators(clustersAppointmentAction, dispatch),
    getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch),
    applyAppointmentScheduleAction : bindActionCreators(applyAppointmentScheduleAction, dispatch),
    addDaypart : bindActionCreators(addDaypart, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetailForm);
