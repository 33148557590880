import React, { Component, useMemo } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Typography, Upload, Input, Select, Switch, Button, Drawer, Popconfirm, message, Divider, Steps, Slider, PageHeader, Tag, InputNumber, TimePicker, Tooltip, DatePicker, Checkbox, Modal, Dropdown, Menu } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, patchAppointmentAction, addAppointmentAction, resetAppointmentAction, clustersAppointmentAction, getAppointmentScheduleAction, applyAppointmentScheduleAction, addDaypart, addAppointmentUserAvailabilityAction, updateAppointmentUserAvailabilityAction, deleteAppointmentUsersAvailabilityAction, getSlotsCounterAppointmentAction } from '../../../../../actions/appointmentsActions';
import PatchAppointmentForm from '../Form/PatchAppointment';
import ClustersForm from '../Form/Clusters';
import DataForm from '../Form/Data';
import { showMultiDelete, showSuccess } from '../../../../../utils/Notifications';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import moment from 'moment';
import 'moment/locale/nl';
import _ from 'underscore';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import AppointmentsSideMenu from '../AppointmentsSideMenu';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import './draganddropcalendar.css';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const { RangePicker } = DatePicker;

dayjs.locale('nl');

var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

var isoWeeksInYear = require('dayjs/plugin/isoWeeksInYear');
var isLeapYear = require('dayjs/plugin/isLeapYear'); // dependent on isLeapYear plugin
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore);

const localizer = momentLocalizer(moment);

const days = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];


class AppointmentAvailability extends Component {

  appointment_calendar = null;

  state = {
      loading: true,
      appointment: {},
      availability: [],
      user_availability: [],
      dry_run_appointment_results: false,
      warnings: [],
      notifications: [],
      selectedRowKeys: [],
      available_users: [],
      email_address: '',
      type: 'free',
      slot: 'range',
      appointment_calendar_date: new Date(),
      appointment_calendar_view: 'week',
      appointment_calendar_selectable: true,
      appointment_calendar_clickable: true,
      appointment_calendar_resizable: true,

      week_numbers: [],
      selected_week: ''+dayjs().subtract(1, 'week').format('w'),
      selected_year: ''+dayjs().format('YYYY'),

      schedule_users: [],
      show_clone_user_schedule: false,
      clone_user_schedule: false,
      clone_week_schedule: false,

      dayparts: [{}]
  };

  componentDidMount()
  {
    //
    this.setState({ loading: true }, () => {

        this.loadData();
    });
  }

  loadData()
  {
      this.props.getAppointmentAction(this.props.match.params.appointmentid).then(() => {

          this.setState({
              appointment: this.props.appointment,
              appointment_calendar_date: this.props.appointment.schedule_from_date ? dayjs(this.props.appointment.schedule_from_date).startOf('week').toDate() : new Date(),
          }, () => {

              //
              var availability = [];

              this.state.appointment.availability.map((record) => {

                  availability.push({
                      id: record.id,
                      title: record.title,
                      start: dayjs(record.date_available + ' ' + record.from_time_available).toDate(),
                      end: dayjs(record.date_available + ' ' + record.untill_time_available).toDate(),
                      max_nr_of_appointments: parseInt(record.max_nr_of_appointments),
                      allDay: false,
                  });
              });

              this.getWeekNumbers(dayjs().format('YYYY'));

              //
              var available_users = [];

              this.state.appointment.users.map((record) => {

                  available_users.push({
                      label: record.email_address,
                      value: record.email_address
                  });
              });

              //
              this.setState({
                  // availability: this.state.appointment.availability,
                  type: 'free',
                  slot: this.state.appointment.slot ? this.state.appointment.slot : 'specific',
                  availability: availability,
                  use_users: this.state.appointment.use_users ? true : false,
                  available_users: available_users,
                  loading: false
              }, () => { this.updateNrOfSlots(true); });
          });
      });
  }

  reloadData()
  {
      this.props.getAppointmentAction(this.props.match.params.appointmentid).then(() => {

          this.setState({ appointment: { ...this.state.appointment, users: this.props.appointment.users } }, () => {

              //
              var availability = [];

              this.state.appointment.availability.map((record) => {

                  availability.push({
                      id: record.id,
                      title: record.title,
                      start: dayjs(record.date_available + ' ' + record.from_time_available).toDate(),
                      end: dayjs(record.date_available + ' ' + record.untill_time_available).toDate(),
                      max_nr_of_appointments: parseInt(record.max_nr_of_appointments),
                      allDay: false,
                  });
              });

              this.getWeekNumbers(dayjs().format('YYYY'));

              //
              var available_users = [];

              this.state.appointment.users.map((record) => {

                  available_users.push({
                      label: record.email_address,
                      value: record.email_address
                  });
              });

              //
              this.setState({
                  // availability: this.state.appointment.availability,
                  type: 'free',
                  slot: this.state.appointment.slot ? this.state.appointment.slot : 'specific',
                  availability: availability,
                  use_users: this.state.appointment.use_users ? true : false,
                  available_users: available_users,
                  loading: false
              }, () => {});
          });
      });
  }

  updateNrOfSlots(use_availability = false)
  {
      if(!this.state.use_users)
      {   // appointment
          this.props.form.validateFields((err, values) => {

              if(values.schedule_from_date)
              {
                  values.schedule_from_date = values.schedule_from_date.format('YYYY-MM-DD');
              }

              if(values.allow_appointments_to_be_made_till)
              {
                  values.allow_appointments_to_be_made_till = values.allow_appointments_to_be_made_till.format('YYYY-MM-DD');
              }

              if(!values.use_users)
              {
                  values.availability = JSON.stringify(this.state.availability);
              }

              this.props.getSlotsCounterAppointmentAction(this.props.match.params.appointmentid, values);
          });
      }
      else
      {   // users
          this.props.form.validateFields((err, values) => {

              if(values.schedule_from_date)
              {
                  values.schedule_from_date = values.schedule_from_date.format('YYYY-MM-DD');
              }

              if(values.allow_appointments_to_be_made_till)
              {
                  values.allow_appointments_to_be_made_till = values.allow_appointments_to_be_made_till.format('YYYY-MM-DD');
              }

              if(!use_availability)
              {
                  values.availability = JSON.stringify(this.state.user_availability);
              }

              this.props.getSlotsCounterAppointmentAction(this.props.match.params.appointmentid, values);
          });
      }
  }

  showAddUser()
  {
      // user availability
      var user_availability = [];

      this.state.appointment.users.map((user) => {

          this.state.appointment.users_availability.map((record) => {

              if(record.appointment_user_id == user.id)
              {
                  user_availability.push({
                      id: record.id,
                      title: record.title,
                      start: dayjs(record.date_available + ' ' + record.from_time_available).toDate(),
                      end: dayjs(record.date_available + ' ' + record.untill_time_available).toDate(),
                      max_nr_of_appointments: parseInt(record.max_nr_of_appointments),
                      allDay: false,
                      currentUser: false,
                  });
              }
          });
      });

      this.setState({
          email_address: '',
          full_name: '',
          company_name: '',
          user_availability: [],
          show_add_new_user: true,
          user_availability: user_availability,
      });
  }

  showUpdateUser(appointment_user_id)
  {
      // user availability
      var user_availability = [];

      this.state.appointment.users.map((user) => {

          this.state.appointment.users_availability.map((record) => {

              if(record.appointment_user_id == user.id)
              {
                  user_availability.push({
                      id: record.id,
                      title: record.title,
                      start: dayjs(record.date_available + ' ' + record.from_time_available).toDate(),
                      end: dayjs(record.date_available + ' ' + record.untill_time_available).toDate(),
                      max_nr_of_appointments: parseInt(record.max_nr_of_appointments),
                      allDay: false,
                      currentUser: user.id == appointment_user_id ? true : false,
                  });
              }
          });

          if(user.id == appointment_user_id)
          {
              //
              this.setState({
                  email_address: user.email_address,
                  full_name: user.full_name,
                  company_name: user.company_name,
                  // user_availability: user_availability,
                  show_update_user: appointment_user_id,
              });
          }
      });

      //
      this.setState({
          user_availability: user_availability,
      });
  }

  saveAppointmentAvailability(dry_run = true)
  {
    this.props.form.validateFields((err, values) => {

      if (!err) {

        if(values.schedule_from_date)
        {
            values.schedule_from_date = values.schedule_from_date.format('YYYY-MM-DD');
        }

        if(values.allow_appointments_to_be_made_till)
        {
            values.allow_appointments_to_be_made_till = values.allow_appointments_to_be_made_till.format('YYYY-MM-DD');
        }

        if(!values.use_users)
        {
            values.availability = JSON.stringify(this.state.availability);
        }

        this.props.patchAppointmentAction(this.props.match.params.appointmentid, values, dry_run).then(() => {

            if(dry_run)
            {
                if(this.props.warnings.length || this.props.notifications.length)
                {
                    this.setState({
                        dry_run_appointment_results: true,
                        warnings: this.props.warnings,
                        notifications: this.props.notifications,
                    });
                }
                else
                {   // no warnings or notifications? save directly
                    this.saveAppointmentAvailability(false);
                }
            }
            else
            {
                showSuccess();

                this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/clusters`);
            }
        });
      }
    });
  }

  addUser()
  {
      var user_availability = [];

      this.state.user_availability.map((record) => {

         if(record.new || record.currentUser)
         {
             user_availability.push(record);
         }
      });

      var values = {
          email_address: this.state.email_address,
          full_name: this.state.full_name,
          company_name: this.state.company_name,
          availability: JSON.stringify(user_availability),
      };

      this.props.addAppointmentUserAvailabilityAction(this.props.match.params.appointmentid, values).then((appointment) => {

          showSuccess();

          this.setState({ show_add_new_user: false });

          this.reloadData();
      });
  }

  updateUser(dry_run = true)
  {
      var user_availability = [];

      this.state.user_availability.map((record) => {

         if(record.new || record.currentUser)
         {
             user_availability.push(record);
         }
      });

      var values = {
          email_address: this.state.email_address,
          full_name: this.state.full_name,
          company_name: this.state.company_name,
          availability: JSON.stringify(user_availability),
      };

      this.props.updateAppointmentUserAvailabilityAction(this.props.match.params.appointmentid, this.state.show_update_user, values, dry_run).then((appointment) => {

          if(dry_run)
          {
              if((this.props.warnings && this.props.warnings.length) || (this.props.notifications && this.props.notifications.length))
              {
                  this.setState({
                      dry_run_update_user_results: true,
                      warnings: this.props.warnings,
                      notifications: this.props.notifications,
                  });
              }
              else
              {   // no warnings or notifications? save directly
                  this.updateUser(false);
              }
          }
          else
          {
              showSuccess();

              this.setState({
                  show_update_user: false,
                  dry_run_update_user_results: false,
              });

              this.reloadData();
          }
      });
  }

  deleteUsers(dry_run = true)
  {
    this.props.deleteAppointmentUsersAvailabilityAction(this.props.match.params.appointmentid, this.state.selectedRowKeys, dry_run).then(() => {

        if(dry_run)
        {
            if(this.props.warnings.length || this.props.notifications.length)
            {
                this.setState({
                    dry_run_delete_users_results: true,
                    warnings: this.props.warnings,
                    notifications: this.props.notifications,
                });
            }
            else
            {   // no warnings or notifications? save directly
                this.deleteUsers(false);
            }
        }
        else
        {
            showSuccess();

            this.reloadData();
        }
    });
  }

  //
  moveEvent(e, type = 'appointment', direct = false)
  {
      this.updateEvent(e, direct, type, false);
  }

  //
  resizeEvent(e, type = 'appointment', direct = false)
  {
      this.updateEvent(e, direct, type, false);
  }

  //
  selectSlots(e, type = 'appointment')
  {
      this.addEvent(e, type);
  }

  //
  selectEvent(e, type = 'appointment')
  {
      this.setState({
          type_of_event: type,
          edit_event: e
      });
  }

  //
  updateEvent(e, direct = false, type = 'appointment', validate = true)
  {
      var availability = [];

      var error = false;
      var errors = [];

      if(validate)
      {
          if(this.state.slot == 'range' && !(direct ? e.title : e.event.title))
          {
              error = true;
              errors['title'] = 'Vul een naam in';
          }

          if(this.state.slot == 'range' && !(direct ? e.max_nr_of_appointments : e.event.max_nr_of_appointments))
          {
              error = true;
              errors['max_nr_of_appointments'] = 'Vul max. aantal afspraken in';
          }
      }

      this.setState({ errors: errors });

      if(!error)
      {
          if(type == 'appointment')
          {
              this.state.availability.map((record) => {

                  if(!direct)
                  {
                      if(record.id == e.id || record.id == e.event.id)
                      {
                          record.title = e.event.title;
                          record.start = e.event.start;
                          record.end = e.event.end;
                          record.max_nr_of_appointments = parseInt(e.event.max_nr_of_appointments);
                      }
                  }
                  else
                  {
                      if(record.id == e.id || (e.event && record.id == e.event.id))
                      {
                          if(e.title){ record.title = e.title; }
                          record.start = e.start;
                          record.end = e.end;
                          if(e.max_nr_of_appointments){ record.max_nr_of_appointments = parseInt(e.max_nr_of_appointments); }
                      }
                  }

                  availability.push(record);
              });

              this.setState({ availability: availability }, () => { this.updateNrOfSlots(); });

              //
              return true;
          }
          else if(type == 'user')
          {
            this.state.user_availability.map((record) => {

                if(!direct)
                {
                    if(record.id == e.event.id || record.id == e.event.id)
                    {
                        record.title = e.event.title;
                        record.start = e.event.start;
                        record.end = e.event.end;
                        record.max_nr_of_appointments = parseInt(e.event.max_nr_of_appointments);
                    }
                }
                else
                {
                    if(record.id == e.id || (e.event && record.id == e.event.id))
                    {
                        if(e.title){ record.title = e.title; }
                        record.start = e.start;
                        record.end = e.end;
                        if(e.max_nr_of_appointments){ record.max_nr_of_appointments = parseInt(e.max_nr_of_appointments); }
                    }
                }

                availability.push(record);
            });

            this.setState({ user_availability: availability }, () => { this.updateNrOfSlots(); });

            //
            return true;
          }

          //
          return true;
      }

      //
      return false;
  }

  //
  addEvent(e, type = 'appointment')
  {
      if(type == 'appointment')
      {
          var availability = this.state.availability;
          var max_id = 0;

          availability.map((record) => {

              if(record.id > max_id)
              {
                  max_id = record.id;
              }
          });

          var start = e.start;
          var end = e.end;
          var min_end = dayjs(start).add(this.state.required_minutes_per_appointment ? this.state.required_minutes_per_appointment : this.state.appointment.required_minutes_per_appointment, 'minute').toDate();

          if(min_end.getTime() > end.getTime())
          {   // min. end time must be minimal the start + appointment duration
              end = min_end; // min_end.toDate();
          }

          availability.push({
              id: ++max_id,
              title: '',
              start: start,
              end: end,
              max_nr_of_appointments: parseInt(e.max_nr_of_appointments),
              new: true
          });

          e.id = max_id;
          e.end = end;

          this.setState({ availability: availability }, () => {

              this.updateNrOfSlots();

              if(this.state.slot == 'range')
              {
                  this.selectEvent(e, type);
              }
          });
      }
      else if(type == 'user')
      {
          var availability = this.state.user_availability;
          var max_id = 0;

          availability.map((record) => {

              if(record.id > max_id)
              {
                  max_id = record.id;
              }
          });

          var start = e.start;
          var end = e.end;
          var min_end = dayjs(start).add(this.state.send_sms_reminder_nr_of_hours_before ? this.state.send_sms_reminder_nr_of_hours_before : this.state.appointment.required_minutes_per_appointment, 'minute').toDate();

          if(min_end.getTime() > end.getTime())
          {   // min. end time must be minimal the start + appointment duration
              end = min_end; // min_end.toDate();
          }

          availability.push({
              id: ++max_id,
              title: '',
              start: start,
              end: end,
              max_nr_of_appointments: parseInt(e.max_nr_of_appointments),
              new: true
          });

          e.id = max_id;
          e.end = end;

          this.setState({ user_availability: availability }, () => {

              this.updateNrOfSlots();

              if(this.state.slot == 'range')
              {
                  this.selectEvent(e, type);
              }
          });
      }
  }

  //
  deleteEvent(id, type = 'appointment')
  {
      var availability = [];

      if(type == 'appointment')
      {
          this.state.availability.map((record) => {

              if(record.id != id)
              {
                  availability.push(record);
              }
          });

          this.setState({ availability: availability });
      }
      else if(type == 'user')
      {
          this.state.user_availability.map((record) => {

              if(record.id != id)
              {
                  availability.push(record);
              }
          });

          this.setState({ user_availability: availability });
      }
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys }, () => {});
  }

  switchAppointmentCalendarDate(direction)
  {
      if(direction == 'now')
      {
          this.setState({
              appointment_calendar_date: new Date(),
              selected_week: ''+dayjs().subtract(1, 'week').format('w')
          }, () => {

              this.getWeekNumbers(dayjs().format('YYYY'));
          });
      }
      else
      {
          var current_date = this.state.appointment_calendar_date;
          var current_view = ''+this.state.appointment_calendar_view;
          var new_date = null;

          //
          if(current_view)
          {
              if(direction == 'previous')
              {
                  new_date = dayjs(current_date).subtract(1, current_view).toDate();
              }
              else if(direction == 'next')
              {
                  new_date = dayjs(current_date).add(1, current_view).toDate();
              }
          }

          //
          if(new_date)
          {
              this.setState({
                  appointment_calendar_date: new_date,
                  selected_week: ''+dayjs(new_date).subtract(1, 'week').format('w')
              }, () => {

                  this.getWeekNumbers(dayjs(new_date).format('YYYY'));
              });
          }
      }
  }

  switchAppointmentCalendarView(view)
  {
      this.setState({ appointment_calendar_view: view }, () => {

          if(view == 'week')
          {
              this.setState({
                  appointment_calendar_date: dayjs(this.state.appointment_calendar_date).startOf('week').toDate(),
                  appointment_calendar_height: false,
                  appointment_calendar_selectable: true,
                  appointment_calendar_clickable: true,
                  appointment_calendar_resizable: true,
              });
          }
          else if(view == 'month')
          {
              this.setState({
                  appointment_calendar_date: dayjs(this.state.appointment_calendar_date).startOf('month').toDate(),
                  appointment_calendar_height: 600,
                  appointment_calendar_selectable: false,
                  appointment_calendar_clickable: false,
                  appointment_calendar_resizable: false,
              });
          }
          else if(view == 'agenda')
          {
              this.setState({
                  appointment_calendar_date: dayjs(this.state.appointment_calendar_date).startOf('month').toDate(),
                  appointment_calendar_height: false,
                  appointment_calendar_selectable: false,
                  appointment_calendar_clickable: false,
                  appointment_calendar_resizable: false,
              });
          }
      });
  }

  getWeekNumbers(year)
  {
      var week_numbers = [];

      var nr_of_weeks = dayjs().year(year).isoWeeksInYear();
      var this_year = dayjs(this.state.appointment_calendar_date).format('YYYY');
      var this_week = dayjs(this.state.appointment_calendar_date).format('w');

      for(var w = 1; w <= nr_of_weeks; w++)
      {
          if(year != this_year || (year == this_year && w != this_week))
          {
              week_numbers.push({
                  label: 'Week ' + w,
                  value: ''+w
              });
          }
      }

      this.setState({ week_numbers: week_numbers });

      //
      return week_numbers;
  }

  getYears()
  {
      var years = [];

      var min_year = 2013;
      var max_year = dayjs().add(2, 'year').format('YYYY');

      for(var year = min_year; year <= max_year; year++)
      {
          years.push({
              label: year,
              value: ''+year
          });
      }

      //
      return years;
  }

  replicateWeek()
  {
      // selected_week & year from state
      var selected_week = this.state.selected_week;
      var selected_year = this.state.selected_year;

      var to_week = dayjs(this.state.appointment_calendar_date).format('w');
      var to_year = dayjs(this.state.appointment_calendar_date).format('YYYY');

      // calculate nr of days between selected and current week
      var date_of_week = dayjs(selected_year + '-01-01').add((selected_week * 7), 'days');
      var day_of_week = date_of_week.isoWeekday();
      var first_day_of_selected_week_date = dayjs((date_of_week).subtract((day_of_week - 1), 'day').format('YYYY-MM-DD'));

      date_of_week = dayjs(to_year + '-01-01').add((to_week * 7), 'days');
      day_of_week = date_of_week.isoWeekday();
      var first_day_of_current_week_date = dayjs((date_of_week).subtract((day_of_week - 1), 'day').format('YYYY-MM-DD'));

      var duration = false;

      if(first_day_of_current_week_date > first_day_of_selected_week_date)
      {
          duration = dayjs.duration(first_day_of_current_week_date.diff(first_day_of_selected_week_date)).asDays();
      }
      else
      {
          duration = -dayjs.duration(first_day_of_selected_week_date.diff(first_day_of_current_week_date)).asDays();
      }

      var max_id = 0;

      this.state.availability.map((record) => {

          if(record.id > max_id)
          {
              max_id = record.id;
          }
      });

      var new_availability = [];

      var availability = this.state.availability;

      availability.map((record) => {

          var record_week = dayjs(record.start).format('w');
          var record_year = dayjs(record.start).format('YYYY');

          if(record_week == selected_week && record_year == selected_year)
          {   // keep and replicate record to calendar week
              new_availability.push(record);

              var new_record = { ...record };

              if(duration > 0)
              {
                  new_record.start = dayjs(new_record.start).add(duration, 'day').toDate();
                  new_record.end = dayjs(new_record.end).add(duration, 'day').toDate();
              }
              else if(duration < 0)
              {
                  new_record.start = dayjs(new_record.start).subtract(-duration, 'day').toDate();
                  new_record.end = dayjs(new_record.end).subtract(-duration, 'day').toDate();
              }

              new_record.id = ++max_id;
              new_record.new = true;

              new_availability.push(new_record);
          }
          else if(record_week != to_week || record_year != to_year)
          {   // keep
              new_availability.push(record);
          }
      });

      //
      this.setState({ availability: new_availability });
  }

  showReplicateWeekSchedule()
  {
    this.setState({
        available_users: [],
        show_clone_user_schedule: true,
        clone_week_schedule: true,
        clone_user_schedule: false,
    });
  }

  showReplicateWeekScheduleUser()
  {
    var schedule_users = [];

    var user_id = false;

    this.state.appointment.users.map((user) => {

        if(user.id != this.state.show_update_user)
        {
            if(!user_id){ user_id = ''+user.id }

            schedule_users.push({
                label: user.full_name,
                value: ''+user.id,
            });
        }
    });

    this.setState({
        schedule_users: schedule_users,
        selected_user_id: user_id,
        show_clone_user_schedule: true,
        clone_week_schedule: true,
        clone_user_schedule: true,
    });
  }

  showReplicateScheduleUser()
  {
      var schedule_users = [];

      var user_id = false;

      this.state.appointment.users.map((user) => {

          if(user.id != this.state.show_update_user)
          {
              if(!user_id){ user_id = ''+user.id }

              schedule_users.push({
                  label: user.full_name,
                  value: ''+user.id,
              });
          }
      });

      this.setState({
          schedule_users: schedule_users,
          selected_user_id: user_id,
          show_clone_user_schedule: true,
          clone_week_schedule: false,
          clone_user_schedule: true,
      });
  }

  /**
   * specific week from current user
   * specific week from selected user
   * all from selected user
   *
   * this.state [clone_week_schedule | clone_user_schedule]
   */
  replicateScheduleForUser()
  {
      // clone_week_schedule & year from state
      var specific_week = this.state.clone_week_schedule;
      var specific_user = this.state.clone_user_schedule;

      // selected_week & year from state
      var selected_user_id = this.state.selected_user_id;
      var selected_week = this.state.selected_week;
      var selected_year = this.state.selected_year;

      var to_week = dayjs(this.state.appointment_calendar_date).format('w');
      var to_year = dayjs(this.state.appointment_calendar_date).format('YYYY');

      // calculate nr of days between selected and current week
      var date_of_week = dayjs(selected_year + '-01-01').add((selected_week * 7), 'days');
      var day_of_week = date_of_week.isoWeekday();
      var first_day_of_selected_week_date = dayjs((date_of_week).subtract((day_of_week - 1), 'day').format('YYYY-MM-DD'));

      date_of_week = dayjs(to_year + '-01-01').add((to_week * 7), 'days');
      day_of_week = date_of_week.isoWeekday();
      var first_day_of_current_week_date = dayjs((date_of_week).subtract((day_of_week - 1), 'day').format('YYYY-MM-DD'));

      var duration = false;

      if(first_day_of_current_week_date > first_day_of_selected_week_date)
      {
          duration = dayjs.duration(first_day_of_current_week_date.diff(first_day_of_selected_week_date)).asDays();
      }
      else
      {
          duration = -dayjs.duration(first_day_of_selected_week_date.diff(first_day_of_current_week_date)).asDays();
      }

      var max_id = 0;

      var new_availability = [];

      var availability = [];

      if(!specific_user)
      {   // use current user availability
          availability = this.state.user_availability;

          this.state.user_availability.map((record) => {

              if(record.id > max_id)
              {
                  max_id = record.id;
              }
          });

          availability.map((record) => {

              var record_week = dayjs(record.start).format('w');
              var record_year = dayjs(record.start).format('YYYY');

              if(record_week == selected_week && record_year == selected_year)
              {   // keep and replicate record to calendar week
                  new_availability.push(record);

                  var new_record = { ...record };

                  if(duration > 0)
                  {
                      new_record.start = dayjs(new_record.start).add(duration, 'day').toDate();
                      new_record.end = dayjs(new_record.end).add(duration, 'day').toDate();
                  }
                  else if(duration < 0)
                  {
                      new_record.start = dayjs(new_record.start).subtract(-duration, 'day').toDate();
                      new_record.end = dayjs(new_record.end).subtract(-duration, 'day').toDate();
                  }

                  new_record.id = ++max_id;
                  new_record.new = true;
                  new_record.currentUser = true;

                  new_availability.push(new_record);
              }
              else if(record_week != to_week || record_year != to_year || !record.currentUser)
              {  // keep
                  new_availability.push(record);
              }
          });
      }
      else if(specific_user && !specific_week)
      {   // use specific user availability
          this.state.appointment.users_availability.map((record) => {

              new_availability.push({
                  id: record.id,
                  title: record.title,
                  start: dayjs(record.date_available + ' ' + record.from_time_available).toDate(),
                  end: dayjs(record.date_available + ' ' + record.untill_time_available).toDate(),
                  max_nr_of_appointments: parseInt(record.max_nr_of_appointments),
                  allDay: false,
                  currentUser: false
              });

              if(record.appointment_user_id == selected_user_id)
              {
                  new_availability.push({
                      id: record.id,
                      title: record.title,
                      start: dayjs(record.date_available + ' ' + record.from_time_available).toDate(),
                      end: dayjs(record.date_available + ' ' + record.untill_time_available).toDate(),
                      max_nr_of_appointments: parseInt(record.max_nr_of_appointments),
                      allDay: false,
                      currentUser: true
                  });
              }

              if(record.id > max_id)
              {
                  max_id = record.id;
              }
          });
      }
      else if(specific_user && specific_week)
      {
          availability = this.state.user_availability;

          this.state.user_availability.map((record) => {

              if(record.id > max_id)
              {
                  max_id = record.id;
              }
          });

          // use "own" data
          availability.map((record) => {

              var record_week = dayjs(record.start).format('w');
              var record_year = dayjs(record.start).format('YYYY');

              if(record_week != to_week || record_year != to_year || !record.currentUser)
              {  // keep
                  new_availability.push(record);
              }
          });

          // replace week with other user week
          this.state.appointment.users_availability.map((record) => {

              if(record.appointment_user_id == selected_user_id)
              {
                  var record_week = dayjs(record.date_available + ' ' + record.from_time_available).format('w');
                  var record_year = dayjs(record.date_available + ' ' + record.from_time_available).format('YYYY');

                  if(record_week == selected_week && record_year == selected_year)
                  {  // add
                      var new_record = { ...record };

                      new_record.id = ++max_id;
                      new_record.new = true;
                      new_record.currentUser = true;

                      if(duration > 0)
                      {
                          new_record.start =  dayjs(record.date_available + ' ' + record.from_time_available).add(duration, 'day').toDate();
                          new_record.end = dayjs(record.date_available + ' ' + record.untill_time_available).add(duration, 'day').toDate();
                      }
                      else if(duration < 0)
                      {
                          new_record.start =  dayjs(record.date_available + ' ' + record.from_time_available).subtract(duration, 'day').toDate();
                          new_record.end = dayjs(record.date_available + ' ' + record.untill_time_available).subtract(duration, 'day').toDate();
                      }

                      new_availability.push(new_record);
                  }
              }
          });
      }

      //
      this.setState({
          user_availability: new_availability,
          show_clone_user_schedule: false,
          selected_user_id: false,
          clone_week_schedule: false,
          clone_user_schedule: false,
      });
  }

  showFastScheduler()
  {
      this.setState({ show_fast_scheduler: true });
  }

  hasRangeDays()
  {
      var range_days = this.state.range_days ? Object.values(this.state.range_days) : null;

      //
      return range_days && range_days.includes && range_days.includes(true) ? true : false;
  }

  updateDaypart(index, key, value)
  {
      var dayparts = this.state.dayparts ? this.state.dayparts : [];

      if(!dayparts || !dayparts[index])
      {
          dayparts[index] = {};
      }

      dayparts[index][key] = value;

      this.setState({ dayparts: dayparts });
  }

  getDaypartValue(index, key)
  {
      var dayparts = this.state.dayparts;

      return this.state.dayparts && this.state.dayparts[index] && this.state.dayparts[index][key] ? this.state.dayparts[index][key] : null;
  }

  applyFastSchedule()
  {
      // STATE:
      // - range_dates (dayjs)
      // - range_days
      // - nr_of_dayparts
      // - dayparts [{ from_time, till_time, name, max_nr_of_appointments }]

      var min_date = this.state.range_dates[0];
      var max_date = this.state.range_dates[1];

      var current_date = min_date;

      var availability = [];
      var max_id = 0;

      if(!this.state.use_users)
      {
          availability = this.state.availability;

          availability.map((record) => {

              if(record.id > max_id)
              {
                  max_id = record.id;
              }
          });
      }
      else
      {
          availability = this.state.user_availability;

          this.state.user_availability.map((record) => {

              if(record.id > max_id)
              {
                  max_id = record.id;
              }
          });
      }

      while(current_date.isSameOrBefore(max_date))
      {
          var day_of_week = current_date.isoWeekday();

          //
          if(this.state.range_days && this.state.range_days[days[(day_of_week - 1)]])
          {
              this.state.dayparts.map((daypart) => {
                  // add to global schedule
                  if(!this.state.use_users)
                  {
                      availability.push({
                          id: ++max_id,
                          title: daypart.name,
                          start: dayjs(current_date.format('YYYY-MM-DD') + ' ' + daypart.from_time.format('HH:mm') + ':00').toDate(),
                          end: dayjs(current_date.format('YYYY-MM-DD') + ' ' + daypart.till_time.format('HH:mm') + ':00').toDate(),
                          max_nr_of_appointments: daypart.max_nr_of_appointments ? parseInt(daypart.max_nr_of_appointments) : null,
                          new: true
                      });
                  }
                  // or add to user schedule
                  else
                  {
                      availability.push({
                          id: ++max_id,
                          title: daypart.name,
                          start: dayjs(current_date.format('YYYY-MM-DD') + ' ' + daypart.from_time.format('HH:mm') + ':00').toDate(),
                          end: dayjs(current_date.format('YYYY-MM-DD') + ' ' + daypart.till_time.format('HH:mm') + ':00').toDate(),
                          max_nr_of_appointments: daypart.max_nr_of_appointments ? parseInt(daypart.max_nr_of_appointments) : null,
                          new: true,
                          currentUser: true
                      });
                  }
              });
          }

          current_date = current_date.add('1', 'day');
      }

      if(!this.state.use_users)
      {
          this.setState({ availability: availability }, () => {

              this.updateNrOfSlots();

              this.setState({ show_fast_scheduler: false });
          });
      }
      else
      {
          this.setState({ user_availability: availability }, () => {

              this.updateNrOfSlots();

              this.setState({ show_fast_scheduler: false });
          });
      }

      // this.setState({
      //     range_dates: [null, null],
      //     range_days: [],
      //     nr_of_dayparts: 1,
      //     dayparts: [{}]
      // });
  }

  includesEmailAddress(email_addresses, value)
  {
    var includes = false;

    email_addresses.map((record) => {

        if(record.value == value)
        {
            includes = true;

            return;
        }
    });

    return includes;
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const user_columns = [
        {
            title: 'E-mail',
            dataIndex: 'email_address',
            render: (text, record) => (
                <Link to={false} onClick={() => { this.showUpdateUser(record.id); }}>{text}</Link>
            )
        },
        {
            title: 'Naam',
            dataIndex: 'full_name',
        },
        {
            title: 'Bedrijf',
            dataIndex: 'company_name',
        },
        {
            title: 'Aantal dagen beschikbaar',
            dataIndex: 'nr_of_days_available'
        }
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange : this.onSelectChange,
    };

    //
    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'1'} />

            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={() => { this.saveAppointmentAvailability(); }}>Opslaan en volgende</Button>
                      <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                <Row className="mbm">
                  <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                    <AppointmentSteps current={2} {...this.props} />
                  </Card>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>

                      <Form
                        onSubmit={() => { this.saveAppointmentAvailability(); }}
                        // onChange={() => { this.updateNrOfSlots(); }}
                        layout={'vertical'}
                        colon={true}
                        >

                        <Row gutter={24} className='mbm'>
                          <Col span={24}>
                            <Card>
                                <Row>
                                  <Col span={24}>
                                  <Form.Item required label="Type afspraak">
                                    {getFieldDecorator('type', {
                                      initialValue: this.state.appointment.type ? this.state.appointment.type : 'free', // 'before',
                                      rules: [{ required: true, message: 'Geef een type afspraak op' }],
                                    })(
                                      <Select>
                                        <Select.Option value="before">Vooraf inplannen</Select.Option>
                                        <Select.Option value="free">Vrije keuze (achteraf inplannen)</Select.Option>
                                      </Select>
                                    )}
                                  </Form.Item>
                                  </Col>
                                  <Col span={24}>
                                    <Form.Item required label={
                                        <span>
                                            Afspraak inplannen
                                            <Tooltip className="mhs" title='Geef op hoe de afspraken ingepland moeten worden. Moet een afspraak op een specifieke dag en tijd gemaakt worden of binnen een bepaald deel van de dag, bijvoorbeeld in de middag.'>
                                              <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                    }>
                                      {getFieldDecorator('slot', {
                                        initialValue: this.state.slot,
                                        onChange: (value) => { this.setState({ slot: value }, () => { this.updateNrOfSlots(); }); },
                                        rules: [{ required: true, message: 'Geef een soort tijdslot op' }],
                                      })(
                                        <Select>
                                          <Select.Option value="specific">Specifieke dag en tijd</Select.Option>
                                          <Select.Option value="range">Bepaald deel van de dag</Select.Option>
                                        </Select>
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item required label={
                                        <span>
                                            Afspraak inplannen vanaf
                                            <Tooltip className="mhs" title='Vul hier een datum in vanaf wanneer de afspraken plaats kunnen vinden. In een volgende stap kunnen exacte dagen en tijdstippen worden aangegeven van wanneer een afspraak plaats kan vinden.'>
                                              <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                    }>
                                      {getFieldDecorator('schedule_from_date', {
                                        initialValue: this.state.appointment && this.state.appointment.schedule_from_date && this.state.appointment.schedule_from_date != '1970-01-01' && this.state.appointment.schedule_from_date != '0000-00-00' ? dayjs(moment(this.state.appointment.schedule_from_date)) : dayjs(),
                                        rules: [{ required: true, message: 'Vul een datum in' }],
                                        onChange: (date, date_string) => { this.setState({ appointment_calendar_date: date.toDate() }); }
                                      })(
                                        <DatePicker format={'DD-MM-YYYY'} />
                                      )}
                                    </Form.Item>
                                  </Col>

                                  {this.props.form.getFieldValue('type') == 'before' ? (
                                    <Col span={12}>
                                      <Form.Item required label="Tot hoeveel uur voor de afspraak mag er gewijzigd/geannuleerd worden?">
                                        {getFieldDecorator('max_hours_before_appointment_change_allowed', {
                                          initialValue: this.state.appointment.max_hours_before_appointment_change_allowed ? this.state.appointment.max_hours_before_appointment_change_allowed : 48,
                                          rules: [{ required: true, message: 'Vul een numerieke waarde in!' }],
                                        })(
                                          <InputNumber min={0} />
                                        )}
                                      </Form.Item>
                                    </Col>
                                  ) : (
                                    <Col span={12}>
                                      <Form.Item required label={
                                          <span>
                                              Afspraken kunnen gemaakt worden tot
                                              <Tooltip className="mhs" title='Vul hier een datum in tot wanneer afspraken gemaakt of gewijzigd kunnen worden. Na deze datum kan de afspraak dus niet meer worden gemaakt of gewijzigd, ook al ligt de datum van de afspraak nog in de toekomst.'>
                                                <InfoCircleTwoTone />
                                              </Tooltip>
                                          </span>
                                      }>
                                        {getFieldDecorator('allow_appointments_to_be_made_till', {
                                          initialValue: this.state.appointment.allow_appointments_to_be_made_till ? dayjs(moment(this.state.appointment.allow_appointments_to_be_made_till)) : null,
                                          rules: [{ required: true, message: 'Vul een datum in!' }],
                                        })(
                                          <DatePicker format={'DD-MM-YYYY'} />
                                        )}
                                      </Form.Item>
                                    </Col>
                                  )}

                                <Col span={12}>
                                  <Form.Item required label="Benodigde tijd (in minuten) per afspraak">
                                    {getFieldDecorator('required_minutes_per_appointment', {
                                      initialValue: this.state.appointment.required_minutes_per_appointment ? this.state.appointment.required_minutes_per_appointment : '',
                                      onChange: (value) => { this.setState({ required_minutes_per_appointment: value }, () => { this.updateNrOfSlots(); }); },
                                      rules: [{ required: true, message: 'Vul een tijd in minuten in' }],
                                    })(
                                      <InputNumber
                                        min={0}
                                        step={1}
                                        // max={480}
                                        precision={0}
                                        />
                                    )}
                                  </Form.Item>
                                </Col>

                                {this.props.form.getFieldValue('type') == 'free' ? (
                                  <Col span={12}>
                                    <Form.Item required label="Melding indien er niet op tijd een dag en tijd gekozen is">
                                      {getFieldDecorator('no_appointment_action', {
                                        initialValue: this.state.appointment.no_appointment_action ? String(this.state.appointment.no_appointment_action) : '1',
                                        rules: [{ required: true, message: 'Vul een herinnering tekst in!' }],
                                      })(
                                        <Select>
                                          <Select.Option key='1'>Niet gepland. Wij nemen contact met u op voor het maken van een afspraak.</Select.Option>
                                          <Select.Option key='2'>Niet gepland. Wij komen op een willekeurig moment bij u langs</Select.Option>
                                          <Select.Option key='3'>Geen melding. Er wordt een door ons gekozen dag en tijd weergegeven.</Select.Option>
                                          <Select.Option key='4'>Aangepaste melding. Vul deze hiernaast in.</Select.Option>
                                        </Select>
                                      )}
                                    </Form.Item>
                                  </Col>
                                ) : null}

                                {this.props.form.getFieldValue('no_appointment_action') == '4' && this.props.form.getFieldValue('type') == 'free' ? (
                                  <Col span={12}>
                                    <Form.Item required label="Aangepaste melding">
                                      {getFieldDecorator('additional_message', {
                                        initialValue: this.state.appointment.additional_message,
                                        rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                      })(
                                        <Input />
                                      )}
                                    </Form.Item>
                                  </Col>
                                ) : null}

                                <Col span={24}>
                                    <Form.Item label="Afspraken koppelen aan één of meerdere verantwoordelijken">
                                      {getFieldDecorator('use_users', {
                                        initialValue: this.state.appointment.use_users ? true : false,
                                        onChange: (checked) => { this.setState({ use_users: checked, appointment: { ...this.state.appointment, use_users: checked } }, () => { this.updateNrOfSlots(); }); },
                                        // rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                      })(
                                        <Switch />
                                      )}
                                    </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>


                        { /* appointment with multiple users */ }
                        { this.state.use_users ? <Row gutter={24} className='mbm'>
                          <Col span={24}>
                            <Card>

                              <Row className="mbs" gutter={24}>
                                <Col span={24}>
                                    <b>Verantwoordelijken en beschikbaarheid (adressen: {this.props.nr_of_addresses} / tijdsloten: {this.props.nr_of_slots})</b>
                                </Col>
                              </Row>

                              <Row className="mbs" gutter={24}>
                                <Col span={24}>
                                    <Button type="new" onClick={() => { this.showAddUser(); /*this.setState({ email_address: '', full_name: '', company_name: '', user_availability: [], show_add_new_user: true });*/ }}>Toevoegen</Button>
                                    <Dropdown trigger={['click']} disabled={ this.state.selectedRowKeys.length > 0 && this.props.HaveAbility('appointment', 'write') ? false : true } overlay={(
                                        <Menu>
                                          <Menu.Item disabled={!this.props.HaveAbility('appointment', 'delete')} onClick={() => { this.deleteUsers(); }} key="5" style={{ color: '#f5222d' }}>Verwijderen</Menu.Item>
                                        </Menu>
                                      )}>
                                      <Button type="secondary">
                                        Acties <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                    <CustomTable
                                      rowKey='id'
                                      size="middle"
                                      columns={user_columns}
                                      rowSelection={rowSelection}
                                      dataSource={this.state.appointment.users}
                                      loading={this.props.isFetching}
                                      />
                                </Col>
                              </Row>

                              <Modal
                                title="Verantwoordelijke wijzigen"
                                width={960}
                                open={this.state.show_update_user}
                                onCancel={() => { this.setState({ show_update_user: false }); }}
                                onOk={() => { this.updateUser(); }}
                                cancelText={'Annuleren'}
                                okText={'Opslaan'}
                                >

                                  <Form layout="vertical">
                                      <Form.Item required label="E-mailadres">
                                        { getFieldDecorator('user_email', {
                                          initialValue: this.state.email_address
                                          // initialValue: this.state.appointment.use_users ? true : false,
                                          // onChange: (checked) => { this.setState({ use_users: checked }); },
                                          // rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                        })(
                                        <Select
                                            showSearch
                                            allowClear={false}
                                            autoClearSearchValue={false}
                                            onSearch={(value) => {
                                                // console.log('onSearch:', value);
                                                if(value)
                                                {
                                                    this.setState({ email_address: value });
                                                }
                                            }}
                                            onChange={(value) => {
                                                // console.log('onChange:', value);
                                                if(value)
                                                {
                                                    this.setState({ email_address: value });
                                                }
                                            }}
                                            onBlur={() => { /*console.log('onBlur!');*/ }}
                                            onClear={() => { /*console.log('onClear!');*/ }}
                                            options={this.state.email_address && !this.includesEmailAddress(this.state.available_users, this.state.email_address)
                                                ?
                                                [{ label: this.state.email_address, value: this.state.email_address }].concat(this.state.available_users)
                                                :
                                                this.state.available_users
                                            }
                                            value={this.state.email_address}
                                            searchValue={this.state.email_address}
                                            />
                                        )}
                                      </Form.Item>

                                      <Form.Item label="Naam">
                                        { getFieldDecorator('full_name', {
                                          initialValue: this.state.full_name
                                          // initialValue: this.state.appointment.use_users ? true : false,
                                          // onChange: (value) => { this.setState({ full_name: value }); },
                                          // rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                        })(
                                          <Input
                                            onChange={(e) => { this.setState({ full_name: e.target.value }); }}
                                            />
                                        )}
                                      </Form.Item>

                                      <Form.Item label="Bedrijfsnaam">
                                        { getFieldDecorator('company_name', {
                                          initialValue: this.state.company_name
                                          // initialValue: this.state.appointment.use_users ? true : false,
                                          // onChange: (value) => { this.setState({ company_name: value }); },
                                          // rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                        })(
                                          <Input
                                            onChange={(e) => { this.setState({ company_name: e.target.value }); }}
                                            />
                                        )}
                                      </Form.Item>

                                      <span>Beschikbaarheid opgeven  (adressen: {this.props.nr_of_addresses} / tijdsloten: {this.props.nr_of_slots})</span><br />
                                      <Row style={{ marginBottom: 12 }} align={'middle'}>
                                          <Col span={16} style={{ textAlign: 'left' }}>
                                            <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('previous'); }} style={{ width: 50, marginRight: 8 }}><LeftOutlined /></Button>
                                              {
                                                  this.state.appointment_calendar_view == 'week' ?
                                                  'week ' + dayjs(this.state.appointment_calendar_date).format('w - YYYY')
                                                  :
                                                  this.state.appointment_calendar_view == 'month' ?
                                                  dayjs(this.state.appointment_calendar_date).format('MMMM YYYY')
                                                  :
                                                  null
                                              }
                                              <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('next'); }} style={{ width: 50, marginLeft: 8, marginRight: 8 }}><RightOutlined /></Button>

                                              <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('now'); }} style={{ width: 100, marginRight: 8 }}>Vandaag</Button>

                                              <Button onClick={() => { this.showFastScheduler(); }} style={{ marginRight: 8 }}>Snel inplannen</Button>

                                              <Dropdown trigger={['click']} overlay={(
                                                  <Menu>
                                                    <Menu.Item onClick={() => { this.showReplicateWeekSchedule(); }} key="week">Week overnemen van andere week</Menu.Item>
                                                    <Menu.Item onClick={() => { this.showReplicateWeekScheduleUser(); }} key="week-user">Week overnemen van andere verantwoordelijke</Menu.Item>
                                                    <Menu.Item onClick={() => { this.showReplicateScheduleUser(); }} key="user">Volledige planning overnemen van andere verantwoordelijke</Menu.Item>
                                                  </Menu>
                                                )}>
                                                <Button type="secondary">
                                                  Overnemen <DownOutlined />
                                                </Button>
                                              </Dropdown>
                                          </Col>
                                          <Col span={8} style={{ textAlign: 'right' }}>
                                              <Button onClick={() => { this.switchAppointmentCalendarView('week'); }} style={{ width: 80, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'week' ? 'normal' : 'bold' }}>Week</Button>
                                              <Button onClick={() => { this.switchAppointmentCalendarView('month'); }} style={{ width: 80, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'month' ? 'normal' : 'bold' }}>Maand</Button>
                                              <Button onClick={() => { this.switchAppointmentCalendarView('agenda'); }} style={{ width: 80, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'agenda' ? 'normal' : 'bold' }}>Agenda</Button>
                                          </Col>
                                      </Row>

                                      <DragAndDropCalendar
                                          localizer={localizer}
                                          events={this.state.user_availability}
                                          formats={{
                                              dayFormat: (date, culture, localizer) => localizer.format(date, 'dd DD MMMM', culture)
                                          }}
                                          min={dayjs('06:00', 'HH:mm').toDate()}
                                          max={dayjs('22:00', 'HH:mm').toDate()}
                                          date={this.state.appointment_calendar_date}
                                          view={this.state.appointment_calendar_view}
                                          showAllEvents={true}
                                          toolbar={false}
                                          onEventDrop={(e) => { if(!e.event.currentUser && !e.event.new){ return false; } this.moveEvent(e, 'user', true); }}
                                          onEventResize={(e) => { if(!e.event.currentUser && !e.event.new){ return false; } this.resizeEvent(e, 'user', true); }}
                                          onSelectSlot={(e) => { this.selectSlots(e, 'user'); }}
                                          onSelectEvent={(e) => { if(!e.currentUser && !e.new){ return false; } this.selectEvent(e, 'user'); }}
                                          selectable={this.state.appointment_calendar_selectable}
                                          resizable={this.state.appointment_calendar_resizable}
                                          showMultiDayTimes={true}
                                          step={15}
                                          style={{ height: (this.state.appointment_calendar_height ? this.state.appointment_calendar_height : 'auto') }}
                                          eventPropGetter={(event, start, end, isSelected) => { if(!event.currentUser && !event.new){ return { style: {
                                                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                                                borderColor: 'rgba(200, 200, 200, 0.67)',
                                                color: 'rgba(0, 0, 0, 0.5)',
                                            } } }
                                            else{ return {}; }
                                          }}
                                          />

                                  </Form>

                              </Modal>

                              <Modal
                                title="Verantwoordelijke toevoegen"
                                width={960}
                                open={this.state.show_add_new_user}
                                onCancel={() => { this.setState({ show_add_new_user: false }); }}
                                onOk={() => { this.addUser(); }}
                                okText={'Opslaan'}
                                cancelText={'Annuleren'}
                                >

                                  <Form layout="vertical">
                                      <Form.Item required label="E-mailadres">
                                        { getFieldDecorator('user_email', {
                                          // initialValue: this.state.appointment.use_users ? true : false,
                                          // onChange: (checked) => { this.setState({ use_users: checked }); },
                                          // rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                        })(
                                          <Select
                                            showSearch
                                            allowClear={false}
                                            autoClearSearchValue={false}
                                            onSearch={(value) => {
                                                // console.log('onSearch:', value);
                                                if(value)
                                                {
                                                    this.setState({ email_address: value });
                                                }
                                            }}
                                            onChange={(value) => {
                                                // console.log('onChange:', value);
                                                if(value)
                                                {
                                                    this.setState({ email_address: value });
                                                }
                                            }}
                                            onBlur={() => { /*console.log('onBlur!');*/ }}
                                            onClear={() => { /*console.log('onClear!');*/ }}
                                            options={this.state.email_address && !this.includesEmailAddress(this.state.available_users, this.state.email_address)
                                                ?
                                                [{ label: this.state.email_address, value: this.state.email_address }].concat(this.state.available_users)
                                                :
                                                this.state.available_users
                                            }
                                            value={this.state.email_address}
                                            searchValue={this.state.email_address}
                                            />
                                        )}
                                      </Form.Item>

                                      <Form.Item label="Naam">
                                        { getFieldDecorator('full_name', {
                                          // initialValue: this.state.appointment.use_users ? true : false,
                                          // onChange: (value) => { this.setState({ full_name: value }); },
                                          // rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                        })(
                                          <Input
                                            onChange={(e) => { this.setState({ full_name: e.target.value }); }}
                                            />
                                        )}
                                      </Form.Item>

                                      <Form.Item label="Bedrijfsnaam">
                                        { getFieldDecorator('company_name', {
                                          // initialValue: this.state.appointment.use_users ? true : false,
                                          // onChange: (value) => { this.setState({ company_name: value }); },
                                          // rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                                        })(
                                          <Input
                                            onChange={(e) => { this.setState({ company_name: e.target.value }); }}
                                            />
                                        )}
                                      </Form.Item>

                                      <span>Beschikbaarheid opgeven (adressen: {this.props.nr_of_addresses} / tijdsloten: {this.props.nr_of_slots})</span><br />
                                      <Row style={{ marginBottom: 12 }} align={'middle'}>
                                          <Col span={16} style={{ textAlign: 'left' }}>
                                            <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('previous'); }} style={{ width: 50, marginRight: 8 }}><LeftOutlined /></Button>
                                              {
                                                  this.state.appointment_calendar_view == 'week' ?
                                                  'week ' + dayjs(this.state.appointment_calendar_date).format('w - YYYY')
                                                  :
                                                  this.state.appointment_calendar_view == 'month' ?
                                                  dayjs(this.state.appointment_calendar_date).format('MMMM YYYY')
                                                  :
                                                  null
                                              }
                                              <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('next'); }} style={{ width: 50, marginLeft: 8, marginRight: 8 }}><RightOutlined /></Button>

                                              <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('now'); }} style={{ width: 100, marginRight: 8 }}>Vandaag</Button>

                                              <Button onClick={() => { this.showFastScheduler(); }} style={{ marginRight: 8 }}>Snel inplannen</Button>

                                              <Dropdown trigger={['click']} overlay={(
                                                  <Menu>
                                                    <Menu.Item onClick={() => { this.showReplicateWeekSchedule(); }} key="week">Week planning</Menu.Item>
                                                    <Menu.Item onClick={() => { this.showReplicateWeekScheduleUser(); }} key="week-user">Week planning van verantwoordelijke</Menu.Item>
                                                    <Menu.Item onClick={() => { this.showReplicateScheduleUser(); }} key="user">Volledige planning van verantwoordelijke</Menu.Item>
                                                  </Menu>
                                                )}>
                                                <Button type="secondary">
                                                  Overnemen <DownOutlined />
                                                </Button>
                                              </Dropdown>
                                          </Col>
                                          <Col span={8} style={{ textAlign: 'right' }}>
                                              <Button onClick={() => { this.switchAppointmentCalendarView('week'); }} style={{ width: 80, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'week' ? 'normal' : 'bold' }}>Week</Button>
                                              <Button onClick={() => { this.switchAppointmentCalendarView('month'); }} style={{ width: 80, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'month' ? 'normal' : 'bold' }}>Maand</Button>
                                              <Button onClick={() => { this.switchAppointmentCalendarView('agenda'); }} style={{ width: 80, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'agenda' ? 'normal' : 'bold' }}>Agenda</Button>
                                          </Col>
                                      </Row>

                                      <DragAndDropCalendar
                                          localizer={localizer}
                                          events={this.state.user_availability}
                                          formats={{
                                              dayFormat: (date, culture, localizer) => localizer.format(date, 'dd DD MMMM', culture)
                                          }}
                                          min={dayjs('06:00', 'HH:mm').toDate()}
                                          max={dayjs('22:00', 'HH:mm').toDate()}
                                          date={this.state.appointment_calendar_date}
                                          view={this.state.appointment_calendar_view}
                                          showAllEvents={true}
                                          toolbar={false}
                                          onEventDrop={(e) => { if(!e.event.currentUser && !e.event.new){ return false; } this.moveEvent(e, 'user', true); }}
                                          onEventResize={(e) => { if(!e.event.currentUser && !e.event.new){ return false; } this.resizeEvent(e, 'user', true); }}
                                          onSelectSlot={(e) => { this.selectSlots(e, 'user'); }}
                                          onSelectEvent={(e) => { if(!e.currentUser && !e.new){ return false; } this.selectEvent(e, 'user'); }}
                                          selectable={this.state.appointment_calendar_selectable}
                                          resizable={this.state.appointment_calendar_resizable}
                                          showMultiDayTimes={true}
                                          step={15}
                                          style={{ height: (this.state.appointment_calendar_height ? this.state.appointment_calendar_height : 'auto') }}
                                          eventPropGetter={(event, start, end, isSelected) => { if(!event.currentUser && !event.new){ return { style: {
                                                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                                                borderColor: 'rgba(200, 200, 200, 0.67)',
                                                color: 'rgba(0, 0, 0, 0.5)',
                                            } } }
                                            else{ return {}; }
                                          }}
                                          />

                                  </Form>

                              </Modal>

                            </Card>
                          </Col>
                        </Row>
                        :
                        <Row>
                            <Col span={24}>
                                <Card>
                                    <b style={{ display: 'block', marginBottom: 12 }}>Beschikbaarheid (adressen: {this.props.nr_of_addresses} / tijdsloten: {this.props.nr_of_slots})</b>
                                    <Row style={{ marginBottom: 12 }} align={'middle'}>
                                        <Col span={14} style={{ textAlign: 'left' }}>
                                            <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('previous'); }} style={{ width: 50, marginRight: 8 }}><LeftOutlined /></Button>

                                            {
                                                this.state.appointment_calendar_view == 'week' ?
                                                'week ' + dayjs(this.state.appointment_calendar_date).format('w - YYYY')
                                                :
                                                this.state.appointment_calendar_view == 'month' ?
                                                dayjs(this.state.appointment_calendar_date).format('MMMM YYYY')
                                                :
                                                null
                                            }

                                            <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('next'); }} style={{ width: 50, marginLeft: 8, marginRight: 8 }}><RightOutlined /></Button>

                                            <Button disabled={this.state.appointment_calendar_view == 'agenda'} onClick={() => { this.switchAppointmentCalendarDate('now'); }} style={{ width: 100, marginRight: 8 }}>Vandaag</Button>

                                            <Button onClick={() => { this.showFastScheduler(); }} style={{ marginRight: 8 }}>Snel inplannen</Button>

                                            <Popconfirm
                                                disabled={this.state.appointment_calendar_view != 'week'}
                                                placement="bottomLeft"
                                                title={null}
                                                icon={null}
                                                description={
                                                <div>
                                                    <Select
                                                        style={{ marginRight: 8 }}
                                                        showSearch
                                                        options={this.state.week_numbers}
                                                        value={''+this.state.selected_week}
                                                        onChange={(value) => { this.setState({ selected_week: value }) }}
                                                        />
                                                    <Select
                                                        options={this.getYears()}
                                                        value={''+this.state.selected_year}
                                                        onChange={(value) => { this.setState({ selected_year: value }); this.getWeekNumbers(value); }}
                                                        />
                                                </div>
                                                }
                                                okText={'Neem over'}
                                                cancelText={'Annuleren'}
                                                onConfirm={() => { this.replicateWeek(); }}
                                                >
                                                <Button style={{ marginRight: 4 }}>Overnemen</Button>
                                                <Tooltip title='Gebruik deze functionaliteit om de beschikbaarheid over te nemen van de geselecteerde week in de weergegeven week van de onderstaande kalender.'>
                                                  <InfoCircleTwoTone />
                                                </Tooltip>
                                            </Popconfirm>
                                        </Col>
                                        <Col span={10} style={{ textAlign: 'right' }}>
                                            <Button onClick={() => { this.switchAppointmentCalendarView('week'); }} style={{ width: 100, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'week' ? 'normal' : 'bold' }}>Week</Button>
                                            <Button onClick={() => { this.switchAppointmentCalendarView('month'); }} style={{ width: 100, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'month' ? 'normal' : 'bold' }}>Maand</Button>
                                            <Button onClick={() => { this.switchAppointmentCalendarView('agenda'); }} style={{ width: 100, marginLeft: 8, fontWeight: this.state.appointment_calendar_view != 'agenda' ? 'normal' : 'bold' }}>Agenda</Button>
                                        </Col>
                                    </Row>

                                    <DragAndDropCalendar
                                        localizer={localizer}
                                        events={this.state.availability}
                                        formats={{
                                            dayFormat: (date, culture, localizer) => localizer.format(date, 'dd DD MMMM', culture)
                                        }}
                                        min={dayjs('06:00', 'HH:mm').toDate()}
                                        max={dayjs('22:00', 'HH:mm').toDate()}
                                        date={this.state.appointment_calendar_date}
                                        view={this.state.appointment_calendar_view}
                                        showAllEvents={true}
                                        toolbar={false}
                                        onEventDrop={(e) => { this.moveEvent(e, 'appointment', true); }}
                                        onEventResize={(e) => { this.resizeEvent(e, 'appointment', true); }}
                                        onSelectSlot={(e) => { this.selectSlots(e); }}
                                        onSelectEvent={(e) => { this.selectEvent(e); }}
                                        selectable={this.state.appointment_calendar_selectable}
                                        resizable={this.state.appointment_calendar_resizable}
                                        showMultiDayTimes={true}
                                        step={15}
                                        style={{ height: (this.state.appointment_calendar_height ? this.state.appointment_calendar_height : 'auto') }}
                                        />
                                </Card>
                            </Col>
                        </Row>
                        }
                      </Form>

                  </Col>
                </Row>

                <Modal
                    title="Dagdeel"
                    open={typeof this.state.edit_event === 'object' ? true : false}
                    onCancel={() => { this.setState({ edit_event: false }); }}
                    footer={
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button style={{ marginRight: 12 }} onClick={() => { this.setState({ edit_event: false, errors: [] }); }}>Annuleren</Button>
                                <Button style={{ marginRight: 12 }} type="primary" danger onClick={() => { this.deleteEvent(this.state.edit_event.id, this.state.type_of_event); this.setState({ edit_event: false, errors: [] }); }}>Verwijderen</Button>
                                <Button type="new" onClick={(e) => { e.preventDefault(); if(this.updateEvent(this.state.edit_event, true, this.state.type_of_event)){ this.setState({ edit_event: false, errors: [] }); return true; } return false; }}>Toepassen</Button>
                            </Col>
                        </Row>
                    }
                    >

                    <Form layout="vertical">

                        <Form.Item required={this.state.slot == 'range' ? true : false} label="Naam">
                            <Input
                                onChange={(e) => { this.setState({ edit_event: { ...this.state.edit_event, title: e.target.value } }); }}
                                value={this.state.edit_event ? this.state.edit_event.title : ''}
                                />
                            { this.state.errors && this.state.errors['title'] ? <span style={{ color: '#ff0000' }}>{this.state.errors['title']}</span> : null }
                        </Form.Item>

                        <Form.Item required label="Datum">
                            <DatePicker
                              format={'DD-MM-YYYY'}
                              onChange={(date) => {
                                  this.setState({
                                      edit_event: {
                                          ...this.state.edit_event,
                                          start: dayjs(date.format('YYYY-MM-DD')+' '+dayjs(this.state.edit_event.start).format('HH:mm')).toDate(),
                                          end: dayjs(date.format('YYYY-MM-DD')+' '+dayjs(this.state.edit_event.end).format('HH:mm')).toDate(),
                                      }
                                  });
                              }}
                              value={this.state.edit_event ? dayjs(this.state.edit_event.start) : ''}
                              />
                        </Form.Item>

                        <Form.Item required label="Begin tijdstip">
                            <TimePicker
                              format={'HH:mm'}
                              onChange={(time) => {
                                  this.setState({
                                      edit_event: {
                                          ...this.state.edit_event,
                                          start: dayjs(dayjs(this.state.edit_event.start).format('YYYY-MM-DD')+' '+time.format('HH:mm:00')).toDate(),
                                      }
                                  });
                              }}
                              value={this.state.edit_event ? dayjs(this.state.edit_event.start) : ''}
                              />
                        </Form.Item>

                        <Form.Item required label="Eind tijdstip">
                            <TimePicker
                              format={'HH:mm'}
                              onChange={(time) => {
                                  this.setState({
                                      edit_event: {
                                          ...this.state.edit_event,
                                          end: dayjs(dayjs(this.state.edit_event.end).format('YYYY-MM-DD')+' '+time.format('HH:mm:00')).toDate(),
                                      }
                                  });
                              }}
                              value={this.state.edit_event ? dayjs(this.state.edit_event.end) : ''}
                              />
                        </Form.Item>

                        { this.state.slot != 'specific' ?
                            <Form.Item required label="Max. aantal afspraken">
                            <InputNumber
                              min={1}
                              step={1}
                              precision={0}
                              onChange={(value) => { this.setState({ edit_event: { ...this.state.edit_event, max_nr_of_appointments: parseInt(value) } }); }}
                              value={this.state.edit_event && this.state.edit_event.max_nr_of_appointments && !isNaN(this.state.edit_event.max_nr_of_appointments) ? parseInt(this.state.edit_event.max_nr_of_appointments) : 0}
                              style={{ clear: 'both' }}
                              /><br />
                            { this.state.errors && this.state.errors['max_nr_of_appointments'] ? <span style={{ color: '#ff0000' }}>{this.state.errors['max_nr_of_appointments']}</span> : null }
                        </Form.Item> : null }

                    </Form>

                </Modal>

            </div>

            <Modal
                title="Afspraak beschikbaarheid opslaan"
                visible={this.state.dry_run_appointment_results}
                footer={
                    <div>
                        <Button onClick={() => { this.setState({ dry_run_appointment_results: false }); }}>Annuleren</Button>
                        <Button type="new" style={{ marginLeft: 12 }} onClick={() => { this.saveAppointmentAvailability(false); }}>Opslaan</Button>
                    </div>
                }
                onCancel={() => {
                    this.setState({ dry_run_appointment_results: false });
                }}
                >
                <div style={{whiteSpace: 'pre-line'}}>
                    <h2>Weet je zeker dat je de beschikbaarheid van de afspraak op wilt slaan?</h2>

                    { this.state.warnings && this.state.warnings.length > 0 ? <div><h3 style={{ color: 'red'}}>Er hebben zich de volgende waarschuwingen voorgedaan:</h3>{this.state.warnings.join("\r\n")+"\r\n\r\n"}</div> : null }

                    { this.state.notifications && this.state.notifications.length > 0 ? <div><h3 style={{ color: 'orange'}}>Er hebben zich de volgende notificaties voorgedaan:</h3>{this.state.notifications.join("\r\n")+"\r\n\r\n"}</div> : null }
                </div>
            </Modal>

            <Modal
                title="Verantwoordelijken verwijderen"
                visible={this.state.dry_run_delete_users_results}
                footer={
                    <div>
                        <Button onClick={() => { this.setState({ dry_run_delete_users_results: false }); }}>Annuleren</Button>
                        <Button type="new" style={{ marginLeft: 12 }} onClick={() => { this.deleteUsers(false); }}>Verwijderen</Button>
                    </div>
                }
                onCancel={() => {
                    this.setState({ dry_run_delete_users_results: false });
                }}
                >
                <div style={{whiteSpace: 'pre-line'}}>
                    <h2>Weet je zeker dat je de geselecteerde verantwoordelijken wilt verwijderen?</h2>

                    { this.state.warnings && this.state.warnings.length > 0 ? <div><h3 style={{ color: 'red'}}>Er hebben zich de volgende waarschuwingen voorgedaan:</h3>{this.state.warnings.join("\r\n")+"\r\n\r\n"}</div> : null }

                    { this.state.notifications && this.state.notifications.length > 0 ? <div><h3 style={{ color: 'orange'}}>Er hebben zich de volgende notificaties voorgedaan:</h3>{this.state.notifications.join("\r\n")+"\r\n\r\n"}</div> : null }
                </div>
            </Modal>

            <Modal
                title="Verantwoordelijke wijzigen"
                visible={this.state.dry_run_update_user_results}
                footer={
                    <div>
                        <Button onClick={() => { this.setState({ dry_run_update_user_results: false }); }}>Annuleren</Button>
                        <Button type="new" style={{ marginLeft: 12 }} onClick={() => { this.updateUser(false); }}>Toepassen</Button>
                    </div>
                }
                onCancel={() => {
                    this.setState({ dry_run_update_user_results: false });
                }}
                >
                <div style={{whiteSpace: 'pre-line'}}>
                    <h2>Weet je zeker dat je de geselecteerde verantwoordelijke wilt wijzigen?</h2>

                    { this.state.warnings && this.state.warnings.length > 0 ? <div><h3 style={{ color: 'red'}}>Er hebben zich de volgende waarschuwingen voorgedaan:</h3>{this.state.warnings.join("\r\n")+"\r\n\r\n"}</div> : null }

                    { this.state.notifications && this.state.notifications.length > 0 ? <div><h3 style={{ color: 'orange'}}>Er hebben zich de volgende notificaties voorgedaan:</h3>{this.state.notifications.join("\r\n")+"\r\n\r\n"}</div> : null }
                </div>
            </Modal>

            <Modal
                title="Beschikbaarheid overnemen"
                open={this.state.show_clone_user_schedule ? true : false}
                onCancel={() => { this.setState({ show_clone_user_schedule: false }); }}
                footer={
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button style={{ marginRight: 12 }} onClick={() => { this.setState({ show_clone_user_schedule: false }); }}>Annuleren</Button>
                            <Button type="new" onClick={() => { this.replicateScheduleForUser(); }}>Neem over</Button>
                        </Col>
                    </Row>
                }
                >
                <Form layout="vertical">

                    { this.state.clone_user_schedule ? <Form.Item label="Verantwoordelijke">
                        <Select
                            style={{ marginRight: 8 }}
                            showSearch
                            options={this.state.schedule_users}
                            value={this.state.selected_user_id ? ''+this.state.selected_user_id : null}
                            onChange={(value) => { this.setState({ selected_user_id: value }) }}
                            />
                    </Form.Item> : null }

                    { this.state.clone_week_schedule ? <Form.Item label="Week">
                        <Select
                            style={{ marginRight: 8 }}
                            showSearch
                            options={this.state.week_numbers}
                            value={''+this.state.selected_week}
                            onChange={(value) => { this.setState({ selected_week: value }) }}
                            />
                    </Form.Item> : null }

                    { this.state.clone_week_schedule ? <Form.Item label="Jaar">
                        <Select
                            options={this.getYears()}
                            value={''+this.state.selected_year}
                            onChange={(value) => { this.setState({ selected_year: value }); this.getWeekNumbers(value); }}
                            />
                    </Form.Item> : null }

                </Form>

            </Modal>

            <Modal
                title="Snel inplannen van de beschikbaarheid"
                width={960}
                open={this.state.show_fast_scheduler ? true : false}
                onCancel={() => { this.setState({ show_fast_scheduler: false }); }}
                footer={
                    <Row style={{ marginTop: 24 }}>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button style={{ marginRight: 12 }} onClick={() => { this.setState({ show_fast_scheduler: false }); }}>Annuleren</Button>
                            <Button type="new" onClick={() => { this.applyFastSchedule(); }}>Toevoegen</Button>
                        </Col>
                    </Row>
                }
                >
                <Form layout="vertical">

                    <Row>
                        <Col span={24}>
                            <Form.Item required label="Vanaf en tot en met welke datum">
                                <RangePicker
                                     format={'DD-MM-YYYY'}
                                     onChange={(dates) => { this.setState({ range_dates: dates }) }}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>

                    { this.state.range_dates ?
                    <Row>
                        <Form.Item required label="Op welke dagen" style={{ width: '100%' }}>
                            <Row>
                                { days.map((day) => {
                                    return (<Col span={3}>
                                        <Checkbox
                                            onChange={(event) => { this.setState({ range_days: { ...this.state.range_days, [day]: event.target.checked } }); }}
                                            >
                                            {day}
                                        </Checkbox>
                                    </Col>)
                                }) }
                            </Row>
                        </Form.Item>
                    </Row> : null }

                    { this.hasRangeDays() ?
                    <div>
                        <Row style={{ marginBottom: 8 }}>
                            <Col span={24}>
                                Op welk(e) tijdstip(pen)
                            </Col>
                        </Row>
                        { Array((this.state.nr_of_dayparts && this.state.nr_of_dayparts > 0 ? this.state.nr_of_dayparts : 1)).fill().map((value, index)=>{ return (<Row align={'top'} gutter={8}>
                            <Col span={4}>
                                <Form.Item required label={!index ? 'Vanaf hoe laat' : null}>
                                    <TimePicker
                                        format={'HH:mm'}
                                        minuteStep={5}
                                        disabledHours={() => { return [0,1,2,3,4,5,23]; }}
                                        onChange={(time) => { this.updateDaypart(index, 'from_time', time); }}
                                        value={this.getDaypartValue(index, 'from_time')}
                                        />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item required label={!index ? 'Tot hoe laat' : null}>
                                    <TimePicker
                                        format={'HH:mm'}
                                        minuteStep={5}
                                        disabledHours={() => { return [0,1,2,3,4,5,23]; }}
                                        onChange={(time) => { this.updateDaypart(index, 'till_time', time); }}
                                        value={this.getDaypartValue(index, 'till_time')}
                                        />
                                </Form.Item>
                            </Col>
                            { this.state.slot == 'range' ? <Col span={6}>
                                <Form.Item label={!index ? 'Naam' : null}>
                                    <Input
                                        onChange={(time) => { this.updateDaypart(index, 'name', time); }}
                                        value={this.getDaypartValue(index, 'name')}
                                        />
                                </Form.Item>
                            </Col> : null }
                            { this.state.slot == 'range' ? <Col span={5}>
                                <Form.Item label={!index ? 'Max. aantal afspraken' : null}>
                                    <InputNumber
                                        min={1}
                                        step={1}
                                        precision={0}
                                        onChange={(time) => { this.updateDaypart(index, 'max_nr_of_appointments', time); }}
                                        value={this.getDaypartValue(index, 'max_nr_of_appointments')}
                                        />
                                </Form.Item>
                            </Col> : null }
                            <Col>
                                <Form.Item label={!index ? <br /> : null}>
                                    { !index ?
                                    <Button onClick={() => {

                                        var nr_of_dayparts = (!this.state.nr_of_dayparts || this.state.nr_of_dayparts <= 1 ? 2 : (this.state.nr_of_dayparts + 1));

                                        var dayparts = this.state.dayparts ? this.state.dayparts : [{}];

                                        dayparts.push({});

                                        this.setState({
                                            nr_of_dayparts: nr_of_dayparts,
                                            dayparts: dayparts
                                        });
                                    }}>+</Button>
                                    :
                                    <Button onClick={() => {
                                        var dayparts = this.state.dayparts;

                                        var new_dayparts = [];

                                        dayparts.map((daypart, i) => {
                                            if(i != index){
                                                new_dayparts.push(daypart);
                                            }
                                        });

                                        this.setState({
                                            nr_of_dayparts: (this.state.nr_of_dayparts - 1),
                                            dayparts: new_dayparts
                                        });
                                    }}>-</Button> }
                                </Form.Item>
                            </Col>
                        </Row>) }) }
                    </div> : null }

                </Form>

            </Modal>

        </div>
    );
  }
}

const AppointmentAvailabilityForm = Form.create({ name: 'appointment_form' })(AppointmentAvailability);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.appointment.isFetching,
    isError: state.appointment.isError,
    appointment: state.appointment.appointment,
    users: state.appointment.users,
    // dayparts: state.appointment.dayparts,
    // clusters: state.appointment.clusters,
    selectedCustomer: state.auth.selectedCustomer,
    project: state.project.project,
    warnings: state.appointment.warnings,
    notifications: state.appointment.notifications,
    nr_of_addresses: state.appointment.nr_of_addresses,
    nr_of_slots: state.appointment.nr_of_slots,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    patchAppointmentAction : bindActionCreators(patchAppointmentAction, dispatch),
    addAppointmentAction : bindActionCreators(addAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    clustersAppointmentAction : bindActionCreators(clustersAppointmentAction, dispatch),
    getAppointmentScheduleAction : bindActionCreators(getAppointmentScheduleAction, dispatch),
    applyAppointmentScheduleAction : bindActionCreators(applyAppointmentScheduleAction, dispatch),
    addAppointmentUserAvailabilityAction : bindActionCreators(addAppointmentUserAvailabilityAction, dispatch),
    updateAppointmentUserAvailabilityAction : bindActionCreators(updateAppointmentUserAvailabilityAction, dispatch),
    deleteAppointmentUsersAvailabilityAction : bindActionCreators(deleteAppointmentUsersAvailabilityAction, dispatch),
    getSlotsCounterAppointmentAction : bindActionCreators(getSlotsCounterAppointmentAction, dispatch),
    // addDaypart : bindActionCreators(addDaypart, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentAvailabilityForm);
