import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPointStatusByHashAction, changePointStatusByHashAction } from '../../actions/deliverActions';
import './Standalone.css';
import Logo from '../../assets/img/logo_zwart.svg';
import { Layout, Col, Row, Button } from 'antd';

class ChangePointStatus extends Component {

  state = {
    loading: false,
  }

  componentDidMount()
  {
    if(this.props.match.params.status)
    {
        this.props.changePointStatusByHashAction(this.props.match.params.id, this.props.match.params.hash, this.props.match.params.status);
    }
    else
    {
        this.props.getPointStatusByHashAction(this.props.match.params.id, this.props.match.params.hash);
    }
  }

  changeStatusTo(status)
  {
    this.props.changePointStatusByHashAction(this.props.match.params.id, this.props.match.params.hash, status);
  }

  render() {

    return (
      <div className="StandaloneScreen" style={{ maxHeight: '100vh', overflow: 'hidden', width: '100%' }}>

        <Layout style={{ backgroundColor: 'white' }}>

          <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" align="middle">

            <Col span={24} type="flex" align="middle">
            
              <div style={{ maxWidth: '400px', padding: 24 }}>

                <img width="200px" height="auto" alt="Cobee" src={Logo} style={{ width: 200, marginBottom: 50 }}></img><br />

                <span style={{ fontSize: 20, fontWeight: 'bold'}}>{ this.props.point.subject }</span><br />
                <br />
                Status veranderd naar: {
                    this.props.point.status == 'open' ? 'Open' :
                    this.props.point.status == 'submitted' ? 'Ingediend' :
                    this.props.point.status == 'closed' ? 'Afgerond' : 
                    'Onbekend'                
                }<br />
                <br />
                { this.props.point.status != 'closed' || this.state.loading ?
                <Button type="primary" onClick={() => { this.setState({ loading: true }); this.changeStatusTo(this.props.point.status == 'open' ? 'submitted' : 'open'); }}>Verander status naar: { this.props.point.status == 'open' ? 'Ingediend' : 'Open' }</Button> : null }

              </div>

            </Col>

          </Row>

        </Layout>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    point : state.deliver.point,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPointStatusByHashAction : bindActionCreators(getPointStatusByHashAction, dispatch),
    changePointStatusByHashAction : bindActionCreators(changePointStatusByHashAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePointStatus);
