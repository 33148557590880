import React, { Component } from 'react';
import { Table, Button, Popconfirm, Tag, Card, Calendar, Row, Col, Checkbox } from 'antd';
import {getAppointmentFreeSlotsAction} from '../../../../../actions/appointmentsActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment'
import _ from 'underscore';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');
var isoWeek = require('dayjs/plugin/isoWeek');
dayjs.extend(isoWeek);

class Cluster extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      selectedDate: null,
      timeslots: [],
      selectedTimeslots: [],
      currentDate: dayjs().format('YYYY-MM-DD')
    };
  }

  componentDidMount() {

    this.getTimeSlots(dayjs(moment(this.props.firstDay, 'YYYY-MM-DD')));
  }

  getActiveDates(currentDate) {

    var activeDays = this.props.activeDays;

    // var current_weekday = currentDate.isoWeek();

    if(currentDate && !activeDays.includes(dayjs(currentDate).isoWeekday())) {
    // if(current_weekday && !activeDays.includes((current_weekday))) {
      return true;
    }

    return false;
  }

  getTimeSlots(date)
  {
    // var dayName = moment(date).locale('en').format('dddd').toLowerCase();
    var dayName = dayjs(date).locale('en').format('dddd').toLowerCase();

    if(this.props.availableTimes[dayName]) {

        this.setState({timeslots: this.props.availableTimes[dayName], selectedDate: date})
    }

    //console.log(dayName, this.props.availableTimes[dayName]);
    //this.props.getAppointmentFreeSlotsAction(this.props.clusterid);
  }

  onSelectTimeSlot(slots) {

    var slotsObject = {
      'id': this.props.clusterid,
      'date': dayjs(this.state.selectedDate).format('YYYY-MM-DD'),
      'timeslots': slots,
    };

    this.props.onSelectSlot(slotsObject);
  }

  checkIfDisabled = (value) => {

    var isDisabled = false;

    this.props.disabledTimeSlots.map((element) => {

      if(element.id != this.props.clusterid) {

        if(_.lastIndexOf(element.timeslots, value) != -1){

          isDisabled = true
        }
      }
    });

    return isDisabled;
  }

  checkIfEnabled = () => {

    var enabledArray =  [];

    enabledArray = this.props.disabledTimeSlots.filter((element) => {

      if(element.id == this.props.clusterid) {

        // console.log(dayjs(element.date).format('YYYY-MM-DD'), 'vs', dayjs(this.state.selectedDate).format('YYYY-MM-DD'));

        if(dayjs(element.date).format('YYYY-MM-DD') == dayjs(this.state.selectedDate).format('YYYY-MM-DD')) {

          return true;
        }
      }
    });

    return _.first(enabledArray) ? _.first(enabledArray).timeslots : [];
  }

  checkAll = () => {

    var checks = this.state.timeslots.map((element) => {

      var valueFormat = this.state.selectedDate.format('YYYY-MM-DD') + ' ' + element;

      if(!this.checkIfDisabled(valueFormat)) {
        return valueFormat;
      }

    });

    this.onSelectTimeSlot(checks)
  }

  unCheckAll = () => {

    this.onSelectTimeSlot([]);
  }

  dateHasSelectedTimeslots(date)
  {
      return this.props.selectedDates && this.props.selectedDates.includes && this.props.selectedDates.includes(date) ? true : false;

      // return false; // true | false
  }

  render() {
    return (
      <Card title={'Cluster ' + (this.props.index + 1) + ' - '+ this.props.addresses.length + ' adressen ' + this.props.timeslotCount + ' geselecteerde tijdvakken'} style={{marginBottom: 24}}>
        <Row gutter={24}>
          <Col span={8}>
            <div style={{ border: '1px solid #d9d9d9', borderRadius: 4 }}>
              <Calendar
                fullscreen={false}
                defaultValue={dayjs(moment(this.props.firstDay))}
                onPanelChange={(date) => {
                    this.setState({ currentDate: this.state.currentDate });
                }}
                onSelect={(date) => {
                    this.getTimeSlots(date);
                }}
                disabledDate={(currentDate) => {
                    return this.getActiveDates(currentDate);
                }}
                validRange={[dayjs(moment(this.props.firstDay)), dayjs(moment(this.props.firstDay).add(10, 'year'))]}
                dateFullCellRender={(date) => {
                    return <span
                        onClick={() => {
                            this.setState({ currentDate: date.format('YYYY-MM-DD') });
                            this.getTimeSlots(date);
                        }}
                        style={{
                            padding: 5,
                            borderRadius: 5,
                            backgroundColor: (date.format('YYYY-MM-DD') === this.state.currentDate ? 'rgba(22,119,255,0.25)' : '#ffffff'),
                            fontWeight: (this.dateHasSelectedTimeslots(date.format('YYYY-MM-DD')) ? 'bold' : 'normal')
                        }}
                        >
                        {date.format('DD')}
                        </span>
                }}
                />
            </div>
          </Col>
          <Col span={16}>
            <div>
              <Button style={{marginBottom: 10, marginRight: 10}} onClick={this.checkAll}>Selecteer alle tijdvakken</Button>
              <Button style={{marginBottom: 10}} onClick={this.unCheckAll}>Deselecteer alle tijdvakken</Button>
              <Checkbox.Group
                value={this.checkIfEnabled()}
                style={{ width: '100%' }}
                onChange={(check) => { this.onSelectTimeSlot(check); }}
                >
                { this.state.timeslots.map((element) => {

                  var valueFormat = dayjs(this.state.selectedDate).format('YYYY-MM-DD') + ' ' + element;

                  return (
                    <Col key={this.state.selectedDate.format('YYYY-MM-DD') + element} span={8}>
                      <Checkbox disabled={this.checkIfDisabled(valueFormat)} value={valueFormat}>{element}</Checkbox>
                    </Col>
                  );
                })}
              </Checkbox.Group>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    address : state.address.addresses,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentFreeSlotsAction: bindActionCreators(getAppointmentFreeSlotsAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cluster);
