export default (state = {
      isError: false,
      isFetching: false,
      projects:[],
      project: {
          user_role: false,
        //   modules: [],
          mail_occupants_messages_to: '',
        //   available_modules: []
      },
      modules: [],
      users: [],
      user: {},
      userModules: {},
      billing: [],
      unassigned: [],
      nr_of_projects: 0,
      license_nr_of_projects: 0,
      nr_of_addresses: 0,
      license_nr_of_addresses: 0,
      address_fields: [],
      address_field: {}
    }, action) => {
    switch (action.type) {

    case 'PROJECT_FIELDS_ACTION_FETCH':
    return {
        ...state,
        address_fields: [],
        isFetching: true,
        isError: false,
    }
    case 'PROJECT_FIELDS_ACTION_SUCCESS':
    return {
        ...state,
        address_fields: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PROJECT_FIELD_ACTION_SUCCESS':
    return {
        ...state,
        address_field: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PROJECT_FIELDS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'DELETE_ADDRESS_FIELDS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'RESET_ADDRESS_FIELD_ACTION':
    return {
        ...state,
        address_fields: [],
        address_field: {},
        isFetching: false,
        isError: false,
    }

    case 'PROJECTS_ACTION_FETCH':
    return {
        ...state,
        projects: [],
        isFetching: true,
        isError: false,
    }

    case 'PROJECT_ACTION_FETCH':
    return {
        ...state,
        project: {
            user_role: false,
            // modules: [],
            mail_occupants_messages_to: '',
            // available_modules: []
        },
        isFetching: true,
        isError: false,
    }

    case 'PROJECTS_ACTION_SUCCESS':
    return {
        ...state,
        projects: action.payload.projects, // changed
        nr_of_projects: action.payload.nr_of_projects, // new
        license_nr_of_projects: action.payload.license_nr_of_projects, // new
        nr_of_addresses: action.payload.nr_of_addresses, // new
        license_nr_of_addresses: action.payload.license_nr_of_addresses, // new
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_PROJECTS_ACTION_SUCCESS':
    return {
        ...state,
        projects: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PROJECT_ACTION_SUCCESS':
    return {
        ...state,
        project: action.payload,
        // modules: action.payload.modules ? action.payload.modules.map((v, k) => v.id) : [],
        isFetching: false,
        isError: false,
    }
    case 'PROJECT_BILLING_ACTION_SUCCESS':
        return {
        ...state,
        billing: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'ADD_PROJECT_ACTION_SUCCESS':
    return {
        ...state,
        projects: [action.payload.project, ...state.projects],
        project: action.payload.project,
        isFetching: false,
        isError: false,
    }
    case 'CLONE_PROJECT_ACTION_SUCCESS':
    return {
        ...state,
        projects: [action.payload.project, ...state.projects],
        project: action.payload.project,
        isFetching: false,
        isError: false,
    }
    case 'DELETE_PROJECTS_ACTION_SUCCESS':
    return {
        ...state,
        projects: state.projects.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'STATUS_PROJECTS_ACTION_SUCCESS':
    return {
        ...state,
        projects: state.projects.filter((v, k) => {
            if(action.payload.ids.indexOf(v.id) > -1) {
                v.status = action.payload.status;
                return v;
            }
            return v;
        }),
        isFetching: false,
        isError: false,
    }
    case 'PROJECTS_ACTION_ERROR':
        return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'PROJECTUSERS_ACTION_SUCCESS':
    return {
        ...state,
        users: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'PROJECTUSER_ACTION_SUCCESS':
    return {
        ...state,
        user: action.payload.user ? action.payload.user : action.payload,
        userModules: action.payload.module ? action.payload.module : {},
        isFetching: false,
        isError: false,
    }
    case 'UPDATE_PROJECTUSER_ACTION_SUCCESS':
    return {
        ...state,
        user: {},
        userModules:[],
        isFetching: false,
        isError: false,
    }
    case 'DELETE_PROJECTUSERS_ACTION_SUCCESS':
    return {
        ...state,
        users: state.users.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'UNASSIGNED_PROJECTUSER_ACTION_SUCCESS':
    return {
        ...state,
        unassigned: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'RESET_PROJECT_USER_ACTION':
    return {
        ...state,
        user: {},
        userModules: {},
        isFetching: false,
        isError: false,
    }
    case 'PROJECT_MODULES_ACTION_SUCCESS':
    return {
        ...state,
        modules: action.payload,
        isFetching: false,
        isError: false,
    }
    default:
        return state
    }
}
