import React, { Component } from 'react';
// import './ProjectsAddressesDossier.css';
import ProjectsAddressesSideMenu from './ProjectsAddressesSideMenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressSharedDataAction, downloadDossier, requestDownloadDossier, resetAddressesDossierAction, deleteAddressDossierFilesAction, getAddressDocumentAction,  addDocumentToAddressDossierAction, updateDocumentForAddressDossierAction, addAddressNoteAction, getAddressNoteAction, patchAddressNoteAction, resetDossierAction } from '../../../../actions/addressesActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { Link } from 'react-router-dom';
import { showSuccess, showMultiDelete } from '../../../../utils/Notifications';
import { CheckOutlined, CloseOutlined, DownOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Switch,
  Button,
  Input,
  Modal,
  Drawer,
  Upload,
  Alert,
  Divider,
  Typography
} from 'antd';
import moment from 'moment';
import 'moment/locale/nl';

const Search = Input.Search;

const { TextArea } = Input;

const confirm = Modal.confirm;

const { Dragger } = Upload;

const { Title } = Typography;

function onChange(pagination, sorter) {
    // console.log('params', pagination, sorter);
}

class ProjectsAddressesDossier extends Component {

    state = {
      selectedRowKeys: [],
      disabled: true,
      edit: false,
      visible: false,
      drawer: false,
      preview: false,
      downloadDossier: false,
      emailDossierTo: '',
      uploading: false,

      modal_type: null,
      note_id: false,
      document_id: false,
      file: null,
      fileList: [],
      errorDocumentFile: false,
      errorDocumentSizeFile: false,

      uploading: false,
    };

    showConfirm() {
      showMultiDelete((multidelete) => {
        if(multidelete) {
          // console.log(this.state.selectedRowKeys);
          // this.props.deleteAddressesDossierNoteAction(this.props.match.params.addressid, {'ids': this.state.selectedRowKeys}).then(() => {
          this.props.deleteAddressDossierFilesAction(this.props.match.params.addressid, {'ids': this.state.selectedRowKeys}).then(() => {
            this.setState({selectedRowKeys: []});
            showSuccess('Succesvol verwijderd');
            this.props.getAddressSharedDataAction(this.props.match.params.addressid).then(() => {});
          });
        }
      });
    }

    componentDidMount() {
      this.props.resetAddressesDossierAction();

      this.props.getAddressSharedDataAction(this.props.match.params.addressid).then(() => {});
    }

    downloadDossierAsZip()
    {
        // direct download
        // this.props.downloadDossier(this.props.match.params.id,
        //   {
        //     ids: [this.props.match.params.addressid]
        //   }).then(() => {
        //
        //   });

        //alert(this.state.emailDossierTo);

        // modal
        this.props.requestDownloadDossier(this.props.match.params.id, { ids: [this.props.match.params.addressid], 'email': this.state.emailDossierTo }).then(() => {
          this.setState({emailDossierTo: ''});
          showSuccess('Download verzoek ingediend');
        });
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    // handleMediaUpload = (file) => {
    //   this.setState({ uploading: true });
    //   showSuccess('Bezig met uploaden', '', '#ffffff');
    //   this.props.addDocumentToAddressDossierAction(this.props.match.params.addressid, {'file': file}).then(() => {
    //       showSuccess('Succesvol toegevoegd');
    //       this.props.getAddressSharedDataAction(this.props.match.params.addressid).then(() => {
    //           this.setState({ uploading: false });
    //       });
    //   });
    //
    //   return false;
    // }

    editNote(id)
    {
        this.props.resetDossierAction();

        this.props.getAddressNoteAction(this.props.match.params.addressid, id).then(() => {

            // set form field values ['subject', 'note', 'visible_for_address']
            this.props.form.setFieldsValue({'subject': this.props.note.subject, 'note': this.props.note.note, 'visible_for_address': this.props.note.visible_for_address});

            this.setState({ note_id: id, show_note_modal: true });
        });
    }

    editDocument(id)
    {
        this.props.resetDossierAction();

        this.props.getAddressDocumentAction(this.props.match.params.addressid, id).then(() => {

            // set form field values ['name', 'hide_for_address'];
            this.props.form.setFieldsValue({'name': this.props.document.name, 'hide_for_address': this.props.document.hide_for_address ? false : true});

            this.setState({ document_id: id, show_document_modal: true, uploading: false });
        });
    }

    handleSubmit = () => {

      var validate_fields = [];

      if(this.state.show_note_modal)
      {
          validate_fields = ['subject', 'note', 'visible_for_address'];
      }
      else if(this.state.show_document_modal)
      {
          validate_fields = ['file', 'name', 'hide_for_address'];
      }

      this.props.form.validateFields(validate_fields, (errors, values) => {

        if(!errors) {
          if(this.state.show_note_modal)
          {
              if(this.state.note_id) {
                this.props.patchAddressNoteAction(this.props.match.params.addressid, this.state.note_id, values).then(() => {
                  // this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/dossier`);
                  showSuccess();

                  this.props.getAddressSharedDataAction(this.props.match.params.addressid).then(() => {});

                  this.setState({ show_note_modal: false });

                  this.props.form.resetFields();
                });
              } else {
                this.props.addAddressNoteAction(this.props.match.params.addressid, values).then(() => {
                  // this.props.history.push(`/projects/${this.props.match.params.id}/addresses/${this.props.match.params.addressid}/dossier`);
                  showSuccess();

                  this.props.getAddressSharedDataAction(this.props.match.params.addressid).then(() => {});

                  this.setState({ show_note_modal: false });

                  this.props.form.resetFields();
                });
              }
          }
          else if(this.state.show_document_modal)
          {
              values.hide_for_address = values.hide_for_address ? false : true; // swap

              this.setState({ uploading: true });

              if(this.state.document_id)
              {
                this.props.updateDocumentForAddressDossierAction(this.props.match.params.addressid, this.state.document_id, values, this.state.file ? this.state.file : null).then(() => {

                  showSuccess();

                  this.props.getAddressSharedDataAction(this.props.match.params.addressid).then(() => {

                      this.setState({ show_document_modal: false, file: null, fileList: [], document_id: false, uploading: false });

                      this.props.form.resetFields();
                  });
                });
              } else {

                  if(this.state.fileList.length == 0 && !this.state.fileAlreadyUploaded) {

                    this.setState({errorDocumentFile: true});

                    return;
                  }
                  else
                  {
                    this.setState({errorDocumentFile: false});

                    this.props.addDocumentToAddressDossierAction(this.props.match.params.addressid, values, this.state.file).then(() => {

                      showSuccess();

                      this.props.getAddressSharedDataAction(this.props.match.params.addressid).then(() => {

                          this.setState({ show_document_modal: false, file: null, fileList: [], uploading: false });

                          this.props.form.resetFields();
                      });
                    });
                  }
              }
          }
        }
      });
    }

    render() {

      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        getCheckboxProps: record => ({
          disabled: record.type !== 'note' && record.type !== 'dossier'
        }),
      };

      const columns = [{
        title: 'Datum',
        dataIndex: 'moment',
        render: (text, record) => {
          return (
            <span>
              { text ? text : moment(record.datetime).format('DD-MM-YYYY - HH:mm:ss') }
            </span>
          )
        },
        sorter: (a, b) => { return moment(a.datetime, 'YYYY-MM-DD HH:mm:ss').unix() - moment(b.datetime, 'YYYY-MM-DD HH:mm:ss').unix()},
      }, {
        title: 'Type',
        dataIndex: 'type',
        render: (index,record) => {
          var label = 'Correspondentie';

          if(record.type == "note") {label="Notitie"}
          else if(record.type == "document") {label="Document"}
          else if(record.type == "intake") {label="Opname"}
          else if(record.type == "support-base") {label="Draagvlak"}
          else if(record.type == "appointment") {label="Afspraak"}
          else if(record.type == "correspondence")
          {
              if(record.method_type == 'mail'){ label = "Brief"; }
              else if(record.method_type == 'email'){ label = "E-mail"; }
              else if(record.method_type == 'email_mail'){ label = "E-mail met brief"; }
              else if(record.method_type == 'sms'){ label = "SMS bericht"; }
              else if(record.method_type == 'push'){ label = "Push bericht"; }
              else { label = record.method_type; }
          }
          else if(record.type == "chat"){ label="Bericht" }
          else if(record.type == "dossier")
          {
              label = "Document";

              if(record.sub_type == 'sms'){ label = "SMS"; }
              if(record.sub_type == 'email'){ label = "E-mail"; }
          }
          else if(record.type == "choice"){ label="Keuze" }
          else if(record.type == "form"){ label="Formulier" }
          else if(record.type == "delivery-form"){ label="Opleverformulier" }
          else if(record.type == "survey"){ label="Enquete" }
          else{ label = record.type; }

          return (<div>{label}</div>);
        }
      }, {
        title: 'Onderwerp',
        dataIndex: 'subject',
        render: (index,record) => {
          if(record.type == 'note') {
            return (
              <Link disabled={!this.props.HaveAbility('dossier', 'write')} onClick={() => this.editNote(record.id)} /*to={'dossier/note/'+record.id}*/>
                {record.subject}
              </Link>
            );
          }

          if(record.type == 'dossier' && record.sub_type == 'document') {
            return (
              <Link disabled={!this.props.HaveAbility('dossier', 'write')} onClick={() => this.editDocument(record.id)}>
                {record.name}
              </Link>
            );
          }

          if(record.type == 'document') {
            return (record.name);
          }

          if(record.type == 'intake') {
            return (
              <Link disabled={!this.props.HaveAbility('dossier', 'write')} to={'/projects/' + record.project_id + '/intake/' + record.form_builder_form_id + '/result/' + record.id} target='_blank'>
                {record.name}
              </Link>
            );
          }

          return (
            <div>{record.subject || record.name}</div>
          )}
        }, {
          title: 'Download',
          dataIndex: 'download_url',
          render: (index,record) => {
            return (<a href={record.download_url + '?view&token=' + localStorage.authToken} target='_blank'>
              Bekijken
            </a>);
          }
        }];

      // const rowSelection = {
      //   onChange: this.onSelectChange,
      //   hideDefaultSelections: true,
      //   onSelection: this.onSelection,
      // };
      const { getFieldDecorator } = this.props.form;

      return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ProjectsAddressesSideMenu projectid={this.props.match.params.id} addressid={this.props.match.params.addressid} activeMenu="3" {...this.props}/>

          <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">
              <Col span={12}>
                  <Dropdown trigger={['click']} disabled={!this.props.HaveAbility('dossier', 'write')} overlay={(
                    <Menu>
                      <Menu.Item key="1" onClick={() => { this.props.resetDossierAction(); this.props.form.resetFields(); this.setState({ note_id: false, 'show_note_modal': true }); }}>Notitie</Menu.Item>
                      <Menu.Item key="2" onClick={() => { this.props.resetDossierAction(); this.props.form.resetFields(); this.setState({ document_id: false, 'show_document_modal': true, uploading: false }); }}>Document</Menu.Item>
                    </Menu>
                    )}>
                    <Button type="new" disabled={!this.props.HaveAbility('dossier', 'write')}>
                      Toevoegen <DownOutlined />
                    </Button>
                  </Dropdown>

                  <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('dossier', 'delete') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                    <Menu>
                      <Menu.Item key="1" style={{ color: '#f5222d' }} onClick={() => this.showConfirm()}>Verwijderen</Menu.Item>
                    </Menu>
                    )}>
                    <Button type="secondary">
                      Acties <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>

              <Divider></Divider>

              <Title level={4}>{this.props.address.full_address}</Title>

              <Row>
                <Col span={24}>

                  <CustomTable
                    size="middle"
                    // rowKey="id"
                    //rowKey={(record) => { return record.type+'-'+record.id; }}
                    rowKey="row_id"
                    rowSelection={rowSelection}
                    columns={columns}
                    loading={this.props.isFetching}
                    dataSource={this.props.shareddata}
                    />

                </Col>
              </Row>
            </div>

            <Modal
              title="Download dossier"
              visible={this.state.downloadDossier}
              onOk={() => {
                  this.downloadDossierAsZip();
                  this.setState({ downloadDossier: false });
              }}
              onCancel={() => this.setState({downloadDossier: false})}
              okText={'Versturen'}
            >
              <Form.Item label="Verstuur de dossier download link naar het volgende e-mailadres:">
                <Input value={this.state.emailDossierTo} onChange={(e) => this.setState({emailDossierTo: e.target.value})} />
              </Form.Item>
            </Modal>

            <Form
                onSubmit={this.handleSubmit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}s
                >

                <Modal
                  title="Voeg notitie toe"
                  visible={this.state.show_note_modal}
                  onOk={() => {
                      this.handleSubmit();
                  }}
                  onCancel={() => this.setState({show_note_modal: false})}
                  okText={'Opslaan'}
                >
                <div style={{ width: '100%' }}>
                  <Row gutter={24} style={{ marginBottom: 16 }}>
                      <Col span={24}>
                          <Form.Item hasFeedback label="Onderwerp">
                              {getFieldDecorator('subject', {
                                initialValue: this.props.note.subject,
                                 rules: [{ required: true, message: 'Vul een onderwerp in' }],
                              })(
                                  <Input />
                               )}
                          </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: 16 }}>
                      <Col span={24}>
                          <Form.Item required hasFeedback label="Beschrijving">
                              {getFieldDecorator('note', {
                                initialValue: this.props.note.note,
                                 rules: [{ required: true, message: 'Vul een beschrijving in'}],
                              })(
                                 <TextArea rows={4} />
                              )}
                          </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: 16 }}>
                      <Col span={24}>
                          <Form.Item label="Tonen op bewonerspagina">
                              {getFieldDecorator('visible_for_address', {
                                initialValue: this.props.note.visible_for_address,
                                valuePropName: 'checked'
                              })(
                                  <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                  />
                              )}
                          </Form.Item>
                      </Col>
                  </Row>
                </div>
                </Modal>

                <Modal
                  title="Voeg document toe"
                  visible={this.state.show_document_modal}
                  onOk={() => {
                      if(!this.state.uploading){ this.handleSubmit(); }
                  }}
                  onCancel={() => this.setState({ show_document_modal: false, uploading: false })}
                  okText={'Opslaan'}
                >

                  { this.state.uploading ?
                  <span><LoadingOutlined style={{ fontSize: 24 }} /> Bezig met opslaan</span>
                  :
                  <div style={{ width: '100%' }}>
                      <Row style={{marginBottom: 16}}>
                        <Col span={24}>
                                <Dragger
                                  required={true}
                                  style={{ backgroundColor: '#fff', width: '100%', flex: 1, maxWidth: '100%' }}
                                  accept=".pdf,.eml,.msg"
                                  //accept=".pdf"
                                  multiple={false}
                                  showUploadList={true} // false
                                  fileList={this.state.fileList}
                                  // beforeUpload={this.handleMediaUpload}
                                  beforeUpload={(file) => {
                                      if(file.size < 8000000) {
                                        this.setState({file: {'file': file}});
                                        this.setState({fileList: [file]});
                                        this.setState({errorDocumentFile: false, errorDocumentSizeFile: false});
                                      } else {
                                        this.setState({fileList: null})
                                        this.setState({errorDocumentFile: false, errorDocumentSizeFile: true});
                                      }
                                      return false;
                                    }}
                                    onRemove={() =>{
                                      this.setState({fileList: null});
                                    }}
                                  disabled={this.state.uploading}
                                  >
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">Klik of sleep een PDF of e-mailbestand (.eml of .msg) hierheen om te uploaden.</p>
                                  <p className="ant-upload-hint">Maximale bestandsgrootte van upload: 8MB.</p>
                                </Dragger>
                                {this.state.errorDocumentFile ? (<Alert message="Upload een bestand" type="error" showIcon />) : null}
                                {this.state.errorDocumentSizeFile ? (<Alert message="Bestand mag niet groter zijn dan 8MB" type="error" showIcon />) : null}
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col span={24}>
                              <Form.Item hasFeedback label="Onderwerp">
                                  {getFieldDecorator('name', {
                                    initialValue: this.props.document ? this.props.document.name : '',
                                     rules: [{ required: true, message: 'Vul een onderwerp in' }],
                                  })(
                                      <Input />
                                   )}
                              </Form.Item>
                          </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col span={24}>
                              <Form.Item label="Tonen op bewonerspagina">
                                  {getFieldDecorator('hide_for_address', {
                                    initialValue: (!this.state.document_id ? false : (this.props.document.hide_for_address ? false : true)),
                                    valuePropName: 'checked'
                                  })(
                                      <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                      />
                                  )}
                              </Form.Item>
                          </Col>
                      </Row>
                  </div>}
                </Modal>

            </Form>

          </div>
      );
      }
    }


const ProjectsAddressesDossierForm = Form.create({ name: 'projects_addresses_dossier' })(ProjectsAddressesDossier);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.address.isFetching,
    isError: state.address.isError,
    shareddata: state.address.shareddata,
    note: state.address.note,
    document: state.address.document,
    address: state.address.address,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddressSharedDataAction : bindActionCreators(getAddressSharedDataAction, dispatch),
    downloadDossier : bindActionCreators(downloadDossier, dispatch),
    requestDownloadDossier : bindActionCreators(requestDownloadDossier, dispatch),
    resetAddressesDossierAction : bindActionCreators(resetAddressesDossierAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),

    addAddressNoteAction : bindActionCreators(addAddressNoteAction, dispatch),
    getAddressNoteAction : bindActionCreators(getAddressNoteAction, dispatch),
    patchAddressNoteAction : bindActionCreators(patchAddressNoteAction, dispatch),

    deleteAddressDossierFilesAction : bindActionCreators(deleteAddressDossierFilesAction, dispatch),
    addDocumentToAddressDossierAction : bindActionCreators(addDocumentToAddressDossierAction, dispatch),
    getAddressDocumentAction : bindActionCreators(getAddressDocumentAction, dispatch),
    updateDocumentForAddressDossierAction : bindActionCreators(updateDocumentForAddressDossierAction, dispatch),

    resetDossierAction : bindActionCreators(resetDossierAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAddressesDossierForm);
