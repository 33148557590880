import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import _ from 'underscore'
import { connect } from 'react-redux';
import HasRole from '../../components/HasRole/HasRole';
import PageHeader from '../../components/PageHeader/PageHeader';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import { resetAppointmentUserAction, getAppointmentUserAction, addCustomerAppointmentUserAction, updateAppointmentUserAction } from '../../actions/appointmentusersActions';
import { showMultiDelete, showSuccess } from '../../utils/Notifications';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Card,
  Tooltip,
  Menu,
  Dropdown,
  Typography,
  Button,
  Divider,
  Input,
  Avatar,
  Select,
  Upload,
  message,
  Alert,
  Checkbox,
} from 'antd';
import NumberFormat from 'react-number-format';
import CustomTable from '../../components/CustomTable/CustomTable';
import api from '../../utils/api';
import { API_URL } from '../../constants/settings';
import AccountsSideMenu from './AccountsSideMenu';
const { Title, Text } = Typography;


const Option = Select.Option;

class AccountsDetail extends Component {
  state = {
    permissions: [],

    // global_role: null,
    // projects: null,
    // user_projects: [],
    // library: null,
    // media: null,
    // users: null,
    // user_role: null,

    // projects: '',
    // library: '',
    // media: '',
    // accounts: '',
    // all_projects: true,
    // sorted_projects: [],
    loading: true,
  };

  componentDidMount()
  {
      this.props.resetAppointmentUserAction();

      if(this.props.match.params.id)
      {
          this.props.getAppointmentUserAction(this.props.match.params.id).then(() => {

              this.setState({ loading: false });
          });
      }
      else
      {
          this.setState({ loading: false });
      }
  }

  handleSubmit = e => {

    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      if (!err) {

        if(this.props.match.params.id) {

          this.props.updateAppointmentUserAction(this.props.match.params.id, values).then(() => {

            this.props.history.push(`/appointment-users`);
          });
        } else {

          this.props.addCustomerAppointmentUserAction(values).then(() => {

            this.props.history.push(`/appointment-users`);
          });
        }

        showSuccess();
      }
    });

  }

  render() {

    // if(this.state.loading){ return null; }

    const { getFieldDecorator } = this.props.form;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
    };

    return (
      <div>

        <PageHeader path={[{'label': 'Verantwoordelijken', 'link': '/appointment-users'}, {'label': this.props.match.params.id && this.props.account ? this.props.account.email_address : 'Toevoegen'}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <AccountsSideMenu activeMenu='4' />
            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12} >
                    <Button disabled={!this.props.HaveAbility('users' ,'write')} type="new" onClick={this.handleSubmit}>
                      Opslaan
                    </Button>
                    <Button onClick={() => this.props.history.push('/appointment-users')} style={{marginRight: 10}}>Sluiten</Button>
                  </Col>
                </Row>

                <Card style={{backgroundColor: 'white', borderRadius: '5px' }}>

                {!this.props.match.params.id ? (
                  <Alert
                    message="Let op!"
                    description="Na het toevoegen van een account ontvangt deze persoon automatisch een e-mail om het account te activeren zodra een opleverpunt aan het account binnen een project is toegekend."
                    type="info"
                    showIcon
                    style={{marginBottom: 24}}
                  />
                ): null}

                <Form layout={'vertical'} onSubmit={this.handleSubmit} colon={true}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item required label="E-mail" hasFeedback>
                        {getFieldDecorator('email_address', {
                          initialValue: this.props.account && this.props.account.email_address,
                          rules: [{ required: true, message: 'Vul een e-mailadres in'}, {type: 'email', message: 'Vul een geldig e-mailadres in'}],
                        })(
                          <Input /*disabled={this.props.match.params.id ? true : false}*/></Input>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                      <Col span={24}>
                          <Form.Item className="mrs" label="Naam" hasFeedback>
                            {getFieldDecorator('full_name', {
                              initialValue: this.props.account && this.props.account.full_name,
                              rules: [{ required: false, message: 'Vul een naam in' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                      </Col>
                  </Row>

                  <Row gutter={24}>
                      <Col span={24}>
                          <Form.Item className="mrs" label="Bedrijfsnaam" hasFeedback>
                            {getFieldDecorator('company_name', {
                              initialValue: this.props.account && this.props.account.company_name,
                              rules: [{ required: false, message: 'Vul een bedrijfsnaam in' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                      </Col>
                  </Row>

                  { this.state.loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : null }

                  { /*this.props.account && this.props.account.projects ?
                    <div>
                        <Row>
                            <Col span={24}>
                                <h2>Afspraken van deze gebruiker</h2>
                            </Col>
                        </Row>
                        <Row>
                          <Col span={24}>

                            <CustomTable
                              size="middle"
                              rowKey='id'
                              rowSelection={rowSelection}
                              loading={this.state.loading}
                              columns={
                                  [{
                                    title: 'Project',
                                    dataIndex: 'name',
                                    sorter: (a, b) => { return a.name.localeCompare(b.name)},
                                    render: (text, record) => (
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Link to={`/projects/${record.id}/dashboard`}>{text}</Link>
                                      </div>
                                    )
                                  },
                                  {
                                    title: 'Nummer',
                                    dataIndex: 'project_number',
                                    sorter: (a, b) => { return a.project_number.localeCompare(b.project_number)},
                                    sortDirections: ['descend', 'ascend'],
                                    render: (text, record) => (
                                      <span>{text}</span>
                                    )
                                  }]
                              }
                              dataSource={this.props.account.projects}
                              />
                          </Col>
                        </Row>
                    </div>
                    : null/**/ }

                  </Form>
                </Card>
            </div>
        </div>
      </div>
    );
  }
}

const AppointmentUserDetailForm = Form.create({ name: 'account_detail' })(AccountsDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment_users.isFetching,
    isError : state.appointment_users.isError,
    account: state.appointment_users.appointment_user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetAppointmentUserAction : bindActionCreators(resetAppointmentUserAction, dispatch),
    getAppointmentUserAction : bindActionCreators(getAppointmentUserAction, dispatch),
    addCustomerAppointmentUserAction : bindActionCreators(addCustomerAppointmentUserAction, dispatch),
    updateAppointmentUserAction : bindActionCreators(updateAppointmentUserAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentUserDetailForm);
