import React, { Component } from 'react';

import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Row, Col, Card, Button, Input, Select, Modal, Divider, Tag, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { showSuccess, showError } from '../../../../utils/Notifications';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDeliveryFormAction, syncDeliveryFormDeliveriesAction, getDeliveriesAction, searchDeliveriesAction, getDeliverAddressesAction, getDrawingsAction } from '../../../../actions/deliverActions';
import { getGroupsAction } from '../../../../actions/groupsActions';

import PageHeader from '../../../../components/PageHeader/PageHeader';
import DeliveryFormSteps from './Steps';

import DeliverSideMenu from '../DeliverSideMenu';
import { HaveAbility } from '../../../../utils/RolesAndAbilities';

import CustomTable from '../../../../components/CustomTable/CustomTable';


class DeliveryFormDeliveries extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filteredInfo: null,
      selectedRowKeys: [],
      disabledRowKeys: [],
      readonly: false,
      expandedRowKeys: [],
      loaded: false
    };
  }

  componentDidMount()
  {
      this.loadData();
  }

  loadData()
  {
      this.props.getDeliveryFormAction(this.props.match.params.form_id).then(() => {

          this.setState({ readonly: this.props.delivery_form.completed });

          this.props.getDeliveriesAction(this.props.match.params.id).then(() => {

              // select parent(s) if all child(s) are selected
              var selection = [];

              this.props.delivery_form.delivery_ids.map((id) => {

                  this.props.deliveries.map((delivery) => {

                      if(/*delivery.id == id &&*/ delivery.nr_of_children > 0)
                      {   // select all child(s)
                          var all_childs_selected = true;

                          delivery.children.map((child) => {

                              if(!this.props.delivery_form.delivery_ids.includes(child.id))
                              {
                                  all_childs_selected = false;
                              }
                              // selection.push(child.id);
                          });

                          if(all_childs_selected)
                          {
                              selection.push(delivery.id);
                          }
                      }
                      //else
                      //{   // select (parent | child) id
                          selection.push(id);
                      //}
                  });
              });

              this.setState({ selectedRowKeys: [...new Set(selection)] /*this.props.delivery_form.delivery_ids*/ }, () => { this.setState({ loaded: true }); });

              // console.log(this.props.delivery_form.disabled_delivery_ids);

              // this.setState({ disabledRowKeys: this.props.delivery_form.disabled_delivery_ids });
              this.setState({ disabledRowKeys: [] });

              // console.log(this.props.delivery_form.disabled_delivery_ids);
          });
      });

      this.props.getDeliverAddressesAction(this.props.match.params.id);

      this.props.getGroupsAction({ 'projectId': this.props.match.params.id });

      this.props.getDrawingsAction(this.props.match.params.id, 'all');
  }

  handleSubmit()
  {
      // filter out selected parent(s)
      var selection = [];

      var delivery_ids = [];

      this.props.deliveries.map((delivery) => {

          delivery_ids.push(delivery.id);
      });

      console.log('nr of ids:', delivery_ids.length);

      // if(!delivery_ids.length)
      // {
      //     showError('Selecteer minimaal 1 regel');
      // }
      // else
      // {   //
          this.props.deliveries.map((delivery) => {

              this.state.selectedRowKeys.map((id) => {

                  if(delivery.id == id && delivery.nr_of_children > 0)
                  {   // select all child(s)
                      delivery.children.map((child) => {

                          if(!selection.includes(child.id))
                          {
                              selection.push(child.id);
                          }
                      });
                  }
                  else if((delivery.nr_of_children === undefined || delivery.nr_of_children < 1) && delivery.id == id && this.state.selectedRowKeys.includes(id))
                  {   // select parent id
                      if(!selection.includes(id))
                      {
                          selection.push(id);
                      }
                  }
                  else if(!delivery_ids.includes(id))
                  {   // select child id
                      if(!selection.includes(id))
                      {
                          selection.push(id);
                      }
                  }
              });
          });

          if(!selection.length)
          {
              showError('Selecteer minimaal 1 regel');
          }
          else
          {
              //
              this.props.syncDeliveryFormDeliveriesAction(this.props.match.params.form_id, { ids: [...new Set(selection)] /*this.state.selectedRowKeys*/ }).then(() => {

                  showSuccess();

                  this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/delivery-form/'+this.props.match.params.form_id+'/form');
              });
          }
      // }
  }

  getAddressFilters()
  {
      var filters = [];

      this.props.addresses.map((record) => {

          filters.push({
              text: record.full_address,
              value: record.id
          });
      });

      return filters;
  }

  getDrawingFilters()
  {
      var filters = [];

      this.props.drawings.map((record) => {

          filters.push({
              text: record.description,
              value: record.id
          });
      });

      return filters;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  onSelectChange = (selectedRowKeys) => {

      var selection = [];
      var deselection = [];

      // deselect parent if not all childs are selected
      selectedRowKeys.map((id, index) => {

          this.props.deliveries.map((delivery) => {

              if(!this.state.selectedRowKeys.includes(delivery.id) && !selectedRowKeys.includes(delivery.id) && delivery.nr_of_children > 0)
              {   // select parent if all child(s) are selected
                  // console.log('select parent if all child(s) are selected');

                  var all_childs_selected = true;

                  delivery.children.map((child) => {

                      if(!selectedRowKeys.includes(child.id))
                      {
                          all_childs_selected = false;
                      }
                      else
                      {
                          selection.push(child.id);
                      }
                  });

                  if(all_childs_selected)
                  {
                      selection.push(delivery.id);
                  }
              }
              else if(!this.state.selectedRowKeys.includes(delivery.id) && selectedRowKeys.includes(delivery.id) && delivery.nr_of_children > 0)
              {   // select all child(s)
                  // console.log('select all child(s)');

                  delivery.children.map((child) => {

                      selection.push(child.id);
                  });

                  selection.push(delivery.id);
              }
              else if(this.state.selectedRowKeys.includes(delivery.id) && !selectedRowKeys.includes(delivery.id) && delivery.nr_of_children > 0)
              {   // deselect all child(s)
                  // console.log('deselect all child(s)');

                  if(!selectedRowKeys.includes(delivery.id) && this.state.selectedRowKeys.includes(delivery.id) && delivery.nr_of_children > 0)
                  { // do nothing
                      //delete selectedRowKeys[index];

                      delivery.children.map((child) => {

                          selectedRowKeys.map((id2, index) => {

                              if(child.id == id2)
                              {
                                  delete selectedRowKeys[index];

                                  deselection.push(id2);
                              }
                          });
                      });
                  }
                  else
                  {
                      selection.push(id);
                  }
              }
              else if(this.state.selectedRowKeys.includes(delivery.id) && selectedRowKeys.includes(delivery.id) && delivery.nr_of_children > 0)
              {   // deselect parent
                  // console.log('deselect parent');

                  var all_childs_selected = true;

                  delivery.children.map((child) => {

                      if(!selectedRowKeys.includes(child.id))
                      {
                          all_childs_selected = false;
                      }
                      else
                      {
                          selection.push(child.id);
                      }
                  });

                  if(all_childs_selected)
                  {
                      selection.push(delivery.id);
                  }
                  else
                  {
                      // delete selectedRowKeys[index];
                      //
                      // deselection.push(delivery.id);

                      selectedRowKeys.map((id2, index) => {

                          if(delivery.id == id2)
                          {
                              delete selectedRowKeys[index];

                              deselection.push(id2);
                          }
                      });
                  }
              }
              else if(selectedRowKeys.includes(id)) //if(!delivery.nr_of_children)
              {
                  // console.log('else');

                  selection.push(id);
              }
          });
      });

      this.setState({ selectedRowKeys: [...new Set(selection)], /*expandedRowKeys: [...new Set(selection)]*/ });
  }

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              if(record.groups)
              {
                  record.groups.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              }

              if(record.groups)
              {
                  group_found = record.groups.includes(value);
              }

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  isRecordDisabled(record)
  {
      //console.log('record', record.children);

      // return record.nr_of_children > 0 ? true : false;
  }

  render() {

      let { filteredInfo } = this.state;
      filteredInfo = filteredInfo || {};

      /*const columns = [
          {
              title: 'Omschrijving',
              dataIndex: 'description',
              sorter: (a, b) => { return a.description.localeCompare(b.description)},
              render: (text, record) => (
                  <span>
                      <Link onClick={(event) => { event.preventDefault(); this.updateDelivery(record.id); }}>
                          { text ? text : record.address }
                      </Link>
                  </span>
              )
          },
          {
              title: 'Adres',
              dataIndex: 'address_label',
              sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
              render: (text, record) => (
                  <span>
                      {text}
                  </span>
              ),
              // filters: this.getAddressFilters(),
              // onFilter: (value, record) => (record.address_id === value || (Array.isArray(record.address_ids) && record.address_ids.includes(value))),
          },
          {
            title: 'Groep',
            dataIndex: 'groups',
            filters: this.props.groups && this.props.groups.map((elem) => {
              return {
                text: elem.name,
                value: elem.name,
              }
            }),
            filterMultiple: true,
            //onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
            onFilter: (value, record) => this.groupsFilter(value, record),
            //filteredValue: filteredInfo.groups || null,
            render: (text, record) =>
                record.groups && record.groups.map((v) => (
                    v.name ? <Tag color={v.color}>{v.name}</Tag> : null
                ))
          },
          {
              title: 'Gekoppelde tekening',
              dataIndex: 'drawing',
              sorter: (a, b) => { return a.delivery_drawing_id > b.delivery_drawing_id ? 1 : (a.delivery_drawing_id < b.delivery_drawing_id ? -1 : 0) },
              render: (text, record) => (
                  <span>
                      {text}
                  </span>
              ),
              filters: this.getDrawingFilters(),
              onFilter: (value, record) => record.delivery_drawing_id === value,
          }
      ];/**/


          const columns = [
              {
                  title: 'Locaties',
                  dataIndex: 'description',
                  render: (text, record) => (
                      <span>
                          { !record.address_label ?
                              text ? text : record.address
                          :
                          record.parent_description}
                      </span>
                  )
              },
              {
                  title: 'Adres',
                  dataIndex: 'address_label',
                  render: (text, record) => (
                      <span>
                          { text }
                      </span>
                  ),
                  sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
                  // filters: this.getAddressFilters(),
                  // onFilter: (value, record) => (record.address_id && record.address_id === value) || (record.address_ids && record.address_ids.includes(value))
              },
              {
                title: 'Groep',
                dataIndex: 'groups',
                filters: this.props.groups && this.props.groups.map((elem) => {
                  return {
                    text: elem.name,
                    value: elem.name,
                  }
                }),
                filterMultiple: true,
                onFilter: (value, record) => (record.groups && record.groups === value) || (record.groups && record.groups.includes(value)),
                //onFilter: (value, record) => record.groups && record.groups.filter((element) => element.name === value).length,
                //onFilter: (value, record) => this.groupsFilter(value, record),
                //filteredValue: filteredInfo.groups || null,
                render: (text, record) =>
                  record.children && record.children.length ? null :
                  record.groups && record.groups.map((v) => (
                      <Tag color={v.color}>{v.name}</Tag>
                  ))
              },
              /*{
                title: 'Status',
                dataIndex: 'status',
                sorter: (a, b) => { return a.status.localeCompare(b.status)},
                render: (text, record) => (
                  this.getStatusText(text)
                ),
                filters: [
                  {
                    text: 'Open',
                    value: 'open',
                  },
                  {
                    text: 'Controleren',
                    value: 'submitted',
                  },
                  {
                    text: 'Afgehandeld',
                    value: 'completed',
                  }
                ],
                onFilter: (value, record) => (typeof record.status == 'array' ? record.status.includes(value) : record.status == value),
                width: 110
              },/**/
              {
                title: 'Punten',
                render: (text, record) => {
                  return (
                    <div>
                      <Badge showZero count={record.nr_of_completed} style={{ opacity: (!record.nr_of_completed ? 0.33 : 1.0), backgroundColor: 'green', color: '#fff', marginRight: 4 }}></Badge>
                      <Badge showZero count={record.nr_of_submitted} style={{ opacity: (!record.nr_of_submitted ? 0.33 : 1.0),  backgroundColor: 'orange', color: '#fff', marginRight: 4 }}></Badge>
                      <Badge showZero count={record.nr_of_open} style={{ opacity: (!record.nr_of_open ? 0.33 : 1.0),  backgroundColor: 'red', color: '#fff', marginRight: 4 }}></Badge>
                    </div>)
                }
              }

              // {
              //     title: 'Adres',
              //     dataIndex: 'address',
              //     render: (text, record) => (
              //         <span>{ text  }</span>
              //     )
              // },
              // {
              //     title: 'Gekoppelde tekening',
              //     dataIndex: 'drawing',
              //     render: (text, record) => (
              //         <span>
              //             {text}
              //         </span>
              //     )
              // }
          ];

      const { selectedRowKeys } = this.state;
      const { expandedRowKeys } = this.state;

      const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
          onSelect: (record, selected) => { if(selected){ var expandedRowKeys = this.state.expandedRowKeys; expandedRowKeys.push(record.id); this.setState({ expandedRowKeys: expandedRowKeys}); } },
          // selections: [
          //   {
          //     key: 'all-data',
          //     text: 'Selecteer alles',
          //     onSelect: () => {
          //       this.onSelectChange(this.props.deliveries.map(value => value.id));
          //     },
          //   },
          //   {
          //     key: 'all-data',
          //     text: 'Deselecteer alles',
          //     onSelect: () => {
          //       this.onSelectChange([]);
          //     },
          //   },
          // ],
          getCheckboxProps: (record) => ({
            disabled: this.isRecordDisabled(record) || this.state.readonly // /*this.state.disabledRowKeys.includes(record.id) ||*/ (record.children && record.children.length > 0) ? true : false
          }),
      };

      const { getFieldDecorator } = this.props.form;

    return (
      <div>

          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <DeliverSideMenu project_id={this.props.match.params.id} activeMenu='7' />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                  <Col span={24}>
                    <div>
                      <Button disabled={!this.props.HaveAbility('deliver' ,'write') || this.state.readonly || !this.state.loaded} type="new" onClick={() => { this.handleSubmit(); }}>Opslaan en volgende</Button>
                      <Link to={`/projects/${this.props.match.params.id}/deliver/delivery-forms`}>
                        <Button>Sluiten</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                <Row className="mbm">
                  <Col span={24}>
                    <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                      <DeliveryFormSteps current={1} {...this.props} do_not_use_deliveries={this.props.delivery_form.do_not_use_deliveries} />
                    </Card>
                  </Col>
                </Row>

                <Row className="mbm">
                    <Col span={12} style={{ paddingTop: 6 }}>
                        Aantal regels geselecteerd: {this.state.selectedRowKeys.length}
                    </Col>
                    <Col span={12}>
                        <Input.Search
                            allowClear
                            placeholder="Zoeken..."
                            onSearch={(value) => { this.props.searchDeliveriesAction(this.props.match.params.id, value); }}
                            style={{ width: 200, float: 'right', marginLeft: 10 }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <CustomTable
                            rowKey='id'
                            size="middle"
                            rowSelection={rowSelection}
                            onChange={this.handleTableChange}
                            columns={columns}
                            dataSource={this.props.deliveries}
                            loading={this.props.isFetching}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded, record) => { var expandedRowKeys = this.state.expandedRowKeys; if(expanded){ expandedRowKeys.push(record.id); }else{ expandedRowKeys = expandedRowKeys.filter(function(value, record){ return record.id === value }); } this.setState({ expandedRowKeys: expandedRowKeys}); }}
                        />
                    </Col>
                </Row>

            </div>

          </div>

      </div>
    );
  }
}


const DeliveryFormDeliveriesForm = Form.create({ name: 'delivery_form_points' })(DeliveryFormDeliveries);

const mapStateToProps = (state, ownProps) => {

    return {

        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        delivery_form : state.deliver.form,
        deliveries : state.deliver.deliveries,
        addresses : state.deliver.addresses,
        groups: state.group.groups,
        drawings : state.deliver.drawings,
    };
}

const mapDispatchToProps = (dispatch) => {

    return {

        getDeliveryFormAction : bindActionCreators(getDeliveryFormAction, dispatch),
        syncDeliveryFormDeliveriesAction : bindActionCreators(syncDeliveryFormDeliveriesAction, dispatch),
        getDeliveriesAction : bindActionCreators(getDeliveriesAction, dispatch),
        searchDeliveriesAction : bindActionCreators(searchDeliveriesAction, dispatch),
        getDeliverAddressesAction : bindActionCreators(getDeliverAddressesAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
        getDrawingsAction : bindActionCreators(getDrawingsAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormDeliveriesForm);
