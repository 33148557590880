import React, { Component } from 'react';
// import '../Customers.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerSideMenu from './CustomerSideMenu';
import { HaveRole } from '../../../utils/RolesAndAbilities'
import { showSuccess } from '../../../utils/Notifications'
import { zipcodeValidation, urlValidation } from '../../../utils/validationRules';
import { getCustomerAction, getCustomersAction, addCustomerAction, patchCustomerAction, resetCustomerAction } from '../../../actions/customerActions';
import { Form } from '@ant-design/compatible';
import { Row, Col, Typography, Button, Divider, Input, Select, message, DatePicker, Switch, Radio, InputNumber } from 'antd';
import { getMyCustomersAction } from '../../../actions/authAction';
import { getProjectsAction } from '../../../actions/projectActions';
import NumberFormat from 'react-number-format';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getExactOrganisationsAction } from '../../../actions/invoiceActions';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');


const { Title, Text } = Typography;

const Option = Select.Option;

class CustomerGeneral extends Component {
  state = {
    disabled: true,
    edit: true,
    saving: false,
  };

  componentDidMount()
  {
    this.props.resetCustomerAction();

    this.props.getExactOrganisationsAction();

    if(this.props.match.params.id)
    {
      this.props.getCustomerAction(this.props.match.params.id);
    }
    else
    {
      this.props.getProjectsAction();
    }

    this.props.getCustomersAction();
  }

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  getExactOrganisationValues()
  { //
    var values = [];

    values.push({
      value: 'new',
      label: 'Voeg een nieuwe klant toe vanuit Cobee in Exact'
    });

    //
    this.props.exact_organisations && this.props.exact_organisations.map && this.props.exact_organisations.map((organisation) => {

      values.push({
        value: organisation.ID,
        label: organisation.Name
      })
    });

    //
    return values;
  }

  selectExactCustomer(value)
  {
    this.props.exact_organisations && this.props.exact_organisations.map && this.props.exact_organisations.map((organisation) => {

      this.setState({ exact_id: value });

      if(organisation.ID == value)
      {
        this.props.form.setFieldsValue({ 
          name: organisation.Name,
          kvk: organisation.ChamberOfCommerce,
          address: organisation.AddressLine1,
          house_number: organisation.AddressLine2,
          zipcode: organisation.Postcode,
          // house_number: ,
          city: organisation.City,
          telephone: organisation.Phone,
          email_address: organisation.Email,
          website: organisation.Website,
        });
      }
    })
  }

  handleSubmit = (e) => {

    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      values.disable_invoices = !values.disable_invoices;

      if (!err) {

        if(this.props.match.params.id) {

          this.props.patchCustomerAction(this.props.match.params.id, values).then(() => {

            //this.props.getMyCustomersAction().then(() => {

              this.props.getCustomerAction(this.props.match.params.id).then(() => {

                this.setState({ saving: false });

                showSuccess();
              })

              // this.setState({ saving: false });

              // showSuccess();
            //});
          });

        } else {

          showSuccess('Bezig met het aanmaken van de nieuwe klant.', 'Let op: Dit kan even duren.');

          this.props.addCustomerAction(values).then(() => {

            this.props.getMyCustomersAction().then(() => {

                this.setState({ saving: false });

                this.props.history.push(`/customers/${this.props.customer.id}/general`);

                showSuccess();
            });
          });
        }
      }
      else
      {
        this.setState({ saving: false });
      }
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[ this.props.HaveRole(['super-admin']) ? {'label': 'Klanten', 'link': '/customers'} : null, {'label': this.props.customer.name}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <CustomerSideMenu id={this.props.match.params.id} activeMenu='1' />

          <Form layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>

            <Row className="mbm">
              <Col span={12} >
                <Button
                    type="new"
                    onClick={(e) => {
                        if(!this.state.saving)
                        {
                            this.setState({ saving: true }, () => { this.handleSubmit(e); }); }
                        }
                    }
                    disabled={this.state.saving ? true : false}
                    >
                  { this.state.saving ? <LoadingOutlined /> : null }
                  Opslaan
                </Button>
                {this.props.HaveRole(['super-admin']) ? (<Button onClick={() => this.props.history.push('/customers')}>Sluiten</Button>) : null}
              </Col>
            </Row>

            <Divider />

            <Row gutter={24}>

              <Col span={24}>
                  <Title level={4}>Exact koppeling</Title>
              </Col>

              <Col span={24}>
                <Form.Item required label="Exact bedrijf" hasFeedback>
                  {getFieldDecorator('exact_id', {
                    initialValue: this.props.customer && this.props.customer.exact_id ? this.props.customer.exact_id : '',
                    value: this.state.exact_id ? this.state.exact_id : (this.props.customer && this.props.customer.exact_id ? this.props.customer.exact_id : ''),
                    rules: [{ required: true, message: 'Selecteer een bedrijf uit Exact om deze te koppelen en de gegevens daarvan voor in te vullen.' }],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      options={this.getExactOrganisationValues()}
                      onChange={(value) => { 
                        if(!this.props.match.params.id)
                        { // only prefil for new customers
                          this.selectExactCustomer(value); 
                        }
                      }}
                      placeholder="Selecteer een klant uit Exact of voeg een nieuwe klant toe vanuit Cobee in Exact"
                      />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <div style={{ display: (this.props.form.getFieldValue('exact_id') ? 'block' : 'none') }}>

                <Row gutter={24}>

                  <Col span={24}>
                    <Title level={4}>Algemene gegevens</Title>
                  </Col>

                  <Col span={12}>
                    {this.state.edit ? (
                      <Form.Item required label="Bedrijfsnaam" hasFeedback>
                        {getFieldDecorator('name', {
                          initialValue: this.props.customer.name,
                          rules: [{ required: true, message: 'Vul een bedrijfsnaam in' }],
                        })(
                          <Input></Input>
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>Bedrijfsnaam</Text>
                        <br />
                        <Text type="secondary">{this.props.customer.name}</Text>
                      </div>
                    )}
                  </Col>

                  {this.props.HaveRole(['super-admin']) ? (
                    <Col span={12}>
                      {this.state.edit ? (
                        <Form.Item label="Subvestiging van" hasFeedback>
                          {getFieldDecorator('parent_id', {
                            initialValue: String(this.props.customer.parent_id ? this.props.customer.parent_id : ''),
                          })(
                            <Select
                              showSearch
                              placeholder="Subvestiging van"
                              allowClear
                              optionFilterProp="children"
                              >
                              {this.props.customers.map((v, k) => (
                                <Option value={String(v.id)}>{v.name}</Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      ) : (
                        <div>
                          <Text strong>Subvestiging van</Text>
                          <br />
                          <Text type="secondary">{this.props.customer.parent_id ? this.props.customer.parent_name : 'Geen subvestiging'}</Text>
                        </div>
                      )}
                    </Col>
                  ) : null}

                  {this.props.HaveRole(['super-admin']) ? (
                    <Col span={12}>
                      {this.state.edit ? (
                        <Form.Item required label="Type account" hasFeedback>
                          {getFieldDecorator('package_id', {
                            initialValue: String(this.props.customer.package_id ? this.props.customer.package_id : ''),
                            rules: [{ required: true, message: 'Selecteer een type account' }],
                            onChange: (value) => { this.setState({ package_id: value }); }
                          })(
                            <Select optionFilterProp="children">
                              <Option value="0">Prospect</Option>
                              <Option value="1">30 dagen</Option>
                              <Option value="2">Aannemer</Option>
                              <Option value="3">Woco</Option>
                            </Select>
                          )}
                        </Form.Item>
                      ) : (
                        <div>
                          <Text strong>Type account</Text>
                          <br />
                          <Text type="secondary">{this.props.customer.package_name}</Text>
                        </div>
                      )}
                    </Col>) : null }
                    { this.state.package_id === '0' && !this.props.match.params.id ?
                    <Col span={12}>
                      <Form.Item label="Kies een demo project om over te nemen">
                        {getFieldDecorator('demo_project_id', {
                            initialValue: ''
                        })(
                          <Select optionFilterProp="children">
                            { this.props.projects && this.props.projects.map && this.props.projects.map((project) => {
                              return <Option value={project.id}>{project.name}</Option>
                            }) }
                          </Select>
                        )}
                      </Form.Item>
                    </Col> : null }

                  </Row>

                  <Row gutter={24}>

                  <Col span={12}>
                    {this.state.edit ? (
                      <Form.Item label="KvK" hasFeedback>
                        {getFieldDecorator('kvk', {
                          initialValue: this.props.customer.kvk ? this.props.customer.kvk : '',
                        })(
                          <Input allowEmptyFormatting={true} format="########" mask="" /*className="ant-input"*/ />
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>KvK</Text>
                        <br />
                        <Text type="secondary">{this.props.customer.kvk}</Text>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row gutter={24}>
                  { this.props.HaveRole(['super-admin']) ? (
                    <Col span={12}>
                      {this.state.edit ? (
                        <Form.Item required label="Sector" hasFeedback>
                          {getFieldDecorator('sector_id', {
                            initialValue: String(this.props.customer.sector_id ? this.props.customer.sector_id : ''),
                            rules: [{ required: true, message: 'Selecteer een sector'}],
                          })(
                            <Select
                              placeholder="Selecteer een sector"
                              optionFilterProp="children"
                              >
                              <Option value="1">Cobee</Option>
                              <Option value="2">Onderhoud / Renovatie</Option>
                              <Option value="3">Infra</Option>
                            </Select>
                          )}
                        </Form.Item>
                      ) : (
                        <div>
                          <Text strong>Sector</Text>
                          <br />
                          <Text type="secondary">{this.props.customer.sector_name}</Text>
                        </div>
                      )}
                    </Col>
                  ) : null }

                  { this.props.HaveRole(['super-admin']) ? (
                    <Col span={12}>

                      <Form.Item required label="Status" hasFeedback>
                        {getFieldDecorator('status', {
                          initialValue: String(this.props.customer.status ? this.props.customer.status : '1'),
                          rules: [{ required: true, message: 'Kies een optie.'}],
                        })(
                          <Select
                            placeholder="Selecteer een status"
                            optionFilterProp="children"
                            >
                            <Option value="1">Actief</Option>
                            <Option value="2">Inactief</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  ) : null }

                </Row>

                <Divider></Divider>

                <Row gutter={24}>
                  <Col span={24}>
                    <Title level={4}>Adresgegevens</Title>
                  </Col>
                  <Col span={12}>
                    {this.state.edit ? (
                      <Form.Item required label="Straat" hasFeedback>
                        {getFieldDecorator('address', {
                          initialValue: this.props.customer.address,
                          rules: [{ required: true, message: 'Vul een straatnaam in'}],
                        })(
                          <Input></Input>
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>Straat</Text>
                        <br />
                        <Text type="secondary">{this.props.customer.address}</Text>
                      </div>
                    )}
                  </Col>
                  <Col span={12}>
                    {this.state.edit ? (
                      <Form.Item required label="Huisnummer" hasFeedback>
                        {getFieldDecorator('house_number', {
                          initialValue: this.props.customer.house_number,
                          rules: [{ required: true, message: 'Vul een huisnummer in'}],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>Huisnummer</Text>
                        <br />
                        <Text type="secondary">{this.props.customer.house_number}</Text>
                      </div>
                    )}
                  </Col>
                  <Col span={12}>
                    {this.state.edit ? (
                      <Form.Item required label="Postcode" hasFeedback>
                        {getFieldDecorator('zipcode', {
                          initialValue: this.props.customer.zipcode,
                          rules: [{ validator: zipcodeValidation }],
                        })(
                          <Input maxLength={6}></Input>
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>Postcode</Text>
                        <br />
                        <Text type="secondary">{this.props.customer.zipcode}</Text>
                      </div>
                    )}
                  </Col>
                  <Col span={12}>
                    {this.state.edit ? (
                      <Form.Item required label="Plaats" hasFeedback>
                        {getFieldDecorator('city', {
                          initialValue: this.props.customer.city,
                          rules: [{ required: true, message: 'Vul een plaatsnaam in'}],
                        })(
                          <Input></Input>
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>Plaats</Text>
                        <br />
                        <Text type="secondary">{this.props.customer.city}</Text>
                      </div>
                    )}
                  </Col>
              </Row>
              
              <Divider></Divider>

              <Row gutter={24}>
                <Col span={24}>
                  <Title level={4}>Contactgegevens</Title>
                </Col>
                <Col span={12}>
                  {this.state.edit ? (
                    <Form.Item required label="Telefoon" hasFeedback>
                      {getFieldDecorator('telephone', {
                        initialValue: this.props.customer.telephone ? this.props.customer.telephone : '',
                        rules: [{ required: true, message: 'Vul een telefoonnummer in'}],
                      })(
                        <Input allowEmptyFormatting={true} format="##########" mask="" /*className="ant-input"*/ />
                      )}
                    </Form.Item>
                  ) : (
                    <div>
                      <Text strong>Telefoon</Text>
                      <br />
                      <Text type="secondary">{this.props.customer.telephone}</Text>
                    </div>
                  )}
                </Col>
                <Col span={12}>
                  {this.state.edit ? (
                    <Form.Item required label="E-mail" hasFeedback>
                      {getFieldDecorator('email_address', {
                        initialValue: this.props.customer.email_address,
                        rules: [{ required: true, message: 'Vul een e-mailadres in'}, {type: 'email', message: 'Vul een geldig e-mailadres in'}],
                      })(
                        <Input></Input>
                      )}
                    </Form.Item>
                  ) : (
                    <div>
                      <Text strong>E-mail</Text>
                      <br />
                      <Text type="secondary">{this.props.customer.email_address}</Text>
                    </div>
                  )}
                </Col>
                <Col span={12}>
                  {this.state.edit ? (
                    <Form.Item required label="Website" hasFeedback>
                      {getFieldDecorator('website', {
                        initialValue: this.props.customer.website,
                        rules: [{required: true, message: 'Vul een geldige URL in'}, { validator: urlValidation }],
                      })(
                        <Input addonBefore="https://" />
                      )}
                    </Form.Item>
                  ) : (
                    <div>
                      <Text strong>Website</Text>
                      <br />
                      <Text type="secondary">{this.props.customer.website}</Text>
                    </div>
                  )}
                </Col>
                {this.props.HaveRole(['super-admin']) ? (
                  <Col span={12}>
                    {this.state.edit ? (
                      <Form.Item label="URL projecten" hasFeedback>
                        {getFieldDecorator('project_domain', {
                          initialValue: this.props.customer.project_domain,
                          rules: [{ required: false }],
                        })(
                          <Input addonBefore="https://" />
                        )}
                      </Form.Item>
                    ) : (
                      <div>
                        <Text strong>URL projecten</Text>
                        <br />
                        <Text type="secondary">{this.props.customer.project_domain}</Text>
                      </div>
                    )}
                  </Col>
                ) : null }
              </Row>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const CustomerGeneralForm = Form.create({ name: 'customer_general' })(CustomerGeneral);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.customer.isFetching,
    isError: state.customer.isError,
    customer: state.customer.customer,
    customers: state.customer.customers,
    projects: state.project.projects,
    exact_organisations: state.invoice.exact_organisations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    patchCustomerAction : bindActionCreators(patchCustomerAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    resetCustomerAction : bindActionCreators(resetCustomerAction, dispatch),
    addCustomerAction : bindActionCreators(addCustomerAction, dispatch),
    HaveRole : bindActionCreators(HaveRole, dispatch),
    getProjectsAction : bindActionCreators(getProjectsAction, dispatch),
    getMyCustomersAction : bindActionCreators(getMyCustomersAction, dispatch),
    getExactOrganisationsAction : bindActionCreators(getExactOrganisationsAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerGeneralForm);
