export default (state = {
        isError: false, 
        isFetching: false, 
        customers:[], 
        customer:{ license: {} }, 
        modules: [], 
        billing: {projects: []}, 
        mollie_accounts: [],
        license: {}
    }
    , action) => {
        
    switch (action.type) {
    case 'CUSTOMERS_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'CUSTOMERS_ACTION_SUCCESS':
    return {
        ...state,
        customers: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMER_ACTION_SUCCESS':
        return {
        ...state,
        customer: action.payload,
        modules: action.payload.modules ? action.payload.modules.map((v, k) => v.id) : [],
        mollie_accounts: action.payload.mollie_accounts ? action.payload.mollie_accounts : [],
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMER_LICENSE_ACTION_SUCCESS':
        return {
        ...state,
        license: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMER_BILLING_ACTION_SUCCESS':
        return {
        ...state,
        billing: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SYNC_CUSTOMERS_ACTION_SUCCESS':
        return {
        ...state,
        modules: action.payload.ids.map((v) => parseInt(v)),
        isFetching: false,
        isError: false,
    }
    case 'ADD_CUSTOMERS_ACTION_SUCCESS':
    return {
        ...state,
        customers: [action.payload.customer, ...state.customers],
        customer: action.payload.customer,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_CUSTOMERS_ACTION_SUCCESS':
    return {
        ...state,
        customers: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'SEARCH_GLOBAL_ACTION_SUCCESS':
    return {
        ...state,
        global_search_results: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'RESET_CUSTOMERS_ACTION_FETCH':
    return {
        ...state,
        customers: [],
        customer: {},
        license: {},
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMER_LICENSE_ACTION_FETCH':
    return {
        ...state,
        license: {},
        isFetching: false,
        isError: false,
    }
    case 'RESET_CUSTOMER_LICENSE_ACTION_FETCH':
    return {
        ...state,
        license: {},
        isFetching: false,
        isError: false,
    }
    case 'DELETE_CUSTOMERS_ACTION_SUCCESS':
    return {
        ...state,
        customers: state.customers.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'CUSTOMERS_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    case 'ADD_MOLLIE_ACCOUNT_ACTION':

    // state.appointment.dayparts.push({'name': '', 'from_time': '', 'till_time': ''});

    return {
        ...state,
        mollie_accounts: [...state.mollie_accounts, {'name': '', 'mollie_api_key': ''}].filter(Boolean),
    }
    default:
        return state
    }
}
