import React, { Component, useRef, useState, useEffect } from 'react';
import Media from "react-media";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess } from '../../../utils/Notifications';
import { getPointsAction, searchPointsAction, exportPointsAction, archivePointsAction, deletePointsAction, resetPointsAction, getUsersAction, multiUpdatePointsAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, getUserFilterAction, addPointsToDeliveryFormAction, getPointsDeliveriesAction } from '../../../actions/deliverActions';
import { getAddressesAction } from '../../../actions/addressesActions';
import { getGroupsAction } from '../../../actions/groupsActions';
import DeliverSideMenu from './DeliverSideMenu';
import DeliverPoint from './DeliverPointModal';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Row, Col, Button, Input, Modal, Select, Dropdown, Menu, Drawer, Tooltip, Tag } from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import './deliver.css';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import dayjs from 'dayjs';

const { Option } = Select;

class DeliverPoints extends Component
{
    resize_timer = null;

    filtered = false;

    state = {
        disabled: true,
        edit: true,
        selectedRowKeys: [],
        expandedRowKeys: [],
        is_archive: false,
        show_status_modal: false,
        show_user_modal: false,
        show_export_modal: false,
        can_write: false,
        can_delete: false,
        toggleAll: false,

        sub_point_selected: false,
        filters: [],
        filtered: false,

        window_width: window.innerWidth
    };

    points = [];

    componentDidMount()
    {
        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write'),
            can_delete: this.props.HaveAbility('deliver', 'delete'),
        });

        this.props.resetPointsAction();

        this.setState({ is_archive: this.isArchive() }, () => {

            this.loadData();
        });

        var this_obj = this;

        window.addEventListener('resize', () => { this_obj.handleResize(this_obj); });
    }

    componentWillReceiveProps(nextprops)
    {
        if(this.isArchive() != this.state.is_archive)
        {
            this.setState({ is_archive: !this.state.is_archive }, () => {

                this.loadData();
            });
        }
    }

    handleResize(this_obj)
    {
        this_obj.setState({ window_width: window.innerWidth }, () => {});

        // clearTimeout(this_obj.resize_timer);
        //
        // this_obj.resize_timer = setTimeout(() => {
        //
        //     this_obj.setState({ window_width: window.innerWidth }, () => {
        //
        //         this_obj.loadData(false);
        //     });
        // }, 500);

        // this_obj.setState({ window_width: window.innerWidth }, () => { this.loadData(false); });
    }

    calculateSubStringLength(length)
    {
        // return 9999;
        // return length;

        var window_width = this.state.window_width; // window.innerWidth;
        var width = 1920;

        if(window_width < width)
        {
            if(window_width < 1600)
            {
                return 4;
            }
            else
            {
                return parseInt(length * window_width / width);
            }
        }
        else
        {
            return length;
        }
    }

    isArchive()
    {
        var url = window.location.href;

        url = url.split('/');

        return (url[url.length - 1] == 'archive' ? true : false);
    }

    loadData(reload = true)
    {
        if(reload)
        {   // filters
            this.props.getUsersAction(this.props.match.params.id, false, 'contractor');
            this.props.getDeliveryFilterAction(this.props.match.params.id);
            this.props.getFormFilterAction(this.props.match.params.id);
            this.props.getLocationFilterAction(this.props.match.params.id);
            this.props.getUserFilterAction(this.props.match.params.id);
            this.props.getPointsDeliveriesAction(this.props.match.params.id);
            this.props.getAddressesAction({'projectId': this.props.match.params.id}, null);
            this.props.getGroupsAction({'projectId': this.props.match.params.id});
        }

        // points
        this.props.getPointsAction(this.props.match.params.id, this.state.is_archive).then(() => {

            this.points = JSON.parse(JSON.stringify(this.props.points)); // original

            var flattened_points = [];

            // is sub point selected
            this.points.map((record) => {

                if(record.children)
                {
                    var children = JSON.parse(JSON.stringify(record.children));

                    // delete record.children;
                    record.children = false;

                    flattened_points.push(record);

                    children.map((child_record) => {

                        flattened_points.push(child_record);
                    });
                }
                else
                {
                    flattened_points.push(record);
                }
            });

            this.setState({ points: flattened_points });

            if(this.props.match.params.point_id)
            {
                this.showPoint(this.props.match.params.point_id, this.props.match.params.parent_id ? this.props.match.params.parent_id : false);
            }
        });
    }

    onSelectChange = (selectedRowKeys) => {

        var sub_point_selected = false;

        // is sub point selected
        this.props.points.map((record) => {

            if(record.children)
            {
                record.children.map((child_record) => {

                    if(selectedRowKeys.includes(child_record.id))
                    {
                        sub_point_selected = true;

                        return;
                    }
                });

                if(sub_point_selected)
                {
                    return;
                }
            }
        });

        // - if parent selected, also select child(s)
        // - if all childs selected, also select parent
        // - if not all childs selected, deselect parent

        //
        this.setState({
            sub_point_selected: sub_point_selected,
            selectedRowKeys
        });
    }

    toggleArchive(status)
    {
        this.props.resetPointsAction();

        this.setState({ is_archive: status }, () => {

            window.history.pushState({}, null, '/projects/'+this.props.match.params.id+'/deliver/points'+(this.state.is_archive ? '/archive' : ''));

            this.loadData();
        });
    }

    changeUser()
    {
        this.props.form.resetFields();

        this.setState({
            show_status_modal: false,
            show_user_modal: true,
            show_export_modal: false,
            errors: {},
        });
    }

    changeStatus()
    {
        this.props.form.resetFields();

        this.setState({
            show_status_modal: true,
            show_user_modal: false,
            show_export_modal: false,
            errors: {},
        });
    }

    chooseExportFormat()
    {
        this.props.form.resetFields();

        this.setState({
            show_status_modal: false,
            show_user_modal: false,
            show_export_modal: true,
            errors: {},
        });
    }

    handleUser = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors.user_id)
            {   // ok
                this.props.multiUpdatePointsAction(this.props.match.params.id, {ids: this.state.selectedRowKeys, assigned_to_delivery_user_id: values.user_id}).then(() => {

                    showSuccess('Succesvol opgeslagen');

                    this.loadData();
                });

                // hide & deselect
                this.setState({
                    show_user_modal: false,
                    selectedRowKeys: [],
                });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    handleStatus = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors.status)
            {   // ok
                this.props.multiUpdatePointsAction(this.props.match.params.id, {ids: this.state.selectedRowKeys, status: values.status}).then(() => {

                    showSuccess('Succesvol opgeslagen');

                    this.loadData();
                });

                // hide & deselect
                this.setState({
                    show_status_modal: false,
                    selectedRowKeys: [],
                });

                // reset fields
                this.props.form.resetFields();
            }
        });
    }

    // handleExport = event => {
    //
    //     event.preventDefault();
    //
    //     this.props.form.validateFields((errors, values) => {
    //
    //         this.setState({ errors: errors });
    //
    //         if(!errors.export_as)
    //         {   // ok
    //             this.props.exportPointsAction(this.props.match.params.id, {ids: this.state.selectedRowKeys, email: values.email}, values.export_as).then(() => {});
    //
    //             // hide & deselect
    //             this.setState({
    //                 show_export_modal: false,
    //                 selectedRowKeys: [],
    //             });
    //
    //             // reset fields
    //             this.props.form.resetFields();
    //
    //             //
    //             showSuccess('Succesvol aangevraagd');
    //         }
    //     });
    // }

    archivePoints()
    {
        Modal.confirm({
          title: 'Wil je de geselecteerde items archiveren?',
          content: 'Als je op JA klikt, worden alle geselecteerde punten gearchiveerd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

                this.props.archivePointsAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
                    showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));

                    this.setState({
                        selectedRowKeys: []
                    });

                    this.loadData();

                    resolve();
                });

            }).catch((e) => { console.log('Error archive points', e) });
          },
          onCancel() {},
        });

        // this.props.archivePointsAction(this.props.match.params.id, this.state.selectedRowKeys, !this.state.is_archive).then(() => {
        //     showSuccess((!this.state.is_archive ? 'Succesvol gearchiveerd' : 'Succesvol hersteld'));
        //
        //     this.setState({
        //         selectedRowKeys: []
        //     });
        //
        //     this.loadData();
        // });
    }

    deletePoints()
    {
        Modal.confirm({
          title: 'Wil je de geselecteerde items verwijderen?',
          content: 'Als je op JA klikt, worden alle geselecteerde punten verwijderd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

                this.props.deletePointsAction(this.props.match.params.id, this.state.selectedRowKeys).then(() => {

                    showSuccess('Succesvol verwijderd');

                    this.setState({
                        selectedRowKeys: []
                    });

                    this.loadData();

                    resolve();
                });
            }).catch((e) => { console.log('Error delete points', e) });
          },
          onCancel() {},
        });
    }

    newPoint()
    {
        // this.props.history.push('/projects/'+this.props.match.params.id+'/deliver/point/add');
        this.showPoint(false, false, 'Opleverpunt toevoegen');
    }

    addToForm()
    {
        this.setState({ show_add_to_form_modal: true });
    }

    handleAddPointsToForm = event => {

        event.preventDefault();

        this.props.form.validateFields((errors, values) => {

            this.setState({ errors: errors });

            if(!errors.add_to_form_id)
            {   // ok
                this.props.addPointsToDeliveryFormAction(values.delivery_form_id, {ids: this.state.selectedRowKeys}).then(() => {

                    showSuccess('Succesvol toegevoegd');

                    this.setState({
                        selectedRowKeys: [],
                        show_add_to_form_modal: false
                    });

                    this.loadData();

                    this.props.form.resetFields();

                }).catch((e) => { console.log('Error add points to form', e) });
            }
        });
    }

    getStatusText(status)
    {
        // if(status == 'new'){ return 'Nieuw'; }
        if(status == 'submitted'){ return 'Controleren'; }
        else if(status == 'completed'){ return 'Afgehandeld'; }
        else { return 'Open'; }
    }

    getStatusColor(status)
    {
        // if(status == 'new'){ return '#888888'; }
        // if(status == 'submitted'){ return '#ff8800'; }
        // else if(status == 'completed'){ return '#00ff00'; }
        // else { return '#ff0000'; }

        if(status == 'submitted'){ return 'orange'; }
        else if(status == 'completed'){ return 'green'; }
        else { return 'red'; }
    }

    // slidePhoto(point_id, step)
    // {
    //     // disable slider buttons
    //     this.setState({ disable_slider_click: true });
    //
    //     // find point
    //     var point = this.props.points.filter(function(object){ return object.id === point_id })[0];
    //
    //     // determine nr
    //     var nr = this.state['photo-nr-' + point_id];
    //
    //     if(!nr){ nr = 1; } // nr net (yet) set
    //
    //     var current_nr = nr;
    //
    //     // direction(s)
    //     if(step > 0)
    //     {
    //         if((nr + step) > point.photos.length){ nr = 1; }
    //         else{ nr += step; }
    //     }
    //     else if(step < 0)
    //     {
    //         if((nr - (-1 * step)) < 1){ nr = point.photos.length; }
    //         else{ nr -= (-1 * step); }
    //     }
    //
    //     // update state
    //     this.setState({ ['photo-nr-' + point_id]: nr });
    //
    //     // show new photo
    //     document.getElementById('point-' + point_id + '-photo-' + nr).style.display = 'block';
    //
    //     // hide old photo
    //     document.getElementById('point-' + point_id + '-photo-' + current_nr).style.display = 'none';
    //
    //     // enable slider buttons
    //     this.setState({ disable_slider_click: false });
    // }

    showPhoto(url)
    {
        this.setState({
            show_photo_modal: true,
            photo_url: url,
        });
    }

    getDeliveryFilters()
    {
        var filters = [];

        this.props.delivery_filters && this.props.delivery_filters.map((record) => {

            filters.push({
                text: record.description,
                value: record.id
            });
        });

        return filters;
    }

    getFormsFilters()
    {
        var filters = [];

        this.props.form_filters && this.props.form_filters.map((record) => {

            filters.push({
                text: record.name,
                value: record.id
            });
        });

        return filters;
    }

    getAddressFilters()
    {
        var filters = [];

        this.props.location_filters && this.props.location_filters.map((record) => {

            if(record.type == 'address')
            {
                filters.push({
                    text: record.full_address,
                    value: record.id
                });
            }
        });

        return filters;
    }

    getUserFilters()
    {
        var filters = [];

        this.props.user_filters && this.props.user_filters.map((record) => {

            filters.push({
                text: record.full_name ? record.full_name + (record.company_name ? ' ('+record.company_name+')' : '') : record.email_address,
                value: record.id
            });
        });

        return filters;
    }

    showPoint(point_id = false, point_parent_id = false, title = 'Opleverpunt')
    {
        this.setState({
            point_id: point_id,
            point_parent_id: point_parent_id,
            modal_title: title,
        }, () => {

            this.setState({
                point_modal: true
            }, () => {});
        });
    }

    refreshData()
    {
        this.loadData();
    }

    setHandleSave(handleSave)
    {
        this.handleSave = handleSave;
    }

    closePoint = (refresh = false/*, changes = false*/, force = false) =>
    {
        // console.log('closePoint', refresh);

        if(!this.state.something_changed || force)
        {
            this.setState({
                point_modal: false,
                something_changed: false
            });

            if(refresh)
            {
                this.loadData();
            }
        }
        else
        {
            Modal.confirm({
                title: 'Niet opgeslagen wijzigingen',
                content: 'Klik op JA om door te gaan en de wijzigingen alsnog op te slaan.',
                okText: 'Ja',
                okType: 'danger',
                cancelText: 'Nee',
                onOk: () => {
                    return new Promise((resolve, reject) => {

                        console.log('handle save (parent)');

                        this.point_ref.handleSave();

                        resolve();

                    }).catch(() => {});
                },
                onCancel: () => {

                    this.closePoint(true, true);
                },
            });
        }
    }

    toggleAll()
    {
        var keys = [];

        if(this.state.toggleAll)
        {   // close all
            this.setState({
                toggleAll: false,
                expandedRowKeys: keys
            });
        }
        else
        {   // open all
            this.props.points.map((record) => {

                if((record.children && record.children.length))
                {
                    keys.push(record.id);
                }
            });

            this.setState({
                toggleAll: true,
                expandedRowKeys: keys
            });
        }
    }

    somethingChanged = () =>
    {
        // console.log('something = changed (parent)');

        this.setState({ something_changed: true });
    }

    applyFilters(filters)
    {
        var filtered = false;

        var filter_keys = Object.keys(/*this.state.*/filters);

        filter_keys.map((key) => {

            if(/*this.state.*/filters[key].length)
            {
                filtered = true;

                return;
            }
        });

        // this.setState({ filtered: filtered });
        this.filtered = filtered;
    }

    filterRecord(record)
    {
        // console.log('filtering', this.state.filters);

        if(!this.state.filters || !this.state.filters.length){ return false; }

        return record.id % 2 ? true : false;
    }

    mySorter(a, b)
    {
        // console.log('my sorter', a, b);
        //
        // return a.localeCompare(b);

        // if(a > b){ return true; }
        // else{ return false; }

        if(a < b){ return -1; }
        else if(a > b){ return 1; }
        else{ return 0; }
    }

    // groupsFilter(value, record)
    // {
    //     var all_groups_found = true;

    //     if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
    //     {
    //         this.state.filteredInfo.groups.map((group_name) => {
    //             var group_found = false;

    //             record.groups.map((record_group) => {
    //                 if(record_group.name == group_name)
    //                 {
    //                     group_found = true;
    //                 }
    //             });

    //             if(!group_found)
    //             {
    //                 all_groups_found = false;
    //             }
    //         });
    //     }

    //     return all_groups_found;
    // }

    pointHasAddressGroup(record, id)
    {
        var has = false;

        record.address_groups && record.address_groups.map && record.address_groups.map((item) => {

            if(item.id == id)
            {
                has = true;

                return;
            }
        });

        return has;
    }

    render()
    {
        // const getResponsiveColumns = smallScreen => columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

        let { filteredInfo } = this.state;
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Onderwerp',
                fixed: 'left',
                width: 300,
                dataIndex: 'subject',
                sorter: (a, b) => { return a.subject.localeCompare(b.subject)},
                render: (text, record) => (
                    <span style={{ paddingLeft: record.parent_id ? 18 : 0, display: 'inline-block' }}>
                        <Link
                            // style={{ paddingLeft: record.parent_id ? 18 : 0 }}
                            onClick={(e) => { e.preventDefault(); this.showPoint(record.id, record.parent_id, record.subject); return false; }}
                            //to={'/projects/'+record.project_id+'/deliver/point/'+record.id}
                            to=""
                            >
                            {/*(record.parent_id ? '- ' : '')} {(text.length > this.calculateSubStringLength(30) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(30))+'...'}</Tooltip> : text)*/}
                            {text}
                        </Link><br />
                        {/*<span style={{ color: '#888888', fontSize: 12 }}>Aanmaakdatum: { dayjs(record.created_at).format('DD-MM-YYYY') }</span>*/}
                    </span>
                ),
                sortDirections: ['ascend', 'descend']
            },
            {
                title: 'Locatie (specifiek)',
                width: 200,
                dataIndex: 'location',
                sorter: (a, b) => { if(!a.location){ a.location = ''; } if(!b.location){ b.location = ''; } return a.location.localeCompare(b.location)},
                render: (text, record) => (
                    <span style={{ display: 'inline-block' }}>
                        {text}
                    </span>
                ),
                sortDirections: ['ascend', 'descend']
            },
            {
                title: 'Locatie',
                width: 300,
                dataIndex: 'description', // 'description',
                sorter: (a, b) => { return a.description.localeCompare(b.description)},
                render: (text, record) => (
                    <span style={{ display: 'inline-block' }}>
                        {/*text ? text : record.parent_id ? '' : 'Niet opgegeven'*/}
                        {text /*? (text.length > this.calculateSubStringLength(26) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(26))+'...'}</Tooltip> : text) : ''*/}
                    </span>
                ),
                filters: this.getDeliveryFilters(),
                onFilter: (value, record) => record.delivery_id === value || record.parent_delivery_id === value,
                sortDirections: ['ascend', 'descend']
            },
            {
                title: 'Adres',
                width: 200,
                dataIndex: 'address_label',
                filters: this.props.addresses && this.props.addresses.map && this.props.addresses.map((elem) => {
                  return {
                    text: elem.full_address,
                    value: elem.id,
                  }
                }),
                sorter: (a, b) => { return a.address_label.localeCompare(b.address_label)},
                sortDirections: ['ascend', 'descend'],
                filterMultiple: true,
                onFilter: (value, record) => record.address_id === value,
                render: (text, record) => (
                    <span style={{ display: 'inline-block' }}>
                        {text /*record.parent_id ? '' : (text.length > this.calculateSubStringLength(20) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(20))+'...'}</Tooltip> : text)*/}
                    </span>
                ),
            },
            {
                title: 'Groep',
                width: 160,
                dataIndex: 'groups',
                filters: this.props.groups && this.props.groups.map && this.props.groups.map((elem) => {
                  return {
                    text: elem.name,
                    value: elem.id,
                  }
                }),
                filterMultiple: true,
                onFilter: (value, record) => this.pointHasAddressGroup(record, value), // record.address_groups && record.address_groups.includes && record.address_groups.includes(value) === true,
                render: (text, record) =>
                record.address_groups && record.address_groups.map && record.address_groups.map((v) => (
                  <Tag color={v.color}>{v.name}</Tag>
                ))
            },
            {
                title: 'Formulier',
                width: 200,
                dataIndex: 'delivery_form_name',
                // sorter: (a, b) => { if(!a.delivery_form_name){ return 0; }else if(!b.delivery_form_name){ return 1; }else{ return a.delivery_form_name.localeCompare(b.delivery_form_name); }},
                sorter: (a, b) => { return a.delivery_form_name.localeCompare(b.delivery_form_name)},
                sortDirections: ['ascend', 'descend'],
                render: (text, record) => (
                    <span style={{ display: 'inline-block' }}>
                        {text}
                    </span>
                ),
                filters: this.getFormsFilters(),
                onFilter: (value, record) =>  record.delivery_form_id && record.delivery_form_id.includes && record.delivery_form_id.includes(value),
            },
            {
                title: 'Verantwoordelijke',
                width: 350,
                dataIndex: 'user_name',
                sorter: (a, b) => { return a.user_name ? a.user_name.localeCompare(b.user_name) : false},
                sortDirections: ['ascend', 'descend'],
                render: (text, record) => (
                    <span style={{ display: 'inline-block' }}>
                        {text /*(text ? (text.length > this.calculateSubStringLength(20) ? <Tooltip placement="right" title={text}>{text.substring(0, this.calculateSubStringLength(20))+'...'}</Tooltip> : text) : '')*/}
                    </span>
                ),
                filters: this.getUserFilters(),
                onFilter: (value, record) => (record.assigned_to_delivery_user_id === value || (Array.isArray(record.sub_assigned_to_delivery_user_id) && record.sub_assigned_to_delivery_user_id.includes(value))),
            },
            {
                title: 'Uiterlijk gereed',
                width: 120,
                dataIndex: 'due_date',
                render: (text, record) => (
                    <span style={{color: (record.filter !== 'expired' || record.status == 'completed' ? '#000000' : '#ff0000')}}>
                        {record.expired ? moment(text).format('DD/MM/YYYY') : (text ? moment(text).format('DD/MM/YYYY') : 'Niet opgegeven')}
                    </span>
                ),
                filters: [
                  {
                    text: 'Verlopen',
                    value: 'expired',
                  },
                  {
                    text: 'Niet verlopen',
                    value: 'not-expired',
                  },
                  {
                    text: 'Niet opgegeven',
                    value: 'not-set',
                  },
                ],
                onFilter: (value, record) => record.filter === value,
            },
            {
                title: 'Aanmaakdatum',
                width: 140,
                dataIndex: 'created_at',
                render: (text, record) => (
                    <span>
                        { dayjs(text).format('DD/MM/YYYY') }
                    </span>
                ),
                sorter: (a, b) => { return dayjs(a.created_at).format('DD/MM/YYYY').localeCompare(dayjs(b.created_at).format('DD/MM/YYYY')); },
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: 'Afgerond op',
                width: 140,
                dataIndex: 'completed_at',
                render: (text, record) => (
                    <span>
                        { text ? dayjs(text).format('DD/MM/YYYY') : '' }
                    </span>
                ),
                sorter: (a, b) => { return dayjs(a.completed_at).format('DD/MM/YYYY').localeCompare(dayjs(b.completed_at).format('DD/MM/YYYY')); },
                sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Status',
              width: 110,
              fixed: 'right',
              dataIndex: 'status',
              //sorter: (a, b) => { return a.status.localeCompare(b.status) },
              sorter: (a, b) => { return this.mySorter(a.status, b.status); },
              render: (text, record) => (
                <span style={{display: 'inline-block', height: 18, width: 18, borderRadius: 18, backgroundColor: this.getStatusColor(text)}}></span>
              ),
              filters: [
                {
                  text: 'Open',
                  value: 'open',
                },
                {
                  text: 'Controleren',
                  value: 'submitted',
                },
                {
                  text: 'Afgehandeld',
                  value: 'completed',
                }
              ],
              onFilter: (value, record) => (record.status === value || (record.sub_status && record.sub_status.includes(value))),
              align: 'center'
            },
        ];

        const { selectedRowKeys } = this.state;
        const { expandedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected) => { if(selected){ var expandedRowKeys = this.state.expandedRowKeys; expandedRowKeys.push(record.id); this.setState({ expandedRowKeys: expandedRowKeys}); } },
            hideDefaultSelections: true,
            getCheckboxProps: (record) => ({
                disabled: record.delivery_form_name ? true : false
            }),
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

                <DeliverSideMenu project_id={this.props.match.params.id} activeMenu="1"/>

                <div style={{padding: 24, display: 'block', width: '100%'}}>

                    <Row className="mbm" style={{display: (this.state.is_archive ? 'none' : 'flex')}}>

                        <Col span={8} className="status-box status-box-open">
                            <div>
                                <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: 'red', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                                <span class="label">Open</span>
                                <span class="number">
                                    {
                                        this.props.isFetching
                                        ?
                                        <LoadingOutlined />
                                        :
                                        <span>{this.props.nr_of_open} <span class="sub"> | {this.props.nr_of_sub_open}</span></span>
                                    }
                                </span>
                            </div>
                        </Col>

                        <Col span={8} className="status-box status-box-submitted">
                            <div>
                                <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: 'orange', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                                <span class="label">Controleren</span>
                                <span class="number">
                                    {
                                        this.props.isFetching
                                        ?
                                        <LoadingOutlined />
                                        :
                                        <span>{this.props.nr_of_submitted} <span class="sub"> | {this.props.nr_of_sub_submitted}</span></span>
                                    }
                                </span>
                            </div>
                        </Col>

                        <Col span={8} className="status-box status-box-completed">
                            <div>
                                <div style={{height: 32, width: 32, borderRadius: 32, backgroundColor: 'green', float: 'right', marginTop: 20, marginBottom: 20}}></div>
                                <span class="label">Afgehandeld</span>
                                <span class="number">
                                    {
                                        this.props.isFetching
                                        ?
                                        <LoadingOutlined />
                                        :
                                        <span>{this.props.nr_of_completed} <span class="sub"> | {this.props.nr_of_sub_completed}</span></span>
                                    }
                                </span>
                            </div>
                        </Col>

                    </Row>

                    <Row className="mbm">
                        <Col span={12}>

                            <Button disabled={!this.state.can_write} type="new" onClick={() => { this.newPoint(); }}>+ Toevoegen</Button>

                            { !this.state.is_archive ?
                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item disabled={this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true} key="1" onClick={() => { this.changeUser(); }}>Verantwoordelijke wijzigen</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item disabled={this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true} key="2" onClick={() => { this.changeStatus(); }}>Status wijzigen</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item disabled={this.state.can_write && this.state.selectedRowKeys.length > 0 && !this.state.sub_point_selected ? false : true} key="3" onClick={() => { this.archivePoints(); }}>{this.state.is_archive ? 'Herstellen' : 'Archiveren'}</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item disabled={this.state.can_delete && this.state.selectedRowKeys.length > 0 ? false : true} style={{color: 'red', opacity: (this.state.can_delete && this.state.selectedRowKeys.length > 0 ? 1 : 0.33)}} key="4" onClick={() => { this.deletePoints(); }}>Verwijderen</Menu.Item>
                                </Menu>
                              )} disabled={this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true}>
                              <Button type="secondary">
                                Acties <DownOutlined />
                              </Button>
                            </Dropdown>
                            :
                            <Dropdown trigger={['click']} overlay={(
                                <Menu>
                                  <Menu.Item disabled={this.state.can_write && this.state.selectedRowKeys.length > 0 ? false : true} key="3" onClick={() => { this.archivePoints(); }}>{this.state.is_archive ? 'Herstellen' : 'Archiveren'}</Menu.Item>
                                  <Menu.Divider></Menu.Divider>
                                  <Menu.Item disabled={this.state.can_delete && this.state.selectedRowKeys.length > 0 ? false : true} style={{color: 'red', opacity: (this.state.can_delete && this.state.selectedRowKeys.length > 0 ? 1 : 0.33)}} key="4" onClick={() => { this.deletePoints(); }}>Verwijderen</Menu.Item>
                                </Menu>
                              )} disabled={ this.state.can_write ? false : true }>
                              <Button type="secondary">
                                Acties <DownOutlined />
                              </Button>
                            </Dropdown>
                            }

                            <Button style={{ marginLeft: 12 }} onClick={() => { this.toggleAll(); }}>Alles { !this.state.toggleAll ? 'open' : 'dicht' }klappen</Button>
                        </Col>
                        <Col span={12}>
                            <Input.Search
                                allowClear
                                placeholder="Zoeken..."
                                onSearch={(value) => { this.props.searchPointsAction(this.props.match.params.id, value, this.state.is_archive); }}
                                style={{ width: 200, float: 'right', marginLeft: 10 }}
                            />
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'none' : 'inline')}} onClick={() => { this.toggleArchive(true); }}>Archief</Button>
                            <Button type="primary" style={{float: 'right', display: (this.state.is_archive ? 'inline' : 'none')}} onClick={() => { this.toggleArchive(false); }}>Overzicht</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ padding: 6, paddingLeft: 0 }}>
                            Aantal regels geselecteerd: {this.state.selectedRowKeys.length}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>

                          { this.filtered ?
                          <CustomTable
                              rowKey='id'
                              size="middle"
                              scroll={{ x: 980 }}
                              style={{ width: (this.state.window_width - 520) }}
                              rowSelection={rowSelection}
                              expandedRowKeys={this.state.expandedRowKeys}
                              onExpandedRowsChange={(id) => {
                                  this.setState({expandedRowKeys: id});
                              }}
                              columns={columns}
                              dataSource={this.state.points}
                              loading={this.props.isFetching}
                              onChange={(pagination, filters, sorter, extra) => {

                                  this.applyFilters(filters);
                              }}
                              indentSize={0}
                              // expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => { var expandedRowKeys = this.state.expandedRowKeys; if(expanded){ expandedRowKeys.push(record.id); }else{ expandedRowKeys = expandedRowKeys.filter(function(value, record){ return record.id === value }); } this.setState({ expandedRowKeys: expandedRowKeys}); }}
                              />
                          :
                          <CustomTable
                              rowKey='id'
                              size="middle"
                              scroll={{ x: 980 }}
                              style={{ width: (this.state.window_width - 520) }}
                              rowSelection={rowSelection}
                              expandedRowKeys={this.state.expandedRowKeys}
                              onExpandedRowsChange={(id) => {

                                  this.setState({expandedRowKeys: id});
                              }}
                              columns={columns}
                              dataSource={this.props.points}
                              loading={this.props.isFetching}
                              onChange={(pagination, filters, sorter, extra) => {

                                  var filtered = false;

                                  var filter_keys = Object.keys(filters);

                                  filter_keys.map((key) => {

                                      if(filters[key] && filters[key].length)
                                      {
                                          filtered = true;

                                          return;
                                      }
                                  });

                                  this.filtered = filtered;
                              }}
                              indentSize={0}
                              // expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => { var expandedRowKeys = this.state.expandedRowKeys; if(expanded){ expandedRowKeys.push(record.id); }else{ expandedRowKeys = expandedRowKeys.filter(function(value, record){ return record.id === value }); } this.setState({ expandedRowKeys: expandedRowKeys}); }}
                              />
                          }
                        </Col>
                    </Row>

                </div>

                <Modal
                    title="Verantwoordelijke wijzigen"
                    visible={this.state.show_user_modal}
                    onCancel={() => {
                        this.setState({ show_user_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'user',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="user"
                        onSubmit={this.handleUser}
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Selecteer een contactpersoon"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('user_id', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Select
                                        showSearch
                                        placeholder="Geef een verantwoordelijke op"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onSearch={(value) => {
                                            if(value)
                                            {
                                                this.setState({ user: value });
                                            }
                                        }}
                                        onBlur={() => {
                                            var users = this.state.users ? this.state.users : [];
                                            var user = this.state.user;

                                            this.setState({ user: '' });

                                            if(user)
                                            {
                                                users.push(user);

                                                this.setState({ users: users }, () => {
                                                    this.props.form.setFieldsValue({ user_id: user });
                                                });
                                            }
                                        }}
                                    >
                                        { this.state.users && this.state.users.length && this.state.users.map((record) => {
                                            return <Option value={record}>{record}</Option>
                                        }) }
                                        { this.props.users.map((record, index) => {
                                            return <Option value={''+record.id}>{record.full_name ? record.full_name + (record.company_name ? ' ('+record.company_name+')' : '') : record.email_address}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    title="Status wijzigen"
                    visible={this.state.show_status_modal}
                    onCancel={() => {
                        this.setState({ show_status_modal: false });
                        this.props.form.resetFields();
                    }}
                    cancelText="Annuleren"
                    okButtonProps={{
                        form: 'status',
                        type: 'new',
                        key: 'submit',
                        htmlType: 'submit'
                    }}
                    okText="Opslaan"
                    >
                    <Form
                        id="status"
                        onSubmit={this.handleStatus}
                        >

                        <Row gutter={24}>
                            <Col span={24}>

                                <Form.Item
                                    label="Status"
                                    hasFeedback
                                    >
                                    {getFieldDecorator('status', {
                                        rules: [{ required: true, message: true }],
                                    })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een status"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="open">Open</Option>
                                        <Option value="submitted">Controleren</Option>
                                        <Option value="completed">Afgehandeld</Option>
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                    </Form>
                </Modal>

                { this.state.point_modal ? <Drawer
                    title={this.state.modal_title}
                    placement={'right'}
                    className="point-details"
                    width={parseInt(window.width / 3)}
                    headerStyle={{ position: 'absolute', width: '100%', zIndex: 9999 }}
                    bodyStyle={{ paddingTop: 70 }}
                    visible={this.state.point_modal}
                    onClose={() => { this.closePoint(true); }}
                    >
                    <DeliverPoint
                        childRef={(ref) => { this.point_ref = ref; }}
                        project_id={this.props.match.params.id}
                        point_id={this.state.point_id}
                        point_parent_id={this.state.point_parent_id}
                        leaflet={true}
                        subpoint={false}
                        closePoint={this.closePoint}
                        somethingChanged={this.somethingChanged}
                        refreshData={this.refreshData}
                        addTo={'delivery'}
                        />
                </Drawer> : null }

            </div>
        );
    }
}

const DeliverPointsForm = Form.create({ name: 'points' })(DeliverPoints);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        points : state.deliver.points,
        nr_of_new : state.deliver.nr_of_new,
        nr_of_open : state.deliver.nr_of_open,
        nr_of_submitted : state.deliver.nr_of_submitted,
        nr_of_completed : state.deliver.nr_of_completed,
        nr_of_sub_open : state.deliver.nr_of_sub_open,
        nr_of_sub_submitted : state.deliver.nr_of_sub_submitted,
        nr_of_sub_completed : state.deliver.nr_of_sub_completed,
        users : state.deliver.users,
        delivery_filters : state.deliver.delivery_filters,
        form_filters : state.deliver.form_filters,
        location_filters : state.deliver.location_filters,
        user_filters : state.deliver.user_filters,
        addresses: state.address.addresses,
        groups: state.group.groups,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPointsAction : bindActionCreators(getPointsAction, dispatch),
        searchPointsAction : bindActionCreators(searchPointsAction, dispatch),
        exportPointsAction : bindActionCreators(exportPointsAction, dispatch),
        archivePointsAction : bindActionCreators(archivePointsAction, dispatch),
        deletePointsAction: bindActionCreators(deletePointsAction, dispatch),
        resetPointsAction : bindActionCreators(resetPointsAction, dispatch),
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        multiUpdatePointsAction : bindActionCreators(multiUpdatePointsAction, dispatch),
        getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
        getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        getUserFilterAction : bindActionCreators(getUserFilterAction, dispatch),
        addPointsToDeliveryFormAction : bindActionCreators(addPointsToDeliveryFormAction, dispatch),
        getPointsDeliveriesAction : bindActionCreators(getPointsDeliveriesAction, dispatch),
        getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
        getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverPointsForm);
