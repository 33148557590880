import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './Standalone.css';
import Logo from '../../assets/img/logo_zwart.svg';
import { Layout, Col, Row, Button } from 'antd';

class KypOauth extends Component {

  state = {
  }

  componentDidMount()
  {
    console.log('Kyp OAUTH response', this.props.location);
  }

  render() 
  {
    return (
      <div className="StandaloneScreen" style={{ maxHeight: '100vh', overflow: 'hidden', width: '100%' }}>

        <Layout style={{ backgroundColor: 'white' }}>

          <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" align="middle">

            <Col span={24} type="flex" align="middle">
            
              <div style={{ maxWidth: '400px', padding: 24 }}>

                <img width="200px" height="auto" alt="Cobee" src={Logo} style={{ width: 200, marginBottom: 50 }}></img><br />

                <span style={{ fontSize: 20, fontWeight: 'bold'}}>TODO: Kyp OAUTH handling</span><br />

              </div>

            </Col>

          </Row>

        </Layout>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(KypOauth);
