import React, { Component } from 'react';
// import '../Customers.css';
import CustomerSideMenu from './CustomerSideMenu';
import { connect } from 'react-redux';
import { zipcodeValidation, urlValidation } from '../../../utils/validationRules';
import { bindActionCreators } from 'redux';
import { getCustomerAction, patchCustomerAction } from '../../../actions/customerActions';
import { showSuccess } from '../../../utils/Notifications'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Typography,
  Button,
  Avatar,
  Divider,
  Switch,
  Input,
  Upload,
  message,
} from 'antd';
import { SketchPicker, ChromePicker, TwitterPicker } from 'react-color';

import {API_URL} from '../../../constants/settings';

import PageHeader from '../../../components/PageHeader/PageHeader';

import AddFromLibrary from '../../../components/AddFromLibrary/AddFromLibrary';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';

const { Title, Text } = Typography;

class CustomerPersonalize extends Component {
  state = {
    edit: true,
    previewVisible: false,
    previewImage: '',
    filelist: [],
    showcolor: false,
    color: '#00000'
  };

  componentDidMount() {
    this.props.getCustomerAction(this.props.match.params.id).then(() => {
      this.setState({color: this.props.customer.color_primary});
      this.setState({use_personal_login_page: this.props.customer.use_personal_login_page});
    });
  }

  handleSubmit = e => {
    e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.patchCustomerAction(this.props.match.params.id, values, this.state.filelist).then(() => {
            showSuccess();
          });
        }
      });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  uploadLogo = (file) => {
    console.log(file);
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': 'Klanten', 'link': '/customers'}, {'label': this.props.customer.name}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <CustomerSideMenu id={this.props.match.params.id} activeMenu='2' />

          <Form layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={12} >
                <Button type="new" onClick={this.handleSubmit}>
                  Opslaan
                </Button>
                <Button onClick={() => this.props.history.push('/customers')}>Sluiten</Button>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Huisstijl</Title>
              </Col>
              <Col span={12}>
                <Form.Item label="Logo">
                    {getFieldDecorator('logo_id', {
                      initialValue: this.props.customer.logo_id,
                    })(
                      <AddFromMedia
                        hideProjectBrowser={true}
                        initialMediaId={this.props.customer.logo_id}
                        initialMediaUrl={this.props.customer.logo_url}
                        onMediaSelection={(item) => this.props.form.setFieldsValue({'logo_id': item.id})}
                        onMediaRemove={() => this.props.form.setFieldsValue({'logo_id': ''})}
                      />
                    )}
                </Form.Item>
              </Col>
              {/*<Col span={12}>
                  <Form.Item required label="Huisstijlkleur">
                    {getFieldDecorator('color_primary', {
                      initialValue: this.props.customer.color_primary,
                      rules: [{ required: true, message: 'Vul een HEX-waarde in' }, { min:6, message: 'Vul minimaal 6 karakters in'}, { max:6, message: 'Vul maximaal 6 karakters in'}],
                    })(
                      <Input maxLength={6} addonBefore="#"></Input>
                    )}
                  </Form.Item>
              </Col>*/}
              <Col span={12}>
                  <Form.Item required label="Huisstijlkleur" hasFeedback>
                    {getFieldDecorator('color_primary', {
                        initialValue: this.props.customer.color_primary,
                        rules: [{ required: true, message: 'Vul een omschrijving in' }],
                      })(
                          <Input prefix={(<span style={{marginLeft: -3, paddingRight: 5, width:15, height: 15, background: this.state.color, borderRadius: 3}}></span>)} onFocus={() => this.setState({showcolor: true})} onBlur={() => this.setState({showcolor: true})}></Input>
                        )}

                        {this.state.showcolor && (
                          <div style={{position: 'absolute', zIndex: '2'}}>
                            <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}} onClick={() => this.setState({showcolor: false})} />
                              <TwitterPicker colors={['#f5222d', '#fa8c16', '#fadb14', '#a0d911', '#52c41a', '#13c2c2', '#1890ff', '#2f54eb', '#722ed1', '#eb2f96']} style={{position: 'absolute'}} color={this.state.color ? this.state.color : ''} onChangeComplete={(color) => {
                                this.setState({color: color ? color.hex : ''});
                                this.props.form.setFieldsValue({'color_primary': color ? color.hex : ''});
                              }} />
                          </div>
                        )}
                  </Form.Item>
                </Col>
              <Col span={12}>
                  <Form.Item label="Envelop ID">
                    {getFieldDecorator('envelope_id', {
                      initialValue: this.props.customer.envelope_id,

                    })(
                      <Input maxLength={6}></Input>
                    )}
                  </Form.Item>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>SMS</Title>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Standaard afzender">
                    {getFieldDecorator('sms_sender', {
                      initialValue: this.props.customer.sms_sender,
                      rules: [{ required: true, message: 'Vul een standaard afzender in' }],
                    })(
                      <Input maxLength={11}></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Standaard afzender</Text>
                    <br />
                    <Text type="secondary">{this.props.customer.sms_sender}</Text>
                  </div>
                )}
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Inlogpagina</Title>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Voor gebruikers">
                    {getFieldDecorator('login_url_admins', {
                      initialValue: this.props.customer.login_url_admins  ? this.props.customer.login_url_admins : 'login.cobee.nl',
                      rules: [{ validator: urlValidation }],
                    })(
                      <Input addonBefore="https://"></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Voor gebruikers</Text>
                    <br />
                    <Text type="secondary">{this.props.customer.login_url_admins}</Text>
                  </div>
                )}
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Voor bewoners">
                    {getFieldDecorator('login_url_occupants', {
                      initialValue: this.props.customer.login_url_occupants ? this.props.customer.login_url_occupants : 'login.cobee.nl',
                      rules: [{ validator: urlValidation }],
                    })(
                      <Input addonBefore="https://"></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Voor bewoners</Text>
                    <br />
                    <Text type="secondary">{this.props.customer.login_url_occupants}</Text>
                  </div>
                )}
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={12}>
                <h2>Persoonlijke login pagina</h2>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                  <Form.Item label="">
                    {getFieldDecorator('use_personal_login_page', {
                      valuePropName: 'checked',
                      initialValue: this.props.customer.use_personal_login_page > 0,
                      onChange: (value) => this.setState({use_personal_login_page: (value ? true : false)})
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
            </Row>

            <div style={{display: this.state.use_personal_login_page ? 'block' : 'none'}}>
                <Row gutter={12}>
                  <Col span={12}>
                      <Form.Item label="Domein">
                        {getFieldDecorator('login_page_domain', {
                          initialValue: this.props.customer.login_page_domain
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item label="Afbeelding">
                          {getFieldDecorator('login_page_image_id', {
                            initialValue: this.props.customer.login_page_image_id,
                          })(
                            <AddFromMedia
                              hideProjectBrowser={true}
                              initialMediaId={this.props.customer.login_page_image_id}
                              initialMediaUrl={this.props.customer.login_page_image_url}
                              onMediaSelection={(item) => this.props.form.setFieldsValue({'login_page_image_id': item.id})}
                              onMediaRemove={() => this.props.form.setFieldsValue({'login_page_image_id': ''})}
                            />
                          )}
                      </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={12}>
                      <Form.Item label="Toon standaard inlogformulier">
                        {getFieldDecorator('login_page_use_cobee_form', {
                          valuePropName: 'checked',
                          initialValue: this.props.customer.login_page_use_cobee_form > 0
                        })(
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            />
                        )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Toon Azure SSO knop">
                      {getFieldDecorator('login_page_use_azure_sso', {
                        valuePropName: 'checked',
                        initialValue: this.props.customer.login_page_use_azure_sso > 0
                      })(
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={12}>
                      <Form.Item label="IDP Naam">
                        {getFieldDecorator('azure_sso_idp_name', {
                          initialValue: this.props.customer.azure_sso_idp_name
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item label="IDP entity ID">
                        {getFieldDecorator('azure_sso_idp_entity_id', {
                          initialValue: this.props.customer.azure_sso_idp_entity_id
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={12}>
                      <Form.Item label="IDP SSO Url">
                        {getFieldDecorator('azure_sso_idp_sso_url', {
                          initialValue: this.props.customer.azure_sso_idp_sso_url
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item label="IDP SL Url">
                        {getFieldDecorator('azure_sso_idp_sl_url', {
                          initialValue: this.props.customer.azure_sso_idp_sl_url
                        })(
                          <Input />
                        )}
                      </Form.Item>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={24}>
                      <Form.Item label="IDP x509 certificaat">
                        {getFieldDecorator('azure_sso_idp_x509_certificate', {
                          initialValue: this.props.customer.azure_sso_idp_x509_certificate
                        })(
                          <Input rows={4} />
                        )}
                      </Form.Item>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={24}>
                      <Form.Item label="Azure App Url">
                        {getFieldDecorator('azure_sso_app_url', {
                          initialValue: this.props.customer.azure_sso_app_url
                        })(
                          <Input rows={4} />
                        )}
                      </Form.Item>
                  </Col>
                </Row>
            </div>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>App</Title>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <Form.Item label="Eigen app">
                    {getFieldDecorator('has_app', {
                      valuePropName: 'checked',
                      initialValue: this.props.customer.has_app ? true : false
                    })(
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Eigen app</Text>
                    <br/>
                    <Switch
                      checked={this.props.customer.has_app ? true : false}
                      disabled={this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  </div>
                )}
              </Col>
              {this.props.form.getFieldValue('has_app') ? (
              <Col span={12}>
                  <Form.Item label="Naam app">
                    {getFieldDecorator('app_name', {
                      initialValue: this.props.customer.app_name,
                      rules: [{ required: true, message: 'Vul een app naam in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
              </Col>
              ) : null}
              {this.props.form.getFieldValue('has_app') ? (
              <Col span={12}>
                  <Form.Item label="Apple app store link">
                    {getFieldDecorator('apple_app_store_link', {
                      initialValue: this.props.customer.apple_app_store_link,
                      rules: [{ required: true, message: 'Vul een url voor de Apple app store in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
              </Col>
              ) : null}
              {this.props.form.getFieldValue('has_app') ? (
              <Col span={12}>
                  <Form.Item label="Google app store link">
                    {getFieldDecorator('google_app_store_link', {
                      initialValue: this.props.customer.google_app_store_link,
                      rules: [{ required: true, message: 'Vul een url voor de Google app store in' }],
                    })(
                      <Input></Input>
                    )}
                  </Form.Item>
              </Col>
              ) : null}
            </Row>
            {/** <Divider style={{margin: '8px 0'}}></Divider>
          <Row className="customerPaper">
          <List header="Briefpapier" size="small" itemLayout="horizontal" grid={{gutter: 8, column: 2}} >
          <List.Item>
          <List.Item.Meta
          title="Voorblad"
          description={
          <Button>
          <Icon type="paper-clip" /> Voorblad.pdf
          </Button>
          }
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Vervolgblad"
          description="Geen vervolgblad geüpload"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Marge adresvak bovenzijde (mm)"
          description="40"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Marge adresvak linkerzijde (mm)"
          description="0"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Marge brief boven (mm)"
          description="20"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Marge brief rechts (mm)"
          description="20"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Marge brief onder (mm)"
          description="20"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Marge brief links (mm)"
          description="20"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Extra marge bovenzijde vervolgpagina (mm):"
          description="0"
          />
          </List.Item>
          <List.Item>
          <List.Item.Meta
          title="Enveloppe ID"
          description="105"
          />
          </List.Item>
          </List>
          </Row> **/}
        </Form>
      </div>
    </div>
    );
}
}

const CustomerPersonalizeForm = Form.create({ name: 'customer_personalize' })(CustomerPersonalize);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customer: state.customer.customer,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    patchCustomerAction : bindActionCreators(patchCustomerAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPersonalizeForm);
