import React, { Component } from 'react';
// import './Appointments.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import { getAppointmentsStatusAction, getAppointmentsAction, searchAppointmentsStatusAction } from '../../../../actions/appointmentsActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { API_URL } from '../../../../constants/settings';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import AppointmentsSideMenu from './AppointmentsSideMenu';
import { Row, Col, Card, Typography, Input, Upload, Tag, Button, Drawer, Modal, Popconfirm, message, Menu,  Dropdown,  Search } from 'antd';

class AppointmentsStatus extends Component {

  state = {
    all_open: false,
    expandedRowKeys: [],
  };

  componentDidMount()
  {
      this.props.getAppointmentsStatusAction(this.props.match.params.id);

      this.props.getAppointmentsAction(this.props.match.params.id);
  }

  toggleStatus()
  {
      var toggle_ids = [];

      if(!this.state.all_open)
      {
          this.props.status.map((obj) => {
              toggle_ids.push(obj.id);
          });

          this.setState({
              expandedRowKeys: toggle_ids,
              all_open: true
          });
      }
      else
      {
          this.setState({
              expandedRowKeys: [],
              all_open: false
          });
      }
  }

  onSearch(value) {
      this.props.searchAppointmentsStatusAction(this.props.match.params.id, { 'search': value });
  }

  render()
  {
      const columns = [
          {
              title: 'Adres',
              dataIndex: 'full_address'
          },
          {
              title: 'Afspraak',
              dataIndex: 'appointment_name',
              filters: this.props.appointments && this.props.appointments.map((elem) => {
                return {
                  text: elem.subject,
                  value: elem.id,
                }
              }),
              filterMultiple: false,
              onFilter: (value, record) => ((record.appointment_id && record.appointment_id === value) || (record.appointment_ids && record.appointment_ids.includes(value))),
          },
          {
              title: 'Datum',
              dataIndex: 'date'
          },
          {
              title: 'Dagdeel',
              dataIndex: 'appointment_daypart_name'
          },
          {
              title: 'Tijdstip',
              dataIndex: 'time',
              render: (text, record) => {
                  return (text ? text.substring(0, 5) : '');
              }
          },
          {
              title: 'Bevestigd',
              dataIndex: 'confirmed'
          }
      ];

      return (

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'3'} />

            <div style={{padding: 24, width: '100%'}}>

                <Row className="mbm">
                    <Col span={12}>
                        <Button onClick={(e) => { this.toggleStatus(); }}>{this.state.all_open ? 'Alles dichtklappen' : 'Alles openklappen'}</Button>
                    </Col>
                    <Col span={12}>
                        <Input.Search
                            allowClear
                            placeholder="Zoeken..."
                            onSearch={(value) => { this.onSearch(value); }}
                            style={{ width: 200, float: 'right' }}
                            />
                    </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CustomTable
                      rowKey='id'
                      size="middle"
                      columns={columns}
                      dataSource={this.props.isFetching ? [] : this.props.status}
                      loading={this.props.isFetching}
                      expandedRowKeys={this.state.expandedRowKeys}
                      onExpandedRowsChange={(keys) => {
                          this.setState({expandedRowKeys: keys});
                      }}
                      />
                  </Col>
                </Row>

            </div>
        </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.appointment.isFetching,
      isError : state.appointment.isError,
      status: state.appointment.status,
      appointments: state.appointment.appointments,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getAppointmentsStatusAction: bindActionCreators(getAppointmentsStatusAction, dispatch),
      getAppointmentsAction: bindActionCreators(getAppointmentsAction, dispatch),
      searchAppointmentsStatusAction: bindActionCreators(searchAppointmentsStatusAction, dispatch),
      HaveAbility: bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsStatus);
