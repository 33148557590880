import React, { Component, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Alert, Checkbox, Switch, Button, Tag, Row, Col, Input } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLibrariesAction, getLibraryAction, resetLibraryAction, getLibraryCategoriesAction, searchLibrariesAction } from '../../actions/librariesActions';
import api from '../../utils/api';
import CustomTable from '../../components/CustomTable/CustomTable';
import AddFromMediaModal from '../AddFromMedia/AddFromMediaModal';
import { Editor } from '@tinymce/tinymce-react';
import { API_URL } from '../../constants/settings';

class CustomEditor extends Component {

  state = {
    editor: false,
    visible: false,
    libraryTextVisible: false,
    libraryId: undefined,
    group_filter: [],
    // value: ''
  }

  componentDidMount() {

      //console.log('project', this.props);

      //this.setState({ value: this.props.initialValue ? this.props.initialValue : '- leeg -' });
  }

  openMedia = () => {
    this.setState({ visible: true });
  }

  openLibraryText = () => {
    this.setState({ libraryTextVisible: true });
  }

  openQRCode = () => {
    this.setState({ addQRCode: true });
  }

  groupsFilter(value, record)
  {
      if(this.state.group_filter_and == true)
      {
          var all_groups_found = true;

          // if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              // this.state.filteredInfo.groups.map((group_name) => {
              this.state.group_filter.map((group_name) => {
                  var group_found = false;

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });

                  if(!group_found)
                  {
                      all_groups_found = false;
                  }
              });
          }

          return all_groups_found;
      }
      else
      {
          var group_found = false;

          if(this.state.group_filter && this.state.group_filter.length > 0)
          {
              this.state.group_filter.map((group_name) => {

                  record.categories.map((record_group) => {
                      if(record_group.name == group_name)
                      {
                          group_found = true;
                      }
                  });
              });
          }

          return group_found;
      }
  }

  render() {

    // var props = this.props;
    // var state = this.state;
    var placeholders = this.placeholders;

    const text_columns = [{
      title: 'Titel',
      dataIndex: 'title',
      sorter: (a, b) => { return a.title.localeCompare(b.title)},
      render: (text, record) => (
        <span><Link onClick={() => {
            this.setState({libraryId: record.id}, () => {

                this.props.getLibraryAction(this.state.libraryId).then(() => {
                    this.state.editor.insertContent(this.props.library.content);
                    this.setState({libraryTextVisible: false, libraryId: undefined});
                });
            });

        }}>{text}</Link></span>
      )
    },
    {
        title: 'Categorieen',
        dataIndex: '',
        key: 'categories',
        width: 160,
        filterMultiple: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 0 }}>

              <div>
                  {this.props.categories && this.props.categories.map((obj) => {
                      return <div style={{width: '100%', padding: 8, paddingBottom: 4}}>
                          <Checkbox
                              onChange={(e) => {
                                  var my_group_filter = this.state.group_filter;

                                  if(e.target.checked == true){ my_group_filter.push(obj.name); }
                                  else{ this.removeItem(my_group_filter, obj.name); }

                                  this.setState({ group_filter: my_group_filter });

                                  setSelectedKeys(my_group_filter);
                              }}
                              checked={this.state.group_filter.indexOf(obj.name) >= 0 ? true : false}
                              >
                              {obj.name}
                          </Checkbox>
                      </div>
                  })}
              </div>

              <div style={{padding: 8, width: '100%', clear: 'both'}}>
                  <Switch checkedChildren="EN" unCheckedChildren="OF" defaultChecked style={{float: 'left'}} onChange={(checked) => { this.setState({ group_filter_and: checked }) }} />
                  <div style={{float: 'left', marginLeft: 8}}>filter</div>
              </div>

              <div style={{width: '100%', clear: 'both'}}>
                  <Button
                    type="link"
                    onClick={() => { confirm(); }}
                    style={{marginLeft: -8, marginRight: 8 }}
                    >OK</Button>

                  <Button onClick={() => { clearFilters(); this.setState({ group_filter: [], group_filter_and: true }); }} type="link" style={{marginRight: -8}}>Reset</Button>
              </div>

            </div>
        ),
        onFilter: (value, record) => this.groupsFilter(value, record),
        render: (text, record) =>
        record.categories && record.categories.map((v) => (<Tag key={v.id}>{v.name}</Tag>)
        )
    }];

    var this_obj = this;

    return (
      <div>

        <Modal
          title="Bibliotheek"
          visible={this.state.libraryTextVisible}
          width={980}
          onCancel={() => this.setState({libraryTextVisible: false})}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <Form layout={'vertical'}>

            <div>

                <Row className="mbm">
                  <Col span={24}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ (value, event) => { event.preventDefault(); this.props.searchLibrariesAction(value); return false; } }
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey="id"
                      columns={text_columns}
                      loading={this.props.isFetching}
                      dataSource={this.props.isFetching ? [] : this.props.libraries}
                      pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: false
                      }}
                      />
                  </Col>
                </Row>

            </div>

          </Form>

        </Modal>

      <Modal
        title="QR-code toevoegen"
        visible={this.state.addQRCode}
        width={600}
        onCancel={() => { this.setState({ addQRCode: false }); }}
        onOk={() => {
            if(this.state.qrcode_url)
            {
                this.state.editor.insertContent('<a href="'+this.state.qrcode_url+'" target="_blank"><img width="160" src="'+API_URL+'/qr-code/url/?url='+encodeURIComponent(this.state.qrcode_url)+'" /></a>');
            }

            this.setState({
                qrcode_url: '',
                addQRCode: false
            });
        }}
        okText={'Toevoegen'}
        // okButtonProps={{ style: { display: 'none' } }}
        >
        <Form layout={'vertical'}>
          <div>
              <Row className="mbm">
                <Col span={24}>
                  <Input
                    placeholder="Link"
                    onChange={(e) => { this.setState({ qrcode_url: e.target.value }); }}
                    style={{ width: '100%' }}
                    />
                </Col>
              </Row>
          </div>
        </Form>

      </Modal>

        <AddFromMediaModal
          projectId={this.props.project_id ? this.props.project_id : false}
          onMediaSelection={(image) => {
            this.state.editor.insertContent('<img width="500" src="'+image.image+'" />');
            this.setState({visible: false});
          }}
          visible={this.state.visible}
          cancel={() => this.setState({visible: false})}
        />

        <Editor
          ref={'editor'}
          apiKey="ykuo266tcg7sy76iy9h1qm2z8ychxu7h4pfik1n7edei7uv8"
          id={this.props.id || ''}
          init={
            {
              language: 'nl',
              language_url: '/langs/nl.js',
              extended_valid_elements: 'placeholder[id|class]',
              custom_elements: '~placeholder',
              toolbar_sticky: true,
              toolbar_sticky_offset: 64,
              setup:(editor) => {

                if(this_obj.props.messageChanged)
                {
                    editor.on('change', function (event) {

                        this_obj.props.messageChanged(editor.getContent());
                    });
                }

                editor.on('ExecCommand', function checkListNodes(evt) {
                   let cmd = evt.command;
                   if (cmd === 'FontSize' || cmd === 'FontName') {
                      let val = evt.value;
                      let node = evt.target.selection.getNode();
                      let nodeParent = node.parentNode;
                      if (node.nodeName === 'SPAN' && nodeParent.nodeName === 'LI') {
                          if (cmd === 'FontSize') {
                              editor.dom.setStyle(nodeParent, 'font-size', val);
                          }
                          if (cmd === 'FontName') {
                             editor.dom.setStyle(nodeParent, 'font-family', val);
                          }
                       } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
                          let li = editor.dom.select('li', node);
                          if (cmd === 'FontSize') {
                              editor.dom.setStyle(li, 'font-size', val);
                          }
                          if (cmd === 'FontName') {
                              editor.dom.setStyle(li, 'font-family', val);
                           }
                      }
                   }
                });

                //
                // var $ = tinymce.dom.DomQuery;
              	var nonEditableClass = editor.getParam('noneditable_noneditable_class', 'mceNonEditable');

                editor.on('BeforeExecCommand', function (e) {
                    editor.dom.setAttrib(editor.dom.select('placeholder'), 'class', '');
                    editor.dom.setAttrib(editor.dom.select('placeholder'), 'contenteditable', true);
                });

                // Turn the contenteditable attribute back to false after the command has executed
                editor.on('ExecCommand', function (e) {
                  editor.dom.setAttrib(editor.dom.select('placeholder'), 'class', 'cobee-tag');
                  editor.dom.setAttrib(editor.dom.select('placeholder'), 'contenteditable', false);
                });

                var items = [];

                api('get', '/wysiwyg/placeholders/'+(this.props.placeholders && this.props.placeholders !== true ? this.props.placeholders : 'formbuilder')+'/'+(this.props.project_id ? this.props.project_id : (!this.props.placeholders || this.props.placeholders === true ? this.props.project.id : 'null'))+(this.props.customer_id ? '/'+this.props.customer_id : '/'+this.props.project.customer_id), null, true).then((data) => {
                    items = eval(data.data);
                });

                this.setState({editor});
                  editor.ui.registry.addMenuButton('placeholders', {
                  text: 'Placeholders',
                  fetch: function(callback){ callback(items); }
                });

                editor.ui.registry.addButton('addMedia', {
                  icon: 'image',
                  onAction: (_) => {
                    this.openMedia();
                  }
                });

                editor.ui.registry.addButton('addLibraryText', {
                  icon: 'search',
                  text: 'teksten',
                  onAction: (_) => {
                    this.props.getLibraryCategoriesAction('text');
                    this.props.getLibrariesAction();
                    this.props.resetLibraryAction();
                    this.openLibraryText();
                  }
                });

                editor.ui.registry.addButton('addQRCode', {
                  icon: 'select-all',
                  text: 'QR-code',
                  onAction: (_) => {
                    this.openQRCode();
                  }
                });
              },
              height: (this.props.height ? this.props.height : (this.props.document ? 1000 : 600)),
              toolbar_mode: 'Wrap',
              width: this.props.width ? this.props.width : '100%',
              spellchecker_language: 'nl',
              statusbar: false,
              content_css : [
                this.props.document ? "document" : "",
                "/tiny.css"
              ],
              menubar: false,
              fontsize_formats: "8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt",
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount imagetools',
                'noneditable tinymcespellchecker'
              ],
              noneditable_noneditable_class: 'cobee-tag', // the cobee-tag class is for noneditable element(s)
              // noneditable_regexp: /(placeholder)/g, // matches 123-456-789
              toolbar: this.props.placeholders == 'sms' || this.props.placeholders == 'appointment-sms' ?
                `undo redo | spellcheckdialog | placeholders`
                :
                `undo redo | formatselect |${this.props.showFonts ? ' fontselect |' : ''} fontsizeselect | bold italic underline backcolor forecolor | \
                alignleft aligncenter alignright alignjustify | spellcheckdialog | \
                bullist numlist outdent indent | removeformat | link QR-code table code |${this.props.hideMedia === true ? '' : ' addMedia'}${this.props.hideVideo === true ? '' : ' media'}${this.props.hideLibraryText === true ? '' : ' addLibraryText'}${this.props.placeholders != false ? ' placeholders' : ''} addQRCode`,
              // font_formats: 'Arial=arial,helvetica,sans-serif,serif; DejaVu=dejavusans; Mono=freemono'
              font_formats: 'Calibri;Arial;OpenSans=opensans;TimesNewRoman;Helvetica;Verdana;Georgia;PT Sans=pt-sans'
            }
          }
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      libraries: state.library.libraries,
      library: state.library.library,
      project: state.project.project,
      customer: state.customer.customer,
      categories : state.library.categories
    };
  }

const mapDispatchToProps = (dispatch) => {
  return {
    getLibrariesAction : bindActionCreators(getLibrariesAction, dispatch),
    getLibraryAction : bindActionCreators(getLibraryAction, dispatch),
    resetLibraryAction : bindActionCreators(resetLibraryAction, dispatch),
    getLibraryCategoriesAction : bindActionCreators(getLibraryCategoriesAction, dispatch),
    searchLibrariesAction : bindActionCreators(searchLibrariesAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEditor);
