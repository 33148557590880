import React from 'react'
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Typography, Upload, Input, Select, Switch, Button, Drawer, Popconfirm, message, Divider, Steps, Slider, PageHeader, Tag, Result } from 'antd';

class AppointmentSteps extends React.Component {

  onChange = current => {

    if(current == 0) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/detail`);
    }

    if(current == 1) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/addresses`);
    }

    if(current == 2) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/availability`);
    }

    if(current == 3) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/clusters`);
    }

    // if(current == 3) {
    //   this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/data`);
    // }

    if(current == 4) {
      this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/overview`);
    }
    // if(current == 4) {
    //   this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/agenda`);
    // }

  }

  render () {
    return (
      <Steps type="navigation" size="small" current={this.props.current} onChange={this.onChange} style={{display: 'block'}}>
        <Steps.Step style={{padding: 10, marginLeft: 25 }} title="Instellingen" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.appointmentid ? false : true} title="Adressen" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.appointmentid ? false : true} title="Plannen" />
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.appointmentid ? false : true} title="Clusters" />
        {/*<Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.appointmentid ? false : true} title="Data (vervalt bij gebruik van verantwoordelijken)" />*/}
        <Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.appointmentid ? false : true} title="Afspraken" />
        {/*<Steps.Step style={{padding: 10, marginLeft: 25 }} disabled={this.props.match.params.appointmentid ? false : true} title="Agenda" />*/}
      </Steps>
    );
  }
}

export default AppointmentSteps;
