export default (
    state = {
        isError: false,
        isFetching: false,
        appointments: [],
        appointment: {
            dayparts: [],
            addresses: [],
            availability: []
        },
        dayparts: [],
        scheduled: [],
        work_days: [],
        available_times: {},
        cluster_timeslots: [],
        addresses: [],
        first_date: null,
        clusters: [],
        users: [],
        first_date: '',
        free_slots: [],
        cluster_users: [],
        address_occupants: [],
        cluster_addresses: [],
        appointments_users: [],
        appointment_user: {
            availability: []
        },
        status: null,
        warnings: [],
        notifications: [],
        labels_filter: [],
        clusters_filter: [],
        users_filter: [],
        appointment_dates_filter: [],
        appointment_times_filter: [],
        appointment_users_filter: [],
        appointments_filter: [],
        appointments_users_filter: [],
        nr_of_addresses: 0,
        nr_of_slots: 0,
    },
action) => {

    switch (action.type) {
    case 'APPOINTMENTS_ACTION_FETCH':
    return {
        ...state,
        appointments: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENT_ACTION_FETCH':
    return {
        ...state,
        appointment: { dayparts: [] },
        addresses: [],
        users: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENTS_ACTION_SUCCESS':
    return {
        ...state,
        appointments: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_ACTION_SUCCESS':
    return {
        ...state,
        appointment: action.payload.appointment,
        dayparts: action.payload.appointment.dayparts ? action.payload.appointment.dayparts : [],
        clusters: action.payload.clusters,
        scheduled: action.payload.schedule,
        users: action.payload.users,
        addresses: action.payload.addresses,
        isFetching: false,
        isError: false,
        warnings: action.payload.warnings,
        notifications: action.payload.notifications,
    }
    case 'APPOINTMENT_UPDATE_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        warnings: action.payload.warnings,
        notifications: action.payload.notifications,
    }
    case 'APPOINTMENT_UPDATE_USER_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        warnings: action.payload.warnings,
        notifications: action.payload.notifications,
    }
    case 'APPOINTMENT_DELETE_USERS_UPDATE_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        warnings: action.payload.warnings,
        notifications: action.payload.notifications,
    }
    case 'APPOINTMENT_SCHEDULE_ACTION_SUCCESS':
    return {
        ...state,
        first_date: action.payload.first_date,
        scheduled: action.payload.scheduled,
        clusters: action.payload.clusters,
        work_days: action.payload.work_days,
        available_times: action.payload.available_times,
        cluster_timeslots: action.payload.cluster_timeslots,
        first_date: action.payload.first_date,
        cluster_addresses: action.payload.cluster_addresses,
        dayparts: action.payload.dayparts,
        appointment: action.payload.appointment,
        isFetching: false,
        isError: false,
    }
    case 'SCHEDULE_APPOINTMENT_FETCH':
    return {
        ...state,
        appointment: {},
        addresses: [],
        labels_filter: [],
        clusters_filter: [],
        users_filter: [],
        isFetching: true,
        isError: false,
    }
    case 'SCHEDULE_APPOINTMENT_SUCCESS':
    return {
        ...state,
        appointment: action.payload.appointment,
        addresses: action.payload.addresses,
        labels_filter: action.payload.labels_filter,
        clusters_filter: action.payload.clusters_filter,
        users_filter: action.payload.users_filter,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_FREESLOTS_ACTION_FETCH':
    return {
        ...state,
        free_slots: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENT_FREESLOTS_ACTION_SUCCESS':
    return {
        ...state,
        free_slots: action.payload.free_slots,
        cluster_users: action.payload.cluster_users,
        address_occupants: action.payload.occupants,
        appointment_dates_filter: action.payload.dates_filter,
        appointment_times_filter: action.payload.times_filter,
        appointment_users_filter: action.payload.users_filter,
        isFetching: false,
        isError: false,
    }
    case 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'UPDATE_APPOINTMENT_SCHEDULE_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'ADD_APPOINTMENT_ACTION_SUCCESS':
    return {
        ...state,
        appointments: [action.payload, ...state.appointments],
        appointment: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_REMOVE_SUCCESS':
    return {
        ...state,
        appointments: state.appointments.filter((v, k) => action.payload.ids.indexOf(''+v.id) === -1),
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENTS_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'APPOINTMENT_ACTION_RESET':
    return {
        ...state,
        appointment: {},
        dayparts: [],
        isFetching: false,
        isError: false,
        warnings: [],
        notifications: [],
    }
    case 'APPOINTMENT_ACTION_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'ADD_DAYPART_ACTION':
    return {
        ...state,
        dayparts: [...state.dayparts, {'name': '', 'from_time': '', 'till_time': '', 'max_nr_of_appointments': null}].filter(Boolean),
    }
    case 'APPOINTMENTS_USERS_ACTION_FETCH':
    return {
        ...state,
        appointments_users: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENTS_USERS_ACTION_SUCCESS':
    return {
        ...state,
        appointments_users: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENTS_USER_ACTION_SUCCESS':
    return {
        ...state,
        appointment_user: action.payload,
        isFetching: false,
        isError: false,
    }

    case 'APPOINTMENTS_STATUS_ACTION_FETCH':
    return {
        ...state,
        status: [],
        isFetching: true,
        isError: false,
    }
    case 'APPOINTMENTS_STATUS_ACTION_SUCCESS':
    return {
        ...state,
        status: action.payload,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENT_ADDRESSES_UPDATE_ACTION_SUCCESS':
    return {
        ...state,
        status: action.payload.status,
        isFetching: false,
        isError: false,
        warnings: action.payload.warnings,
        notifications: action.payload.notifications,
    }
    case 'APPOINTMENTS_CALENDAR_ACTION_SUCCESS':
    return {
        ...state,
        appointments: action.payload.appointments,
        appointments_filter: action.payload.appointments_filter,
        appointments_users_filter: action.payload.appointments_users_filter,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENTS_CALENDAR_FILTER_ACTION_SUCCESS':
    return {
        ...state,
        appointments: action.payload.appointments,
        isFetching: false,
        isError: false,
    }
    case 'APPOINTMENTS_CALENDAR_ACTION_FETCH_ERROR':
    return {
        ...state,
        isFetching: false,
        isError: true,
    }
    case 'SLOTS_COUNTER_APPOINTMENT_ACTION_SUCCESS':
    return {
        ...state,
        nr_of_addresses: action.payload.nr_of_addresses,
        nr_of_slots: action.payload.nr_of_slots,
    }
    case 'SLOTS_COUNTER_CLUSTER_ACTION_SUCCESS':
    return {
        ...state,
        nr_of_addresses: action.payload.nr_of_addresses,
        nr_of_slots: action.payload.nr_of_slots,
    }
    case 'RESET_MADE_APPOINTMENTS_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }

    default:
        return state
    }
}
