import React, { Component, cloneElement } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Button, Popconfirm, Tag, Typography, Form, Select, Row, Col } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

class Cluster extends Component {

  // counter = 0;

  constructor(props) {

    super(props);

    this.state = {

      selectedRowKeys: this.props.selected ? this.props.selected : [],
      selectedUsers: this.getSelectedUserIds(),

      filteredInfo: null,

      addresses: [],

      counter: this.props.counter,
    };

    this.counter = this.props.counter;

  }

  componentDidMount() {

      this.setState({ addresses: this.props.addresses });

      this.props.setClusterRef(this, this.props.index);
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys }, () => {

      selectedRowKeys['appointment_user_ids'] = this.state.selectedUsers;
      this.props.onSelect(selectedRowKeys);
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  };

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  getUsers()
  {
      var users = [];

      //
      this.props.users.map((user) => {

          users.push({
              label: user.email_address,
              value: user.id
          });
      });

      //
      return users;
  }

  getSelectedUsers()
  {
      var values = [];

      //
      this.props.users.map((user) => {

          if(this.props.appointment && this.props.appointment.clusters && this.props.appointment.clusters[this.props.index] && this.props.appointment.clusters[this.props.index].appointment_user_ids && this.props.appointment.clusters[this.props.index].appointment_user_ids.includes && this.props.appointment.clusters[this.props.index].appointment_user_ids.includes(user.id))
          {
              values.push({
                  label: user.email_address,
                  value: user.id
              });
          }
      });

      //
      return values;
  }

  getSelectedUserIds()
  {
      var ids = [];

      //
      this.props.users.map((user) => {

          if(this.props.appointment && this.props.appointment.clusters && this.props.appointment.clusters[this.props.index] && this.props.appointment.clusters[this.props.index].appointment_user_ids && this.props.appointment.clusters[this.props.index].appointment_user_ids.includes && this.props.appointment.clusters[this.props.index].appointment_user_ids.includes(user.id))
          {
              ids.push(user.id);
          }
      });

      //
      return ids;
  }

  updateCounter(counter)
  {
      this.setState({ counter: counter });
  }

  render() {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        key: 'sort',
        width: 20,
      },
      {
        title: 'Adres',
        dataIndex: 'full_address',
      },
      // {
      //   title: 'Postcode',
      //   dataIndex: 'zipcode',
      // },
      // {
      //   title: 'Plaats',
      //   dataIndex: 'city',
      // },
      {
          title: 'Groep',
          dataIndex: 'groups',
          filters: this.props.groups && this.props.groups.map((elem) => {
            return {
              text: elem.name,
              value: elem.name,
            }
          }),
          filterMultiple: true,
          // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
          onFilter: (value, record) => this.groupsFilter(value, record),
          render: (text, record) =>
            record.groups && record.groups.map((v) => (
                <Tag color={v.color} key={v.id}>{v.name}</Tag>
          ))
      }
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange : this.onSelectChange,
    };

    const onDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {

        var addresses = this.state.addresses;

        const activeIndex = addresses.findIndex((i) => i.id === active.id);
        const overIndex = addresses.findIndex((i) => i.id === over.id);

        var sorted_addresses = arrayMove(addresses, activeIndex, overIndex);

        sorted_addresses.map((record, index) => {
            record.position = (index + 1);
        });

        this.setState({ addresses: sorted_addresses }, () => {

          this.props.updateAddressesSortOrder(sorted_addresses, this.state.selectedUsers);
        });
      }
    };

    return (
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={this.state.addresses.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
            >
              <Table
                rowKey='id'
                size='middle'
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ paddingLeft: 24, paddingRight: 24, width: '100%' }}>
                      <Row>
                        <Col span={12}>
                            <Typography.Title level={4}>Cluster {this.props.index + 1}</Typography.Title>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <Popconfirm
                            title="Weet je het zeker?"
                            icon={<QuestionCircleOutlined style={{ color: '#f5222d' }} />}
                            onConfirm={this.props.onDeleteCluster}
                            okText="Ja"
                            cancelText="Nee"
                            >
                            <Button type='link' style={{ color: '#f5222d', marginTop: 24 }}>Verwijder</Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                      { this.props.appointment.use_users ? <Form layout='vertical'>
                          <Form.Item label="Verantwoordelijken">
                            <Select
                                mode="multiple"
                                allowClear
                                options={this.getUsers()}
                                defaultValue={this.getSelectedUsers()}
                                onChange={(value) => {
                                    this.setState({ selectedUsers: value });

                                    this.props.setSelectedUsers(value);

                                    // this.counter = this.props.counter;
                                }}
                                style={{ width: '100%' }}
                                />
                          </Form.Item>
                      </Form> : null }
                      {this.state.selectedRowKeys.length} / {this.state.addresses.length} adressen geselecteerd & {this.state.counter} tijdsloten beschikbaar (voor verantwoordelijken)
                    </div>
                  </div>
                )}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.state.addresses.filter((element) => {

                  if(this.state.selectedRowKeys.includes(element.id)) {
                    return true;
                  }

                  return !this.props.exclude.includes(element.id)
                })}
                scroll={{ y: 300 }}
                pagination={false}
                style={{backgroundColor: 'white', borderRadius: '5px', border: '1px solid #e8e8e8', marginBottom: 24 }}
                onChange={(pagination, filters, sorter, extra) => { this.handleTableChange(pagination, filters, sorter); }}
                components={{
                    body: {
                        row: DraggableRow
                    }
                }}
              />
          </SortableContext>
        </DndContext>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.address.isFetching,
    isError : state.address.isError,
    address : state.address.addresses,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cluster);
