import React, { Component } from 'react';
// import './Appointments.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import { getAppointmentsUsersAction } from '../../../../actions/appointmentsActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { API_URL } from '../../../../constants/settings';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import AppointmentsSideMenu from './AppointmentsSideMenu';
import { Row, Col, Card, Typography, Input, Upload, Tag, Button, Drawer, Modal, Popconfirm, message, Menu,  Dropdown,  Search } from 'antd';

class AppointmentsUsers extends Component {

  componentDidMount()
  {
      this.props.getAppointmentsUsersAction(this.props.match.params.id);
  }

  render()
  {
      const columns = [
          {
              title: 'E-mail',
              dataIndex: 'email',
              render: (text, record) => (
                  <Link to={'/projects/'+this.props.match.params.id+'/modules/appointments/person/'+record.id}>{text}</Link>
              )
          },
          {
              title: 'Naam',
              dataIndex: 'name',
              render: (text, record) => (
                  <span>{record.first_name} {record.affix} {record.last_name}</span>
              )
          }
      ];

      return (

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

            <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'2'} />

            <div style={{padding: 24, width: '100%'}}>

                <Row>
                  <Col span={24}>
                    <CustomTable
                      rowKey='id'
                      size="middle"
                      columns={columns}
                      dataSource={this.props.isFetching ? [] : this.props.appointments_users}
                      loading={this.props.isFetching}
                      />
                  </Col>
                </Row>

            </div>
        </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.appointment.isFetching,
      isError : state.appointment.isError,
      appointments_users: state.appointment.appointments_users,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getAppointmentsUsersAction : bindActionCreators(getAppointmentsUsersAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsUsers);
