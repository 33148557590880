import api from '../utils/api'

export const getInvoiceDefaultPricesAction = () => dispatch => {

    dispatch({
        type: 'INVOICE_ACTION_FETCH'
    })

    return api('get', '/invoice/default-prices', null, true).then((response) => {
        dispatch({
            type: 'INVOICE_DEFAULT_PRICES_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'INVOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const getInvoiceSettingsForCustomerAction = (customer_id) => dispatch => {

    dispatch({
        type: 'INVOICE_ACTION_FETCH'
    })

    return api('get', '/invoice/prices/customer/'+customer_id, null, true).then((response) => {
        dispatch({
            type: 'INVOICE_SETTINGS_CUSTOMER_GET_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'INVOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const updateInvoiceSettingsForCustomerAction = (customer_id, invoice_settings) => dispatch => {

    dispatch({
        type: 'INVOICE_ACTION_FETCH'
    })

    return api('post', '/invoice/prices/customer/'+customer_id, { invoice_settings, simplicate_id: invoice_settings.simplicate_id }, true).then((response) => {
        dispatch({
            type: 'INVOICE_SETTINGS_CUSTOMER_UPDATE_ACTION_SUCCESS',
            payload: response.data
        });

        return response;
    }).catch((e) => {
        dispatch({
            type: 'INVOICE_ACTION_ERROR',
        })
        return false;
    });
}

// export const getSimplicateOrganisationsAction = () => dispatch => {

//     dispatch({
//         type: 'INVOICE_ACTION_FETCH'
//     })

//     return api('get', '/simplicate/organisations', null, true).then((response) => {
//         dispatch({
//             type: 'INVOICE_SIMPLICATE_ORGANISATIONS_GET_ACTION_SUCCESS',
//             payload: response.data
//         });

//         return response.data;
//     }).catch((e) => {
//         dispatch({
//             type: 'INVOICE_ACTION_ERROR',
//         })
//         return false;
//     });
// }

export const getExactOrganisationsAction = () => dispatch => {

    dispatch({
        type: 'INVOICE_ACTION_FETCH'
    })

    return api('get', '/exact/organisations', null, true).then((response) => {
        dispatch({
            type: 'INVOICE_EXACT_ORGANISATIONS_GET_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;
    }).catch((e) => {
        dispatch({
            type: 'INVOICE_ACTION_ERROR',
        })
        return false;
    });
}

export const resetCobeeOptionAction = () => dispatch => {
    dispatch({
        type: 'COBEEOPTION_ACTION_RESET'
    })
}
