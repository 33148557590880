import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Alert, Card, Typography, Upload, Input, Select, Switch, Button, Drawer, Popconfirm, message, Divider, Steps, Slider, PageHeader, Tag, Table, Modal } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAppointmentAction, resetAppointmentAction, saveAppointmentAddressesAction } from '../../../../../actions/appointmentsActions';
import { getAddressesAction } from '../../../../../actions/addressesActions';
import { getGroupsAction } from '../../../../../actions/groupsActions';
import { showSuccess } from '../../../../../utils/Notifications';
import PatchAppointmentForm from '../Form/PatchAppointment';
import Cluster from '../Component/Cluster';
import DataForm from '../Form/Data';
import AppointmentsForm from '../Form/Appointments';
import CalendarForm from '../Form/Calendar';
import AppointmentSteps from '../Steps/AppointmentSteps';
import _ from 'underscore';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import AppointmentsSideMenu from '../AppointmentsSideMenu';

class AppointmentAddresses extends Component {

  state = {
      selectedRowKeys: [],
      dry_run_results: false,
      warnings: [],
      notifications: [],
  };

  constructor(props) {

    super(props);
  }

  componentDidMount() {

    this.props.resetAppointmentAction();

    this.props.getAppointmentAction(this.props.match.params.appointmentid).then(() => {

        // var selected_address_ids = [];
        //
        // this.props.appointment.addresses.map((record) => {
        //
        //     selected_address_ids.push(record.id);
        // });
        //
        // console.log('selected address ids:', selected_address_ids);

        this.setState({ selectedRowKeys: this.props.appointment.address_ids });
    });

    this.props.getAddressesAction({ 'projectId': this.props.match.params.id }).then(() => {

      this.props.getGroupsAction({ 'projectId': this.props.match.params.id });
    });
  }

  saveAppointmentAddresses(dry_run = true) {

      this.props.saveAppointmentAddressesAction(this.props.match.params.appointmentid, this.state.selectedRowKeys, dry_run).then(() => {

          if(dry_run)
          {
              if(this.props.warnings.length || this.props.notifications.length)
              {
                  this.setState({
                      dry_run_results: true,
                      warnings: this.props.warnings,
                      notifications: this.props.notifications,
                  });
              }
              else
              {   // no warnings or notifications? save directly
                  this.saveAppointmentAddresses(false);
              }
          }
          else
          {
              showSuccess();

              this.props.history.push(`/projects/${this.props.match.params.id}/modules/appointment/${this.props.match.params.appointmentid}/availability/`);
          }
      });
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys }, () => {});
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
        sortedInfo: sorter,
    });
  }

  groupsFilter(value, record)
  {
      var all_groups_found = true;

      if(this.state.filteredInfo && this.state.filteredInfo.groups && this.state.filteredInfo.groups.length > 0)
      {
          this.state.filteredInfo.groups.map((group_name) => {
              var group_found = false;

              record.groups.map((record_group) => {
                  if(record_group.name == group_name)
                  {
                      group_found = true;
                  }
              });

              if(!group_found)
              {
                  all_groups_found = false;
              }
          });
      }

      return all_groups_found;
  }

  render() {

      const columns = [
        {
          title: 'Adres',
          dataIndex: 'full_address',
        },{
          title: 'Postcode',
          dataIndex: 'zipcode',
        },{
          title: 'Plaats',
          dataIndex: 'city',
        }, {
            title: 'Groep',
            dataIndex: 'groups',
            filters: this.props.groups && this.props.groups.map((elem) => {
              return {
                text: elem.name,
                value: elem.name,
              }
            }),
            filterMultiple: true,
            // onFilter: (value, record) => record.groups.filter((element) => element.name === value).length,
            onFilter: (value, record) => this.groupsFilter(value, record),
            render: (text, record) =>
              record.groups.map((v) => (
                  <Tag color={v.color} key={v.id}>{v.name}</Tag>
            ))
        }
      ];

      const { selectedRowKeys } = this.state;

      const rowSelection = {
        selectedRowKeys,
        onChange : this.onSelectChange,
      };

    return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'1'} />

          <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('appointment', 'write')} type="new" onClick={() => { this.saveAppointmentAddresses(true); }}>Opslaan en volgende</Button>
                  <Link to={`/projects/${this.props.match.params.id}/modules/appointments`}>
                    <Button>Sluiten</Button>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="mbm">
              <Card bodyStyle={{flex: 0, padding: 0, display: 'block'}}>
                <AppointmentSteps current={1} {...this.props} />
              </Card>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Card>
                      <Table
                        rowKey='id'
                        size='middle'
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={this.props.addresses}
                        onChange={(pagination, filters, sorter, extra) => { this.handleTableChange(pagination, filters, sorter); }}
                        />
                </Card>
              </Col>
            </Row>

        </div>

        <Modal
            title="Geselecteerde adressen opslaan"
            visible={this.state.dry_run_results}
            footer={
                <div>
                    <Button onClick={() => { this.setState({ dry_run_results: false }); }}>Annuleren</Button>
                    <Button /*icon={<PlusOutlined />}*/ type="new" style={{ marginLeft: 12 }} onClick={() => { this.saveAppointmentAddresses(false); }}>Opslaan</Button>
                </div>
            }
            onCancel={() => {
                this.setState({ dry_run_results: false });
            }}
            >
            <div style={{whiteSpace: 'pre-line'}}>
                <h2>Weet je zeker dat je de geselecteerde adressen op wilt slaan?</h2>

                { this.state.warnings && this.state.warnings.length > 0 ? <div><h3 style={{ color: 'red'}}>Er hebben zich de volgende waarschuwingen voorgedaan:</h3>{this.state.warnings.join("\r\n")+"\r\n\r\n"}</div> : null }

                { this.state.notifications && this.state.notifications.length > 0 ? <div><h3 style={{ color: 'orange'}}>Er hebben zich de volgende notificaties voorgedaan:</h3>{this.state.notifications.join("\r\n")+"\r\n\r\n"}</div> : null }
            </div>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    appointment: state.appointment.appointment,
    addresses: state.address.addresses,
    groups: state.group.groups,
    warnings: state.appointment.warnings,
    notifications: state.appointment.notifications,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentAction : bindActionCreators(getAppointmentAction, dispatch),
    resetAppointmentAction : bindActionCreators(resetAppointmentAction, dispatch),
    getAddressesAction : bindActionCreators(getAddressesAction, dispatch),
    getGroupsAction : bindActionCreators(getGroupsAction, dispatch),
    saveAppointmentAddressesAction : bindActionCreators(saveAppointmentAddressesAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentAddresses);
