import React, { Component } from 'react';
// import './Appointments.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showMultiDelete, showSuccess } from '../../../../utils/Notifications';
import { getAppointmentsCalendarAction } from '../../../../actions/appointmentsActions';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { API_URL } from '../../../../constants/settings';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import AppointmentsSideMenu from './AppointmentsSideMenu';
import { Row, Col, Card, Typography, Input, Upload, Tag, Button, Drawer, Modal, Popconfirm, message, Menu, Dropdown, Search, Calendar, Select } from 'antd';
import './calendar.css';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';

dayjs.locale('nl');

class AppointmentsCalendar extends Component {

  state = {
    all_open: false,
    expandedRowKeys: [],
    show_appointment: [],
  };

  componentDidMount()
  {
      this.props.getAppointmentsCalendarAction(this.props.match.params.id);
  }

  onPanelChange(value, mode){}

  toggleAppointmentInCalendar(id)
  {
    var status = this.state.show_appointment && this.state.show_appointment[id] ? true : false;

    this.setState({
        // show_appointment: { ...this.state.show_appointment, [id]: !status }, // all can be toggled open or closed
        show_appointment: { [id]: !status }, // only one can be toggled open
    });
  }

  getCalendarCell(date)
  {
      var cell = [];
      var formatted_date = dayjs(date).format('YYYY-MM-DD');

      this.props.appointments && this.props.appointments.map && this.props.appointments.map((appointment) => {

          if(appointment.date == formatted_date)
          {
              cell.push(
                  <div
                    className={'cobee-calendar-cell'}
                    onClick={() => { this.toggleAppointmentInCalendar(appointment.id); }}
                    style={{
                      backgroundColor: appointment.color ? appointment.color : 'rgba(200, 200, 200, 0.33)',
                      display: 'block',
                      marginTop: 5,
                      padding: 6,
                      borderRadius: 6,
                      textAlign: 'left',
                      fontSize: 12,
                      cursor: 'pointer',
                      overflow: this.state.show_appointment && this.state.show_appointment[appointment.id] ? 'inherit' : 'hidden',
                      whiteSpace: this.state.show_appointment && this.state.show_appointment[appointment.id] ? 'inherit' : 'nowrap',
                    }}
                    >
                      <span
                        style={{
                          display: 'block',
                          textOverflow: this.state.show_appointment && this.state.show_appointment[appointment.id] ? 'none' : 'ellipsis',
                          fontWeight: this.state.show_appointment && this.state.show_appointment[appointment.id] ? 'bold' : 'normal',
                          width: 'auto',
                          overflow: 'hidden',
                        }}
                        >
                        { appointment.time /*&& appointment.time.substr && appointment.time.substr(0, 5)*/ } { appointment.full_address }
                      </span>
                      <div style={{ display: this.state.show_appointment && this.state.show_appointment[appointment.id] ? 'block' : 'none' }}>
                        <span>Afspraak: <span style={{ fontWeight: 'bold' }}>{appointment.subject}</span></span><br />
                        <span>Verantwoordelijke: <span style={{ fontWeight: 'bold' }}>{appointment.available_user_name}</span></span>
                      </div>
                  </div>
              );
          }
      });

      //
      return <div style={{ cursor: 'default' }}>{cell.map((item) => { return item })}</div>;
  }

  getAppointmentsFilter()
  {
      var options = [];

      this.props.appointments_filter.map((option) => {

          options.push({
              value: option.value,
              label: <span><span style={{ display: 'inline-block', backgroundColor: option.color, width: 12, height: 12, borderRadius: 3 }}></span> {option.label}</span>
          });
      });

      return options;
  }

  getAppointmentsUsersFilter()
  {
      return this.props.appointments_users_filter;
  }

  filterByAppointment(e)
  {
      this.setState({ filterByAppointment: e }, () => {

          this.applyFilters();
      });
  }

  filterByAppointmentUser(e)
  {
      this.setState({ filterByAppointmentUser: e }, () => {

          this.applyFilters();
      });
  }

  applyFilters()
  {
      var filters = {
          appointment_id: this.state.filterByAppointment,
          appointment_user_id: this.state.filterByAppointmentUser
      };

      this.props.getAppointmentsCalendarAction(this.props.match.params.id, filters);
  }

  downloadICSFile()
  {
      var ics_url = API_URL+'/appointments/'+this.props.match.params.id+'/calendar/download-ics?';

      if(this.state.filterByAppointment)
      {
          ics_url += 'appointment_id='+this.state.filterByAppointment+'&';
      }
      if(this.state.filterByAppointmentUser)
      {
          ics_url += 'appointment_user_id='+this.state.appointment_user_id+'&';
      }

      ics_url += 'token='+localStorage.getItem('authToken');

      //
      window.open(ics_url, '_blank');
  }

  getCalendarURL()
  {
      var calendar_url = API_URL+'/appointments/'+this.props.match.params.id+'/calendar/subscribe-to-ics?';

      if(this.state.filterByAppointment)
      {
          calendar_url += 'appointment_id='+this.state.filterByAppointment+'&';
      }
      if(this.state.filterByAppointmentUser)
      {
          calendar_url += 'appointment_user_id='+this.state.appointment_user_id+'&';
      }

      calendar_url += 'token='+localStorage.getItem('authToken');

      //
      this.setState({ subscribeModal: true, subscribeUrl: calendar_url });

      //
      // window.open(calendar_url, '_blank');
  }

  render()
  {
      return (

        <div style={{ backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden' }}>

            <AppointmentsSideMenu project_id={this.props.match.params.id} activeMenu={'4'} />

            <div className={'appointment-calendar'} style={{padding: 24, paddingTop: 0, width: '100%'}}>

                <Form
                    layout="vertical"
                    >

                    <Row>
                        <Col span={24}>
                            <Calendar
                                onPanelChange={(value, mode) => { this.onPanelChange(value, mode); }}
                                headerRender={({ value, type, onChange, onTypeChange }) => {
                                  const start = 0;
                                  const end = 12;
                                  const monthOptions = [];

                                  let current = value.clone();
                                  const localeData = value.localeData();
                                  const months = [];
                                  for (let i = 0; i < 12; i++) {
                                    current = current.month(i);
                                    // months.push(localeData.monthsShort(current));
                                    months.push(localeData.months(current));
                                  }

                                  for (let i = start; i < end; i++) {
                                    monthOptions.push(
                                      <Select.Option key={i} value={i} className="month-item">
                                        {months[i]}
                                      </Select.Option>,
                                    );
                                  }

                                  const year = value.year();
                                  const month = value.month();
                                  const options = [];
                                  for (let i = year - 10; i < year + 10; i += 1) {
                                    options.push(
                                      <Select.Option key={i} value={i} className="year-item">
                                        {i}
                                      </Select.Option>,
                                    );
                                  }
                                  return (
                                    <div style={{ padding: 8 }}>
                                      <Typography.Title level={4}>Agenda</Typography.Title>
                                      <Row gutter={24}>
                                        <Col span={18}>

                                            <Select
                                                allowClear
                                                placeholder='Filter op afspraak'
                                                options={this.getAppointmentsFilter()}
                                                style={{ marginRight: 12, minWidth: 250, maxWidth: 500 }}
                                                onChange={(e) => { this.filterByAppointment(e); }}
                                                />

                                            <Select
                                                allowClear
                                                placeholder='Filter op verantwoordelijke'
                                                options={this.getAppointmentsUsersFilter()}
                                                style={{ marginRight: 12, minWidth: 250, maxWidth: 500 }}
                                                onChange={(e) => { this.filterByAppointmentUser(e); }}
                                                />

                                            <Dropdown trigger={['click']} disabled={false} overlay={(
                                                <Menu>
                                                  <Menu.Item disabled={false} onClick={() => { this.downloadICSFile(); }} key="1">Download ics bestand</Menu.Item>
                                                  <Menu.Item disabled={false} onClick={() => { this.getCalendarURL(); }} key="2">Abonneren op deze agenda</Menu.Item>
                                                </Menu>
                                              )}>
                                              <Button type="secondary">
                                                Acties <DownOutlined />
                                              </Button>
                                            </Dropdown>

                                        </Col>
                                        <Col span={6} style={{ textAlign: 'right' }}>
                                          <Select
                                            dropdownMatchSelectWidth={false}
                                            value={month}
                                            onChange={(newMonth) => {
                                              const now = value.clone().month(newMonth);
                                              onChange(now);
                                            }}
                                            style={{ marginLeft: 12 }}
                                            >
                                            {monthOptions}
                                          </Select>

                                          <Select
                                            dropdownMatchSelectWidth={false}
                                            className="my-year-select"
                                            value={year}
                                            onChange={(newYear) => {
                                              const now = value.clone().year(newYear);
                                              onChange(now);
                                            }}
                                            style={{ marginLeft: 12 }}
                                            >
                                            {options}
                                          </Select>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                }}
                                dateFullCellRender={(date) => {

                                    var current_date = dayjs(date).isSame(dayjs(), 'day') ? true : false;

                                    return (
                                        <div style={{ backgroundColor: (current_date ? '#e8e8e8' : 'inherit'), minHeight: 80, borderTopWidth: 2, borderTopStyle: 'solid', borderTopColor: 'rgba(5, 5, 5, 0.06)', cursor: 'default', paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}>
                                            <span style={{ textAlign: 'right', fontWeight: current_date ? 'bold' : 'normal' }}>{dayjs(date).format('D')}</span><br />
                                            { this.getCalendarCell(date) }
                                        </div>
                                    );
                                }}
                                />
                        </Col>
                    </Row>

                </Form>
            </div>

            <Modal
                open={this.state.subscribeModal}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText={'Sluiten'}
                onCancel={() => { this.setState({ subscribeModal: false }); }}
                >
                <div>
                    Gebruik de onderstaande link om te importeren in je agenda:<br />
                    <p>
                        <Link to={{ pathname: this.state.subscribeUrl }} target="_blank">{ this.state.subscribeUrl }</Link>
                    </p>
                </div>
            </Modal>
        </div>
      );

  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching : state.appointment.isFetching,
      isError : state.appointment.isError,
      status: state.appointment.status,
      appointments: state.appointment.appointments,
      appointments_filter: state.appointment.appointments_filter,
      appointments_users_filter: state.appointment.appointments_users_filter,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      getAppointmentsCalendarAction : bindActionCreators(getAppointmentsCalendarAction, dispatch),
      HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsCalendar);
