import api from '../utils/api'


// DELIVERY

export const resetDeliveriesAction = () => dispatch => {
    dispatch({
        type: 'DELIVERIES_ACTION_RESET'
    })
}

export const resetDeliveryAction = () => dispatch => {
    dispatch({
        type: 'DELIVER_ACTION_RESET'
    })
}

export const resetDeliveryFormAction = () => dispatch => {
    dispatch({
        type: 'DELIVER_FORM_ACTION_RESET'
    })
}

export const getDeliveriesAction = (project_id, archive = false) => dispatch => {

    dispatch({
        type: 'DELIVERIES_ACTION_FETCH'
    })

    return api('get', 'deliver/deliveries/project/'+project_id+(archive === 'all' ? '/all' : (archive === true ? '/archive' : '')), null, true).then((response) => {

        dispatch({
            type: 'DELIVERIES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const getPointsDeliveriesAction = (project_id) => dispatch => {

    dispatch({
        type: 'DELIVERY_POINTS_ACTION_FETCH'
    })

    return api('get', 'deliver/deliveries/project/'+project_id+'/for-points', null, true).then((response) => {

        dispatch({
            type: 'POINTS_DELIVERIES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const searchDeliveriesAction = (project_id, search_for, archive = false) => dispatch => {

    dispatch({
        type: 'DELIVERIES_ACTION_FETCH'
    })

    return api('post', 'deliver/deliveries/project/'+project_id+(archive ? '/archive' : '')+'/search', {search: search_for}, true).then((response) => {

        dispatch({
            type: 'DELIVERIES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const deleteDeliveriesAction = (project_id, ids) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/deliveries/project/'+project_id+'/delete', {ids: ids}, true).then((response) => {

        dispatch({
            type: 'DELETE_DELIVERIES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const exportPointsAction = (project_id, params, format) => dispatch => {

    // dispatch({
    //     type: 'DELIVERY_ACTION_FETCH'
    // })

    return api('post', 'deliver/points/project/'+project_id+'/export/'+format , params, true).then((response) => {

        dispatch({
            type: 'EXPORT_POINTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        // dispatch({
        //     type: 'DELIVER_ACTION_ERROR',
        // });

        return false;
    });
}

export const archiveDeliveriesAction = (project_id, ids, archive) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/deliveries/project/'+project_id+'/'+(archive ? 'archive' : 'restore') , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'ARCHIVE_DELIVERIES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const sendNotificationAction = (project_id, ids, user_id = false, user_email = false) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/points/project/'+project_id+'/send', {ids: ids, user_id: user_id, user_email: user_email}, true).then((response) => {

        dispatch({
            type: 'SEND_DELIVERIES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const getDeliveryAction = (id) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('get', 'deliver/delivery/'+id, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const addDeliveryAction = (project_id, params) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/deliveries/project/'+project_id+'/add', params, true).then((response) => {

        dispatch({
            type: 'ADD_DELIVERY_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const updateDeliveryAction = (id, params) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/delivery/'+id, params, true).then((response) => {

        dispatch({
            type: 'UPDATE_DELIVERY_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const addPointsToDeliveryFormAction = (id, params) => dispatch => {

    dispatch({
        type: 'DELIVER_ACTION_ADD_DELIVERY_POINTS_TO_FORM'
    })

    return api('post', 'deliver/form/'+id+'/add-points', params, true).then((response) => {

        dispatch({
            type: 'ADD_DELIVERY_POINTS_TO_FORM_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}


// DRAWING

export const resetDrawingsAction = () => dispatch => {
    dispatch({
        type: 'DRAWINGS_ACTION_RESET'
    })
}

export const resetDrawingAction = () => dispatch => {
    dispatch({
        type: 'DRAWING_ACTION_RESET'
    })
}

export const getDrawingsAction = (project_id, archive = false) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('get', 'deliver/drawings/project/'+project_id+(archive === 'all' ? '/all' : (archive === true ? '/archive' : '')), null, true).then((response) => {

        dispatch({
            type: 'DRAWINGS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const searchDrawingsAction = (project_id, search_for, archive = false) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/drawings/project/'+project_id+(archive ? '/archive' : '')+'/search', {search: search_for}, true).then((response) => {

        dispatch({
            type: 'DRAWINGS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const deleteDrawingsAction = (project_id, ids) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/drawings/project/'+project_id+'/delete' , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'DELETE_DRAWINGS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const archiveDrawingsAction = (project_id, ids, archive) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/drawings/project/'+project_id+'/'+(archive ? 'archive' : 'restore') , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'ARCHIVE_DRAWINGS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const addDrawingAction = (project_id, values, filelist) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/drawings/project/'+project_id+'/add' , values, true, filelist).then((response) => {

        dispatch({
            type: 'ADD_DRAWING_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const updateDrawingAction = (id, values, filelist) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/drawing/'+id, values, true, filelist).then((response) => {

        dispatch({
            type: 'UPDATE_DRAWING_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}


// POINT

export const resetPointsAction = () => dispatch => {
    dispatch({
        type: 'POINTS_ACTION_RESET'
    })
}

export const resetPointAction = () => dispatch => {
    dispatch({
        type: 'POINT_ACTION_RESET'
    })
}

export const getPointsAction = (project_id, archive = false) => dispatch => {

    dispatch({
        type: 'DELIVERY_POINTS_ACTION_FETCH'
    })

    return api('get', 'deliver/points/project/'+project_id+(archive === 'all' ? '/all' : (archive === true ? '/archive' : '')), null, true).then((response) => {

        dispatch({
            type: 'POINTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const getDeliveryFormPointsAction = (form_id) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_POINTS_ACTION_FETCH'
    })

    return api('get', 'deliver/form/'+form_id+'/points', null, true).then((response) => {

        dispatch({
            type: 'FORM_POINTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const getPointAction = (id) => dispatch => {

    dispatch({
        type: 'DELIVERY_POINT_ACTION_FETCH'
    })

    return api('get', 'deliver/point/'+id, null, true).then((response) => {

        dispatch({
            type: 'POINT_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const searchPointsAction = (project_id, search_for, archive = false) => dispatch => {

    dispatch({
        type: 'DELIVERY_POINTS_ACTION_FETCH'
    })

    return api('post', 'deliver/points/project/'+project_id+(archive === true ? '/archive' : (archive ? '/'+archive : ''))+'/search', {search: search_for}, true).then((response) => {

        dispatch({
            type: 'POINTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const archivePointsAction = (project_id, ids, archive) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/points/project/'+project_id+'/'+(archive ? 'archive' : 'restore') , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'ARCHIVE_POINTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const deletePointsAction = (project_id, ids) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/points/project/'+project_id+'/delete' , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'DELETE_POINTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const multiUpdatePointsAction = (project_id, params) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/points/project/'+project_id+'/multi-update', params, true).then((response) => {

        dispatch({
            type: 'UPDATE_POINTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const updatePointAction = (id, params, files) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/point/'+id, params, true, files).then((response) => {

        dispatch({
            type: 'UPDATE_POINT_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const addPointAction = (project_id, params, files) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/points/project/'+project_id+'/add', params, true, files).then((response) => {

        dispatch({
            type: 'ADD_POINT_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}


// USER

export const resetUsersAction = () => dispatch => {
    dispatch({
        type: 'USERS_ACTION_RESET'
    })
}

export const resetUserAction = () => dispatch => {
    dispatch({
        type: 'USER_ACTION_RESET'
    })
}

export const getUsersAction = (project_id, role = 'all') => dispatch => {

    dispatch({
        type: 'DELIVERY_USERS_ACTION_FETCH'
    })

    return api('get', 'deliver/users/project/'+project_id+'/'+ role, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_USERS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_USERS_ACTION_ERROR',
        });

        return false;
    });
}

export const searchUsersAction = (project_id, search_for, archive = false, role = 'all') => dispatch => {

    dispatch({
        type: 'DELIVERY_USERS_ACTION_FETCH'
    })

    return api('post', 'deliver/users/project/'+project_id/*+(archive ? '/archive' : '')*/+'/search/' + role, {search: search_for}, true).then((response) => {

        dispatch({
            type: 'DELIVER_USERS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_USERS_ACTION_ERROR',
        });

        return false;
    });
}

export const deleteUsersAction = (project_id, ids, archive) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/users/project/'+project_id+'/delete', {ids: ids}, true).then((response) => {

        dispatch({
            type: 'DELETE_USERS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const archiveUsersAction = (project_id, ids, archive) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/users/project/'+project_id+'/'+(archive ? 'archive' : 'restore') , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'ARCHIVE_USERS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const addUserAction = (project_id, values) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/users/project/'+project_id+'/add' , values, true).then((response) => {

        dispatch({
            type: 'ADD_USER_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const updateUserAction = (id, values) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    })

    return api('post', 'deliver/user/'+id, values, true).then((response) => {

        dispatch({
            type: 'UPDATE_USER_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}


// FILTERS

export const getDeliveryFilterAction = (project_id) => (dispatch) => {

    return api('get', 'deliver/points/project/'+project_id+'/deliveries', null, true).then((response) => {

        dispatch({
            type: 'DELIVER_POINTS_FILTER_DELIVERIES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        return false;
    });
}

export const getFormFilterAction = (project_id) => (dispatch) => {

    return api('get', 'deliver/points/project/'+project_id+'/forms', null, true).then((response) => {

        dispatch({
            type: 'DELIVER_POINTS_FILTER_FORMS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        return false;
    });
}

export const getLocationFilterAction = (project_id) => (dispatch) => {

    return api('get', 'deliver/points/project/'+project_id+'/locations', null, true).then((response) => {

        dispatch({
            type: 'DELIVER_POINTS_FILTER_LOCATIONS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        return false;
    });
}

export const getUserFilterAction = (project_id) => (dispatch) => {

    return api('get', 'deliver/points/project/'+project_id+'/users', null, true).then((response) => {

        dispatch({
            type: 'DELIVER_POINTS_FILTER_USERS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        return false;
    });
}


// SETTINGS

export const getDeliverSettingsAction = (project_id) => dispatch => {

    return api('get', 'deliver/settings/project/'+project_id, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_SETTINGS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        return false;
    });
}

export const updateDeliverSettingsAction = (project_id, values) => dispatch => {

    dispatch({
        type: 'DELIVERY_SETTINGS_ACTION_FETCH'
    })

    return api('post', 'deliver/settings/project/'+project_id, values, true).then((response) => {

        dispatch({
            type: 'UPDATE_DELIVER_SETTINGS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_SETTINGS_ACTION_ERROR',
        });

        return false;
    });
}


// (PRE)FORMS CUSTOMER

export const getCustomerDeliveryFormsAction = (customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'DELIVERY_FORMS_ACTION_FETCH'
    });

    return api('get', 'deliver/forms/customer/'+customer_id, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_CUSTOMER_FORMS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_ERROR',
        });

        return false;
    });
}

export const searchCustomerDeliveryFormsAction = (search_for, customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'DELIVERY_FORMS_ACTION_FETCH'
    });

    return api('post', 'deliver/forms/customer/'+customer_id+'/search', {search: search_for}, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_ERROR',
        });

        return false;
    });
}

// export const getDeliveryFormAction = (id) => (dispatch, getState) => {
//
//     dispatch({
//         type: 'DELIVER_FORMS_ACTION_FETCH'
//     });
//
//     return api('get', 'deliver/form/'+id, null, true).then((response) => {
//
//         dispatch({
//             type: 'DELIVER_FORM_ACTION_SUCCESS',
//             payload: response.data
//         });
//
//     }).catch((e) => {
//
//         dispatch({
//             type: 'DELIVER_FORMS_ACTION_ERROR',
//         });
//
//         return false;
//     });
// }

export const addCustomerDeliveryFormAction = (values, customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    });

    return api('post', 'deliver/form/customer/'+customer_id+'/add', values, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const deleteCustomerDeliveryFormsAction = (ids, customer_id = false) => (dispatch, getState) => {

    if(!customer_id) {
        customer_id = localStorage.getItem('customerId');
    }

    if(!customer_id && getState().auth.selectedCustomer) {
      customer_id = getState().auth.selectedCustomer.id;
    }

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    })

    return api('post', 'deliver/forms/customer/'+customer_id+'/delete' , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'DELETE_FORM_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}

// export const updateDeliveryFormAction = (id, values) => (dispatch, getState) => {
//
//     dispatch({
//         type: 'DELIVERY_FORM_ACTION_FETCH'
//     });
//
//     return api('post', 'deliver/form/'+id, values, true).then((response) => {
//
//         dispatch({
//             type: 'DELIVER_FORM_ACTION_SUCCESS',
//             payload: response.data
//         });
//
//     }).catch((e) => {
//
//         dispatch({
//             type: 'DELIVER_FORM_ACTION_ERROR',
//         });
//
//         return false;
//     });
// }


// (PRE)FORMS

export const getDeliveryFormsAction = (project_id) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORMS_ACTION_FETCH'
    });

    return api('get', 'deliver/forms/project/'+project_id, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_ERROR',
        });

        return false;
    });
}

export const searchDeliveryFormsAction = (project_id, search_for) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORMS_ACTION_FETCH'
    });

    return api('post', 'deliver/forms/project/'+project_id+'/search', {search: search_for}, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_ERROR',
        });

        return false;
    });
}

export const getDeliveryFormAction = (id) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    });

    return api('get', 'deliver/form/'+id, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORMS_ACTION_ERROR',
        });

        return false;
    });
}

export const addDeliveryFormAction = (project_id, values) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    });

    return api('post', 'deliver/form/project/'+project_id+'/add', values, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_SUCCESS',
            payload: response.data
        });

        return response.data;

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const deleteDeliveryFormsAction = (project_id, ids) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    })

    return api('post', 'deliver/forms/project/'+project_id+'/delete' , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'DELETE_FORM_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const updateDeliveryFormAction = (id, values) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    });

    return api('post', 'deliver/form/'+id, values, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const syncDeliveryFormDeliveriesAction = (id, values) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    });

    return api('post', 'deliver/form/'+id+'/sync-deliveries', values, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORM_SYNC_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const duplicateDeliveryFormAction = (id, type, type_id = null) => (dispatch, getState) => {

  dispatch({
    type: 'DELIVERY_FORM_ACTION_FETCH'
  })

  var params = {};

  if(type == 'customer')
  {
      if(type_id)
      {
          params.customer_id = type_id;
      }

      if(!params.customer_id) {
          params.customer_id = localStorage.getItem('customerId');
      }

      if(!params.customer_id && getState().auth.selectedCustomer)
      {
          params.customer_id = getState().auth.selectedCustomer.id;
      }
  }
  else if(type == 'project'){ params.project_id = type_id; }

  return api('post','/deliver/form/'+id+'/clone', params, true).then((response) => {
    dispatch({
      type: 'DUPLICATE_DELIVER_FORM_ACTION',
      payload: response.data
    });
  }).catch((e) => {
    dispatch({
      type: 'DELIVER_FORM_ACTION_ERROR',
    })
    return false;
  });
}


// STATUS

export const getStatusAction = (project_id) => dispatch => {

    dispatch({
        type: 'DELIVERY_STATUS_ACTION_FETCH'
    })

    return api('get', 'deliver/status/project/'+project_id, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_STATUS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const searchStatusAction = (id, search) => dispatch => {

    dispatch({
        type: 'DELIVERY_STATUS_ACTION_FETCH'
    });

    return api('post', 'deliver/status/project/'+id+'/search', {search: search}, true).then((response) => {

        dispatch({
            type: 'DELIVER_STATUS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const viewDeliveryFormPDFAction = (id, delivery_id = false, type = 'form', format = 'zip', ids = []) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    });

    return api('post', 'deliver/form/' + id + (delivery_id ? '/' + delivery_id : '') + '/pdf', { type: type, format: format, ids: ids }, true).then((response) => {

        dispatch({
            type: 'DELIVER_STATUS_POST_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const viewDeliveryFormLogAction = (id, search) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_LOG_ACTION_FETCH'
    });

    return api('post', 'deliver/form/'+id+'/log', false, true).then((response) => {

        dispatch({
            type: 'DELIVER_STATUS_POST_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}

export const emailDeliveryFormPDFAction = (id, email, delivery_id = false) => dispatch => {

    dispatch({
        type: 'DELIVERY_ACTION_FETCH'
    });

    return api('post', 'deliver/form/' + id + (delivery_id ? '/' + delivery_id : '') + '/e-mail', {email: email}, true).then((response) => {

        dispatch({
            type: 'DELIVER_STATUS_POST_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_ACTION_ERROR',
        });

        return false;
    });
}


// COMMENT

export const addDeliveryPointCommentAction = (point_id, values) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    });

    return api('post', 'deliver/point/' + point_id + '/comment', values, true).then((response) => {

        dispatch({
            type: 'DELIVER_POINT_COMMENT_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}

export const deleteDeliveryPointCommentsAction = (project_id, ids) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    })

    return api('post', 'deliver/point/'+project_id+'/comment/delete' , {ids: ids}, true).then((response) => {

        dispatch({
            type: 'DELETE_POINT_COMMENTS_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}


// ACTIONS

export const unlockFormAction = (form_id, delivery_id) => dispatch => {

    dispatch({
        type: 'DELIVERY_FORM_ACTION_FETCH'
    })

    return api('post', 'deliver/form/'+form_id+'/'+(delivery_id ? delivery_id + '/' : '')+'unlock' , null, true).then((response) => {

        dispatch({
            type: 'DELIVER_FORM_UNLOCK_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'DELIVER_FORM_ACTION_ERROR',
        });

        return false;
    });
}


// UTIL

export const getDeliverAddressesAction = (project_id) => dispatch => {

    dispatch({
        type: 'DELIVERY_ADDRESSES_ACTION_FETCH'
    });

    return api('get', 'deliver/addresses/project/'+project_id, null, true).then((response) => {

        dispatch({
            type: 'DELIVER_ADDRESSES_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        // dispatch({
        //     type: 'DELIVER_ACTION_ERROR',
        // });

        return false;
    });
}


// PROTECTED BY HASH

export const getPointStatusByHashAction = (id, hash) => dispatch => {

    dispatch({
        type: 'HASH_POINT_ACTION_FETCH'
    });

    return api('get', 'get/point/'+id+'/'+hash, null, false).then((response) => {

        dispatch({
            type: 'HASH_POINT_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'HASH_POINT_ACTION_ERROR',
        });

        return false;
    });
}

export const changePointStatusByHashAction = (id, hash, status) => dispatch => {

    dispatch({
        type: 'HASH_POINT_ACTION_FETCH'
    });

    return api('post', 'update/point/'+id+'/'+hash, { status: status }, false).then((response) => {

        dispatch({
            type: 'HASH_POINT_ACTION_SUCCESS',
            payload: response.data
        });

    }).catch((e) => {

        dispatch({
            type: 'HASH_POINT_ACTION_ERROR',
        });

        return false;
    });
}