import React, { Component } from 'react';
// import '../Customers.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { getCustomerAction/*, syncCustomerModulesAction*/ } from '../../../actions/customerActions';
import { getCustomerAction, patchCustomerAction } from '../../../actions/customerActions';
import { getInvoiceDefaultPricesAction, getInvoiceSettingsForCustomerAction, updateInvoiceSettingsForCustomerAction, getExactOrganisationsAction/*, getExactOrganisationsAction*/ } from '../../../actions/invoiceActions';
import { showSuccess, showError } from '../../../utils/Notifications';
import CustomerSideMenu from './CustomerSideMenu';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Divider, Input, Select, message, DatePicker, Switch, Radio, InputNumber, Tooltip } from 'antd';
import PageHeader from '../../../components/PageHeader/PageHeader';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

const { Title } = Typography;

class CustomerLicense extends Component {
  
  state = {
    edit: true,
  };

  componentDidMount() {

    // this.props.getCustomerAction(this.props.match.params.id).then(() => {

    //     this.setState({use_personal_login_page: this.props.customer.use_personal_login_page});
    // });

    this.props.getCustomerAction(this.props.match.params.id);

    this.props.getInvoiceDefaultPricesAction();

    // this.props.getExactOrganisationsAction();

    this.props.getInvoiceSettingsForCustomerAction(this.props.match.params.id).then(() => {});
  }

  toggle = () => {
    this.setState({
      edit: !this.state.edit
    });
  };

  handleSubmit = e => {

      e.preventDefault();

      this.props.form.validateFields((err, values) => {

        if(!err)
        {
          // this.props.updateInvoiceSettingsForCustomerAction(this.props.match.params.id, values).then((response) => {
            this.props.patchCustomerAction(this.props.match.params.id, values).then((response) => {

            if(response && response.success)
            {
              showSuccess('Gegevens opgeslagen');
            }
            else
            {
              showError('Gegevens niet opgeslagen');
            }
          });
        }

      });

  };

  getExactOrganisationValues()
  { //
    var values = [];

    values.push({
      value: 'new',
      label: 'Voeg een nieuwe klant toe vanuit Cobee in Exact'
    })

    //
    this.props.exact_organisations.map((organisation) => {

      values.push({
        value: organisation.id,
        label: organisation.name
      })
    });

    //
    return values;
  }

  selectExactCustomer(value)
  {
    this.props.exact_organisations && this.props.exact_organisations.map && this.props.exact_organisations.map((organisation) => {

      if(organisation.id == value)
      {
        this.props.form.setFieldsValue({ 
          name: organisation.name,
          kvk: organisation.coc_code,
          address: organisation.visiting_address ? organisation.visiting_address.line_1 : '',
          zipcode: organisation.visiting_address && organisation.visiting_address.postal_code ? organisation.visiting_address.postal_code.replace(' ', '') : '',
          // house_number: ,
          city: organisation.visiting_address ? organisation.visiting_address.locality : '',
          telephone: organisation.phone,
          email_address: organisation.email,
          website: organisation.url,
        });
      }
    })
  }

  getModulePrice(name)
  {
      if(name == 'Afspraken')
      {
          return this.state.price_module_appointment;
      }
      else if(name == 'Communicatie')
      {
          return this.state.price_module_communication;
      }
      else if(name == 'Draagvlak')
      {
          return this.state.price_module_support_base;
      }
      else if(name == 'Formulieren')
      {
          return this.state.price_module_form;
      }
      else if(name == 'Keuze')
      {
          return this.state.price_module_choice;
      }
      else if(name == 'Oplevering')
      {
          return this.state.price_module_deliver;
      }

      //
      return 0;
  }

  render()
  {
    const { getFieldDecorator } = this.props.form;

    const optional_modules = [
      {
          id: 'appointment',
          name: 'Afspraken',
      },
      {
          id: 'communication',
          name: 'Communicatie',
      },
      {
          id: 'support_base',
          name: 'Draagvlak',
      },
      {
          id: 'form',
          name: 'Formulieren',
      },
      {
          id: 'choice',
          name: 'Keuze',
      },
      {
          id: 'delivery',
          name: 'Oplevering',
      }
    ];

    // if(!this.props.customer){ return null; }

    return (
      <div>

        <PageHeader path={[{'label': 'Klanten', 'link': '/customers'}, {'label': this.props.customer.name}]} />

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <CustomerSideMenu id={this.props.match.params.id} activeMenu='3' />

          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>

            <Row className="mbm">
              <Col span={24} >
                <Button type="new" onClick={this.handleSubmit}>
                  Opslaan
                </Button>
                <Button onClick={() => this.props.history.push('/customers')}>Sluiten</Button>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
                <Col span={24}>
                  <Title level={4}>Facturatie</Title>
                </Col>
            </Row>

            <Row gutter={24}>
              {/*<Col span={24}>
                <Form.Item required label="Exact bedrijf" hasFeedback>
                  {getFieldDecorator('exact_id', {
                    initialValue: this.props.customer && this.props.customer.exact_id ? this.props.customer.exact_id : '',
                    rules: [{ required: true, message: 'Selecteer een bedrijf uit Exact om deze te koppelen en de gegevens daarvan voor in te vullen.' }],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      options={this.getExactOrganisationValues()}
                      onChange={(value) => { 
                        if(!this.props.match.params.id)
                        { // only prefil for new customers
                          this.selectExactCustomer(value); 
                        }
                      }}
                      placeholder="Selecteer een klant uit Exact of voeg een nieuwe klant toe vanuit Cobee in Exact"
                      />
                  )}
                </Form.Item>
              </Col>/**/}
              <Col span={8}>
                <Form.Item label="Factuur kenmerk van de klant" hasFeedback>
                  {getFieldDecorator('invoice_reference', {
                    initialValue: this.props.customer && this.props.customer.license && this.props.customer.license.invoice_reference ? this.props.customer.license.invoice_reference : '',
                    rules: [{ required: false }],
                  })(
                    <Input></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
            
            <div>

              <Row gutter={24}>

                <Col span={8}>
                  <Form.Item required label="Ingangsdatum facturering" hasFeedback>
                    {getFieldDecorator('billing_start_date', {
                      initialValue: this.props.customer && this.props.customer.billing_start_date ? dayjs(moment(this.props.customer.billing_start_date)) : null,
                      rules: [{ required: true, message: 'Geef een ingangsdatum voor de facturering op.' }],
                    })(
                      <DatePicker 
                        format={'DD-MM-YYYY'}
                        />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item required label="Verzamelfactuur" hasFeedback>
                    {getFieldDecorator('collective_invoice', {
                      initialValue: !this.props.customer || (this.props.customer && (this.props.customer.collective_invoice == undefined || this.props.customer.collective_invoice == true)) ? true : false,
                      valuePropName: 'checked',
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item required label="Facturatie frequentie (basis)" hasFeedback>
                    {getFieldDecorator('billing_frequency', {
                      initialValue: !this.props.customer || (this.props.customer && (this.props.customer.collective_invoice == undefined || this.props.customer.billing_frequency == 'month')) ? 'month' : 'year',
                      rules: [{ required: true, message: 'Geef een waarde op.' }],
                    })(
                      <Radio.Group>
                        <Radio value={'month'}>Maand</Radio>
                        <Radio value={'year'}>Jaar</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>

              </Row>

              <Title level={4}>Prijzen</Title>

              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item required label="Basis prijs">
                    {getFieldDecorator('basic_price', {
                        initialValue: this.props.customer && this.props.customer.license && this.props.customer.license.basic_price ? this.props.customer.license.basic_price : null,
                        rules: [{ required: true, message: 'Geef een waarde op.' }],
                      })(
                      <InputNumber
                        min={0}
                        formatter={(value) => `€ ${value}`}
                        />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item required label="Alle modules vrijgeven" hasFeedback>
                      {getFieldDecorator('all_modules', {
                        initialValue: !this.props.customer || (this.props.customer && (this.props.customer.all_modules == undefined || this.props.customer.all_modules != true)) ? false : true,
                        valuePropName: 'checked',
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                  </Col>
              </Row>

              <Row gutter={24}>

                  <Col span={8}>
                    <Form.Item required label="Aantal projecten limiet">
                      {getFieldDecorator('license_nr_of_projects', {
                            initialValue: this.props.customer && this.props.customer.license_nr_of_projects ? this.props.customer.license_nr_of_projects : null,
                            rules: [{ required: true, message: 'Geef een waarde op.' }],
                          })(
                          <InputNumber
                            min={0}
                            style={{ marginRight: 12 }}
                            />
                          )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item required label="Aantal gebruikers limiet">
                      {getFieldDecorator('license_nr_of_users', {
                          initialValue: this.props.customer && this.props.customer.license_nr_of_users ? this.props.customer.license_nr_of_users : null,
                          rules: [{ required: true, message: 'Geef een waarde op.' }],
                        })(
                        <InputNumber
                          min={0}
                          style={{ marginRight: 12 }}
                          />
                        )}
                    </Form.Item>
                  </Col>

              </Row>            

              <Row gutter={24}>
                  {/*<Col span={8}>
                    <Form.Item label="Basis prijs">
                        <InputNumber
                          min={0}
                          onChange={(value) => { this.setState({ basic_price: value }, () => {}) }}
                          value={this.state.basic_price}
                          formatter={(value) => `€ ${value}`}
                          />
                    </Form.Item>
                  </Col>*/}
                  <Col span={8}>
                    <Form.Item required label="Prijs per (extra) project">
                      {getFieldDecorator('extra_project_price', {
                          initialValue: this.props.customer && this.props.customer.license && this.props.customer.license.extra_project_price ? this.props.customer.license.extra_project_price : null,
                          rules: [{ required: true, message: 'Geef een waarde op.' }],
                        })(
                        <InputNumber
                          min={0}
                          formatter={(value) => `€ ${value}`}
                          />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item required label="Prijs per (extra) gebruiker">
                      {getFieldDecorator('extra_user_price', {
                            initialValue: this.props.customer && this.props.customer.license && this.props.customer.license.extra_user_price ? this.props.customer.license.extra_user_price : null,
                            rules: [{ required: true, message: 'Geef een waarde op.' }],
                          })(
                          <InputNumber
                            min={0}
                            formatter={(value) => `€ ${value}`}
                            />
                      )}
                    </Form.Item>
                  </Col>
              </Row>

              <Divider />

              <Row gutter={24}>
                  { optional_modules.map((optional_module) => { return (<Col span={8}>
                      <Form.Item required label={'Prijs module: ' + optional_module.name}>
                        {getFieldDecorator('module_' + optional_module.id + '_price', {
                              initialValue: this.props.customer && this.props.customer.license && this.props.customer.license['module_' + optional_module.id + '_price'] ? this.props.customer.license['module_' + optional_module.id + '_price'] : null,
                              rules: [{ required: true, message: 'Geef een waarde op.' }],
                            })(
                            <InputNumber
                              min={0}
                              formatter={(value) => `€ ${value}`}
                              />
                        )}
                      </Form.Item>
                  </Col>);
                  }) }
              </Row>

            </div>

            <div style={{ display: (this.props.form.getFieldValue('all_modules') ? 'none' : 'block') }}>

            <Title level={4}>Modules</Title>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Correspondentie">
                    {getFieldDecorator('5', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(5) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Berichten">
                    {getFieldDecorator('4', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(4) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Informatie">
                      {getFieldDecorator('12', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(12) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Werkvolgorde">
                    {getFieldDecorator('11', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(11) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Planning">
                    {getFieldDecorator('1', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(1) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Contactpersonen">
                      {getFieldDecorator('6', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(6) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Nieuws">
                    {getFieldDecorator('7', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(7) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Partners">
                    {getFieldDecorator('2', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(2) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Enquête">
                    {getFieldDecorator('9', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(9) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Afspraken">
                      {getFieldDecorator('3', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(3) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Documenten">
                    {getFieldDecorator('8', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(8) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="SMS">
                      {getFieldDecorator('10', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(10) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                {/*<Col span={12}>
                    <Form.Item label="Opname">
                      {getFieldDecorator('13', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(13) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          />
                      )}
                    </Form.Item>
                </Col>*/}
                <Col span={12}>
                    <Form.Item label="Draagvlak">
                      {getFieldDecorator('14', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(14) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Oplever">
                      {getFieldDecorator('15', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(15) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Keuze">
                      {getFieldDecorator('16', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(16) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Dossier">
                      {getFieldDecorator('17', {
                        valuePropName: 'checked',
                        initialValue: this.props.modules.indexOf(17) > -1
                      })(
                        <Switch
                          disabled={!this.state.edit}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Eigen module">
                    {getFieldDecorator('18', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(18) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Formulieren">
                    {getFieldDecorator('19', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(19) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={
                      <span>
                          Exporteren
                          <Tooltip class="mhs" title="Alleen van toepassing op de modules waar de gebruiker toegang tot heeft en welke een export mogelijkheid bieden.">
                              &nbsp;<InfoCircleTwoTone />
                          </Tooltip>
                      </span>
                  }>
                    {getFieldDecorator('20', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(20) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={
                      <span>
                          BAG data
                          <Tooltip class="mhs" title="Let op: Dit is geen standaard module voor klanten.">
                              &nbsp;<InfoCircleTwoTone />
                          </Tooltip>
                      </span>
                  }>
                    {getFieldDecorator('24', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(24) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
                </Col>
              </Row>              
            </div>

          </Form>

        </div>

      </div>
    );
  }
}

const CustomerLicenseForm = Form.create({ name: 'customer_modules' })(CustomerLicense);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customer: state.customer.customer,
    modules: state.customer.modules,
    default_prices: state.invoice.default_prices,
    customer_prices: state.invoice.customer_prices,
    exact_organisations: state.invoice.exact_organisations,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    getInvoiceDefaultPricesAction : bindActionCreators(getInvoiceDefaultPricesAction, dispatch),
    getInvoiceSettingsForCustomerAction : bindActionCreators(getInvoiceSettingsForCustomerAction, dispatch), 
    updateInvoiceSettingsForCustomerAction : bindActionCreators(updateInvoiceSettingsForCustomerAction, dispatch),
    getExactOrganisationsAction : bindActionCreators(getExactOrganisationsAction, dispatch),    
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    patchCustomerAction : bindActionCreators(patchCustomerAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLicenseForm);
