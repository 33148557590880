import React, { Component } from 'react';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Select, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../../../../utils/Notifications';
import ScheduleKypSteps from '../Steps/ScheduleKypSteps';
import { HaveRole, HaveAbility } from '../../../../../utils/RolesAndAbilities';
import { updateScheduleSettingsAction, getScheduleKypItemsAction } from '../../../../../actions/scheduleActions';
import { getInformationAction } from '../../../../../actions/informationActions';

class ScheduleKypInformationItems extends Component
{
  state = {
      cobee_kyp_addresses: [],
      cobee_kyp_items: []
  };

  componentDidMount()
  {
    this.props.getScheduleKypItemsAction(this.props.match.params.id, this.props.project.kyp_project_id).then(() => {

        this.setState({ cobee_kyp_items: this.props.kyp_items });
    });

    this.props.getInformationAction(this.props.match.params.id);
  }

  saveSettings(e) {

      var values = {};

      values.cobee_kyp_items = JSON.stringify(this.state.cobee_kyp_items);

      this.props.updateScheduleSettingsAction(this.props.match.params.id, values).then(() => {

        showSuccess('Succesvol opgeslagen');
      });
  }

  render()
  {
    return (
      <div>
        <Row className="mbm">
          <Col span={24}>
            <div>
              <Button disabled={!this.props.HaveAbility('scheduling', 'write')} type="new" onClick={(e) => this.saveSettings(e) }>Opslaan</Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Card className="mbm" style={{backgroundColor: '', borderRadius: '5px'}} bodyStyle={{padding: 0}}>
            <ScheduleKypSteps current={2} {...this.props} />
          </Card>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Card>

                <Row gutter={24} style={{ marginBottom: 8 }}>
                    <Col span={6} style={{ fontWeight: 'bold' }}>Kyp actie</Col>
                    <Col span={6} style={{ fontWeight: 'bold' }}>Cobee informatie</Col>
                </Row>

                { this.props.kyp_items && this.props.kyp_items.map && this.props.kyp_items.map((kyp_item) => (
                <Row gutter={24} style={{ marginBottom: 4 }}>
                    <Col span={6}>{kyp_item.name}</Col>
                    <Col span={6}>
                        <Select
                            defaultValue={''+(kyp_item.information_item_id ? kyp_item.information_item_id : '')}
                            onChange={(value) => {
                                var cobee_kyp_items = this.state.cobee_kyp_items;

                                cobee_kyp_items.map((cobee_kyp_item) => {
                                    if(cobee_kyp_item.name == kyp_item.name)
                                    {
                                        cobee_kyp_item.information_item_id = ''+value;
                                    }
                                });

                                this.setState({ cobee_kyp_items: cobee_kyp_items });
                            }}
                            style={{ width: '100%' }}
                            >
                            <Select.Option value={''}></Select.Option>
                            { this.props.information_items && this.props.information_items.map && this.props.information_items.map((information_item) => {
                                return <Select.Option value={''+information_item.id}>{information_item.title}</Select.Option>
                            }) }
                        </Select>
                    </Col>
                </Row>
                ))}

            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.appointment.isFetching,
    isError : state.appointment.isError,
    project: state.project.project,
    information_items: state.information.information,
    kyp_items: state.schedule.kyp_items,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateScheduleSettingsAction : bindActionCreators(updateScheduleSettingsAction, dispatch),
    getInformationAction : bindActionCreators(getInformationAction, dispatch),
    getScheduleKypItemsAction : bindActionCreators(getScheduleKypItemsAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleKypInformationItems);
