import React, { Component } from 'react';
import {
    CalendarOutlined,
    UserOutlined,
    ExclamationOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

export default class AppointmentsSideMenu extends Component {

    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 240}}
                selectedKeys={[this.props.activeMenu]}
                >

                <Menu.Item key={"1"}>
                    <Link to={`/projects/${this.props.project_id}/modules/appointments`}>
                        <CalendarOutlined />
                        <span>Afspraken</span>
                    </Link>
                </Menu.Item>

                {/*<Menu.Item key={"2"}>
                    <Link to={`/projects/${this.props.project_id}/modules/appointments/people`}>
                        <UserOutlined />
                        <span>Beschikbaarheid</span>
                    </Link>
                </Menu.Item>*/}

                <Menu.Item key={"3"}>
                    <Link to={`/projects/${this.props.project_id}/modules/appointments/status`}>
                        <ExclamationOutlined />
                        <span>Status</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key={"4"}>
                    <Link to={`/projects/${this.props.project_id}/modules/appointments/calendar`}>
                        <CalendarOutlined />
                        <span>Agenda</span>
                    </Link>
                </Menu.Item>

            </Menu>
        );
    }
}
