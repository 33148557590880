import React, { Component } from 'react';
// import './ProjectsSettings.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';
import { getPersonalizationAction, getProjectAction, patchProjectAction, patchPersonalizationProjectAction } from '../../../actions/projectActions';
import { getLetterPapersAction } from '../../../actions/letterPapersActions';
import ProjectSidemenu from './ProjectSidemenu';
import { showSuccess } from '../../../utils/Notifications'
import { HaveRole, HaveAbility } from '../../../utils/RolesAndAbilities';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Avatar, Divider, Input, Select } from 'antd';
import CustomEditor from '../../../components/CustomEditor/CustomEditor';
import _ from 'underscore';
import { SketchPicker, ChromePicker, TwitterPicker } from 'react-color';

const { Title, Text } = Typography;

const { TextArea } = Input;
const { Option } = Select;

class ProjectsPersonalize extends Component {
  state = {
    disabled: true,
    edit: true,
    letterpapers: [],
    envelopes: [],
    showcolor: false,
    color: '',
  };

  componentDidMount() {
    this.props.getProjectAction(this.props.match.params.id).then(() => {
      this.setState({
          envelopes: this.props.project && this.props.project.envelopes ? this.props.project.envelopes : [],
          color: this.props.project && this.props.project.corporate_identity_color ? this.props.project.corporate_identity_color : ''
      });
    });

    this.props.getLetterPapersAction().then(() => {
      this.setState({
          letterpapers: this.props.letterpapers
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.patchPersonalizationProjectAction(this.props.match.params.id, values, this.state.filelist).then(() => {
          showSuccess();
        });
      }
    });
  };

  handleCancel = () => {this.setState({ previewVisible: false });}

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => {this.setState({ fileList });}

  render() {
    // console.log(this.props.selectedCustomer);
    const { getFieldDecorator } = this.props.form;

    return (
      <div>

        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ProjectSidemenu id={this.props.match.params.id} activeMenu={["2"]} {...this.props} />

          <Form layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={24}>
                <div>
                  <Button disabled={!this.props.HaveAbility('project-personalisation', 'write')} type="new" onClick={this.handleSubmit}>Opslaan</Button>
                </div>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Huisstijl</Title>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <div className="clearfix">
                    <Form.Item label="Logo">
                      {getFieldDecorator('logo_id', {
                        initialValue: this.props.project.logo_id,
                      })(
                        <AddFromMedia
                          projectId={this.props.match.params.id}
                          initialMediaId={this.props.project.logo_id}
                          initialMediaUrl={this.props.project.logo_url}
                          onMediaSelection={(item) => this.props.form.setFieldsValue({'logo_id': item.id})}
                          onMediaRemove={() => this.props.form.setFieldsValue({'logo_id': ''})}
                          />
                      )}
                    </Form.Item>

                  </div>
                ) : (
                  <div>
                    <Text strong>Logo</Text>
                    <br />
                    <Avatar size={120} shape="square"  style={{height: 'auto'}} src={this.props.project.logo_url} />
                  </div>
                )}
              </Col>
              {/*<Col span={12}>
                {this.state.edit ? (
                  <Form.Item required label="Huisstijlkleur">
                    {getFieldDecorator('corporate_identity_color', {
                      initialValue: this.props.project.corporate_identity_color,
                      rules: [{ required: true, message: 'Vul een HEX-waarde in' }, { min:6, message: 'Vul minimaal 6 karakters in'}, { max:6, message: 'Vul maximaal 6 karakters in'}],
                    })(
                      <Input maxLength={6} addonBefore="#"></Input>
                    )}
                  </Form.Item>
                ) : (
                  <div>
                    <Text strong>Huisstijlkleur</Text>
                    <br />
                    <Avatar size={24} style={{ backgroundColor: '#'+this.props.project.corporate_identity_color, marginRight: 8 }}/>
                  </div>
                )}
              </Col>*/}
              <Col span={12}>
                  <Form.Item required label="Huisstijlkleur" hasFeedback>
                    {getFieldDecorator('corporate_identity_color', {
                        initialValue: this.props.project.corporate_identity_color,
                        rules: [{ required: true, message: 'Vul een omschrijving in' }],
                      })(
                          <Input prefix={(<span style={{marginLeft: -3, paddingRight: 5, width:15, height: 15, background: this.state.color, borderRadius: 3}}></span>)} onFocus={() => this.setState({showcolor: true})} onBlur={() => this.setState({showcolor: true})}></Input>
                        )}

                        {this.state.showcolor && (
                          <div style={{position: 'absolute', zIndex: '2'}}>
                            <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}} onClick={() => this.setState({showcolor: false})} />
                              <TwitterPicker colors={['#f5222d', '#fa8c16', '#fadb14', '#a0d911', '#52c41a', '#13c2c2', '#1890ff', '#2f54eb', '#722ed1', '#eb2f96']} style={{position: 'absolute'}} color={this.state.color} onChangeComplete={(color) => {
                                this.setState({color: color.hex});
                                this.props.form.setFieldsValue({'corporate_identity_color': color.hex});
                              }} />
                          </div>
                        )}
                  </Form.Item>
                </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Welkomstboodschap</Title>
              </Col>
              <Col span={24}>

                {/*<Form.Item label="Bericht">
                  {getFieldDecorator('welcome_message', {
                    initialValue: this.props.project.welcome_message,
                    whitespace: true,
                    rules: [{ required: false, message: 'Vul een welkomstboodschap in' }],
                  })(
                    <TextArea rows={5}></TextArea>
                  )}
                </Form.Item>*/}
                <Form.Item label="Bericht">
                  { getFieldDecorator('welcome_message', {
                    initialValue: this.props.project.welcome_message,
                    valuePropName: 'value',
                    trigger: 'onEditorChange',
                    rules: [{ required: false, message: 'Vul een welkomstboodschap in' }],
                  })(
                    <CustomEditor placeholders={'project'} project_id={this.props.match.params.id} />
                  ) }
                </Form.Item>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Briefpapier</Title>
              </Col>
              <Col span={12}>
                <Form.Item label="Type briefpapier" hasFeedback>
                  {getFieldDecorator('letterpaper_id', {
                    initialValue: String(this.props.project.letterpaper_id),
                  })(
                    <Select>
                      <Option key="0">Blanco briefpapier</Option>
                      {this.state.letterpapers.map((v, k) => (
                        <Option key={String(v.id)}>{v.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type envelop" hasFeedback>
                  {getFieldDecorator('envelope_type', {
                    initialValue: this.props.project.envelope_type ? this.props.project.envelope_type : 'blanco',
                  })(
                    <Select>
                      <Option key="blanco">Blanco envelop (A5)</Option>
                      {/*<Option disabled={(this.props.selectedCustomer.envelope_id || this.props.selectedCustomer.envelope_id != '') ? false : true} key="corporate_identity">Envelop in huisstijl</Option>*/}
                      {this.state.envelopes.map((v, k) => (
                        <Option key={String(v.id)}>{v.name + ' ('+v.size+')'}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <div style={{display: (this.props.customer.mailgun_api_key ? 'block' : 'none')}}>
                <Divider></Divider>

                <Row gutter={24}>
                    <Col span={24}>
                      <Title level={4}>E-mail afzender</Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                            <Form.Item label="Afzender adres">
                          {getFieldDecorator('mailgun_from_email', {
                            initialValue: this.props.project.mailgun_from_email,
                            rules: [
                                {
                                    validator: (rule:  any, value: string, cb: (msg?: string) => void) => {

                                        if(!value){ return true; }

                                        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*$/;

                                        return !this.props.customer.mailgun_api_key || value.match(validRegex) ? cb() : cb('Vul alleen een geldig e-mailadres in tot de @. Alles vanaf de @ wordt door Cobee bepaald (deze waarde staat achteraan dit veld).')
                                    }
                                }
                            ]
                          })(
                            <Input addonAfter={'@'+this.props.customer.mailgun_from_domain} />
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Afzender naam">
                          {getFieldDecorator('mailgun_from_name', {
                            initialValue: this.props.project.mailgun_from_name,
                          })(
                            <Input />
                          )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Divider></Divider>

            <Row gutter={24}>
                <Col span={24}>
                  <Title level={4}>SMS</Title>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="SMS">
                      {getFieldDecorator('sms_sender', {
                        initialValue: this.props.project.sms_sender,
                      })(
                        <Input placeholder={this.props.customer.sms_sender} maxLength={11} />
                      )}
                    </Form.Item>
                </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={24}>
                <Title level={4}>Export</Title>
              </Col>
              <Col span={12}>
                {this.state.edit ? (
                  <div className="clearfix">
                    <Form.Item label="Voorblad afbeelding">
                      {getFieldDecorator('frontpage_id', {
                        initialValue: this.props.project.frontpage_id,
                      })(
                        <AddFromMedia
                          projectId={this.props.match.params.id}
                          initialMediaId={this.props.project.frontpage_id}
                          initialMediaUrl={this.props.project.frontpage_url}
                          onMediaSelection={(item) => this.props.form.setFieldsValue({'frontpage_id': item.id})}
                          onMediaRemove={() => this.props.form.setFieldsValue({'frontpage_id': ''})}
                          />
                      )}
                    </Form.Item>

                  </div>
                ) : (
                  <div>
                    <Text strong>Voorblad afbeelding</Text>
                    <br />
                    <Avatar size={120} shape="square"  style={{height: 'auto'}} src={this.props.project.frontpage_url} />
                  </div>
                )}
              </Col>
            </Row>

          </Form>
        </div>
      </div>
    );
  }
}

const ProjectsPersonalizeForm = Form.create({ name: 'projects_personalize' })(ProjectsPersonalize);


const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.project.isFetching,
    isError : state.project.isError,
    customer: state.customer.customer,
    project: state.project.project,
    letterpapers: state.letterpaper.letterpapers,
    selectedCustomer: state.auth.selectedCustomer
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectAction : bindActionCreators(getProjectAction, dispatch),
    patchProjectAction : bindActionCreators(patchProjectAction, dispatch),
    getLetterPapersAction : bindActionCreators(getLetterPapersAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    getPersonalizationAction : bindActionCreators(getPersonalizationAction, dispatch),
    patchPersonalizationProjectAction : bindActionCreators(patchPersonalizationProjectAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPersonalizeForm);
