import React, { Component, cloneElement } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
import {
    AuditOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ContactsOutlined,
    ContainerOutlined,
    ExperimentOutlined,
    FolderOutlined,
    ReconciliationOutlined,
    TeamOutlined,
    DownOutlined
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import {
    Modal,
    Button,
    Dropdown,
    Menu,
    Input,
    Row,
    Col,
    Upload,
    message,
    Drawer,
    Transfer,
    Tag,
    InputNumber,
    Checkbox,
    Select,
    Badge,
    Alert,
    Spin,
    Radio,
} from 'antd';
import { showSuccess, showError } from '../../../../utils/Notifications';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../constants/settings';
import _ from 'underscore';
import './export.css';
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {children.map && children.map((child) => {
        if (child.key === "sort") {
          return cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "pointer"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

class DemoCombinedExport extends Component {

  state = {
      elements: [
          {
              id: 1,
              form: 'Formulier 1',
              element: 'Vraag 1',
              type: 'Korte vraag'
          },
          {
              id: 2,
              form: 'Formulier 1',
              element: 'Vraag 2',
              type: 'Selectie'
          },
          {
              id: 3,
              form: 'Formulier 2',
              element: 'Vraag 1',
              type: 'Lange vraag'
          },
      ]
  };

  constructor(props)
  {
      super(props);
  }

  componentDidMount()
  {
  }

  //
  render()
  {
      const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
          // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        // getCheckboxProps: (record: DataType) => ({
        //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //   name: record.name,
        // }),
      };

      const columns = [
          {
            key: "sort",
            width: 20
          },
          {
            title: 'Formulier',
            dataIndex: 'form',
            render: (text, record) => (
              <span>{text}</span>
            )
          },
          {
            title: 'Element',
            dataIndex: 'element',
            render: (text, record) => (
              <span>{text}</span>
            )
          },
          {
            title: 'Type',
            dataIndex: 'type',
            render: (text, record) => (
              <span>{text}</span>
            ),
          }
      ];

      const onDragEnd = ({ active, over }) => {

        if (active.id !== over.id) {

          var elements = this.state.elements;

          const activeIndex = elements.findIndex((i) => i.id === active.id);
          const overIndex = elements.findIndex((i) => i.id === over.id);

          this.setState({ elements: arrayMove(elements, activeIndex, overIndex) }, () => {

            // var sort = this.state.addresses.map((v,k) => v.id);
            //
            // this.props.updateWorkOrderAction(this.props.match.params.id, {'ids' : sort}).then(() => {});
          });
        }
      };

      return (
          <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

              <Menu
                id="export_menu"
                mode="inline"
                style={{ width: 300}}
                selectedKeys={['combined']}
                >

                <Menu.Item onClick={() => { this.export('addresses') }} key="addresses"><ContactsOutlined /> Adressen</Menu.Item>

                { (this.props.HaveAbility('address', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 17}) && _.findWhere(this.props.selectedCustomer.modules, {id: 17}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('dossier', 'read') || this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 17})} onClick={() => { this.export('dossier') }} key="dossier"><FolderOutlined /> Dossier</Menu.Item>
                : null }

                { (this.props.HaveAbility('appointment', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 3}) && _.findWhere(this.props.selectedCustomer.modules, {id: 3}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('appointment', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 3})} onClick={() => { this.export('appointments') }} key="appointments"><CalendarOutlined /> Afspraken</Menu.Item>
                : null }

                { (this.props.HaveAbility('support-base', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 14}) && _.findWhere(this.props.selectedCustomer.modules, {id: 14}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('support-base', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 14})} onClick={() => { this.export('support_base') }} key="support_base"><TeamOutlined /> Draagvlak</Menu.Item>
                : null }

                { (this.props.HaveAbility('form', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 19}) && _.findWhere(this.props.selectedCustomer.modules, {id: 19}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('form', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 19})} onClick={() => { this.export('form') }} key="form"><ContainerOutlined /> Formulieren</Menu.Item>
                : null }

                { (this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 16}) && _.findWhere(this.props.selectedCustomer.modules, {id: 16}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('choice', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 16})} onClick={() => { this.export('choice_forms') }} key="choice_forms"><ReconciliationOutlined /> Keuze</Menu.Item>
                : null }

                { (this.props.HaveAbility('form', 'read') || this.props.HaveAbility('choice', 'read') || this.props.project.user_role === 'admin') && (_.findWhere(this.props.project.modules, {id: 19}) || _.findWhere(this.props.project.modules, {id: 16})) && (_.findWhere(this.props.selectedCustomer.modules, {id: 19}) || _.findWhere(this.props.selectedCustomer.modules, {id: 16})) ?
                <Menu.Item disabled={(!this.props.HaveAbility('form', 'read') && !this.props.HaveAbility('choice', 'read')) || !this.props.project.user_role === 'admin' || (!_.findWhere(this.props.project.modules, {id: 16}) && !_.findWhere(this.props.project.modules, {id: 19}))} onClick={() => { this.export('fuse_box_list') }} key="fuse_box_list"><ExperimentOutlined /> Meterkastlijst</Menu.Item>
                : null }

                { (this.props.HaveAbility('scheduling', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 1}) && _.findWhere(this.props.selectedCustomer.modules, {id: 1}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('scheduling', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 1})} onClick={() => { this.export('schedule') }} key="schedule"><ExperimentOutlined /> Planning</Menu.Item>
                : null }

                { (this.props.HaveAbility('survey', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 9}) && _.findWhere(this.props.selectedCustomer.modules, {id: 9}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('survey', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 9})} onClick={() => { this.export('survey_forms') }} key="survey_forms"><ExperimentOutlined /> Enquête</Menu.Item>
                : null }

                { (this.props.HaveAbility('deliver', 'read') || this.props.project.user_role === 'admin') && _.findWhere(this.props.project.modules, {id: 15}) && _.findWhere(this.props.selectedCustomer.modules, {id: 15}) ?
                <Menu.Item disabled={(!this.props.HaveAbility('deliver', 'read') || !this.props.project.user_role === 'admin') || !_.findWhere(this.props.project.modules, {id: 15})} onClick={() => { this.export('delivery_points') }} key="delivery_points"><AuditOutlined /> Opleveren</Menu.Item>
                : null }

                <Menu.Item onClick={() => { this.props.history.push('/projects/'+this.props.match.params.id+'/exports/combined'); }} key="combined"><AuditOutlined /> Samengesteld</Menu.Item>

              </Menu>

              <div style={{padding: 24, width: '100%'}}>

                  <Row className="mbm">
                      <Col span={24} >
                          <h2>Samengesteld</h2>

                          <Alert style={{marginBottom: 18}} showIcon type="info" message="Exporteren" description="Cobee genereert een Zip bestand met daarin de geselecteerde velden van de formulieren van het project. Let op: Op basis van de hoeveelheid data kan dit even duren." />

                          <Form layout="vertical">

                              <Form.Item required label="Type export">
                                  <Radio.Group value={'export'}>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'export'}>Verzamel bestand (Excel)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'pdf'}>Verzamel bestand (PDF)</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              <Form.Item required label="Opmaak">
                                  <Radio.Group value={'compact'}>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'compact'}>Compact / meterkastlijst (PDF)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'pdf'}>Formulier (PDF)</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'pdf_and_photos'}>Formulier + foto's (PDF)</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              <Form.Item required label="Voorblad">
                                  <Radio.Group value={'with_front'}>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'with_front'}>Met voorblad</Radio>
                                      <Radio style={{display: 'block', lineHeight: '30px'}} value={'without_front'}>Zonder voorblad</Radio>
                                  </Radio.Group>
                              </Form.Item>

                              <Form.Item label="Kies een opgeslagen template">
                                  <Select value={2} options={[
                                      { value: 1, label: 'Standaard' },
                                      { value: 2, label: 'Template 1' },
                                      { value: 3, label: 'Template 2' },
                                    ]}
                                    />
                              </Form.Item>

                              <Form.Item label="Type formulier">
                                  <Select value={1} options={[
                                      { value: 1, label: 'Formulier' },
                                      { value: 2, label: 'Keuze' }
                                    ]}
                                    />
                              </Form.Item>

                              <Form.Item label="Formulier">
                                  <Select value={1} options={[
                                      { value: 1, label: 'Formulier 1' },
                                      { value: 2, label: 'Formulier 2' }
                                    ]}
                                    />
                              </Form.Item>

                              <Form.Item label="Element">
                                  <Select
                                    value={[1,2]}
                                    mode="multiple"
                                    options={[
                                      { label: 'Sectie 1', title: 'Sectie 1', options: [
                                          { value: 1, label: 'Element 1' },
                                          { value: 2, label: 'Element 2' }
                                      ]},
                                      { label: 'Sectie 2', title: 'Sectie 2', options: [
                                          { value: 3, label: 'Element 3' },
                                          { value: 4, label: 'Element 4' }
                                      ]},
                                    ]}
                                    />
                              </Form.Item>

                          </Form>

                          <Button type="new">Exporteren</Button>

                          <Dropdown disabled={!this.props.HaveAbility('address', 'write')} trigger={['click']} overlay={(
                            <Menu>
                              <Menu.Item key="1">
                                <Link to={null} onClick={() => {}}>
                                  Verwijderen
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="2">
                                <Link to={null} onClick={() => {}}>
                                  Opslaan als nieuwe template
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="3">
                                <Link to={null} onClick={() => {}}>
                                  Opslaan in template
                                </Link>
                              </Menu.Item>
                            </Menu>
                          )}>
                            <Button>
                              Acties <DownOutlined />
                            </Button>
                          </Dropdown>
                      </Col>
                  </Row>

                  <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                      items={this.state.elements && this.state.elements.map && this.state.elements.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      <CustomTable
                        rowKey='id'
                        columns={columns}
                        dataSource={this.state.elements}
                        components={{
                            body: {
                                row: DraggableRow
                            }
                        }}
                        size="middle"
                        // scroll={{ y: 600 }}
                        rowSelection={{
                          type: 'checkbox',
                          ...rowSelection,
                        }}
                        pagination={false}
                        />
                    </SortableContext>
                  </DndContext>

              </div>
          </div>
      );
    }
}

const DemoCombinedExportForm = Form.create({})(DemoCombinedExport);

const mapStateToProps = (state, ownProps) => {
  return {
    project: state.project.project,
    selectedCustomer: state.auth.selectedCustomer
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoCombinedExportForm);
