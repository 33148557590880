import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, Radio, DatePicker, Select, Row, Col, Table, Checkbox, Card, InputNumber, Switch, TimePicker, Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';
import moment from 'moment';
import _ from 'underscore';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

class PatchAppointment extends Component {

  state = {
      occupant_view_only: false
  }

  componentDidMount() {

    this.setState({
        occupant_view_only: (this.props.appointment.occupant_view_only ? true : false),
        automatic_reminder: (this.props.appointment.nr_of_hours_before_appointment_reminder >= 1 ? true : false),
        nr_of_hours_before_appointment_reminder: this.props.appointment.nr_of_hours_before_appointment_reminder >= 1 ? this.props.appointment.nr_of_hours_before_appointment_reminder : 0
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.props.onSubmit} layout={'vertical'} colon={true}>
        <Row gutter={24} className='mbm'>
            <Col span={24}>
                <Card>
                    <Row gutter={24} className='mbm'>
                      <Col span={24}>
                        <Form.Item required label="Onderwerp" hasFeedback>
                          {getFieldDecorator('subject', {
                            initialValue: this.props.appointment.subject,
                            rules: [{ required: true, message: 'Vul een onderwerp in' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>

                        {/*<Form.Item label="Aangemaakt door" hasFeedback>
                          {getFieldDecorator('created_by_user_id', {
                          initialValue: this.props.appointment.created_by_user_id,
                          //rules: [{ required: true, message: 'Vul een gebruiker in!' }],
                          })(
                          <Input />
                          )}
                          </Form.Item>*/}
                      </Col>
                      <Col span={12}>
                          <Form.Item required label="Contactpersoon e-mailadres" hasFeedback>
                            {getFieldDecorator('contact_email', {
                              initialValue: this.props.appointment.contact_email,
                              rules: [{ required: true, type: 'email', message: 'Vul een geldig e-mailadres in'}],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                      </Col>

                      <Col span={12}>
                          <Form.Item key={2} required label="Contactpersoon telefoonnummer" hasFeedback>
                            {getFieldDecorator('contact_phone_number', {
                              initialValue: this.props.appointment.contact_phone_number ? this.props.appointment.contact_phone_number : '',
                              rules: [{ required: true, message: 'Vul een telefoonnummer in' }, {min: 10, message: 'Vul minimaal 10 cijfers in'}],
                            })(
                              <Input allowEmptyFormatting={true} format="##########" mask="" className="ant-input"/>
                            )}
                          </Form.Item>
                      </Col>

                    </Row>

                    <Row gutter={24} className='mbm'>

                        <Col span={12}>
                            <Form.Item label="Bewoner mag afspraak alleen inzien">
                              {getFieldDecorator('occupant_view_only', {
                                valuePropName: 'checked',
                                initialValue: this.props.appointment.occupant_view_only ? true : false,
                                onChange: (value) => { this.setState({ occupant_view_only: value }); }
                              })(
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                />
                              )}
                            </Form.Item>
                        </Col>

                        <Col span={12} style={{ display: !this.state.occupant_view_only ? 'block' : 'none' }}>
                            <Form.Item label="Bewoner heeft mogelijkheid om afspraak te annuleren">
                              {getFieldDecorator('possibility_for_occupant_to_cancel_appointment', {
                                valuePropName: 'checked',
                                initialValue: this.props.appointment.possibility_for_occupant_to_cancel_appointment ? true : false,
                                //rules: [{ required: true, message: 'Vul een tijd in min. in!' }],
                              })(
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                />
                              )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24} className='mbm'>
                        <Col span={12}>
                            <Form.Item label="Automatisch een herinnering versturen">
                              {getFieldDecorator('automatic_reminder', {
                                valuePropName: 'checked',
                                initialValue: this.props.appointment.nr_of_hours_before_appointment_reminder >= 1 ? true : false,
                                onChange: (value) => { this.setState({ automatic_reminder: value }); this.props.form.setFieldsValue({nr_of_hours_before_appointment_reminder: (!value ? 0 : 24)}); }
                                //rules: [{ required: true, message: 'Vul een tijd in min. in!' }],
                              })(
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                />
                              )}
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{display: this.state.automatic_reminder ? 'block' : 'none'}}>
                            <Form.Item label="Hoeveel uur voor de afspraak moet er een herinnering verstuurd worden?">
                              {getFieldDecorator('nr_of_hours_before_appointment_reminder', {
                                initialValue: this.state.nr_of_hours_before_appointment_reminder,
                                value: this.state.nr_of_hours_before_appointment_reminder, // this.props.appointment.nr_of_hours_before_appointment_reminder ? this.props.appointment.nr_of_hours_before_appointment_reminder : 24,
                                onChange: (value) => { this.setState({ nr_of_hours_before_appointment_reminder: value }); this.props.form.setFieldsValue({ nr_of_hours_before_appointment_reminder: value }); },
                                rules: [{ required: false, message: 'Vul een numerieke waarde in!' }],
                              })(
                                <InputNumber min={0} />
                              )}
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={24} className='mbm'>
                        <Col span={12}>
                          <Form.Item label={(
                              <span>
                                E-mail tekst voor bevestiging
                                {/*<Tooltip className="mhs">
                                  <Icon type="info-circle" theme="twoTone" />
                                </Tooltip>*/}
                              </span>
                              )}>
                              {getFieldDecorator('confirmation_email', {
                                initialValue: this.props.appointment && this.props.appointment.confirmation_email ? this.props.appointment.confirmation_email : 'Geachte heer/mevrouw,<br />\
<br />\
Hierbij bevestigen wij uw afspraak voor <placeholder id="current" class="cobee-tag">afspraak: onderwerp</placeholder><br />\
Datum: <placeholder id="current" class="cobee-tag">datum afspraak</placeholder><br />\
Tijdstip: <placeholder id="current" class="cobee-tag">tijd afspraak</placeholder><br />\
<br />\
Indien u vragen heeft over de afspraak, neemt u dan contact op via telefoonnummer <placeholder id="current" class="cobee-tag">contactpersoon telefoonnummer</placeholder>.<br />\
<br />\
Bent u verhinderd? Dan verzoeken wij u minimaal <placeholder id="current" class="cobee-tag">minimale tijd in uren voor annuleren</placeholder> uur voorafgaand aan de afspraak te annuleren. Om de afspraak te wijzigen of annuleren logt u in en gaat u naar de pagina "Afspraak maken".<br />\
<br />\
Met vriendelijke groet,<br />\
<br />\
<placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                                rules: [{ required: true, message: 'Vul een herinneringstekst voor de e-mail in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                                // onEditorChange: (value, editor) => { this.props.form.setFieldsValue({confirmation_email: value}); this.setState({ confirmation_email: value }); }
                              })(
                                <CustomEditor height="400" placeholders={'appointment-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ confirmation_email: e }); }} />
                              )}
                            </Form.Item>
                        </Col>
                        { this.props.sms_enabled ? <Col span={12}>
                          <Form.Item label={(
                              <span>
                                SMS tekst voor bevestiging
                                <Tooltip className="mhs" title='Gebruik de placeholder [datum afspraak] om de datum en [tijd afspraak] om de tijd van de afspraak per adres in de SMS in te laden.'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                              )}>
                              {/*getFieldDecorator('reminder_text', {
                                initialValue: this.props.appointment && this.props.appointment.reminder_text ? this.props.appointment.reminder_text : 'Beste bewoner, graag herinneren wij u aan de afspraak op [datum afspraak] om [tijdstip afspraak] uur. Graag tot dan!',
                                rules: [{ message: 'Vul een herinneringstekst voor de SMS in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                                onEditorChange: (value, editor) => { this.props.form.setFieldsValue({reminder_text: value}); this.setState({ reminder_text: value }); },
                              })(
                                <CustomEditor placeholders={'appointment-sms'} project_id={this.props.match.params.id} />
                              )*/}
                              {getFieldDecorator('confirmation_text', {
                                  initialValue: this.props.appointment && this.props.appointment.confirmation_text ? this.props.appointment.confirmation_text : 'Beste bewoner, hierbij bevestigen wij de afspraak op [datum afspraak] om [tijd afspraak] uur. Graag tot dan!',
                                  rules: [{ required: true, message: 'Vul een bevestigingstekst in' }],
                              })(
                              <Input.TextArea />
                              )}

                              {(this.props.form.getFieldValue('confirmation_text') ? this.props.form.getFieldValue('confirmation_text').length : 0) + " tekens / " + Math.ceil(this.props.form.getFieldValue('confirmation_text') ? (this.props.form.getFieldValue('confirmation_text').length / 160) : 0) + " SMS bericht(en)" }

                            </Form.Item>
                        </Col> : null}
                    </Row>


                    <Row gutter={24} className='mbm'>
                        <Col span={12}>
                          <Form.Item label={(
                              <span>
                                E-mail tekst voor herinnering
                                {/*<Tooltip className="mhs">
                                  <Icon type="info-circle" theme="twoTone" />
                                </Tooltip>*/}
                              </span>
                              )}>
                              {getFieldDecorator('reminder_email', {
                                initialValue: this.props.appointment && this.props.appointment.reminder_email ? this.props.appointment.reminder_email : 'Beste bewoner,<br/>\
<br/>\
Graag herinneren wij u aan de afspraak <placeholder id="current" class="cobee-tag">afspraak: onderwerp</placeholder><br/>\
Datum:<placeholder id="current" class="cobee-tag">datum afspraak</placeholder><br/>\
Tijd: <placeholder id="current" class="cobee-tag">tijd afspraak</placeholder><br/>\
<br/>\
Graag tot dan!<br/>\
<br/>\
Met vriendelijke groet,<br/>\
<br/>\
<placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                                rules: [{ required: true, message: 'Vul een herinneringstekst voor de e-mail in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                                // onEditorChange: (value, editor) => { this.props.form.setFieldsValue({reminder_email: value}); this.setState({ reminder_email: value }); }
                              })(
                                <CustomEditor height="400" placeholders={'appointment-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ reminder_email: e }); }} />
                              )}
                            </Form.Item>
                        </Col>
                        { this.props.sms_enabled ? <Col span={12}>
                          <Form.Item label={(
                              <span>
                                SMS tekst voor herinnering
                                <Tooltip className="mhs" title='Gebruik de placeholder [datum afspraak] om de datum en [tijd afspraak] om de tijd van de afspraak per adres in de SMS in te laden.'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                              )}>
                              {/*getFieldDecorator('reminder_text', {
                                initialValue: this.props.appointment && this.props.appointment.reminder_text ? this.props.appointment.reminder_text : 'Beste bewoner, graag herinneren wij u aan de afspraak op [datum afspraak] om [tijdstip afspraak] uur. Graag tot dan!',
                                rules: [{ message: 'Vul een herinneringstekst voor de SMS in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                                onEditorChange: (value, editor) => { this.props.form.setFieldsValue({reminder_text: value}); this.setState({ reminder_text: value }); },
                              })(
                                <CustomEditor placeholders={'appointment-sms'} project_id={this.props.match.params.id} />
                              )*/}
                              {getFieldDecorator('reminder_text', {
                                  initialValue: this.props.appointment && this.props.appointment.reminder_text ? this.props.appointment.reminder_text : 'Beste bewoner, graag herinneren wij u aan de afspraak op [tijdstip afspraak] uur. Graag tot dan!',
                                  rules: [{ required: true, message: 'Vul een herinneringstekst in' }],
                              })(
                              <Input.TextArea />
                              )}

                              {(this.props.form.getFieldValue('reminder_text') ? this.props.form.getFieldValue('reminder_text').length : 0) + " tekens / " + Math.ceil(this.props.form.getFieldValue('reminder_text') ? (this.props.form.getFieldValue('reminder_text').length / 160) : 0) + " SMS bericht(en)" }

                            </Form.Item>
                        </Col> : null }
                    </Row>


                    <Row gutter={24} className='mbm'>
                        <Col span={12}>
                          <Form.Item label={(
                              <span>
                                E-mail tekst voor annulering
                              </span>
                              )}>
                              {getFieldDecorator('cancel_email', {
                                initialValue: this.props.appointment && this.props.appointment.cancel_email ? this.props.appointment.cancel_email : 'Beste bewoner,<br/>\
<br/>\
Hierbij bevestigen wij de annulering van de afspraak <placeholder id="current" class="cobee-tag">afspraak: onderwerp</placeholder><br/>\
Datum:<placeholder id="current" class="cobee-tag">datum afspraak</placeholder><br/>\
Tijd: <placeholder id="current" class="cobee-tag">tijd afspraak</placeholder><br/>\
<br/>\
Met vriendelijke groet,<br/>\
<br/>\
<placeholder class="cobee-tag">bedrijfsnaam</placeholder>',
                                rules: [{ required: true, message: 'Vul een annuleringstekst voor de e-mail in' }],
                                valuePropName: 'value',
                                trigger: 'onEditorChange',
                              })(
                                <CustomEditor height="400" placeholders={'appointment-email'} project_id={this.props.match.params.id} messageChanged={(e) => { this.setState({ cancel_email: e }); }} />
                              )}
                            </Form.Item>
                        </Col>
                        { this.props.sms_enabled ? <Col span={12}>
                          <Form.Item label={(
                              <span>
                                SMS tekst voor annulering
                                <Tooltip className="mhs" title='Gebruik de placeholder [datum afspraak] om de datum en [tijd afspraak] om de tijd van de afspraak per adres in de SMS in te laden.'>
                                  <InfoCircleTwoTone />
                                </Tooltip>
                              </span>
                              )}>
                              {getFieldDecorator('cancel_text', {
                                  initialValue: this.props.appointment && this.props.appointment.cancel_text ? this.props.appointment.cancel_text : 'Beste bewoner, hierbij bevestigen wij de annulering van de afspraak op [tijdstip afspraak].',
                                  rules: [{ required: true, message: 'Vul een annuleringstekst in' }],
                              })(
                              <Input.TextArea />
                              )}

                              {(this.props.form.getFieldValue('cancel_text') ? this.props.form.getFieldValue('cancel_text').length : 0) + " tekens / " + Math.ceil(this.props.form.getFieldValue('cancel_text') ? (this.props.form.getFieldValue('cancel_text').length / 160) : 0) + " SMS bericht(en)" }

                            </Form.Item>
                        </Col> : null }
                    </Row>

                </Card>
            </Col>
        </Row>

        <Row gutter={24} className='mbm'>
          <Col span={24}>
            <Card>
                <Row>
                  <Col span={24}>
                  <Form.Item disabled required label="Type afspraak" hasFeedback>
                    {getFieldDecorator('type', {
                      initialValue: this.props.appointment.type ? this.props.appointment.type : 'before',
                      rules: [{ required: true, message: 'Geef een type afspraak op' }],
                    })(
                      <Select>
                        <Select.Option value="before">Vooraf inplannen</Select.Option>
                        <Select.Option value="free">Vrije keuze</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item disabled required label={
                        <span>
                            Afspraak inplannen
                            <Tooltip className="mhs" title='Geef op hoe de afspraken ingepland moeten worden. Moet een afspraak op een specifieke dag en tijd gemaakt worden of binnen een bepaald deel van de dag, bijvoorbeeld in de middag.'>
                              <InfoCircleTwoTone />
                            </Tooltip>
                        </span>
                    } hasFeedbackhasFeedback>
                      {getFieldDecorator('slot', {
                        initialValue: this.props.appointment.slot ? this.props.appointment.slot : 'specific',
                        rules: [{ required: true, message: 'Geef een soort tijdslot op' }],
                      })(
                        <Select>
                          <Select.Option value="specific">Specifieke dag en tijd</Select.Option>
                          <Select.Option value="range">Bepaald deel van de dag</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item required label={
                        <span>
                            Afspraak inplannen vanaf
                            <Tooltip className="mhs" title='Vul hier een datum in vanaf wanneer de afspraken plaats kunnen vinden. In een volgende stap kunnen exacte dagen en tijdstippen worden aangegeven van wanneer een afspraak plaats kan vinden.'>
                              <InfoCircleTwoTone />
                            </Tooltip>
                        </span>
                    } hasFeedback>
                      {getFieldDecorator('schedule_from_date', {
                        initialValue: this.props.appointment && this.props.appointment.schedule_from_date ? dayjs(moment(this.props.appointment.schedule_from_date)) : dayjs(moment()),
                        rules: [{ required: true, message: 'Vul een datum in' }],
                      })(
                        <DatePicker format={'DD/MM/YYYY'} />
                      )}
                    </Form.Item>
                  </Col>

                  {this.props.form.getFieldValue('type') == 'before' ? (
                    <Col span={12}>
                      <Form.Item required label="Tot hoeveel uur voor de afspraak mag er gewijzigd/geannuleerd worden?" hasFeedback>
                        {getFieldDecorator('max_hours_before_appointment_change_allowed', {
                          initialValue: this.props.appointment.max_hours_before_appointment_change_allowed ? this.props.appointment.max_hours_before_appointment_change_allowed : 48,
                          rules: [{ required: true, message: 'Vul een numerieke waarde in!' }],
                        })(
                          <InputNumber min={0} />
                        )}
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={12}>
                      <Form.Item required label={
                          <span>
                              Afspraken kunnen gemaakt worden tot
                              <Tooltip className="mhs" title='Vul hier een datum in tot wanneer afspraken gemaakt of gewijzigd kunnen worden. Na deze datum kan de afspraak dus niet meer worden gemaakt of gewijzigd, ook al ligt de datum van de afspraak nog in de toekomst.'>
                                <InfoCircleTwoTone />
                              </Tooltip>
                          </span>
                      }>
                        {getFieldDecorator('allow_appointments_to_be_made_till', {
                          initialValue: this.props.appointment.allow_appointments_to_be_made_till ? dayjs(moment(this.props.appointment.allow_appointments_to_be_made_till)) : null,
                          rules: [{ required: true, message: 'Vul een datum in!' }],
                        })(
                          <DatePicker format={'DD/MM/YYYY'} />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                <Col span={12}>
                  <Form.Item required label="Benodigde tijd (in minuten) per afspraak" hasFeedback>
                    {getFieldDecorator('required_minutes_per_appointment', {
                      initialValue: this.props.appointment.required_minutes_per_appointment ? this.props.appointment.required_minutes_per_appointment : '',
                      rules: [{ required: true, message: 'Vul een tijd in minuten in' }],
                    })(
                      <InputNumber min={1} />
                    )}
                  </Form.Item>
                </Col>



                {this.props.form.getFieldValue('type') == 'free' ? (
                  <Col span={12}>
                    <Form.Item required label="Melding indien er niet op tijd een dag en tijd gekozen is" hasFeedback>
                      {getFieldDecorator('no_appointment_action', {
                        initialValue: this.props.appointment.no_appointment_action ? String(this.props.appointment.no_appointment_action) : '1',
                        rules: [{ required: true, message: 'Vul een herinnering tekst in!' }],
                      })(
                        <Select>
                          <Select.Option key='1'>Niet gepland. Wij nemen contact met u op voor het maken van een afspraak.</Select.Option>
                          <Select.Option key='2'>Niet gepland. Wij komen op een willekeurig moment bij u langs</Select.Option>
                          <Select.Option key='3'>Geen melding. Er wordt een door ons gekozen dag en tijd weergegeven.</Select.Option>
                          <Select.Option key='4'>Aangepaste melding. Vul deze hiernaast in.</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                ) : null}

                {this.props.form.getFieldValue('no_appointment_action') == '4' && this.props.form.getFieldValue('type') == 'free' ? (
                  <Col span={12}>
                    <Form.Item required label="Aangepaste melding" hasFeedback>
                      {getFieldDecorator('additional_message', {
                        initialValue: this.props.appointment.additional_message,
                        rules: [{ required: true, message: 'Vul een Aangepaste melding in!' }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                ) : null}
                </Row>
            </Card>
          </Col>
        </Row>

        { this.props.form.getFieldValue('slot') == 'specific' ?
        <Row gutter={24} className='mbm'>
          <Col span={24}>
            <Card>
              <Row gutter={24}>
              <Col span={12}>
                <Row className="mbs" gutter={24}>
                  <Col span={8}><b>Dag</b></Col>
                  <Col span={8}><b>Van</b></Col>
                  <Col span={8}><b>Tot</b></Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('monday_workday', {
                        initialValue: this.props.appointment.monday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Maandag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('monday_from', {
                        initialValue: this.props.appointment.monday_from ? dayjs(moment(this.props.appointment.monday_from, 'HH:mm')) : null,
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('monday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('monday_till', {
                        initialValue: this.props.appointment.monday_till ? dayjs(moment(this.props.appointment.monday_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('monday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('tuesday_workday', {
                        initialValue: this.props.appointment.tuesday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Dinsdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('tuesday_from', {
                        initialValue: this.props.appointment.tuesday_from ? dayjs(moment(this.props.appointment.tuesday_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('tuesday_workday') } minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('tuesday_till', {
                        initialValue: this.props.appointment.tuesday_till ? dayjs(moment(this.props.appointment.tuesday_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('tuesday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('wednesday_workday', {
                        initialValue: this.props.appointment.wednesday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Woensdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('wednesday_from', {
                        initialValue: this.props.appointment.wednesday_from ? dayjs(moment(this.props.appointment.wednesday_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('wednesday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('wednesday_till', {
                        initialValue: this.props.appointment.wednesday_till ? dayjs(moment(this.props.appointment.wednesday_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('wednesday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('thursday_workday', {
                        initialValue: this.props.appointment.thursday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Donderdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('thursday_from', {
                        initialValue: this.props.appointment.thursday_from ? dayjs(moment(this.props.appointment.thursday_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('thursday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('thursday_till', {
                        initialValue: this.props.appointment.thursday_till ? dayjs(moment(this.props.appointment.thursday_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('thursday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('friday_workday', {
                        initialValue: this.props.appointment.friday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Vrijdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('friday_from', {
                        initialValue: this.props.appointment.friday_from ? dayjs(moment(this.props.appointment.friday_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('friday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('friday_till', {
                        initialValue: this.props.appointment.friday_till ? dayjs(moment(this.props.appointment.friday_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('friday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('saturday_workday', {
                        initialValue: this.props.appointment.saturday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Zaterdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('saturday_from', {
                        initialValue: this.props.appointment.saturday_from ? dayjs(moment(this.props.appointment.saturday_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('saturday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('saturday_till', {
                        initialValue: this.props.appointment.saturday_till ? dayjs(moment(this.props.appointment.saturday_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('saturday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('sunday_workday', {
                        initialValue: this.props.appointment.sunday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Zondag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('sunday_from', {
                        initialValue: this.props.appointment.sunday_from ? dayjs(moment(this.props.appointment.sunday_from, 'HH:mm')) : null,
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('sunday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('sunday_till', {
                        initialValue: this.props.appointment.sunday_till ? dayjs(moment(this.props.appointment.sunday_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('sunday_workday')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row className="mbs" gutter={24}>
                  <Col span={8}><b>Pauze</b></Col>
                  <Col span={8}><b>Van</b></Col>
                  <Col span={8}><b>Tot</b></Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_1', {
                        initialValue: this.props.appointment.break_1 ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Pauze 1
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_1_from', {
                        initialValue: this.props.appointment.break_1_from ? dayjs(moment(this.props.appointment.break_1_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('break_1')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_1_till', {
                        initialValue: this.props.appointment.break_1_till ? dayjs(moment(this.props.appointment.break_1_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('break_1')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_2', {
                        initialValue: this.props.appointment.break_2 ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Pauze 2
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_2_from', {
                        initialValue: this.props.appointment.break_2_from ? dayjs(moment(this.props.appointment.break_2_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('break_2')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_2_till', {
                        initialValue: this.props.appointment.break_2_till ? dayjs(moment(this.props.appointment.break_2_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('break_2')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_3', {
                        initialValue: this.props.appointment.break_3 ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Pauze 3
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_3_from', {
                        initialValue: this.props.appointment.break_3_from ? dayjs(moment(this.props.appointment.break_3_from, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('break_3')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('break_3_till', {
                        initialValue: this.props.appointment.break_3_till ? dayjs(moment(this.props.appointment.break_3_till, 'HH:mm')) : null
                      })(
                        <TimePicker disabled={/*this.props.appointment.type ||*/ !this.props.form.getFieldValue('break_3')} minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mbs" gutter={24}>
                  <Col span={8}><b>Dagdeel</b>
                  <Tooltip className="mhs" title='Deze dagdelen zijn alleen bedoeld voor communicatiedoeleinden.'>
                    <InfoCircleTwoTone />
                  </Tooltip></Col>
                  <Col span={8}><b>Van</b></Col>
                  <Col span={8}><b>Tot</b></Col>
                </Row>
                { this.props.dayparts && this.props.dayparts.map((obj, index) => {
                return <Row className="mbs" gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('dayparts['+index+']', {
                        initialValue: obj.name ? obj.name : null
                      })(
                        <Input style={{width: '100%'}} />
                      )}
                    </Form.Item>

                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('from_time['+index+']', {
                        initialValue: obj.from_time ? dayjs(obj.from_time, 'HH:mm') : null
                      })(
                        <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('till_time['+index+']', {
                        initialValue: obj.till_time ? dayjs(obj.till_time, 'HH:mm') : null
                      })(
                        <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                }) }
                <Row className="mbs" gutter={24}>
                  <Col span={24}>
                    <Button type="dashed" onClick={() => { this.props.addDaypart(); }}>
                      <PlusOutlined /> Dagdeel toevoegen
                    </Button>
                  </Col>
                </Row>
              </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        :
        <Row>
          <Col span={24}>

            <Card>

            <Row gutter={24}>

              <Col span={8}>

                <Row className="mbs" gutter={24}>
                  <Col span={8}><b>Dag</b></Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('monday_workday', {
                        initialValue: this.props.appointment.monday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Maandag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('tuesday_workday', {
                        initialValue: this.props.appointment.tuesday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Dinsdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('wednesday_workday', {
                        initialValue: this.props.appointment.wednesday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Woensdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('thursday_workday', {
                        initialValue: this.props.appointment.thursday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Donderdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('friday_workday', {
                        initialValue: this.props.appointment.friday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Vrijdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('saturday_workday', {
                        initialValue: this.props.appointment.saturday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Zaterdag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('sunday_workday', {
                        initialValue: this.props.appointment.sunday_workday ? true : false,
                        valuePropName: 'checked'
                      })(
                        <Checkbox>
                          Zondag
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

              </Col>

              <Col span={16}>

                <Row className="mbs" gutter={24}>
                  <Col span={12}><b>Dagdeel</b></Col>
                  <Col span={4}><b>Van</b></Col>
                  <Col span={4}><b>Tot</b></Col>
                  <Col span={4}><b>Hoeveel afspraken</b></Col>
                </Row>
                { this.props.dayparts && this.props.dayparts.map((obj, index) => {
                return <Row className="mbs" gutter={24}>
                  <Col span={12}>
                    <Form.Item>
                      {getFieldDecorator('dayparts['+index+']', {
                        initialValue: obj.name ? obj.name : null
                      })(
                        <Input style={{width: '100%'}} placeholder={'Omschrijving (bijvoorbeeld: ochtend)'} />
                      )}
                    </Form.Item>

                  </Col>
                  <Col span={4}>
                    <Form.Item>
                      {getFieldDecorator('from_time['+index+']', {
                        initialValue: obj.from_time ? dayjs(obj.from_time, 'HH:mm') : null
                      })(
                        <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item>
                      {getFieldDecorator('till_time['+index+']', {
                        initialValue: obj.till_time ? dayjs(obj.till_time, 'HH:mm') : null
                      })(
                        <TimePicker minuteStep={15} format="HH:mm" style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item>
                      {getFieldDecorator('max_nr_of_appointments['+index+']', {
                        initialValue: obj.max_nr_of_appointments ? obj.max_nr_of_appointments : 1
                      })(
                        <InputNumber stepSize={1} min={1} style={{width: '100%'}} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                }) }
                <Row className="mbs" gutter={24}>
                  <Col span={24}>
                    <Button type="dashed" onClick={() => { this.props.addDaypart(); }}>
                      <PlusOutlined /> Dagdeel toevoegen
                    </Button>
                  </Col>
                </Row>
              </Col>

              </Row>

            </Card>

          </Col>
        </Row>
        }
      </Form>
    );
      }
    }

export default Form.create({ name: 'patch_appointment_form' })(PatchAppointment);
