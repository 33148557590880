import React from 'react'
import { v1 as uuidv1 } from 'uuid'
import { FormOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Button, Input, Select, Tooltip, Modal, Affix, List } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'underscore'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import SectionItem from './Components/SectionItem'

// elements form
import TextInput from './Components/Elements/TextInput';
import TextAreaInput from './Components/Elements/TextAreaInput';
import NumberInput from './Components/Elements/NumberInput';
import TimeInput from './Components/Elements/TimeInput';
import SelectInput from './Components/Elements/SelectInput';
import TitleInput from './Components/Elements/TitleInput';
import DateInput from './Components/Elements/DateInput';
import SignatureInput from './Components/Elements/SignatureInput';
import ContactInput from './Components/Elements/ContactInput';
import PhotoInput from './Components/Elements/PhotoInput';
import DrawingInput from './Components/Elements/DrawingInput';
import Image from './Components/Elements/Image';
import SmileyInput from './Components/Elements/SmileyInput';

// support base
import SBTextInput from './Components/SupportBaseElements/SBTextInput';
import SBTextAreaInput from './Components/SupportBaseElements/SBTextAreaInput';
import SBNumberInput from './Components/SupportBaseElements/SBNumberInput';
import SBTimeInput from './Components/SupportBaseElements/SBTimeInput';
import SBSelectInput from './Components/SupportBaseElements/SBSelectInput';
import SBTitleInput from './Components/SupportBaseElements/SBTitleInput';
import SBDateInput from './Components/SupportBaseElements/SBDateInput';
import SBAgreeInput from './Components/SupportBaseElements/SBAgreeInput';
import SBSignatureInput from './Components/SupportBaseElements/SBSignatureInput';
import SBContactInput from './Components/SupportBaseElements/SBContactInput';

// survey
import STitleInput from './Components/Survey/STitleInput';
import SSelectInput from './Components/Survey/SSelectInput';
import STextInput from './Components/Survey/STextInput';
import STextAreaInput from './Components/Survey/STextAreaInput';
import SSmileyInput from './Components/Survey/SSmileyInput';
import SPhotoInput from './Components/Survey/SPhotoInput';

// choice
import CTextInput from './Components/Choice/CTextInput';
import CTextAreaInput from './Components/Choice/CTextAreaInput';
import CNumberInput from './Components/Choice/CNumberInput';
import CCalculatorInput from './Components/Choice/CCalculatorInput';
import CTimeInput from './Components/Choice/CTimeInput';
import CChoiceInput from './Components/Choice/CChoiceInput';
import CTitleInput from './Components/Choice/CTitleInput';
import CDateInput from './Components/Choice/CDateInput';
//import CSignatureInput from './Components/Choice/CSignatureInput';
import CContactInput from './Components/Choice/CContactInput';
import CSelectInput from './Components/Choice/CSelectInput';
import CAddressInput from './Components/Choice/CAddressInput';
import CPhotoInput from './Components/Choice/CPhotoInput';

// form
import FTextInput from './Components/Form/FTextInput';
import FTextAreaInput from './Components/Form/FTextAreaInput';
import FNumberInput from './Components/Form/FNumberInput';
import FTimeInput from './Components/Form/FTimeInput';
import FSelectInput from './Components/Form/FSelectInput';
import FTitleInput from './Components/Form/FTitleInput';
import FDateInput from './Components/Form/FDateInput';
import FPhotoInput from './Components/Form/FPhotoInput';
import FContactInput from './Components/Form/FContactInput';
import FDrawingInput from './Components/Form/FDrawingInput';
// import FImage from './Components/Form/FImage';
// import FSignatureInput from './Components/Form/FSignatureInput';

// deliver
import DTextInput from './Components/Deliver/DTextInput';
import DTextAreaInput from './Components/Deliver/DTextAreaInput';
import DNumberInput from './Components/Deliver/DNumberInput';
import DTimeInput from './Components/Deliver/DTimeInput';
import DSelectInput from './Components/Deliver/DSelectInput';
import DTitleInput from './Components/Deliver/DTitleInput';
import DDateInput from './Components/Deliver/DDateInput';
import DPhotoInput from './Components/Deliver/DPhotoInput';
import DContactInput from './Components/Deliver/DContactInput';
import DDrawingInput from './Components/Deliver/DDrawingInput';
import DDeliveryPointsInput from './Components/Deliver/DDeliveryPointsInput';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result
}

const grid = 24;

const getSectionStyle = (isDragging, draggableStyle) => ({

  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  height: isDragging ? 48 : null,
  //padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle
});

const getElementStyle = (isDragging, draggableStyle) => ({

  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  height: isDragging ? 48 : null,
  //padding: grid * 2,
  margin: `0 0 ${isDragging ? grid : 24}px 0`,
  ...draggableStyle
});

class FormBuilder extends React.Component {

  nr_of_signature_fields = 0;
  nr_of_agree_fields = 0;
  nr_of_deliverypoints_fields = 0;

  constructor(props) {

    super(props);

    this.state = {
      nr_of_signature_fields: 0,
      nr_of_agree_fields: 0,
      nr_of_deliverypoints_fields: 0,
      saveSectionPresetModal: false,
      saveElementPresetModal: false,
      elements: [
        {'id':'title', 'element':'title', 'label': 'Toelichting', 'component': <TitleInput />},
        {'id':'textinput', 'element':'textinput', 'label': 'Kort antwoord', 'component': <TextInput />},
        {'id':'textareainput', 'element':'textareainput', 'label': 'Lang antwoord', 'component': <TextAreaInput />},
        {'id':'numberinput', 'element':'numberinput', 'label': 'Nummer', 'component': <NumberInput />},
        {'id':'timeinput', 'element':'timeinput', 'label': 'Tijd', 'component': <TimeInput />},
        {'id':'select', 'element':'select', 'label': 'Selectie', 'component': <SelectInput />},
        {'id':'dateinput', 'element':'dateinput', 'label': 'Datum', 'component': <DateInput />},
        {'id':'signatureinput', 'element':'signatureinput', 'label': 'Handtekening', 'component': <SignatureInput />},
        {'id':'contactinput', 'element':'contactinput', 'label': 'Contactpersoon', 'component': <ContactInput />},
        {'id':'photoinput', 'element':'photoinput', 'label': 'Foto', 'component': <PhotoInput />},
        {'id':'drawinginput', 'element':'drawinginput', 'label': 'Tekening', 'component': <DrawingInput />},
        {'id':'image', 'element':'image', 'label': <><span>Afbeelding</span><Tooltip className="mhs" title='Deze afbeelding is alleen zichtbaar op het ingevulde formulier.'><InfoCircleTwoTone /></Tooltip></>, 'component': <Image />},
        {'id':'smileyinput', 'element':'smileyinput', 'label': 'Waardering ☺', 'component': <SmileyInput />},

        {'id':'sb_title', 'element':'sb_title', 'label': 'Toelichting', 'component': <SBTitleInput />},
        {'id':'sb_textinput', 'element':'sb_textinput', 'label': 'Kort antwoord', 'component': <SBTextInput />},
        {'id':'sb_textareainput', 'element':'sb_textareainput', 'label': 'Lang antwoord', 'component': <SBTextAreaInput />},
        {'id':'sb_numberinput', 'element':'sb_numberinput', 'label': 'Nummer', 'component': <SBNumberInput />},
        {'id':'sb_timeinput', 'element':'sb_timeinput', 'label': 'Tijd', 'component': <SBTimeInput />},
        {'id':'sb_select', 'element':'sb_select', 'label': 'Selectie', 'component': <SBSelectInput />},
        {'id':'sb_dateinput', 'element':'sb_dateinput', 'label': 'Datum', 'component': <SBDateInput />},
        {'id':'sb_agreeinput', 'element':'sb_agreeinput', 'label': 'Akkoord', 'component': <SBAgreeInput />},
        {'id':'sb_signatureinput', 'element':'sb_signatureinput', 'label': 'Handtekening', 'component': <SBSignatureInput />},
        {'id':'sb_contactinput', 'element':'sb_contactinput', 'label': 'Contactpersoon', 'component': <SBContactInput />},

        {'id':'s_title', 'element':'s_title', 'label': 'Toelichting', 'component': <STitleInput />},
        {'id':'s_textinput', 'element':'s_textinput', 'label': 'Kort antwoord', 'component': <STextInput />},
        {'id':'s_textareainput', 'element':'s_textareainput', 'label': 'Lang antwoord', 'component': <STextAreaInput />},
        {'id':'s_select', 'element':'s_select', 'label': 'Selectie', 'component': <SSelectInput />},
        {'id':'s_smileyinput', 'element':'s_smileyinput', 'label': 'Waardering ☺', 'component': <SSmileyInput />},
        // {'id':'s_numberinput', 'element':'s_numberinput', 'label': 'Waardering', 'component': <SNumberInput />},
        {'id':'s_photoinput', 'element':'s_photoinput', 'label': 'Foto', 'component': <SPhotoInput />},

        {'id':'c_textinput', 'element':'c_textinput', 'label': 'Kort antwoord', 'component': <CTextInput />},
        {'id':'c_textareainput', 'element':'c_textareainput', 'label': 'Lang antwoord', 'component': <CTextAreaInput />},
        {'id':'c_numberinput', 'element':'c_numberinput', 'label': 'Nummer', 'component': <CNumberInput />},
        {'id':'c_calculatorinput', 'element':'c_calculatorinput', 'label': 'Formule', 'component': <CCalculatorInput />},
        {'id':'c_timeinput', 'element':'c_timeinput', 'label': 'Tijd', 'component': <CTimeInput />},
        {'id':'c_title', 'element':'c_title', 'label': 'Toelichting', 'component': <CTitleInput />},
        {'id':'c_dateinput', 'element':'c_dateinput', 'label': 'Datum', 'component': <CDateInput />},
        //{'id':'c_signatureinput', 'element':'c_signatureinput', 'label': 'Handtekening', 'component': <CSignatureInput />},
        {'id':'c_choice', 'element':'c_choice', 'label': 'Keuze', 'component': <CChoiceInput />},
        {'id':'c_contactinput', 'element':'c_contactinput', 'label': 'Contactpersoon', 'component': <CContactInput />},
        {'id':'c_select', 'element':'c_select', 'label': 'Selectie', 'component': <CSelectInput />},
        {'id':'c_addressinput', 'element':'c_addressinput', 'label': 'Adres', 'component': <CAddressInput />},
        {'id':'c_photoinput', 'element':'c_photoinput', 'label': 'Foto', 'component': <CPhotoInput />},

        {'id':'f_title', 'element':'f_title', 'label': 'Toelichting', 'component': <FTitleInput />},
        {'id':'f_textinput', 'element':'f_textinput', 'label': 'Kort antwoord', 'component': <FTextInput />},
        {'id':'f_textareainput', 'element':'f_textareainput', 'label': 'Lang antwoord', 'component': <FTextAreaInput />},
        {'id':'f_numberinput', 'element':'f_numberinput', 'label': 'Nummer', 'component': <FNumberInput />},
        {'id':'f_timeinput', 'element':'f_timeinput', 'label': 'Tijd', 'component': <FTimeInput />},
        {'id':'f_select', 'element':'f_select', 'label': 'Selectie', 'component': <FSelectInput />},
        {'id':'f_dateinput', 'element':'f_dateinput', 'label': 'Datum', 'component': <FDateInput />},
        {'id':'f_photoinput', 'element':'f_photoinput', 'label': 'Foto', 'component': <FPhotoInput />},
        {'id':'f_contactinput', 'element':'f_contactinput', 'label': 'Contactpersoon', 'component': <FContactInput />},
        {'id':'f_drawinginput', 'element':'f_drawinginput', 'label': 'Tekening', 'component': <FDrawingInput />},

        {'id':'d_title', 'element':'d_title', 'label': 'Toelichting', 'component': <DTitleInput />},
        {'id':'d_textinput', 'element':'d_textinput', 'label': 'Kort antwoord', 'component': <DTextInput />},
        {'id':'d_textareainput', 'element':'d_textareainput', 'label': 'Lang antwoord', 'component': <DTextAreaInput />},
        {'id':'d_numberinput', 'element':'d_numberinput', 'label': 'Nummer', 'component': <DNumberInput />},
        {'id':'d_timeinput', 'element':'d_timeinput', 'label': 'Tijd', 'component': <DTimeInput />},
        {'id':'d_select', 'element':'d_select', 'label': 'Selectie', 'component': <DSelectInput />},
        {'id':'d_dateinput', 'element':'d_dateinput', 'label': 'Datum', 'component': <DDateInput />},
        {'id':'d_photoinput', 'element':'d_photoinput', 'label': 'Foto', 'component': <DPhotoInput />},
        {'id':'d_contactinput', 'element':'d_contactinput', 'label': 'Contactpersoon', 'component': <DContactInput />},
        {'id':'d_drawinginput', 'element':'d_drawinginput', 'label': 'Tekening', 'component': <DDrawingInput />},
        {'id':'d_deliverypointsinput', 'element':'d_deliverypointsinput', 'label': 'Opleverpunten', 'component': <DDeliveryPointsInput />},
      ],
      formSections: [],
      openKeys:[],
    };
  }

  sanitizeString = (string) => {

      if(typeof string === 'string')
      {
          string = string.trim();
      }

      return string;
  }

  toObject = (arr) => {

    if(typeof arr === 'array')
    {
        var obj = arr.reduce(function(acc, cur, i) {
            acc[i] = cur;
            return acc;
        }, {});
    }

    return obj;
  }

  parse = (data) => {

    var this_obj = this;

    if(data && data.length > 0) {

      var sanitized_data = data;

      if(sanitized_data)
      {
          sanitized_data.map((section) => {

              section.elements.map((element) => {

                  if(element.data)
                  {
                      Object.keys(element.data).forEach(function(key, index) {

                          element.data[key] = this_obj.sanitizeString(element.data[key]);
                      });
                  }
              });
          });
      }

      this.setState({ formSections: sanitized_data });
    }

    // disable some elements
    data && data.map((section) => {

        section.elements.map((element) => {

            if(element && element.element)
            {
                // signatureinput is allowed multiple times, the *_signatureinput not
                if(element.element.includes('sb_signatureinput'))
                {
                    this.nr_of_signature_fields += 1;

                    this.setState({nr_of_signature_fields: this.nr_of_signature_fields});
                }
                if(element.element.includes('agreeinput'))
                {
                    this.nr_of_agree_fields += 1;

                    this.setState({nr_of_agree_fields: this.nr_of_agree_fields});
                }
                if(element.element.includes('d_deliverypointsinput'))
                {
                    this.nr_of_deliverypoints_fields += 1;

                    this.setState({nr_of_deliverypoints_fields: this.nr_of_deliverypoints_fields});
                }
            }
        });
    });
  }

  reInit = (data) => {

      this.nr_of_signature_fields = 0;
      this.nr_of_agree_fields = 0;
      this.nr_of_deliverypoints_fields = 0;

      this.setState({nr_of_signature_fields: 0});
      this.setState({nr_of_agree_fields: 0});
      this.setState({nr_of_deliverypoints_fields: 0});

      // disable some elements
      data.map((section) => {
          section.elements.map((element) => {

              if(element && element.element)
              {
                  if(element.element.includes('sb_signatureinput'))
                  {
                      this.nr_of_signature_fields += 1;

                      this.setState({nr_of_signature_fields: this.nr_of_signature_fields});
                  }
                  if(element.element.includes('agreeinput'))
                  {
                      this.nr_of_agree_fields += 1;

                      this.setState({nr_of_agree_fields: this.nr_of_agree_fields});
                  }
                  if(element.element.includes('d_deliverypointsinput'))
                  {
                      this.nr_of_deliverypoints_fields += 1;

                      this.setState({nr_of_deliverypoints_fields: this.nr_of_deliverypoints_fields});
                  }
              }
          });
      });
  }

  onDragEnd = result => {

    if(this.props.disabled == true) {
      return;
    }

    const {source, destination} = result;

    if (!destination) {
      return;
    }

    // Add new section
    if(source.droppableId == 'sections') {
      const formSections = reorder([
        {'id': uuidv1(), 'label': 'Nieuwe sectie', elements: []},
        ...this.state.formSections
      ], result.source.index, result.destination.index)

      this.setState({ formSections }, () => {
        this.props.onChangeForm(this.state.formSections);
      });
    }

    // Reorder sections
    if(source.droppableId == 'section_area' && source.droppableId == destination.droppableId) {
      const formSections = reorder(this.state.formSections, result.source.index, result.destination.index)
      this.setState({ formSections } , () => {
        this.props.onChangeForm(this.state.formSections);
      });
    }

    // Add element to section
    if(source.droppableId == 'elements') {
      var section = _.findWhere(this.state.formSections, {id: destination.droppableId});
      var formSections = this.state.formSections.map((element) => {
        if(element.id == section.id) {

          section.elements = reorder([{'id': uuidv1(), 'element': result.draggableId}, ...section.elements], 0, result.destination.index);
          //section.elements = [{'id': uuidv1(), 'element': result.draggableId}, ...section.elements];

          if(result.draggableId.includes('sb_signatureinput'))
          // if(result.draggableId.includes('signatureinput'))
          {
              this.nr_of_signature_fields += 1;

              this.setState({nr_of_signature_fields: this.nr_of_signature_fields});
          }
          if(result.draggableId.includes('agreeinput'))
          {
              this.nr_of_agree_fields += 1;

              this.setState({nr_of_agree_fields: this.nr_of_agree_fields});
          }
          if(result.draggableId.includes('d_deliverypointsinput'))
          {
              this.nr_of_deliverypoints_fields += 1;

              this.setState({nr_of_deliverypoints_fields: this.nr_of_deliverypoints_fields});
          }

          return section;
        }
        return element;
      });

      this.setState({ formSections }, () => {
        this.props.onChangeForm(this.state.formSections);
      });

    }

    // reorder elements
    if(result.type == 'element_order' && source.droppableId == destination.droppableId) {

      var section = _.findWhere(this.state.formSections, {id: destination.droppableId});

      var formSections = this.state.formSections.map((element) => {
        if(element.id == section.id) {
          section.elements = reorder(section.elements, result.source.index, result.destination.index);

          return section;
        }
        return element;
      })

      this.setState({ formSections }, () => {
        this.props.onChangeForm(this.state.formSections);
      });

    }

    // Move elements
    if(result.type == 'element_order' && source.droppableId != 'elements' && source.droppableId != destination.droppableId) {

      var sectionFrom = _.findWhere(this.state.formSections, {id: source.droppableId});
      var sectionTo   = _.findWhere(this.state.formSections, {id: destination.droppableId});

      var elementToMove = sectionFrom.elements[source.index];

      // add to new section
      if(elementToMove) {
        var formSections = this.state.formSections.map((element) => {
          if(element.id == sectionTo.id) {
            sectionTo.elements = reorder([elementToMove, ...sectionTo.elements], 0, result.destination.index);
            return sectionTo;
          }

          // clean old section
          if(element.id == sectionFrom.id) {
            sectionFrom.elements = sectionFrom.elements.filter((element) => element.id != elementToMove.id)
          }

          return element;
        })

        this.setState({ formSections }, () => {
          this.props.onChangeForm(this.state.formSections);
        });
      }
    }

  };

  removeSection = (id) => {
    this.setState({formSections: this.state.formSections.filter((element) => element.id != id)}, () => {
      this.props.onChangeForm(this.state.formSections);

      this.reInit(this.state.formSections);
    });
  }

  removeElement = (id) => {
    var formSections = this.state.formSections.map((element) => {
      element.elements = element.elements.filter((an_element) => an_element.id != id);

      element.elements.map((an_element) => {

          if(an_element.id == id)
          {
              if(an_element.element.includes('sb_signatureinput'))
              {
                  this.nr_of_signature_fields -= 1;

                  this.setState({nr_of_signature_fields: this.nr_of_signature_fields});
              }
              if(an_element.element.includes('agreeinput'))
              {
                  this.nr_of_agree_fields -= 1;

                  this.setState({nr_of_agree_fields: this.nr_of_agree_fields});
              }
              if(an_element.element.includes('d_deliverypointsinput'))
              {
                  this.nr_of_deliverypoints_fields -= 1;

                  this.setState({nr_of_deliverypoints_fields: this.nr_of_deliverypoints_fields});
              }
          }
      });

      return element;
    });

    this.setState({ formSections }, () => {
      this.props.onChangeForm(this.state.formSections);

      this.reInit(formSections);
    });
  }

  addSection = () => {
    this.setState({ formSections: [...this.state.formSections, {'id': uuidv1(), 'label': 'Nieuwe sectie', elements: []}] }, () => {
      this.props.onChangeForm(this.state.formSections);
    });
  }

  duplicateSection = (id) => {
      var newFormSections = [];

      this.state.formSections.map((section, key) => {

          newFormSections.push(section);

          if(section.id == id)
          {
              var newSection = JSON.parse(JSON.stringify(section));

              var newsection_elements = [];

              newSection.id = uuidv1(); // new id
              newSection.label = newSection.label + ' (kopie)'; // change label

              // generate new id's for items in section
              newSection.elements.map((element, index) => {

                  if(
                      (element.element.includes('sb_signatureinput') && this.state.nr_of_signature_fields >= 1)
                      ||
                      (element.element.includes('agreeinput') && this.state.nr_of_agree_fields >= 1)
                      ||
                      (element.element.includes('d_deliverypointsinput') && this.state.nr_of_deliverypoints_fields >= 1)
                  )
                  {
                  }
                  else
                  {
                      element.id = uuidv1();

                      newsection_elements.push(element);
                  }
              });

              newSection.elements = newsection_elements;

              newFormSections.push(newSection);
          }
      });

      this.setState({ formSections: newFormSections }, () => {
          this.props.onChangeForm(this.state.formSections);

          this.reInit(this.state.formSections);
      });
  }

  duplicateElement = (id ) => {
    this.state.formSections.map((section) => {
      if(section.elements) {

        //var sectionElements = JSON.parse(JSON.stringify(section.elements));

        var newSectionElements = [];
        var elementFound = false;

        section.elements.map((element, key) => {

          newSectionElements.push(element);

          if(element.id == id) {
            elementFound = true;

            var newElement = JSON.parse(JSON.stringify(element));

            newElement.id = uuidv1(); // new id

            newSectionElements.push(newElement);
          }
        });

        if(elementFound)
        {
            section.elements = newSectionElements;
        }
      }
    });

    this.props.onChangeForm(this.state.formSections);
  }

  addDataToElement = (id, data, silent = false) => {
    this.state.formSections.map((section) => {
      if(section.elements) {
        section.elements.map((element) => {
          if(element.id == id) {
            element.data = data;
          }
        });
      }
    });

    if(!silent)
    {
        this.props.onChangeForm(this.state.formSections);
    }
  }

  updateSectionLabel = (id, label) => {
    this.state.formSections.map((section) => {
      // console.log(section);
      if(section.id == id) {
        section.label = label;
      }
      // if(section.elements) {
      //   section.elements.map((element) => {
      //     if(element.id == id) {
      //       element.data = data;
      //     }
      //   });
      // }
    });

    this.props.onChangeForm(this.state.formSections);
  }

  updateSectionColor = (id, color) => {

    this.state.formSections.map((section) => {

      if(section.id == id) {

        section.color = color;
      }
    });

    this.props.onChangeForm(this.state.formSections);
  }

  updateCloneSection = (id, clone_section) => {

    this.state.formSections.map((section) => {

      if(section.id == id) {

        section.clone_section = clone_section;
      }
    });

    this.props.onChangeForm(this.state.formSections);
  }

  getFormData()
  {
      return this.state.formSections;
  }

  render () {

    return (
      <div>
        <DragDropContext disabled={this.props.disabled} onDragEnd={this.onDragEnd}>

          {/* sections drop area */}
          <Row gutter={24} className="mbm">
            <Col span={16}>

              <Droppable disabled={this.props.disabled} droppableId="section_area" type='section_order'>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.state.formSections.length > 0 && this.state.formSections.map((element, index) => (
                  <Draggable isDragDisabled={this.props.disabled ? true : false} key={element.id} draggableId={element.id} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                    <SectionItem
                        disabled={this.props.disabled}
                        style={{backgroundColor: 'green'}}
                        title={element.label}
                        element={element}
                        provided={provided}
                        sections={this.props.sections}
                        cloneSections={this.props.cloneSections}
                        removeSection={(id) => this.removeSection(id)}
                        duplicateSection={(id) => this.duplicateSection(id)}
                        onSaveTitle={(id, label) => this.updateSectionLabel(id, label)}
                        onSaveCloneSection={(id, clone_section) => this.updateCloneSection(id, clone_section)}
                        onSaveColor={(id, color) => this.updateSectionColor(id, color)}
                        {...this.props}
                        >

                    <Droppable disabled={this.props.disabled} droppableId={element.id} type='element_order'>
                    {(provided, snapshot) => (
                      <div style={{ position: 'relative', minHeight: 68 }} ref={provided.innerRef} {...provided.droppableProps}>

                      {element.elements.length > 0
                      ? element.elements.map((element, index2) => {
                         return !element ? null : (

                        <Draggable isDragDisabled={this.props.disabled ? true : false} key={element.id} draggableId={element.id} index={index2}>
                        {(provided, snapshot) => (
                          <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getSectionStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                            {element.element == 'title'          && (<TitleInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'textinput'      && (<TextInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'textareainput'  && (<TextAreaInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'numberinput'    && (<NumberInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'timeinput'      && (<TimeInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'select'         && (<SelectInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'dateinput'      && (<DateInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'signatureinput' && (<SignatureInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'contactinput'   && (<ContactInput label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'photoinput'     && (<PhotoInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'drawinginput'   && (<DrawingInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'image'          && (<Image formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'smileyinput'    && (<SmileyInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}

                            {element.element == 'sb_title'          && (<SBTitleInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_textinput'      && (<SBTextInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_textareainput'  && (<SBTextAreaInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_numberinput'    && (<SBNumberInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_timeinput'      && (<SBTimeInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_select'         && (<SBSelectInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_dateinput'      && (<SBDateInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_agreeinput'     && (<SBAgreeInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_signatureinput' && (<SBSignatureInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'sb_contactinput'   && (<SBContactInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}

                            {element.element == 's_title'         && (<STitleInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 's_textinput'     && (<STextInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 's_textareainput' && (<STextAreaInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 's_select'        && (<SSelectInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 's_smileyinput'   && (<SSmileyInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 's_photoinput'    && (<SPhotoInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}

                            {element.element == 'c_title'           && (<CTitleInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_textinput'       && (<CTextInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_textareainput'   && (<CTextAreaInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_numberinput'     && (<CNumberInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_calculatorinput' && (<CCalculatorInput disablePrices={this.props.disablePrices} formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_timeinput'       && (<CTimeInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_choice'          && (<CChoiceInput disablePrices={this.props.disablePrices} formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_dateinput'       && (<CDateInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_contactinput'    && (<CContactInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_select'          && (<CSelectInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_addressinput'    && (<CAddressInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'c_photoinput'      && (<CPhotoInput formId={this.props.formId} label={element.label} id={element.id} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}

                            {element.element == 'f_title'         && (<FTitleInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_textinput'     && (<FTextInput label={element.label} id={element.id} disabled={this.props.disabled} addressFields={this.props.addressFields} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_textareainput' && (<FTextAreaInput label={element.label} id={element.id} disabled={this.props.disabled} addressFields={this.props.addressFields} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_numberinput'   && (<FNumberInput label={element.label} id={element.id} disabled={this.props.disabled} addressFields={this.props.addressFields} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_timeinput'     && (<FTimeInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_select'        && (<FSelectInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_dateinput'     && (<FDateInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_photoinput'    && (<FPhotoInput formId={this.props.formId} label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_contactinput'  && (<FContactInput formId={this.props.formId} label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'f_drawinginput'  && (<FDrawingInput formId={this.props.formId} label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}

                            {element.element == 'd_title'         && (<DTitleInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_textinput'     && (<DTextInput label={element.label} id={element.id} disabled={this.props.disabled} addressFields={this.props.addressFields} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_textareainput' && (<DTextAreaInput label={element.label} id={element.id} disabled={this.props.disabled} addressFields={this.props.addressFields} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_numberinput'   && (<DNumberInput label={element.label} id={element.id} disabled={this.props.disabled} addressFields={this.props.addressFields} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_timeinput'     && (<DTimeInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_select'        && (<DSelectInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_dateinput'     && (<DDateInput label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_photoinput'    && (<DPhotoInput formId={this.props.formId} label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_contactinput'  && (<DContactInput formId={this.props.formId} label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_drawinginput'  && (<DDrawingInput formId={this.props.formId} label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}
                            {element.element == 'd_deliverypointsinput'  && (<DDeliveryPointsInput formId={this.props.formId} label={element.label} id={element.id} disabled={this.props.disabled} removeElement={() => this.removeElement(element.id)} duplicateElement={() => { this.duplicateElement(element.id)}} modus={'edit'} parseData={element.data} onSaveData={(id, data, silent = false) => this.addDataToElement(id, data, silent)} />)}


                          </div>
                        )}
                        </Draggable>

                      )}
                    ) : (
                      <div style={{position: 'absolute', height: 48, border: '1px dashed #cecece', color: '#cecece', width: '100%', borderRadius: 6, backgroundColor: '#ffffff', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>Sleep hier een formulier element</div>
                    )}

                      {provided.placeholder}

                      </div>
                    )}
                    </Droppable>
                    </SectionItem>

                    </div>
                  )}
                  </Draggable>
                ))}
                {provided.placeholder}
                </div>
              )}
              </Droppable>
              <Row>
                <Col style={{display: (this.props.sections != 1 ? 'flex' : 'none'), }}>
                  <Button disabled={this.props.disabled} icon={<PlusOutlined />} type='primary' onClick={() => this.addSection()}>Sectie toevoegen</Button>
                </Col>
              </Row>
            </Col>


          {/* Add sections */}
          <Col span={8}>
            {/*<Card size="small" title={(<div><Icon style={{marginRight: 5}} type="unordered-list" /> Secties</div>)} style={{marginBottom: 24}}>
              <Form.Item>
                <Input.Search placeholder={'Zoeken naar secties'} style={{width:'100%'}} />
              </Form.Item>

              <Droppable droppableId="sections" type='section_order' isDropDisabled={true}>
              {(provided, snapshot) => (
                <div
                ref={provided.innerRef}>
                {this.state.sections.map((item, index) => (
                  <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}>
                  {(provided, snapshot) => (
                    <div
                      style={{width: 200}}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <span>
                        {item.content}
                      </span>
                    </div>
                  )}
                  </Draggable>
                ))}
                {provided.placeholder}
                </div>
              )}
              </Droppable>
            </Card>*/}

            {/* Add Form elements */}
            <Affix offsetTop={76}>
            <Card size="small" title={(<div><FormOutlined style={{marginRight: 5}} />Formulier elementen</div>)}>

              <Droppable droppableId="elements" type='element_order' isDropDisabled={true}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                <List
                  grid={{
                    gutter: 16,
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2,
                  }}
                  dataSource={this.state.elements.filter((item) => this.props.enabledElements.includes(item.element))}
                  renderItem={(item, index) => (
                    <List.Item>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={this.props.disabled || (item.element.includes('agreeinput') && this.state.nr_of_agree_fields >= 1) || (item.element.includes('signatureinput') && this.state.nr_of_signature_fields >= 1) ? true : false}
                          >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getElementStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}>
                            <span>

                            <div style={{
                                backgroundColor: '#FAFAFA',
                                display: 'flex',
                                visibility:
                                    (item.element.includes('agreeinput') && this.state.nr_of_agree_fields >= 1)
                                    ||
                                    (item.element.includes('signatureinput') && this.state.nr_of_signature_fields >= 1)
                                    ||
                                    (item.element.includes('d_deliverypointsinput') && this.state.nr_of_deliverypoints_fields >= 1)
                                    ?
                                    'hidden' : 'visible',
                                flex: 1,
                                padding: 6,
                                border: '1px solid #D9D9D9',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 6
                              }}>
                              {item.label}
                            </div>

                          </span>
                          </div>
                        )}
                        </Draggable>
                    </List.Item>
                  )}
                />
                {provided.placeholder}
                </div>
              )}
              </Droppable>
            </Card>
            </Affix>
          </Col>
          </Row>

        </ DragDropContext>
      </div>
    );
  }
}

export default FormBuilder;
